import styled from "styled-components";

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding: 45px 30px 0px;
`;

export const HeaderTittle = styled.h1`
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgba(16, 24, 40, 1);
`;

export const NavOptions = styled.ul`
  display: flex;
  gap: 11px;
  overflow-x: auto;
`;

export const NavOption = styled.li`
  list-style: none;

  padding: 9px;
  background-color: ${(props) => (props.isSelected ? "rgba(220, 232, 255, 1)" : "none")};
  border-radius: ${(props) => (props.isSelected ? "8px" : "none")};
  color: ${(props) => (props.isSelected ? "rgba(26, 26, 26, 1)" : "rgba(102, 112, 133, 1)")};
`;

export const ButtonOption = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: ${(props) => (props.isSelected ? "rgba(26, 26, 26, 1)" : "rgba(102, 112, 133, 1)")};
  cursor: pointer;
`;

export const ListServices = styled.div`
  display: grid;
  gap: 55px;
  grid-template-columns: repeat(auto-fill, minmax(490px, 1fr));
  grid-auto-rows: auto;
  max-width: 100%;
`;

export const ViewAllContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    margin-right: 20px;

    @media (max-width: 1024px) {
        justify-content: center;
        margin-right: 0;
    }
`;

export const ErrorResultProducts = styled.p`
  font-weight: 600;
  line-height: 24px;
  color: rgba(16, 24, 40, 1);
`;