import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 200px 10% 0;
  color: white;
  max-width: 1250px;
  margin: 0 auto;
  h1 {
    font-size: 55px;
    font-weight: 700;
    line-height: 65px;
    margin: 0;
  }
  h3 {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
  }
  button {
    all:  unset;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    padding: 120px 20px 0;
    gap: 55px;
    margin-bottom: 50px;
    p {
      margin: 0;
      font-size: 16px;
    }
    button {
      display: flex;
      width: 100%;
    }
  }
`;

export const Header = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  text-align: center;
  @media (max-width: 768px) {
    text-align: left;
    align-items: flex-start;
    h1 {
      font-size: 39px;
      line-height: 46px;
      text-align: left;
    }
    p {
      text-align: left;
    }
  }
`;

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  @media (max-width: 768px) {
    gap: 20px;
  }
`;

export const TeamMenu = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

export const TeamMenuButton = styled.div`
  display: flex;
  align-items: center;
  gap: 33px;
  border: 1px solid #2240d9;
  background-color: ${({ active }) => (active ? "#2240d9" : "transparent")};
  border-radius: 8px;
  padding: 13px 20px;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const TeamMember = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 55px;
    img {
      max-width: 250px;
    }
  }
`;

export const TeamMemberDescription = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  @media (max-width: 768px) {
    max-width: 100%;
    h1 {
      font-size: 39px;
      font-weight: 700;
      line-height: 47px;
      letter-spacing: 0em;
      text-align: left;
      padding: 10px 0 0;
      margin-top: 20px;
    }
    p {
      font-family: DM Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      padding: 10px 0 20px;
    }
  }
`;
