import styled from "styled-components"
import { useEffect } from "react"
import { device } from "../style/device"
import { NavLink as Link } from "react-router-dom"
import { isMobile } from "react-device-detect"
import { Vacancies } from '../vacancies';
import { useTranslation } from "react-i18next";

const Body = styled.div`
padding: 120px 30px 50px;
display: flex;
flex-direction: column;

.green-title {
    color: #00AA58;
    font-size: 20px;
  }

@media ${device.tablet} {
  padding: 130px 80px 0px;
  flex-direction: row;
  .left-decoration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 185px;
  }
  .two-columns {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-bottom: 30px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .green-title {
    font-size: 18px;
  }
  .one-column {
    width: 50%;
    max-width: 400px;
    padding: 0px 7% 50px 0px;
  }
}
`
const VacanciesLs = styled.div`
padding-bottom: 30px;
.location {
  display: flex;
  justify-content: baseline;
}
.line {
  width: 329px;
  height: 1px;
  background-color: rgba(255,255,255,0.3);
}
a {
  font-size: 25px;
  &:hover {
    color: #FFA800;
    text-decoration: underline;
  }
}
img {
  padding-right: 5px;
}
p {
  font-size: 14px;
  font-weight: 700;
  color: #9A9A9A;
  margin: 5px 0;
}
`

export default function Hiring() {
  const { t } = useTranslation(["hiring"]);
  function groupByType(arr) {
    const newObj = arr.reduce(function (acc, currentValue) {
      if (!acc[currentValue['type']]) {
        acc[currentValue['type']] = [];
      }
      acc[currentValue['type']].push(currentValue);
      return acc;
    }, {});
    return newObj;
  }

  const vacancies = groupByType(Vacancies)

  useEffect(() => {
    console.log(Object.entries(vacancies))
  }, [vacancies])

  return (
    <>
      <Body>
        { isMobile ? <img src="/v1/puzzle.svg" alt="Rompecabezas" style={{height: '154px', paddingBottom: '30px'}} /> : null }
        <div className='left-decoration'>
          { isMobile ? <h2>{t("header.titleMob")}</h2> : <h2>{t("header.title1")}<br />{t("header.title2")}</h2> }
          <h5>{t("header.subtitle")}</h5>
          { !isMobile ? <img src="/v1/puzzle.svg" alt="Rompecabezas" /> : null }
        </div>
        <div style={{width: '100%'}}>
          { isMobile ? <h3>{t("form.titleMob")}</h3> : <h3>{t("form.title")}</h3> }

          <div className='two-columns'>
            {
              Object.entries(vacancies).map(arr => {
                return (
                  <div className='one-column'>
                    <p className='green-title'>{arr[0]}</p>
                    {arr[1].map(vacancie => {
                        return (
                          <VacanciesLs>
                            <Link to={`/v1/hiring/${vacancie.url}`} >{vacancie.name}</Link>
                            <div className='location'>
                              <img src="/v1/location-gray.svg" alt="pin" />
                              <p>{vacancie.location}</p>
                            </div>
                            <div className='line'></div>
                          </VacanciesLs>
                        )
                      })
                    }
                  </div>  
                )
              })
            }
          </div>

        </div>
      </Body>
    </>
  )
}

