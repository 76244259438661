import styled from 'styled-components';

export const MainLogo = styled.img`
    max-width: 136px;
    max-height: 28.7px;
    margin-left: 20px;
    margin-top: 20px;
`;

export const NavBarConainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    width: 100%;
    height: 72px;
    border-bottom: 1px solid var(--Colors-Border-border-secondary, rgba(234, 236, 240, 1));
`;

export const ContentNavBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const NavList = styled.ul`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const NavLi = styled.li`
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
`;

export const ImageLogoMain = styled.img`

`;

export const ConfigIcon = styled.img`

`;

export const NotificationsIcon = styled.img`

`;

export const ImageUser = styled.div`
    width: 40px;
    height: 40px;
    gap: 0px;
    border-radius: 50%;
    border: 0.75px 0px 0px 0px;
    opacity: 0px;

    background-color: rgba(234, 236, 240, 1);
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;