import styled from 'styled-components';
import NavbarIcon from '../NavbarIcon';
import SVGComponent from '../SVGComponent';
import useVersionExtractor from '../../hooks/useExtractVersionFromPath';

const SidebarNav = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  padding-top: var(--spacing-4xl, 32px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 80px;
  align-self: stretch;
  border-right: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  background-color: #0e1d39;
`;

const SVGWrapper = styled.div`
  width: 24px;
  height: 24px;
  padding: 6px;
  opacity: 0.5;
  cursor: pointer;
`;

const Navbar = ({ setShowProyectHistory = () => {} }) => {
  const version = useVersionExtractor();
  return (
    <>
      <SidebarNav>
        <SVGWrapper onClick={() => setShowProyectHistory((prev) => !prev)}>
          <SVGComponent src="/v1/swarm/showPropsIcon.svg" />
        </SVGWrapper>
        <NavbarIcon
          src="/v1/swarm/chat-icon.svg"
          to={`/v1/${version}/chat`}
          name="chat"
        />
        <NavbarIcon
          src="/v1/swarm/settings-icon.svg"
          to={`/v1/${version}/settings`}
          name="settings"
        />
        <NavbarIcon
          src="/v1/swarm/logout-icon.svg"
          to={`/v1/${version}/logout`}
          name="logout"
        />
      </SidebarNav>
    </>
  );
};

export default Navbar;
