import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    body {
      margin: 0; line-height: normal;
    }
:root {

/* fonts */
--common-body: 'DM Sans';

/* font sizes */
--common-body-size: 16px;
--font-size-xs: 12px;
--font-size-6xl: 25px;
--common-small-body-size: 14px;
--common-title-size: 20px;
--desktop-h3-size: 35px;
--desktop-h1-size: 55px;
--font-size-21xl: 40px;
--font-size-lg: 18px;
--font-size-6xl-6: 25.6px;

/* Colors */
--white: #fff;
--secondary-blue: #2240d9;
--navy: #0e1d39;
--secondary-green: #00aa58;
--color-orange: #ffa800;

/* Gaps */
--gap-3xs: 10px;
--gap-29xl: 48px;
--gap-31xl: 50px;
--gap-11xl: 30px;
--gap-13xl: 32px;
--gap-8xs: 5px;
--gap-21xl: 40px;
--gap-7xs: 6px;
--gap-mini: 15px;
--gap-base: 16px;

/* Paddings */
--padding-45xl: 64px;
--padding-101xl: 120px;
--padding-13xl: 32px;
--padding-18xl: 37px;
--padding-81xl: 100px;
--padding-22xl: 41px;
--padding-3xs: 10px;
--padding-xl: 20px;
--padding-5xs: 8px;
--padding-base: 16px;
--padding-11xl: 30px;
--padding-31xl: 50px;

/* Border radiuses */
--br-5xs: 8px;

}
`;
