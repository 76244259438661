import { useEffect } from 'react';
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isMobile } from "react-device-detect";

const SvgBullet02 = () => {

  useEffect(() => {
    if (!isMobile) {
      setTimeout(() => {
      //SVG Animacion Bullet 02
      const animatedFigures = gsap.utils.toArray('.geo-figure-01');
      animatedFigures.forEach(animatedFigure => {
        gsap.from(animatedFigure, { 
          duration: 1.5,
          autoAlpha: 0,
          x: -80,
          ease: "back.out(1.5)",
          scrollTrigger: {
            trigger: animatedFigure,
            scrub: 1,
            end: "bottom +40%"
          }
        })
      });
      }, 700);
    }

    return () => {
      ScrollTrigger.getAll().forEach(t => t.kill());
    }
  }, [])

  return (
    <div>
      <svg className="geo-arrow" width="290" height="290" viewBox="0 0 276 276" fill="none" style={{ overflow: "visible" }}>
      <circle className="geo-figure-01" cx="50.5644" cy="224.862" r="50.5644" fill="#00AA58"/>
      <rect className="geo-figure-01" x="134.331" y="79.49" width="91.6479" height="91.6479" transform="rotate(45 134.331 79.49)" fill="#2240D9"/>
      <rect className="geo-figure-01" x="169.865" y="20.2349" width="85.3274" height="85.3274" fill="#EF0000"/>
      <path d="M50.8457 224.801L273.645 2.00157" stroke="white" strokeWidth="3"/>
      <path d="M72.4424 2L273.639 2L273.639 203.197" stroke="white" strokeWidth="3"/>
      </svg>
    </div>
  )
}

export default SvgBullet02
