/* eslint-disable */
import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import Googlecloud from '../assets/loop/googlecloud.png';
import Aws from '../assets/loop/aws.png';
import Linkedin from '../assets/loop/linkedinads.png';
import Salesforce from '../assets/loop/salesforce.png';
import Figma from '../assets/loop/figma.png';
import Flutter from '../assets/loop/flutter.png';
import Googleads from '../assets/loop/googleads.png';
import Openai from '../assets/loop/openai.png';

const Loop = () => {
  const LoopArr = [
    {
      img: Googlecloud,
      alt: 'Google Cloud',
    },
    {
      img: Aws,
      alt: 'AWS',
    },
    {
      img: Linkedin,
      alt: 'Linkedin Ads',
    },
    {
      img: Salesforce,
      alt: 'Salesforce',
    },
    {
      img: Figma,
      alt: 'Figma',
    },
    {
      img: Flutter,
      alt: 'Flutter',
    },
    {
      img: Googleads,
      alt: 'Google Ads',
    },
    {
      img: Openai,
      alt: 'Open AI',
    },
  ];

  return (
    <>
      <Slider>
        <SlideTrack>
          {LoopArr.map((item) => (
            <>
              <Slide>
                <img src={item.img} alt={item.alt} />
              </Slide>
            </>
          ))}
          {LoopArr.map((item) => (
            <>
              <Slide>
                <img src={item.img} alt={item.alt} />
              </Slide>
            </>
          ))}
          {LoopArr.map((item) => (
            <>
              <Slide>
                <img src={item.img} alt={item.alt} />
              </Slide>
            </>
          ))}
          {LoopArr.map((item) => (
            <>
              <Slide>
                <img src={item.img} alt={item.alt} />
              </Slide>
            </>
          ))}
          {LoopArr.map((item) => (
            <>
              <Slide>
                <img src={item.img} alt={item.alt} />
              </Slide>
            </>
          ))}
          {LoopArr.map((item) => (
            <>
              <Slide>
                <img src={item.img} alt={item.alt} />
              </Slide>
            </>
          ))}
          {LoopArr.map((item) => (
            <>
              <Slide>
                <img src={item.img} alt={item.alt} />
              </Slide>
            </>
          ))}
          {LoopArr.map((item) => (
            <>
              <Slide>
                <img src={item.img} alt={item.alt} />
              </Slide>
            </>
          ))}
        </SlideTrack>
      </Slider>
    </>
  );
};

export default Loop;

// Keyframes for the scroll animation
const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-250px * 7)); }
`;

// Animation speed
const animationSpeed = '80s';

const Slider = styled.div`
  padding: 10px 0;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
`;

const SlideTrack = styled.div`
  animation: ${scroll} ${animationSpeed} linear infinite;
  display: flex;
  width: calc(250px * 14);
  gap: 68px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1023px) {
    animation: ${scroll} 40s linear infinite;
  }
`;

const Slide = styled.div``;
