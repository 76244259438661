/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Checkbox, TextField } from '@material-ui/core';
import emailjs from 'emailjs-com';
import styled, { css } from 'styled-components';
import useGetLocationIp from '../hooks/LocationIp';
import { getQueryVariable } from '../helpers/ReadUrl';
import PageWrapperBase from './NewRocket/components/PageWrapperBase';
import { useApolloActions } from '../hooks/useApolloActionsContact';
import { sendEmailWithTemplate } from '../utils/sendEmail';
import { submitGTM } from '../utils/submitGTM';

function analytics() {
  const tagManagerArgs = {
    gtmId: 'GTM-TJGF6MN',
    dataLayer: {
      event: window.location.pathname,
      pageName: 'The Rocket Code - Let’s work together!',
    },
  };
  TagManager.initialize(tagManagerArgs);
}

const Card = ({ color, img, title, children }) => {
  return (
    <Flex
      border={`1px solid ${color || '#FFA800'}`}
      width='100%'
      padding='32px 16px'
      radius='8px'
      minHeight='232px'
      gap='16px'
      direction='column'
      align='flex-start'
      justify='flex-start'
    >
      <Flex direction='row' gap='16px' align='center' justify='flex-start'>
        <img src={img} />
        <Text weight='700' size='20px'>
          {title}
        </Text>
      </Flex>
      <Text size='16px'>{children}</Text>
    </Flex>
  );
};

const CatalogAiForm = () => {
  let history = useHistory();
  useEffect(() => {
    analytics();
  }, []);

  useEffect(() => {
    getQueryVariable('utm_source');
    getQueryVariable('utm_medium');
    getQueryVariable('utm_campaign');
  }, []);

  const [check, setCheck] = useState(false);
  const [btndisabled, Setbtndisabled] = useState(true);
  const [form, setForm] = useState({
    full_name: '',
    whatsapp: '',
    email: '',
  });

  const [pais, , ,] = useGetLocationIp();

  const [error, setError] = useState({
    full_name: false,
    whatsapp: false,
    email: false,
  });

  const [textError, SettextError] = useState({
    full_name: '',
    whatsapp: '',
    email: '',
  });

  const ValidPhone = () => {
    if (
      form.whatsapp.length < 9 ||
      ('whatsapp' && !/^[0-9]+$/i.test(form.whatsapp))
    ) {
      textError.whatsapp =
        "Please enter a phone number that's at least 10 numbers long.";
      error.whatsapp = true;
    } else {
      textError.whatsapp = '';
      error.whatsapp = false;
    }
    eBtn();
  };

  const eBtn = () => {
    if (
      form.email.length !== '' &&
      form.email.length !== 0 &&
      !error.email &&
      form.full_name.length !== '' &&
      form.full_name.length !== 0 &&
      !error.full_name &&
      form.whatsapp.length !== '' &&
      form.whatsapp.length !== 0 &&
      !error.whatsapp &&
      check
    ) {
      Setbtndisabled(false);
    } else {
      Setbtndisabled(true);
    }
  };

  const ValidEmail = () => {
    if (
      form.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,15}$/i.test(form.email)
    ) {
      textError.email = 'Email must be formatted correctly.';
      error.email = true;
    } else {
      textError.email = '';
      error.email = false;
    }
    eBtn();
  };

  const ValidLetras = (nombre, valor) => {
    if (nombre && !/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+$/i.test(valor)) {
      textError[nombre] = 'Please complete the information.';
      error[nombre] = true;
    } else {
      textError[nombre] = '';
      error[nombre] = false;
    }
    eBtn();
  };

  useEffect(() => {
    eBtn();
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case 'email':
        ValidEmail();
        break;
      case 'full_name':
        ValidLetras('full_name', form.full_name);
        break;
      case 'whatsapp':
        ValidPhone();
        break;
      default:
        break;
    }
  };

  const formName = 'CatalogAI';
  const templateID = 'template_4plaqa8';

  const { handleApolloActions } = useApolloActions(form, formName);

  const guardar = async (e) => {
    e.preventDefault();
    // ValidPhone2();
    if (btndisabled) return;
    else {
      if (!error.email && !error.full_name && !error.whatsapp) {

        try {
          await handleApolloActions();
          await sendEmailWithTemplate(form, formName, templateID);
          submitGTM();

          history.push('/v1/catalog-ai-thankyou');
        } catch (error) {
          console.error('Error en la operación:', error);
        }
      } else {
        // console.log("Validación del formulario fallida.");
      }
    }
  };

  const checker = () => {
    if (check) {
      setCheck(false);
      ('#4E596E');
    } else {
      setCheck(true);
    }
  };

  const allowLetters = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ ]/g, ''); //[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+
  };

  const wordsToUppercase = (e) => {
    e.target.value = e.target.value.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
  };

  return (
    <>
      <PageWrapperBase>
        <Flex
          direction='column'
          justify='center'
          align='center'
          mPadding='150px 24px 0px'
          padding='150px 96px 36px'
          width='100%'
          style={{ overflow: 'hidden' }}
        >
          <Flex
            id='headContainer'
            align='stretch'
            mDirection='column'
            padding='0 0 74px'
            mPadding='0 0 40px'
          >
            <Flex
              id='HeadInfoContainer'
              gap='24px'
              direction='column'
              width='100%'
              align='start'
            >
              <Flex id='headTitle' direction='column'>
                <Text weight='400' size='40px' color='#FFA800'>
                  La nueva forma de homologar catálogos:
                </Text>
                <Text weight='400' size='48px'>
                  CatalogAI
                </Text>
              </Flex>
              <Text>
                Tu aliado para{' '}
                <span style={{ fontWeight: '700' }}>automatizar</span> tu{' '}
                <span style={{ fontWeight: '700' }}>
                  proceso de homologación de catálogos
                </span>{' '}
                con{' '}
                <span style={{ fontWeight: '700' }}>
                  inteligencia artificial.
                </span>
              </Text>
              <Flex id='bulletsContainer' direction='column' gap='24px'>
                <Flex align='center' gap='16px'>
                  <img src='./bullet1.svg' alt='icon' />
                  <Text size='20px' weight='400' color='#FFF'>
                    Precisión como nunca antes:{' '}
                    <span style={{ color: '#FFA800' }}>
                      eficiencia del{' '}
                      <span style={{ fontWeight: '700' }}>95%</span>
                    </span>
                  </Text>
                </Flex>
                <Flex align='center' gap='16px'>
                  <img src='./bullet2.svg' alt='icon' />
                  <Text size='20px' weight='400' color='#FFF'>
                    Ahorra tiempo y{' '}
                    <span style={{ color: '#FFA800' }}>evita errores</span> en
                    la homologación
                  </Text>
                </Flex>
                <Flex align='center' gap='16px'>
                  <img src='./bullet3.svg' alt='icon' />
                  <Text size='20px' weight='400' color='#FFF'>
                    <span style={{ color: '#FFA800' }}>Reduce procesos</span>{' '}
                    manuales extensos y costos operativos.
                  </Text>
                </Flex>
              </Flex>
              <Text style={{ marginTop: '16px' }}>
                Conoce nuestros paquetes{' '}
                <a
                  href='/v1/catalogos#pricing'
                  style={{
                    color: '#fff',
                    textDecoration: 'underline',
                    fontWeight: '700',
                  }}
                >
                  aquí
                </a>
              </Text>
            </Flex>
            <Flex
              id='headFormContainer'
              width='100%'
              align='center'
              justify='center'
              padding='0 80px'
              direction='column'
              gap='24px'
              mPadding='130px 0 0'
              style={{ translate: '0 -50px' }}
            >
              <Flex direction='column' gap='16px' width='100%'>
                <Text
                  style={{
                    marginTop: '16px',
                    marginBottom: '32px',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  Déjanos tus datos y recibe tu demo{' '}
                  <span
                    style={{
                      color: '#fff',
                      fontWeight: '700',
                    }}
                  >
                    gratis
                  </span>
                </Text>
                <TextField
                  error={error.full_name}
                  helperText={textError.full_name}
                  type='text'
                  id='full_name'
                  name='full_name'
                  value={form.full_name}
                  onChange={handleChange}
                  onInput={(e) => {
                    allowLetters(e);
                    wordsToUppercase(e);
                  }}
                  variant='outlined'
                  label='Nombre completo*'
                  fullWidth
                />
                <TextField
                  error={error.whatsapp}
                  helperText={textError.whatsapp}
                  type='tel'
                  id='whatsapp'
                  name='whatsapp'
                  value={form.whatsapp}
                  onChange={handleChange}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                  inputProps={{ maxLength: pais === 'mx' ? 10 : 13 }}
                  variant='outlined'
                  label='Teléfono*'
                  fullWidth
                />
                <TextField
                  error={error.email}
                  helperText={textError.email}
                  id='email'
                  name='email'
                  type='email'
                  value={form.email}
                  onChange={handleChange}
                  variant='outlined'
                  label='Email*'
                  fullWidth
                />
              </Flex>
              <Flex align='center' width='100%'>
                <Checkbox
                  color='primary'
                  checked={check}
                  onChange={checker}
                  name='Privacy policy'
                />
                <Text size='14px'>
                  He leído y estoy de acuerdo con nuestra{' '}
                  <a
                    href='https://therocketcode.com/v1/privacy'
                    style={{
                      color: '#fff',
                      textDecoration: 'underline',
                      fontWeight: '700',
                    }}
                  >
                    Política de privacidad
                  </a>
                </Text>
              </Flex>
              <BtnStyled
                disabled={btndisabled}
                onClick={guardar}
                action='https://therocketcode.com/'
                style={{ width: 'calc(100% - 16px)', translate: '-5px' }}
              >
                AGENDA DEMO
              </BtnStyled>
            </Flex>
          </Flex>
          <Flex padding='74px 0 ' gap='40px' width='100%' direction='column'>
            {' '}
            <Flex direction='column' gap='32px' width='100%'>
              <Text weight='700' size='24px'>
                Beneficios
              </Text>
              <Text weight='400' size='48px'>
                Lleva tu negocio al siguiente nivel con{' '}
                <span style={{ fontWeight: '700', color: '#FFA800' }}>
                  CatalogAI
                </span>
              </Text>
            </Flex>
            <Flex width='100%' direction='row' mDirection='column' gap='24px'>
              <Flex
                direction='column'
                gap='24px'
                width='100%'
                style={{ zIndex: '2' }}
              >
                <Flex
                  direction='row'
                  mDirection='column'
                  gap='24px'
                  width='100%'
                  align='stretch'
                >
                  <Card
                    color='#00AA58'
                    img='./CardGreen.png'
                    title='Soluciones a tu medida'
                  >
                    Ofrecemos paquetes diseñados para adaptarse a tus
                    necesidades
                  </Card>
                  <Card
                    color='#2240D9'
                    img='./CardBlue.png'
                    title='Contratación Fácil'
                  >
                    Obtén nuestro servicio en 3 pasos:
                    <div style={{ margin: '8px 0 0' }}>
                      <div>1. Selecciona las aseguradoras</div>
                      <div>2. Conéctate a nueestra API</div>
                      <div>3. Empieza a utilizar CatalogAI</div>
                    </div>
                  </Card>
                </Flex>
                <Flex
                  direction='row'
                  mDirection='column'
                  gap='24px'
                  width='100%'
                >
                  <Card
                    color='#FFA800'
                    img='./CardYellow.png'
                    title='Experimenta el poder de la IA'
                  >
                    Recibe coincidencias rápidas y de alta fidelidad{' '}
                  </Card>
                  <Card
                    color='#F42500'
                    img='./CardRed.png'
                    title='Explora tu historial con nuestro Dashboard'
                  >
                    Obtén un detalle de tus resultados y gestiona tu negocio
                  </Card>
                </Flex>
              </Flex>
              <Flex
                position='relative'
                width='100%'
                height='350px'
                mJustify='center'
              >
                <StyledImg src='./Rocketimg1.png' />
              </Flex>
            </Flex>
            <Text>
              ¿Quieres saber más? visita nuestra página{' '}
              <a
                href='/v1/catalogos'
                style={{
                  color: '#fff',
                  textDecoration: 'underline',
                  fontWeight: '700',
                }}
              >
                aquí
              </a>{' '}
            </Text>
          </Flex>
        </Flex>
        <Flex width='100%' align='stretch' mDirection='column'>
          <Flex
            bg='#FFA800'
            maxWidth='500px'
            width='100%'
            mMaxWidth='100%'
            padding='64px 96px'
            align='start'
            justify='center'
            direction='column'
            gap='24px'
          >
            <Text color='#0E1D39' size='40px'>
              Ellos <span style={{ fontWeight: '700' }}>confían</span> en
              nosotros
            </Text>
            <Text color='#0E1D39' size='32px'>
              Sé tú el próximo
            </Text>
          </Flex>
          <Flex
            bg='#2240D9'
            width='100%'
            padding='64px 96px'
            align='center'
            justify='center'
            direction='row'
            gap='56px'
            mDirection='column'
          >
            <img src='./LogoVolt.png' />
            <img src='./LogoSicas.png' />
          </Flex>
        </Flex>
      </PageWrapperBase>
    </>
  );
};

export default CatalogAiForm;

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'DM Sans';
  font-weight: ${(props) => props.weight || '400'} 400;
  font-size: ${(props) => props.size || '20px'};
  color: ${(props) => props.color || '#FFF'};
  line-height: 120%;
`;

const Flex = styled.div`
  position: ${(props) => props.position || null};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  min-height: ${(props) => props.minHeight || 'auto'};
  max-height: ${(props) => props.maxHeight || 'auto'};
  min-width: ${(props) => props.minWidth || 'auto'};
  max-width: ${(props) => props.maxWidth || 'auto'};
  display: flex;
  justify-content: ${(props) => props.justify || 'start'};
  align-items: ${(props) => props.align || 'start'};
  flex-direction: ${(props) => props.direction || 'row'};
  padding: ${(props) => props.padding || '0'};
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'no-wrap')};
  background-color: ${(props) => props.bg || 'transparent'};
  border: ${(props) => props.border || 'none'};
  gap: ${(props) => props.gap || '0'};
  border-radius: ${(props) => props.radius || '0'};

  @media (max-width: 1023px) {
    flex-direction: ${(props) => (props.mDirection ? props.mDirection : null)};
    align-items: ${(props) => (props.mAlign ? props.mAlign : null)};
    justify-content: ${(props) => (props.mJustify ? props.mJustify : null)};
    padding: ${(props) => (props.mPadding ? props.mPadding : null)};
    max-width: ${(props) => (props.mMaxWidth ? props.mMaxWidth : null)};
  }
`;

export const BtnStyled = styled.button`
  padding: 14px 70px;
  color: black;
  cursor: pointer;
  position: relative;
  transform: translate(12px);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  ${(props) =>
    props.disabled
      ? css`
          background-color: #868e9c;
          border: 2px solid #868e9c;
        `
      : css`
          background-color: white;
          border: 2px solid white;
        `};
  & .arrow-btn {
    position: absolute;
    padding-left: 40px;
    width: 15px;
  }
  &:after {
    transition: all 0.2s ease-in-out;
    content: '';
    position: absolute;
    top: 1px;
    left: -12px;
    right: 1px;
    bottom: -12px;
    border: 4px solid white;
    z-index: -1;
    ${(props) =>
      props.disabled
        ? css`
            border: 4px solid #868e9c;
          `
        : css`
            border: 4px solid white;
          `};
  }
  &:hover {
    ${(props) =>
      props.disabled
        ? css``
        : css`
            transition: all 0.2s ease-in-out;
            transform: translate(17px, -5px);
            background: #fff;
            color: #010606;
          `};
  }
  &:hover:after {
    ${(props) =>
      props.disabled
        ? css``
        : css`
            transition: all 0.2s ease-in-out;
            content: '';
            position: absolute;
            top: 6px;
            left: -17px;
            right: 6px;
            bottom: -17px;
            border: 4px solid white;
            z-index: -1;
          `};
  }
`;

const StyledImg = styled.img`
  position: absolute;
  width: 150%;
  min-width: 800px;
  height: auto;
  left: -20px;
  top: 50%;
  transform: translate(0, -30%);
  @media (max-width: 1023px) {
    left: 0;
    top: 0;
    width: 130%;
    max-height: 400px;
    width: auto;
    left: 50%;
    transform: translate(-40%, 0);
    min-width: auto;
  }
`;
