import styled from 'styled-components';
import Navbar from '../Navigation/Navbar';

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  min-height: 100vh;
  width: 100vw;
`;

const AppLayout = ({ children }) => {
  return (
    <LayoutWrapper>
      <Navbar />
      {children}
    </LayoutWrapper>
  );
};

export default AppLayout;
