/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Nav,
  NavLink,
  NavBtn,
  NavBtnLink,
  NavMenuNew,
} from '../style/navbar-styles';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';
// import { isMobile } from 'react-device-detect';
import i18next from 'i18next';

export default function LayoutApp({ children }) {
  const [lang, setLang] = useState('en');
  const [langOpen, setLangOpen] = useState(false);
  const isMarketplace = window.location.pathname.includes('/v1/marketplace');

  const { t } = useTranslation(['home']);

  useEffect(() => {
    if (localStorage.getItem('i18nextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
    if (localStorage.getItem('i18nextLng') === 'es') {
      setLang('es');
    }
    if (localStorage.getItem('i18nextLng') === 'en') {
      setLang('en');
    }
  }, []);

  useEffect(() => {
    if (lang === 'es') {
      i18next.changeLanguage('es');
    }
    if (lang === 'en') {
      i18next.changeLanguage('en');
    }
  }, [lang]);

  // const { i18n } = useTranslation();

  const languageStyle = (language) => ({
    color: lang === language ? 'white' : 'gray',
    textDecoration: lang === language ? 'underline' : 'none',
    cursor: 'pointer',
  });

  const hadleClick = () => {
    langOpen ? setLangOpen(false) : setLangOpen(true);
  };

  // const scroll = () => {
  //   window.scrollTo({
  //     top: 1400,
  //     behavior: 'smooth',
  //   });
  // };

  const isTeamContactCard = window.location.pathname.includes('team');

  const NotLoadHeader =
    isMarketplace ||
    window.location.pathname === '/' ||
    window.location.pathname === '/v1/clutch' ||
    window.location.pathname === '/v1/login-ocr' ||
    window.location.pathname === '/v1/ocr/' ||
    window.location.pathname === '/v1/new-homerocket' ||
    window.location.pathname === '/v1/new-services' ||
    window.location.pathname.includes('/v1/swarm') ||
    window.location.pathname.includes('/v1/autosales') ||
    window.location.pathname.includes('/v1/error') ||
    window.location.pathname.includes('/v1/new-homerocket/coming-soon') ||
    window.location.pathname.includes('/v1/contact') ||
    window.location.pathname.includes('/v1/marketplace') ||
    window.location.pathname.includes('/v1/rocket-code-Intro');
  const NotLoadFooter =
    isMarketplace ||
    window.location.pathname === '/' ||
    window.location.pathname === '/v1/clutch' ||
    window.location.pathname === '/v1/dashboard' ||
    window.location.pathname === '/v1/login' ||
    window.location.pathname === '/v1/login-ocr' ||
    window.location.pathname === '/v1/ocr/' ||
    window.location.pathname === '/v1/password-recovery' ||
    window.location.pathname === '/v1/forgot-password' ||
    window.location.pathname === '/v1/new-homerocket' ||
    window.location.pathname === '/v1/new-homerocket/coming-soon' ||
    window.location.pathname === '/v1/error' ||
    window.location.pathname === '/v1/new-services' ||
    window.location.pathname.includes('/v1/contact') ||
    window.location.pathname.includes('/v1/swarm') ||
    window.location.pathname.includes('/v1/autosales') ||
    window.location.pathname.includes('/v1/rocket-code-Intro')
      ? true
      : false;

  // console.log('NotLoadFooter', NotLoadFooter, window.location.pathname);

  const CatalogHeader =
    isMarketplace ||
    window.location.pathname === '/v1/catalogos' ||
    window.location.pathname === '/v1/catalogos-demo' ||
    window.location.pathname === '/v1/contact-ai'
      ? true
      : false;

  const CatalogAIForm =
    window.location.pathname === '/v1/contact-ai2' ? true : false;

  useEffect(() => {
    if (CatalogAIForm) {
      setLang('es');
    }
  }, []);

  const NewHiring =
    isMarketplace ||
    window.location.pathname === '/v1/new-hiring' ||
    window.location.pathname.startsWith('/v1/new-hiring/')
      ? true
      : false;

  useEffect(() => {
    if (NewHiring) {
      setLang('es');
    }
  }, []);

  if (isMarketplace) {
    return <>{children}</>;
  }

  if (!isTeamContactCard) {
    return (
      <>
        {NotLoadHeader ? null : (
          <Nav langOpen={langOpen}>
            <div
              className="lang-opacity"
              onClick={() => setLangOpen(false)}
            ></div>
            <NavLink
              to={
                CatalogHeader
                  ? '/v1/catalogos'
                  : CatalogAIForm
                  ? '/v1/contact-ai2'
                  : '/'
              }
            >
              <img
                className="logo-nav"
                src="/v1/icon-rocketcode.svg"
                alt="Rocket Code"
                style={{ zIndex: '100' }}
              />
            </NavLink>
            {CatalogAIForm ? null : (
              <NavMenuNew>
                <>
                  <div className="language__text">
                    <p
                      style={languageStyle('es')}
                      onClick={() => setLang('es')}
                    >
                      Español{' '}
                    </p>{' '}
                    <span className="language__span"></span>{' '}
                    {NewHiring ? null : (
                      <p
                        style={languageStyle('en')}
                        onClick={() => setLang('en')}
                      >
                        {' '}
                        English
                      </p>
                    )}
                  </div>
                </>

                <NavBtn>
                  <NavBtnLink
                    to={CatalogHeader ? '/v1/contact-ai' : '/v1/contact'}
                    btnGreen
                  >
                    {t('navbar.cta')}
                  </NavBtnLink>
                </NavBtn>
                <div className="final__dropdown" onClick={hadleClick}>
                  <>
                    <button button className="final__dropdown__hover"></button>
                    <div className="modal-lang">
                      <NavLink to="/v1/ai">{t('navbar.process')}</NavLink>
                      <a href="#services">{t('navbar.services')}</a>
                      <NavLink to="/v1/case-studies">
                        {t('navbar.projects')}
                      </NavLink>
                      <NavLink to="/v1/new-hiring">
                        {t('navbar.careers')}
                      </NavLink>
                      <NavBtnLink
                        to={CatalogHeader ? '/v1/contact-ai' : '/v1/contact'}
                      >
                        {t('navbar.cta')}
                      </NavBtnLink>
                    </div>
                  </>
                </div>
              </NavMenuNew>
            )}
          </Nav>
        )}

        {children}

        {NotLoadFooter ? null : <Footer />}
      </>
    );
  } else {
    return <>{children}</>;
  }
}
