// PDFViewer.js
import React from 'react';
import styled from 'styled-components';

const PDFContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

const PDFViewer = ({ urlPDF }) => {
  return (
    <PDFContainer>
      <iframe
        src={urlPDF}
        title="PDF Viewer"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    </PDFContainer>
  );
};

export default PDFViewer;
