import styled from 'styled-components';

export const MainContainerNav = styled.div`
    width: 100%;
    max-width: 1376px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ContainerSections = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const NavBarOCR = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 70px;
    padding: 0px 64px 0px 64px;
    gap: 0px;
    border: 0px 0px 1px 0px;
    opacity: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 1);

    margin: 0px 0px 0px 0px;
`;

export const NavSections = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
`;

export const SelectOCRSection = styled.select`
`;

export const NavIconSection = styled.div`
`;

export const ButtonSection = styled.div`
    width: ${props => props.width ? props.width : 'auto'};
`;

export const HamburguerMenu = styled.img`

`;

export const ContainerHamburguerMenu = styled.div`
    display: none;

    @media (max-width: 768px) {
        width: 100%;
        min-height: 70px;
        display: flex;
        justify-content: end;
        align-items: center;
    }
`;

export const ContainerSectionsMovile = styled.div`
    display: ${props => props.isMenuOpen ? 'flex' : 'none'};

    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgb(14, 29, 57);

    justify-content: center;
    align-items: center;
`;

export const BlockContainerMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        color: white;
        font-size: 40px;
        font-weight: 700;
        line-height: 23.8px;
        text-align: left;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const HamburguerMenuClose = styled.div`
    position: absolute;
    top: 20px;
    right: 60px;
    display: flex;
    justify-content: end;
    align-items: center;

    color: white;
    cursor: pointer;

    span {
        font-size: 30px;
        color: #ffffff;
        font-weight: bold;
    }

    &:hover {
        opacity: 0.8;
    }
`;

// export const StyledButton = styled.button`
//   width: 134px;
//   height: 34px;
//   padding: 8px 16px;
//   gap: 10px;
//   opacity: 0;
//   background: rgba(0, 170, 88, 1);

//   border: none;
//   border: 1px solid rgba(34, 64, 217, 1);
//   border-radius: 10px;
//   box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
//   color: white;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

export const StyledButton = styled.button`
    width: ${props => props.width ? props.width : '134px'};
    height: ${props => props.height ? props.height : '34px'};
    padding: 8px, 16px, 8px, 16px;
    gap: 6px;
    border: none;
    opacity: 1;
    background: rgba(0, 170, 88, 1);
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;


    font-size: ${props => props.fontSize ? props.fontSize : '14px'};
    font-weight: 700;
    line-height: 17.92px;
    text-align: left;
    cursor: pointer;
    &:hover {
        background: rgba(0, 170, 88, 0.8);
    }
`;


