import gql from "graphql-tag";

export const GET_LOCATION_BY_IP = gql`
   query getLocationByIp($ip: String) {
      getLocationByIp(ip: $ip) {
         response
         data
         statusCode
         message
         error
      }
   }
`;
