import styled from 'styled-components';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';
import useVersionExtractor from '../../hooks/useExtractVersionFromPath';

const LogoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  min-height: 100vh;
  background-color: #0e1d39;
  padding: 16px;
  color: #fff;
  font-family: 'DM Sans';
`;

const Logout = () => {
  const version = useVersionExtractor();
  const history = useHistory();
  useEffect(() => {
    localStorage.removeItem('token');
    history.push(`/v1/${version}/login`);
  }, [history]);
  return (
    <LogoutContainer>
      <Loader />
    </LogoutContainer>
  );
};

export default Logout;
