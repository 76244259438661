import styled from 'styled-components';
import DownloadButton from './DownloadButton';

const GoodDesignIs = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const Con = styled.span``;
const Swarm = styled.b`
  color: #ffa800;
`;
const GoodDesignIsContainer = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 120%;
`;
const GoodDesignIsParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0px;
`;
const ButtonsbuttonWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  font-family: Inter;
`;
const FinishViewRoot = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px 0px;
  min-width: 300px;
  max-width: 900px;
  padding: 32px 96px 0px;
  text-align: left;
  font-size: 40px;
  color: #0e1d39;
  font-family: 'DM Sans';

  @media screen and (max-width: 642px) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
  }

  @media screen and (max-width: 420px) {
    padding: 0px;
  }
`;

const FinishView = ({ threadId, ...props }) => {
  return (
    <FinishViewRoot>
      <GoodDesignIsParent>
        <GoodDesignIs>¡Tu propuesta está lista!</GoodDesignIs>
        <GoodDesignIsContainer>
          <Con>{`Con `}</Con>
          <Swarm>AutoSales Proposal</Swarm>
          <Con>
            , pasa rápidamente de la idea al impacto: Convierte tus
            requerimientos en propuestas comerciales al instante. ¡Tu propuesta
            está lista para descargar!
          </Con>
        </GoodDesignIsContainer>
      </GoodDesignIsParent>
      <ButtonsbuttonWrapper>
        <DownloadButton threadId={threadId} />
      </ButtonsbuttonWrapper>
    </FinishViewRoot>
  );
};

export default FinishView;
