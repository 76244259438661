import styled from "styled-components";

export const Container = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 150px;
`;

export const Subtitle = styled.h4 `
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  text-align: center;
  color: #FFFFFF;

  @media (max-width: 760px) {
    font-size: ${(props) => props.fSizeM || '16px'};
  }
`;

export const Text = styled.p `
  font-weight: ${(props) => props.fWeight || '400'};
  font-size: ${(props) => props.fSize || '24px'};
  line-height: ${(props) => props.lHeight || '31px'};
  text-align: ${(props) => props.tAlign || 'center'};
  color: #FFFFFF;
  @media (max-width: 760px) {
    font-size: ${(props) => props.fSizeM || '16px'};
    text-align: ${(props) => props.tAlignM || 'center'};
    padding: ${(props) => props.paddingM || ''};
    margin: ${(props) => props.marginM || ''};
    font-weight: ${(props) => props.fWeightM || '400'};
  }
`;

export const Button = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: ${(props) => props.color || '#FFFFFF'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  background: ${(props) => props.background || '#2240d9'};
  border-radius: 8px;
  border: 0;
  min-width: 200px;
  cursor: pointer;
  @media (max-width: 800px) {
    width: ${(props) => props.widthM || '100%'};
  }
`;

export const Header = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding-top: 170px;
  max-width: 1200px;
  .logo-name {
    display: flex;
    align-items: center;
    gap: 25px;
  }
  @media (max-width: 800px) {
    max-width: 326px;
    img {
      max-width: 100px;
    }
    .logo-name {
      flex-direction: column;
    }
  }
`;

export const SectionFunction = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
`;

export const Title = styled.h2 `
  font-weight: 500;
  font-size: 44px;
  line-height: ${(props) => props.lHeight || '53px'};
  text-align: center;
  color: #FFFFFF;
  @media (max-width: 760px) {
    font-size: 31px;
    font-weight: ${(props) => props.fontWM || '500'};
  }
`;

export const ImgBullet = styled.img `
  max-width: 150px;
  height: 150px;
  width: auto;

  @media (max-width: 760px) {
    max-width: 95px;
    height: 95px;
    width: auto;
  }
`;

export const Bullet = styled.div `
  align-items: center;
  padding: 16px 32px;
  gap: 64px;
  width: 276px;
  height: 375px;
  border: 2px solid #2240D9;
  border-radius: 8px;
  max-width: 276px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  small {
    font-size: 16px;
    opacity: 0;
  }
  p {
    display: none;
    font-size: 38px;
    margin: 12px 0 0 0;
    font-weight: 700;
  }
  span {
    display: block;
    font-size: 38px;
    margin-top: 35px;
  }
  // &:hover {
  //   // scale: 105%;
  //   height: auto;
  //   small {
  //     opacity: 1;
  //   }
  //   span {
  //     display: none;
  //   }
  //   p {
  //     display: block;
  //   }
  // }
  @media (max-width: 760px) {
    width: 120px;
    padding: 16px;
    height: 240px;
    small {
      font-size: 14px;
    }
  }
`;

export const ContainerBullet = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  max-width: 1200px;
  @media (max-width: 760px) {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    max-width: 345px;
  }
`;

export const SectionProject = styled.section `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 120px;
  gap: 64px;
  height: auto;
  background: #2240D9;
  width: 100%;
  max-width: 100%;
  @media (max-width: 760px) {
    padding: 32px;
    width: auto;
  }
`;

export const Columns = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  max-width: 1200px;
  @media (max-width: 760px) {
    display: block;
    max-width: 326px;
  }
`;

export const SectionCards = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 28px;
  max-width: 1200px;
  @media (max-width: 760px) {
    max-width: 326px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const CardColor = styled.div `
  padding: 16px 32px;
  gap: 64px;
  width: 279px;
  height: 413.7px;
  background: ${(props) => props.background || '#2240D9'};
  border-radius: 8px;
  text-align: center;
  @media (max-width: 760px) {
    max-width: 147px;
    width: 147px;
    padding: 16px 0;
    max-height: 346px;
  }
`;

export const IconCard = styled.img `
  width: 96px;
  height: auto;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 25% auto;
  @media (max-width: 760px) {
    margin: 24px auto;
  }
`;

export const SectionButton = styled.section `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  max-width: 1200px;
  margin-bottom: 70px;
  @media (max-width: 760px) {
    max-width: 326px;
  }
`;
