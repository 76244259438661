import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Carousel from 'react-material-ui-carousel';
// import PaginationBullets from '../../NewRocket/components/PaginationBullets/index.jsx';
import clutchember from '../assets/awards/clutchember.svg';
import clutchflutter from '../assets/awards/clutchflutter.svg';
import clutchhtml from '../assets/awards/clutchhtml.svg';
import clutchsmarttv from '../assets/awards/clutchsmarttv.svg';
import clutchunity from '../assets/awards/clutchunity.svg';
import clutchglobal from '../assets/awards/clutchglobal.svg';
import zendesksalesexpert from '../assets/awards/zendesksalesexpert.svg';
import zendesksalesspecialist from '../assets/awards/zendesksalesspecialist.svg';
import uipathpartner from '../assets/awards/uipathpartner.svg';
import uiautomation from '../assets/awards/uiautomation.svg';
import zendeskistqb from '../assets/awards/zendeskistqb.svg';
import certiprofscrum from '../assets/awards/certiprofscrum.svg';
import zendeskpresales from '../assets/awards/zendeskpresales.svg';
import zendeskbestpractices from '../assets/awards/zendeskbestpractices.svg';
import hubspot from '../assets/awards/hubspot.svg';
import uipathautomationaasociate from '../assets/awards/uipathautomationaasociate.svg';
import aws from '../assets/awards/aws.svg';
import aim from '../assets/awards/aim.svg';
// import ArrowLeft from '../assets/carrusel/leftarrow.svg';
// import Pause from '../assets/carrusel/pause.svg';
import Stars from '../assets/carrusel/stars.svg';

const RedesignCarrusel = () => {
  const items = [
    {
      content: [
        clutchember,
        clutchflutter,
        clutchhtml,
        clutchsmarttv,
        clutchunity,
        clutchglobal,
        zendesksalesexpert,
        zendesksalesspecialist,
        uipathpartner,
        uiautomation,
        zendeskistqb,
        certiprofscrum,
        zendeskpresales,
        zendeskbestpractices,
        hubspot,
        uipathautomationaasociate,
        aws,
        aim,
      ],
    },
  ];

  const [activeLikeBullet, setActiveLikeBullet] = useState(0);

  const [width, setWidth] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(false);
  //   const [isClickable, setIsClickable] = useState(true);
  //   const [autoPlay, setAutoPlay] = useState(true);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    // Limpia el evento cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // efecto a ejecutar
    if (width < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    // setActiveLikeBullet(0);
  }, [width]);

  //   const blockClicksTemporarily = (callback, delay) => {
  //     if (!isClickable) return;
  //     setIsClickable(false);
  //     callback();
  //     setTimeout(() => setIsClickable(true), delay);
  //   };

  //   const PrevPage = (length) => {
  //     if (!autoPlay) {
  //       blockClicksTemporarily(() => {
  //         setActiveLikeBullet((prevActiveLikeBullet) => {
  //           if (prevActiveLikeBullet > 0) {
  //             return prevActiveLikeBullet - 1;
  //           } else {
  //             return length - 1;
  //           }
  //         });
  //       }, 500);
  //     }
  //   };

  //   const NextPage = (length) => {
  //     if (!autoPlay) {
  //       blockClicksTemporarily(() => {
  //         setActiveLikeBullet((prevActiveLikeBullet) => {
  //           if (prevActiveLikeBullet < length - 1) {
  //             return prevActiveLikeBullet + 1;
  //           } else {
  //             return 0;
  //           }
  //         });
  //       }, 500);
  //     }
  //   };

  const renderContent = (item) => {
    // ----------------------

    const chunkArray = (arr, size) => {
      const chunks = [];
      for (let i = 0; i < arr.length; i += size) {
        chunks.push(arr.slice(i, i + size));
      }
      return chunks;
    };

    return (
      <OptionsContent>
        {item.content.length > (mobile ? 3 : 5) ? (
          <>
            <Carousel
              activeIndicatorIconButtonProps={{
                style: { color: 'blue' },
              }}
              animation='slide'
              interval={3000}
              //   autoPlay={autoPlay}
              autoPlay
              indicators={false}
              navButtonsAlwaysInvisible
              index={activeLikeBullet}
              onChange={(index) => setActiveLikeBullet(index)}
            >
              {chunkArray(item.content, mobile ? 3 : 5).map((chunk, index) => (
                <>
                  <ItemsContent>
                    {/* Mapear cada imagen dentro del subarray */}

                    {chunk.map((image, i) => (
                      <ImgContainer>
                        <ImageComponent key={i} src={image} />
                      </ImgContainer>
                    ))}
                  </ItemsContent>
                </>
              ))}
            </Carousel>
            {/* <PaginationSection>
              <PaginationContainer>
                <PlayPause src={Pause} onClick={() => setAutoPlay(!autoPlay)} />
                <PaginationBullets
                  onHandleBulletClick={(indexBullet) => {
                    setActiveLikeBullet(indexBullet);
                  }}
                  totalSlides={chunkArray(item.content, mobile ? 4 : 5).length}
                  rootActiveBullet={activeLikeBullet}
                  activeBulletColor='#ffa800'
                  bulletColor='#D4DEE9'
                  notClickable
                />
              </PaginationContainer>
              <Circle
                autoPlay={autoPlay}
                onClick={() =>
                  PrevPage(chunkArray(item.content, mobile ? 4 : 5).length)
                }
              >
                <img src={ArrowLeft} alt='line' style={{ height: '22px' }} />
              </Circle>
              <Circle
                autoPlay={autoPlay}
                onClick={() =>
                  NextPage(chunkArray(item.content, mobile ? 4 : 5).length)
                }
              >
                <img
                  src={ArrowLeft}
                  alt='line'
                  style={{ height: '22px', transform: 'rotate(180deg)' }}
                />
              </Circle>
            </PaginationSection> */}
          </>
        ) : (
          <>
            <ItemsContent shouldWrap={item.content.length > 4}>
              {item.content.map((imagePath, idx) => (
                <ImageComponent key={idx} src={imagePath} alt='iconImg' />
              ))}
            </ItemsContent>
          </>
        )}
      </OptionsContent>
    );
  };

  return (
    <GeneralContainer>
      <ContainerCarousel>
        <ContentCarousel>
          <Content2>{renderContent(items[0])}</Content2>
        </ContentCarousel>
      </ContainerCarousel>
      <RatedContainer>
        Clutch
        <RatedImg alt='stars' src={Stars} />
      </RatedContainer>
    </GeneralContainer>
  );
};

export default RedesignCarrusel;

const GeneralContainer = styled.div`
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const RatedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 26px;
  align-items: center;
  font-family: 'Red Hat Text';
  font-size: 44px;
  font-color: #fff;
  font-weight: 500;
  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

const RatedImg = styled.img`
  height: 30px;
  @media (max-width: 1024px) {
    height: 15px;
  }
`;

const PlayPause = styled.img`
  border-radius: 50%;
  width: 25px;
  &:hover {
    cursor: pointer;
    background-color: rgb(94 111 141);
  }
`;

const Circle = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #395280;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: ${({ autoPlay }) => (autoPlay ? 'brightness(0.7)' : '')};
  &:hover {
    cursor: pointer;
    filter: ${({ autoPlay }) =>
      autoPlay ? 'brightness(0.7)' : 'brightness(1.2)'};
  }
`;

const PaginationContainer = styled.div`
  width: auto;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
  background-color: #395280;
  height: 44px;
  align-items: center;
  display: flex;
`;

const PaginationSection = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 10px;
`;

const ContainerCarousel = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 28.937px;
  flex-shrink: 0;
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const HeaderCarousel = styled.div`
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  align-self: stretch;
  @media (max-width: 1024px) {
    align-items: flex-start;
    padding: 54px 30px 0px;
  }
`;

const TypographyCompanies = styled.div`
  width: auto;
  color: #fff;
  text-align: center;
  font-family: 'Red Hat Text';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const TypographyWork = styled.div`
  width: 306px;
  color: #fff;
  text-align: center;
  font-family: 'Red Hat Text';
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media (max-width: 1024px) {
    width: auto;
    font-size: 24px;
    min-width: 92px;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  gap: 32px;
`;

const OptionsHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  gap: 10px;
`;

const ItemsHeader = styled.div`
  display: flex;
  width: 100%;
  min-height: 22px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  color: ${({ active }) => (active ? '#fff' : '#395280')};
  text-align: left;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding: 5px 10px;
  border-radius: 5px;
  &:hover {
    color: ${({ active }) => (active ? '#fff' : '#6D80A3')};
  }
  @media (max-width: 1024px) {
    font-size: 10px;
  }
`;

const ContentCarousel = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  height: auto;
  padding: 10px;
  justify-content: center;
  align-items: start;
  flex-shrink: 0;
  @media (max-width: 1024px) {
    align-items: start;
    height: auto;
  }
`;

const Content2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  width: 100%;
  @media (max-width: 1024px) {
    gap: 24px;
    min-height: 0;
    flex-direction: column;
  }
`;

const OptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  .Carousel-root-1 {
    width: 100%;
  }
`;

const TitleContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  color: #ffa800;
  text-align: center;
  font-family: 'Red Hat Text';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

const ItemsContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: ${(props) => (props.shouldWrap ? 'wrap' : 'nowrap')};
  width: 100%;
  height: fit-content;
  gap: 16px;
  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;

const ImageComponent = styled.img`
  max-width: 250px;
  height: 120px;
  max-height: 120px;
  @media (max-width: 1024px) {
    max-width: 90px;
    max-height: 90px;
    height: 90px;
  }
`;

const Iconimg = styled.img`
  max-width: 65px;
  max-height: 65px;
  @media (max-width: 1024px) {
    max-width: 35px;
    max-height: 35px;
  }
`;
