import styled from 'styled-components';

export const Semana = styled.b`
  position: relative;
  line-height: 120%;
`;
export const SemanaWrapper = styled.div`
  width: 89px;
  border-radius: 4px 4px 0px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  ${(props) =>
    props.selected ? `border: 1px solid #fff;` : 'border: 1px solid #00aa58;'}
`;

export const CantidadDeBsquedas = styled.b`
  flex: 1;
  position: relative;
  line-height: 120%;
`;
export const SemanaDel22Al28DeEneroWrapper = styled.div`
  align-self: stretch;
  flex: 1;
  border-bottom: 1px solid #00aa58;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  text-align: right;
`;
export const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
`;
export const TotalConsultas = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
export const Span = styled.span``;
export const Consultas = styled.span`
  font-size: 16px;
`;
export const Consultas1 = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  font-size: 32px;
`;
export const Iconstoparrow = styled.img`
  width: 16px;
  position: relative;
  height: 16px;
  object-fit: contain;
`;
export const Span1 = styled.span`
  color: #00aa58;
`;
export const IconstoparrowParent = styled.div`
  border-radius: 4px;
  border: 1px solid #00aa58;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 4px;
`;
export const Semana1 = styled.div`
  position: relative;
  line-height: 120%;
`;
export const FrameParent1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  font-size: 14px;
`;
export const TotalConsultasParent = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: 8px;
  border: 2px solid #2240d9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px;
  gap: 16px;
  max-width: 460px;
`;
export const CantidadDeBsquedasRealizadWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
export const K = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 44px;
  line-height: 120%;
  font-weight: 500;
  font-family: 'Red Hat Text';
`;
export const FrameParent2 = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: 8px;
  border: 2px solid #00aa58;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px;
  gap: 16px;
  max-width: 460px;
`;
export const FrameDiv = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Data = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 16px;
  gap: 24px;
`;
export const FrameChild = styled.div`
  width: 10px;
  position: relative;
  border-radius: 12px;
  background-color: #ffa800;
  height: 44px;
`;
export const Lunes = styled.div`
  width: 50px;
  position: relative;
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const RectangleParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;
export const FrameItem = styled.div`
  width: 10px;
  position: relative;
  border-radius: 12px;
  background-color: #00aa58;
  height: 127px;
`;
export const FrameInner = styled.div`
  width: 10px;
  position: relative;
  border-radius: 12px;
  background-color: #2240d9;
  height: 96px;
`;
export const Mircoles = styled.div`
  width: 60px;
  position: relative;
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const RectangleDiv = styled.div`
  width: 10px;
  position: relative;
  border-radius: 12px;
  background-color: #ffa800;
  height: 155px;
`;
export const FrameChild1 = styled.div`
  width: 10px;
  position: relative;
  border-radius: 12px;
  background-color: #00aa58;
  height: 96px;
`;
export const FrameChild2 = styled.div`
  width: 10px;
  position: relative;
  border-radius: 12px;
  background-color: #2240d9;
  height: 68px;
`;
export const FrameChild3 = styled.div`
  width: 10px;
  position: relative;
  border-radius: 12px;
  background-color: #2240d9;
  height: 120px;
`;
export const Bar = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 4px;
  color: #fff;
`;
export const Graph = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  color: #395280;
`;
export const Card = styled.div`
  align-self: stretch;
  border-radius: 8px;
  background-color: #0e1d39;
  border: 2px solid #ffa800;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px;
  gap: 16px;
  max-height: 256px;
  box-sizing: border-box;
  max-width: 500px;
`;
export const GroupIcon = styled.img`
  position: absolute;
  top: 0px;
  left: -9.1px;
  width: 130px;
  height: 130px;
`;
export const S = styled.div`
  bottom: 0px;
  left: calc(50% - 46px);
  line-height: 120%;
  font-weight: 500;
`;
export const GroupParent = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: center;
  gap: 8px;
  width: fit-content;
`;
export const Tiempo = styled.p`
  margin: 0;
`;
export const TiempoDeRespuestaContainer = styled.div`
  flex: 1;
  position: relative;
  font-size: 14px;
  letter-spacing: -0.01em;
  line-height: 120%;
  font-family: 'DM Sans';
  text-align: left;
`;
export const FrameParent3 = styled.div`
  margin: auto 0;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  text-align: center;
  font-size: 44px;
  font-family: 'Red Hat Text';
  justify-self: center;
`;
export const PerformanceParent = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 2px solid #ef0000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px;
  max-height: 256px;
  height: 256px;
  max-width: 424px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const HorariosDeConsulta = styled.b`
  position: relative;
  font-size: 20px;
  line-height: 120%;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(props) => (props.selected ? `border-bottom: 2px solid #ffa800;` : '')}
`;

export const Data1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const BarChild = styled.div`
  flex: 1;
  position: relative;
  border-radius: 12px;
  background-color: #2240d9;
  height: 44px;
`;
export const BarItem = styled.div`
  flex: 1;
  position: relative;
  border-radius: 12px;
  background-color: #2240d9;
  height: 41px;
`;
export const BarInner = styled.div`
  flex: 1;
  position: relative;
  border-radius: 12px;
  background-color: #2240d9;
  height: 34px;
`;
export const BarChild1 = styled.div`
  flex: 1;
  position: relative;
  border-radius: 12px;
  background-color: #2240d9;
  height: 24px;
`;
export const BarChild2 = styled.div`
  flex: 1;
  position: relative;
  border-radius: 12px;
  background-color: #2240d9;
  height: 29px;
`;
export const BarChild3 = styled.div`
  flex: 1;
  position: relative;
  border-radius: 12px;
  background-color: #2240d9;
  height: 37px;
`;
export const BarChild4 = styled.div`
  flex: 1;
  position: relative;
  border-radius: 12px;
  background-color: #2240d9;
  height: 49px;
`;
export const BarChild5 = styled.div`
  flex: 1;
  position: relative;
  border-radius: 12px;
  background-color: #00aa58;
  height: 55px;
`;
export const Bar1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 12px;
`;
export const FrameChild4 = styled.div`
  width: 2px;
  position: relative;
  border-radius: 12px;
  background-color: #395280;
  height: 9px;
`;
export const RectangleParent1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Graph1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
`;
export const Horas = styled.span`
  color: #ffa800;
`;
export const IconsclockParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
export const HorariosDeConsultaParent = styled.div`
  flex: 1;
  border-radius: 8px;
  border: 2px solid #00aa58;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px;
  gap: 16px;
  height: 288px;
  max-height: 288px;
  box-sizing: border-box;
  max-width: 460px;
`;
export const BarChild6 = styled.div`
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #395280;
  height: 24px;
`;
export const Bar2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 16px;
  z-index: 0;
`;
export const Data2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  z-index: 1;
`;
export const BarChild7 = styled.div`
  width: 224px;
  position: relative;
  border-radius: 8px;
  background-color: #2240d9;
  height: 24px;
  z-index: 0;
`;
export const BarChild8 = styled.div`
  width: 196px;
  position: relative;
  border-radius: 8px;
  background-color: #2240d9;
  height: 24px;
  z-index: 1;
`;
export const BarChild9 = styled.div`
  width: 168px;
  position: relative;
  border-radius: 8px;
  background-color: #2240d9;
  height: 24px;
  z-index: 2;
`;
export const BarChild10 = styled.div`
  width: 84px;
  position: relative;
  border-radius: 8px;
  background-color: #2240d9;
  height: 24px;
  z-index: 3;
`;
export const BarChild11 = styled.div`
  width: 28px;
  position: relative;
  border-radius: 8px;
  background-color: #2240d9;
  height: 24px;
  z-index: 4;
`;
export const Txt = styled.div`
  width: 151px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
export const Data3 = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0px 16px;
  gap: 16px;
  z-index: 5;
`;
export const Bar3 = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 16px;
  z-index: 2;
  text-align: left;
  color: #fff;
`;
export const Graph2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: 16px;
`;
export const GrficaCompleta = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: 12px;
  color: #395280;
`;
export const Card1 = styled.div`
  flex: 1;
  border-radius: 8px;
  background-color: #0e1d39;
  border: 2px solid #2240d9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px;
  gap: 16px;
  font-size: 20px;
  height: 288px;
  max-height: 288px;
  box-sizing: border-box;
  max-width: 460px;
`;
export const FrameParent4 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  gap: 24px;
  font-size: 12px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const FrameContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  max-width: 944px;
  @media (min-width: 1440px) {
    min-width: 944px;
  }
`;
export const DataRowChild = styled.div`
  width: 10px;
  position: relative;
  border-radius: 50%;
  background-color: #ffa800;
  height: 10px;
  max-width: 10px;
  max-height: 10px;
  min-width: 10px;
  min-height: 10px;
`;
export const CantidadDeBsquedas1 = styled.div`
  flex: 1;
  position: relative;
  line-height: 120%;
`;
export const DataRow = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;
export const Data4 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;
export const DataWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
`;
export const Submarca = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 120%;
  color: #00aa58;
`;
export const MarcamodeloParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
export const FrameChild5 = styled.div`
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #395280;
  height: 2px;
`;
export const FrameParent5 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 16px;
`;
export const Card2 = styled.div`
  border-radius: 8px;
  background-color: #0e1d39;
  border: 2px solid #395280;
  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px;
  gap: 16px;
  box-sizing: border-box;
  min-height: 324px;
  width: 100%;
  max-width: 460px;
`;
export const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  @media (max-width: 1440px) {
    width: 100%;
    flex-direction: row;
    div {
      width: 50%;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    div {
      width: 100%;
    }
  }
`;
export const FrameGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  font-size: 20px;
  max-width: 1428px;
  @media (max-width: 1440px) {
    flex-wrap: wrap;
  }
`;
export const GraphsRoot = styled.div`
  background-color: #0e1d39;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px 24px 80px;
  box-sizing: border-box;
  gap: 24px;
  text-align: left;
  font-size: 18px;
  color: #fff;
  font-family: 'DM Sans';
`;
