import styled from "styled-components";

export const Container = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  @media (max-width: 760px) {
    gap: 50px;
  }
`;

export const Button = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: ${(props) => props.color || '#FFFFFF'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  background: ${(props) => props.background || '#2240d9'};
  border-radius: 8px;
  border: 0;
  min-width: 200px;
  cursor: pointer;
  margin: 32px auto;
  @media (max-width: 800px) {
    width: ${(props) => props.widthM || '100%'};
  }
`;

export const Subtitle = styled.h4 `
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  text-align: center;
  color: #FFFFFF;

  @media (max-width: 760px) {
    font-size: ${(props) => props.fSizeM || '16px'};
  }
`;

export const Text = styled.p `
  font-weight: ${(props) => props.fWeight || '400'};
  font-size: ${(props) => props.fSize || '24px'};
  line-height: ${(props) => props.lHeight || '31px'};
  text-align: ${(props) => props.tAlign || 'center'};
  color: #FFFFFF;
  @media (max-width: 760px) {
    font-size: ${(props) => props.fSizeM || '16px'};
    text-align: ${(props) => props.tAlignM || 'center'};
    padding: ${(props) => props.paddingM || ''};
    margin: ${(props) => props.marginM || ''};
    font-weight: ${(props) => props.fWeightM || '400'};
  }
`;

export const Header = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding-top: 170px;
  max-width: 1200px;
  .logo-name {
    display: flex;
    align-items: center;
    gap: 25px;
  }
  @media (max-width: 800px) {
    max-width: 326px;
    img {
      max-width: 100px;
    }
    .logo-name {
      flex-direction: column;
    }
  }
`;

export const SectionFunction = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 769px) {
    width: ${(props) => props.width || ''};
  }
`;

export const Title = styled.h2 `
  font-weight: 500;
  font-size: 44px;
  line-height: ${(props) => props.lHeight || '53px'};
  text-align: ${(props) => props.textA || 'center'};
  color: #FFFFFF;
  @media (max-width: 760px) {
    font-size: 31px;
    font-weight: ${(props) => props.fontWM || '500'};
  }
`;

export const ImgBullet = styled.img `
  height: 21.6px;
  width: auto;

  @media (max-width: 760px) {
    height: 21.6px;
    width: auto;
    float: right;
  }
`;

export const Bullet = styled.div `
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: auto;
  height: 48px;
  border: 2px solid #2240D9;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  justify-content: space-between;
  small {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color : #FFFFFF;
  }
  @media (max-width: 760px) {
    display: none;
  }
`;

export const BulletMob = styled.div `
  box-sizing: border-box;
  display: block;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  height: 48px;
  border: 2px solid #2240D9;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  justify-content: space-between;
  margin: 8px auto;
  small {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color : #FFFFFF;
  }
  @media (min-width: 760px) {
    display: none;
    }
  }
`;

export const ContainerBullet = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  max-width: 1200px;
  flex-flow: wrap;
  @media (max-width: 760px) {
    display: ${(props) => props.displayM || 'flex'};
    flex-flow: wrap;
    justify-content: center;
    max-width: 345px;
  }
`;

export const Content = styled.div`
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  max-width: 1200px;
  flex-flow: wrap;
  @media (max-width: 760px) {
    justify-content: center;
    width: ${(props) => props.width || ''};
    max-width: 345px;
  }
`;

export const Columns = styled.div `
  padding: 0px;
  gap: 40px;
  max-width: 1200px;
  @media (max-width: 760px) {
    display: block;
    max-width: 326px;
    margin: auto;
  }
`;

export const IconCard = styled.img `
  width: 80%;
  height: auto;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: auto 10%;
  @media (max-width: 760px) {
    margin: auto;
    width: 100%;
  }
`;

export const InputSearch = styled.input`
  width: 70%;
  margin: auto 15%;
  height: 56px;
  padding: 20px 40px;
  border-radius: 8px;
  font-size: 16px;
  background: #FFFFFF url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  @media (max-width: 760px) {
    width: 100%;
    margin: auto;
  }
  `;

export const Testimonies = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  .company {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      max-width: 150px;
      margin-bottom: 20px;
    }
    span {
      width: 100%;
      text-align: center;
    }
    .name {
      font-weight: 700;
      margin: 0;
      font-size: 1rem;
      text-align: center;
    }
  }
  .bullet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 50px;
    padding-bottom: 30px;
    span {
      max-width: 380px;
    }
    .division {
      height: 250px;
      width: 5px;
      background: white;
    }
  }
  @media (max-width: 768px) {
    gap: 30px;
    margin-bottom: 32px;
    h2 {
      text-align: center;
    }
    .bullet {
      flex-direction: column;
      padding-bottom: 0;
      padding: 10px;
      box-sizing: border-box;
      .division {
        height: 5px;
        width: 250px;
        background: white;
      }
      .title {
        width: 100%;
      }
      .testimony {
        text-align: center;
      }
    }
  }
`;
