import { useEffect } from 'react';
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isMobile } from "react-device-detect";

const SvgBullet01 = () => {

  useEffect(() => {
    if (!isMobile) {
      setTimeout(() => {
      //SVG Animacion Bullet 01
      const animatedFigures = gsap.utils.toArray('.geo-figure');
      animatedFigures.forEach(animatedFigure => {
        gsap.from(animatedFigure, { 
          duration: 1.5,
          autoAlpha: 0,
          x: 80,
          ease: "back.out(1.5)",
          scrollTrigger: {
            trigger: animatedFigure,
            scrub: 1,
            end: "bottom +40%"
          }
        })
      });
      }, 700);
    }

    return () => {
      ScrollTrigger.getAll().forEach(t => t.kill());
    }
  }, [])

  return (
    <div>
      <svg width="512" height="455" viewBox="0 0 512 455" fill="none">
        <path d="M1 1V454.333" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M511 1.00073L1.00001 1.00071" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M57.6665 1.00073V454.334" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M511 57.6672L1.00001 57.6672" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M114.333 1.00073V454.334" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M511 114.334L1.00001 114.334" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M171 1.00073V454.334" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M511 171.001L1.00001 171.001" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M227.667 1.00073V454.334" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M511 227.667L1.00001 227.667" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M284.333 1.00073V454.334" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M511 284.334L1.00001 284.334" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M341 1.00073V454.334" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M511 341.001L1.00001 341.001" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M397.667 1.00073V454.334" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M511 397.667L1.00001 397.667" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M454.333 1.00073V454.334" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M511 454.334L1.00001 454.334" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <path d="M511 1.00073V454.334" stroke="white" strokeOpacity="0.1" strokeWidth="1.11111"/>
        <circle className="geo-figure" cx="312.666" cy="142.666" r="22.7778" fill="#00AA58"/>
        <circle className="geo-figure" cx="200.444" cy="198.221" r="22.7778" fill="#EF0000"/>
        <circle className="geo-figure" cx="312.666" cy="312.666" r="22.7778" fill="#FFA800"/>
        <circle className="geo-figure" cx="256" cy="368.221" r="22.7778" fill="#00AA58"/>
        <rect className="geo-figure" x="232.111" y="232.111" width="47.7778" height="47.7778" fill="#00AA58"/>
        <rect className="geo-figure" x="232" y="118.666" width="47.7778" height="47.7778" fill="#FFA800"/>
        <path className="geo-figure" d="M312.666 232.11L338.777 277.666L286.555 277.666L312.666 232.11Z" fill="#2240D9"/>
        <path className="geo-figure" d="M200.445 64.3331L226.556 109.889L174.334 109.889L200.445 64.3331Z" fill="#EF0000"/>
        <path className="geo-figure" d="M223.222 312.666L177.666 338.777V286.555L223.222 312.666Z" fill="#2240D9"/>
        <rect className="geo-figure" x="171" y="57.666" width="170" height="336.667" rx="30" stroke="white" strokeWidth="3"/>
      </svg>
    </div>
  )
}

export default SvgBullet01
