import styled, { keyframes } from 'styled-components';

const load = keyframes`
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
`;

const LoaderSkeleton = styled.div`
  position: relative;
  width: 100%;
  height: 18px;
  background-color: #9a9a9a;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
  transition: all 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0.7),
      transparent
    );
    animation: ${load} 2s infinite;
  }
`;

const TextSkeleton = () => {
  return <LoaderSkeleton />;
};

export default TextSkeleton;
