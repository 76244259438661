import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import { Vacancies } from './data/vacancies';

const GoodDesignIs = styled.b`
  width: 100%;
  position: relative;
  line-height: 120%;
  display: inline-block;
  font-size: 55px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  line-height: 120%
  @media screen and (max-width: 768px) {
    font-size: 39px;
  }

`;
const ImageIcon = styled.img`
  width: 201px;
  position: relative;
  height: 148px;
  overflow: hidden;
  flex-shrink: 0;
`;
const JoinOurInnovative = styled.b`
font-family: "DM Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 120%
`;
const OurMissionIs = styled.p`
  margin: 0;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.16px;
`;
const GoodDesignIsContainer = styled.div`
  flex: 1;
  position: relative;
  line-height: 120%;
`;
const ImageParent = styled.div`
  width: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  text-align: left;
  font-size: 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const GoodDesignIsParent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
const FrameWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;
const HeaderInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  background-color: #0e1d39;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 64px 120px;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 32px 32px;
  }
`;
const GoodDesignIs1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 44px;
  line-height: 120%;
  font-weight: 500;
  font-family: 'Red Hat Text';
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;
const GoodDesignIs_1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 28px;
  line-height: 120%;
  font-weight: 500;
  font-family: 'Red Hat Text';
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;
const ServicespersMgmntIcon = styled.img`
  width: 32px;
  position: relative;
  height: 32px;
`;
const ScrumMaster = styled.b`
  position: relative;
  line-height: 120%;
  width: 100%;
`;
const ScrumMasterParent = styled.div`
  display: flex;
  padding: 8px 16px;
  border-radius: 8px;
  border: 2px solid #2240d9;
  gap: 32px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${(props) => {
    return props.isTechVacancies ? '#2240d9' : 'transparent';
  }};
  &:hover {
    background-color: #2240d9;
  }
  cursor: pointer;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const GoodDesignIs2 = styled.b`
  // align-self: stretch;
  line-height: 120%;
`;
const Hiringwebeng = styled.div`
  border-radius: 8px;
  border: 2px solid #2240d9;
  padding: 32px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 16px;
  }
`;
const GoodDesignIsGroup = styled.div`
  width: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  @media screen and (max-width: 768px) {
    gap: 16px;
  }
`;
const HeaderChild = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Header1 = styled.div`
  width: 100%;
  background-color: #0e1d39;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 64px 90px;
  box-sizing: border-box;
  font-size: 20px;
  @media screen and (max-width: 768px) {
    padding: 32px;
  }
`;
const GoodDesignIs3 = styled.div`
  flex: 1;
  position: relative;
  font-family: "DM Sans";
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  @media screen and (max-width: 768px) {
    font-size: 31px;
  }
`;
const HorariosFlexiblesIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;
const GoodDesignIs4 = styled.b`
  position: relative;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 112%
`;
const HorariosFlexiblesParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;
const FrameGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  text-align: center;
  font-size: 16px;
  font-family: 'DM Sans';
`;
const Section = styled.div`
  align-self: stretch;
  background-color: #ffa800;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 64px 120px;
  gap: 64px;
  font-size: 44px;
  font-family: 'Red Hat Text';
  @media screen and (max-width: 768px) {
    padding: 32px;
    box-sizing: border-box;
    flex-direction: column;
    gap: 32px;
  }
`;
const HiringengRoot = styled.div`
  width: 100%;
  background-color: #0e1d39;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 55px;
  color: #fff;
  font-family: 'DM Sans';
`;

const Hiring = () => {
  const [selectedType, setSelectedType] = useState(null);
  const [isTechVacancies, setIsTechVacancies] = useState(false);

  // Esta función maneja el cambio del tipo seleccionado
  const handleSelectType = (type) => {
    setSelectedType(selectedType === type ? null : type); 
  };

  // Filtrar las vacantes basadas en el tipo seleccionado
  const filteredVacancies = Vacancies.filter(
    (vacancy) => selectedType && vacancy.type === selectedType
  );

  return (
    <HiringengRoot>
      <Header>
        <HeaderInner>
          <FrameWrapper>
            <GoodDesignIsParent>
              <GoodDesignIs>Trabaja con nosotros</GoodDesignIs>
              <ImageParent>
                <ImageIcon alt='' src='/v1/hiring/puzzle.svg' />
                <GoodDesignIsContainer>
                  <JoinOurInnovative>
                    ¡Únete a nuestro innovador equipo de Rockedianos!
                  </JoinOurInnovative>
                  <OurMissionIs>
                    Nuestra misión es trascender lo ordinario y generar un profundo impacto en cada proyecto que tocamos.
                  </OurMissionIs>
                </GoodDesignIsContainer>
              </ImageParent>
            </GoodDesignIsParent>
          </FrameWrapper>
        </HeaderInner>
      </Header>
      <Header1>
        <HeaderChild>
          <GoodDesignIsGroup>
            <GoodDesignIs1>Vacantes</GoodDesignIs1>
            <Grid
              container
              spacing={2}
              style={{
                display: 'flex',
                width: '100%',
                maxHeight: '100%',
              }}
            >
              {Array.from(new Set(Vacancies.map((v) => v.type))).map((type, index) => {
                const matchingVacancy = Vacancies.find(v => v.type === type);
                return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                  key={index} // Mover key aquí para evitar el warning de React
                >
                  <ScrumMasterParent onClick={() => handleSelectType(type)} isTechVacancies={selectedType === type}>
                    <ScrumMaster>{type}</ScrumMaster>
                    {matchingVacancy && <ServicespersMgmntIcon alt="" src={`/v1/hiring/${matchingVacancy.svg}.svg`} />}
                  </ScrumMasterParent>
                </Grid>
              )})}
            </Grid>
            {/* Mostrar las vacantes filtradas si hay un tipo seleccionado */}
            {selectedType && (
              <>
              <GoodDesignIs_1>{ selectedType }</GoodDesignIs_1>
              <Grid
                container
                spacing={2}
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                {filteredVacancies.map((vacancy, index) => (
                  <Grid item xs={12} md={6} lg={4} key={index}>
                    <Link to={`/v1/new-hiring/${vacancy.url}`}>
                      <Hiringwebeng>
                        <GoodDesignIs2>{vacancy.name}</GoodDesignIs2>
                      </Hiringwebeng>
                    </Link>
                  </Grid>
                ))}
              </Grid>
              </>
            )}
          </GoodDesignIsGroup>
        </HeaderChild>
      </Header1>
      <Section>
        <GoodDesignIs3>Explora nuestros beneficios</GoodDesignIs3>
        <FrameGroup>
          <HorariosFlexiblesParent>
            <HorariosFlexiblesIcon alt='' src='/v1/hiring/flexible-hours.svg' />
            <GoodDesignIs4>Horarios flexibles</GoodDesignIs4>
          </HorariosFlexiblesParent>
          <HorariosFlexiblesParent>
            <HorariosFlexiblesIcon
              alt=''
              src='/v1/hiring/hybrid-remote-roles.svg'
            />
            <GoodDesignIs4>Roles híbridos y remotos</GoodDesignIs4>
          </HorariosFlexiblesParent>
          <HorariosFlexiblesParent>
            <HorariosFlexiblesIcon alt='' src='/v1/hiring/referral-program.svg' />
            <GoodDesignIs4>Programa de referenciados</GoodDesignIs4>
          </HorariosFlexiblesParent>
          <HorariosFlexiblesParent>
            <HorariosFlexiblesIcon
              alt=''
              src='/v1/hiring/collaborative-environment-work.svg'
            />
            <GoodDesignIs4>Ambiente y trabajo en equipo colaborativo</GoodDesignIs4>
          </HorariosFlexiblesParent>
          <HorariosFlexiblesParent>
            <HorariosFlexiblesIcon
              alt=''
              src='/v1/hiring/activities-virtual-contests.svg'
            />
            <GoodDesignIs4>Actividades y concuros virtuales</GoodDesignIs4>
          </HorariosFlexiblesParent>
        </FrameGroup>
      </Section>
    </HiringengRoot>
  );
};

export default Hiring;
