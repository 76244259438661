import styled from "styled-components";

export const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  align-items: center;
  img {
    width: 240px; 
    height: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 150px;
  padding: 100px 10% 0;
  color: white;
  max-width: 1250px;
  margin: 0 auto;
  h2 {
    margin: 0;
  }
  @media (max-width: 768px) {
    padding: 60px 20px 0;
    gap: 70px;
  }
`;

export const Btn = styled.a`
  border: 0;
  box-sizing: border-box;
  color: ${({ secondary }) => (secondary ? "black" : "white")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 16px;
  width: 316px;
  height: 54px;
  background: ${({ secondary }) => (secondary ? "white" : "#25d366")};
  border-radius: 8px;
  cursor: pointer;
  margin: 20px 0;
  text-decoration: none; /* Para asegurarte de que no tenga subrayado */
  @media (max-width: 768px) {
    padding: 14px 20px;
    width: 100%;
    max-width: 316px;
  }
`;


export const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  p {
    max-width: 600px;
    margin-bottom: 50px;
  }
  img {
    box-sizing: border-box;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    img {
      max-width: 100%;
    }
    h1 {
      text-align: center;
    }
    p {
      max-width: 600px;
      margin: 10px 0 30px;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Services = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  gap: 70px;
  h2 {
    text-align: center
  }
  .bullet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 50px;
    div {
      max-width: 350px;
      h3 {
        margin: 15px 0;
      }
      ul {
        all: inherit;
        padding-left: 20px;
      }
      li {
        margin: 5px 0;
      }
    }
  }
  @media (max-width: 768px) {
    gap: 30px;
    .bullet {
      flex-direction: column;
      gap: 20px;
      div {
        h3 {
          font-size: 20px;
          font-weight: 700;
        }
      }
      img {
        max-height: 160px;
      }
      margin-bottom: 30px;
    }
  }
`;

export const Steps = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  .bullet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 50px;
    padding-bottom: 30px;
    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      margin: 0;
      width: 50%;
    }
    .cardImg {
      display: flex;
      flex-direction: row;
      justifiy-content: center;
      align-items: center;
    }
    img {
      position: relative;
      width: 120px;
      height: 120px;
      object-position: top;
      object-fit: cover;
      border-style: solid;
      border-radius: 50%;
      border-color: transparent;
      border-width: 2px;
      margin-right: 20px;
    }
    span {
      max-width: 380px;
      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    .division {
      height: 60px;
      width: 5px;
      background: white;
    }
  }
  @media (max-width: 768px) {
    gap: 30px;
    h2 {
      text-align: center;
    }
    .bullet {
      flex-direction: column;
      padding-bottom: 0;
      padding: 10px;
      .title {
        width: 100%;
      }
      .division {
        display: none;
      }
    }
  }
`;

export const Contact = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  p {
    text-align: center;
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
`;
