import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { BtnGeneral } from "../../style/btn-styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { IoMdUnlock, IoMdLock } from "react-icons/io";
// import TagManager from "react-gtm-module";
import GlobalStyles from "./CatalogServices-style";

const animationShadowInsetCenter = keyframes`
    0% {
        box-shadow: inset 0 0 0 0 transparent;
    }
    100% {
        box-shadow: inset 0 0 14px 0 rgb(0 0 0 / 50%);
    }
`;
const GoodDesignIs8 = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 66px;

  @media screen and (max-width: 768px) {
    font-size: var(--font-size-21xl);
  }
`;
const GoodDesignIs9 = styled.div`
  position: relative;
  font-size: var(--common-body-size);
  line-height: 120%;
  text-align: left;
  display: inline-block;
  width: 75%;
  @media screen and (max-width: 768px) {
    font-size: var(--common-small-body-size);
    text-align: justify;
    align-self: stretch;
    width: auto;
  }
`;
const GoodDesignIsContainer = styled.div`
  align-self: stretch;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
  @media screen and (max-width: 768px) {
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
  }
`;
const Header2 = styled.div`
  align-self: stretch;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-45xl) var(--padding-101xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--desktop-h1-size);
  margin-top: 64px;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 170px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0px;
    padding-right: 0px;
    box-sizing: border-box;
  }
`;
const Reassurance = styled.b`
  position: relative;
  font-size: var(--common-title-size);
  line-height: 120%;
  font-family: var(--common-body);
  color: var(--white);
  text-align: left;
`;
const ServicesassuranceIcon = styled.img`
  position: relative;
  width: 32px;
  height: 32px;
`;
const ReassuranceParent = styled.button`
  cursor: pointer;
  border: none;
  padding: var(--padding-5xs) var(--padding-base);
  background-color: var(--secondary-blue);
  border-radius: var(--br-5xs);
  width: 500px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: var(--gap-13xl);
  min-width: 250px;
  &:hover {
    cursor: pointer;
    animation: 1s step-start 0s 1 normal forwards ${animationShadowInsetCenter};
    opacity: 1;
  }
  @media screen and (max-width: 1200px) {
    width: 50%;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;
const ReassuranceVehiculeParent = styled.button`
  cursor: pointer;
  border: 2px solid var(--secondary-blue);
  padding: var(--padding-5xs) var(--padding-31xl);
  background-color: transparent;
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  width: 500px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-13xl);
  min-width: 250px;
  &:hover {
    border: 5px solid #223fd8;
  }
  @media screen and (max-width: 1200px) {
    width: 50%;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
const FrameGroup = styled.div`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-13xl);
  @media screen and (max-width: 1200px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-21xl);
    align-items: center;
    justify-content: center;
    justify-items: center;
  }
`;
const GoodDesignIs10 = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  display: inline-block;
  height: 66px;
  flex-shrink: 0;
  @media screen and (max-width: 350px) {
    font-size: var(--font-size-21xl);
  }
`;
const GoodDesignIs11 = styled.div`
  position: relative;
  font-size: var(--common-body-size);
  letter-spacing: -0.01em;
  line-height: 120%;
`;
const FrameDiv = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  @media screen and (max-width: 350px) {
    align-self: stretch;
    width: auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
`;
const GoodDesignIs12 = styled.div`
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 120%;
  @media screen and (max-width: 350px) {
    text-align: left;
  }
`;
const GoodDesignIsWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media screen and (max-width: 350px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
const FrameWrapper1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--common-body-size);
  @media screen and (max-width: 350px) {
    width: 100%;
    flex-direction: row;
  }
`;
const FrameContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-13xl);
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 350px) {
    width: 90%;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
    box-sizing: border-box;
  }
`;
const FrameWrapper = styled.div`
  border-radius: var(--br-5xs);
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  @media screen and (max-width: 1200px) {
    align-self: stretch;
    width: auto;
    align-items: center;
    justify-content: space-between;
    gap: 0px;
    padding-right: 0px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 768px) {
    width: auto;
    align-self: unset;
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 350px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
`;
const GoodDesignIs13 = styled.b`
  flex: 1;
  position: relative;
  line-height: 120%;
`;
const GoodDesignText = styled.b`
  flex: 1;
  position: relative;
  line-height: 24px;
  font-size: 20px;
  font-weight: 700;
`;
const GoodDesignIsFrame = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const FrameWrapper2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const GoodDesignIs14 = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  font-size: var(--desktop-h3-size);
  line-height: 120%;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: space-between;
  }
`;
const Aws = styled.div`
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 120%;
  font-weight: 700;
`;
const Iconsdropleft = styled.img`
  position: relative;
  width: 24px;
  height: 24px;
  object-fit: contain;
`;
const AwsParent = styled.div`
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
  gap: var(--gap-13xl);
`;
const Contact = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const FrameParent3 = styled.div`
  align-self: stretch;
  border-radius: var(--br-5xs);
  border: 2px solid var(--color-orange);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  font-size: var(--common-body-size);
`;
const AwsGroup = styled.div`
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
`;
const AwsGroupContent = styled.div`
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  align-content: space-between;
  flex-direction: column;
  padding: var(--padding-5xs) var(--padding-base);
  font-size: var(--common-body-size);
`;
const GoodDesignIsParent2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
  gap: var(--gap-base);
`;
const FrameWrapper3 = styled.div`
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const FrameWrapper4 = styled.div`
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const FrameParent2 = styled.div`
  align-self: stretch;
  border-radius: var(--br-5xs);
  border: 2px solid var(--secondary-green);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-13xl);
  font-size: var(--common-body-size);
`;
const GoodDesignIsParent1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const FrameParent1 = styled.div`
  border-radius: var(--br-5xs);
  border: 2px solid var(--secondary-blue);
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-13xl);
  gap: var(--gap-13xl);
  font-size: var(--common-title-size);
  @media screen and (max-width: 1200px) {
    align-self: stretch;
    width: auto;
  }
  @media screen and (max-width: 960px) {
    height: auto;
  }
  @media screen and (max-width: 350px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const FrameParent = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  @media screen and (max-width: 1200px) {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
  }
  @media screen and (max-width: 768px) {
    align-self: stretch;
    width: auto;
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
  }
`;
const Property1defaultInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: center;
  }
`;
const Property1default = styled.div`
  //   position: absolute;
  top: 0px;
  left: 0px;
  background-color: var(--navy);
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-45xl) var(--padding-101xl);
  box-sizing: border-box;
  @media screen and (max-width: 1200px) {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    height: auto;
    align-items: flex-start;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 768px) {
    height: auto;
  }
  @media screen and (max-width: 350px) {
    height: 90rem;
    align-items: center;
    justify-content: flex-start;
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
`;
const Header3 = styled.div`
  align-self: stretch;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--desktop-h1-size);
`;
const ServicesCopyRoot = styled.div`
  position: relative;
  background-color: var(--navy);
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-6xl-6);
  color: var(--white);
  font-family: var(--common-body);
  @media screen and (max-width: 1200px) {
    width: auto;
    align-self: unset;
    align-items: center;
    justify-content: flex-start;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-13xl);
  margin-top: 20px;
`;

const StyledButton = styled.button`
  background-color: #2240d9;
  width: 100%;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2240d999;
  }
`;

const StyledButtonClear = styled.button`
  background-color: #ffffff;
  width: 100%;
  color: #2240d9;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ffffff99;
  }
`;

const ListContainer = styled.div`
  background-color: #333;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: auto;
  width: 100%;
`;

const ListContent = styled.div`
  max-height: 370px;
  width: auto;
  margin: auto;
  overflow-y: auto;
`;

const ListItem = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  &:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0;
    font-size: 16px;
    color: #ffffff;
  }
`;

const TableContainer = styled.div`
  background-color: #333;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: auto;
  width: 100%;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: #555;
  color: white;
  padding: 10px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #474747;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  color: #ffffff;
  border-bottom: 1px solid #777;
`;

const AwsGroupContentParameter = styled.div`
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  align-content: space-between;
  flex-direction: column;
  padding: var(--padding-5xs) var(--padding-base);
  font-size: var(--common-body-size);
`;

const AwsGroupContentParameterItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-13xl);
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const Label = styled.label`
  width: 15%;
  margin-bottom: 5px;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    width: 100%;
    align-self: flex-start;
  }
`;

const Select = styled.select`
  width: 85%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Option = styled.option`
  padding: 20px;
`;

const Input = styled.input`
  width: 83%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  @media screen and (max-width: 768px) {
    width: 96%;
  }
  @media screen and (max-width: 430px) {
    width: 90%;
  }
`;

const ButtonLock = styled.button`
  background-color: transparent;
  border: 2px solid #4caf50;
  color: #4caf50;
  padding: 8px 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  gap: 8px;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: #4caf50;
    color: white;
  }
`;

const CatalogServices = () => {
  const { t } = useTranslation(["catalog-services"]);

  const [isAwsVisible, setIsAwsVisible] = useState({
    aws: false,
    aws1: false,
    aws2: false,
    aws3: false,
    aws4: false,
  });
  const [isParametersVisible, setIsParametersVisible] = useState({
    aws: false,
    aws1: false,
    aws2: false,
    aws3: false,
    aws4: false,
  });
  const [isResponsesVisible, setIsResponsesVisible] = useState({
    aws: false,
    aws1: false,
    aws2: false,
    aws3: false,
    aws4: false,
  });

  const toggleAwsVisibility = ({ section, response }) => {
    setIsAwsVisible((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
    setIsParametersVisible((prevState) => ({
      ...prevState,
      [section]: false,
    }));
    setIsResponsesVisible((prevState) => ({
      ...prevState,
      [section]: false,
    }));
    setData((prevState) => ({
      ...prevState,
      [response]: null,
    }));
  };

  const toggleParametersVisibility = (section) => {
    setIsParametersVisible((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const toggleResponsesVisibility = (section) => {
    setIsResponsesVisible((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const [isTimeout, setIsTimeout] = useState(false);

  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedSubBrand, setSelectedSubBrand] = useState("");
  const [selectdVehicleExtens, setSelectedVehicleExtens] = useState("");
  const [inputVehicleShorts, setInputVehicleShorts] = useState("");

  const [data, setData] = useState({
    brands: null,
    brandOption: null,
    subbrands: null,
    subbrandOption: null,
    descriptionVehicles: null,
    descriptionVehicleOption: null,
    vehicleExtens: null,
    vehicleShorts: null,
  });

  const fetchData = async ({ url, params, section }) => {
    const username = auth.username;
    const password = auth.password;
    const headers = new Headers();
    headers.append("Authorization", "Basic " + btoa(username + ":" + password));
    headers.append("Accept", "application/json");

    // Función para convertir el objeto de parámetros en una cadena de consulta
    let queryParams = params ? new URLSearchParams(params).toString() : "";
    queryParams = queryParams.replace(/\+/g, "%");
    const fullUrl = queryParams ? `${url}?${queryParams}` : url;
    // console.log("fullUrl:", fullUrl);

    try {
      const baseUrl = process.env.REACT_APP_API_URL_CATALOGO_DOCUMENTACION;
      const response = await fetch(
        `${baseUrl}${fullUrl}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      // console.log(response);

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      // console.log("response:", response);

      const data = await response.json();
      setData((prevState) => ({
        ...prevState,
        [section]: data,
      }));

      // console.log(`data.${[section]}:`, data);
      setIsTimeout(false);
    } catch (error) {
      console.error("Error al realizar la solicitud:", error);
      setIsTimeout(false);
    }
  };

  const handleFetchData = ({ link, param, sections, response }) => {
    setIsTimeout(true);

    fetchData({ url: link, params: param, section: sections });

    setIsResponsesVisible((prevState) => ({
      ...prevState,
      [response]: true,
    }));
  };

  const clearData = ({ section }) => {
    if (data[section] !== null) {
      setData((prevState) => ({
        ...prevState,
        [section]: null,
      }));
    }
    setIsTimeout(false);
  };

  // useEffect(() => {
  //   if (data.brandOption === null || data.brandOption.length === 0) {
  //     handleFetchData({
  //       link: "catalogs/qualitas/brands/all",
  //       sections: "brandOption",
  //     });
  //   }
  // }, [data.brandOption]);

  useEffect(() => {
    if (selectedBrand !== "" && selectedModel !== "") {
      handleFetchData({
        link: "catalogs/qualitas/subbrands",
        sections: "subbrandOption",
        param: {
          brand: selectedBrand,
          model: selectedModel,
        },
      });
    }
  }, [selectedBrand, selectedModel]);

  useEffect(() => {
    if (
      selectedBrand !== "" &&
      selectedModel !== "" &&
      selectedSubBrand !== ""
    ) {
      handleFetchData({
        link: "catalogs/qualitas/description",
        sections: "descriptionVehicleOption",
        param: {
          brand: selectedBrand,
          model: selectedModel,
          subBrand: selectedSubBrand,
        },
      });
    }
  }, [selectedBrand, selectedModel, selectedSubBrand]);

  //MODAL
  const [auth, setAuth] = useState({
    username: "",
    password: "",
  });

  const [lock, setLock] = useState(false);

  const deviceDialogStyles = () => {
    return {
      root: {
        position: "absolute",
        backgroundColor: "#FFA800",
        backgroundImage: 'url("./Group 381.png")',
        border: "none",
        outline: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "50px 60px",
        width: "auto", // Ancho por defecto
        height: "auto", // Altura por defecto
        "@media (max-width: 600px)": {
          // width: "70%", // Ancho para pantallas pequeñas
          // height: "40%", // Altura para pantallas pequeñas
          padding: "30px 20px", // Padding ajustado para pantallas pequeñas
        },
      },
    };
  };

  const useDialogStyles = makeStyles(() => deviceDialogStyles());

  const useBackdropStyles = makeStyles({
    root: {
      backgroundColor: "rgba(14,29,57,0.7)",
      backdropFilter: "blur(5px)",
    },
  });

  const [dialogStyle] = useState(getDialogStyle);
  const dialogClasses = useDialogStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const backdropClasses = useBackdropStyles();
  const [errorAuth, setErrorAuth] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  function getDialogStyle() {
    return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
      border: "none",
    };
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAuth((prevAuth) => ({
      ...prevAuth,
      [name]: value,
    }));
  };

  const submitAuth = () => {
    setAuth({
      username: document.getElementById("username").value,
      password: document.getElementById("password").value,
    });
    handleCloseDialog();

    // console.log("auth:", auth.password);

    handleFetchData({
      link: "catalogs/qualitas/brands/all",
      sections: "brandOption",
    });

    setLock(true);
    setErrorAuth(true);
  };

  const bodyDialog = () => (
    <div className={dialogClasses.root} style={dialogStyle}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <h1
          style={{
            fontFamily: "DM Sans",
            fontSize: "40px",
            margin: "0px",
            textAlign: "center",
            fontWeight: "700",
            color: "black",
            lineHeight: "50px",
          }}
        >
          {`${t("modal.title")}`}
        </h1>
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "18px",
            textAlign: "center",
            color: "black",
          }}
        >
          {`${t("modal.subtitle1")}`}
          <Input
            placeholder={`${t("modal.subtitle1")}`}
            type="email"
            id="username"
            name="username"
            onChange={handleInputChange}
            value={auth.username}
          />
          <br />
          {`${t("modal.subtitle2")}`}
          <Input
            placeholder={`${t("modal.subtitle2")}`}
            type="password"
            id="password"
            name="password"
            onChange={handleInputChange}
            value={auth.password}
          />
          {auth.username === "" && auth.password === ""
            ? null
            : errorAuth &&
              (data.brandOption?.message ? (
                <p
                  style={{
                    fontFamily: "DM Sans",
                    fontStyle: "italic",
                    fontSize: "16px",
                    textAlign: "center",
                    color: "red",
                    margin: "0px",
                  }}
                >
                  {t("modal.errorFailed")}
                </p>
              ) : (
                <p
                  style={{
                    fontFamily: "DM Sans",
                    fontStyle: "italic",
                    fontSize: "16px",
                    textAlign: "center",
                    color: "green",
                    margin: "0px",
                  }}
                >
                  {t("modal.errorSuccess")}
                </p>
              ))
            }
        </p>
        <BtnGeneral
          to="/v1/catalogos-documentacion"
          style={{ margin: "5px 30px 20px" }}
          onClick={() => submitAuth()}
        >
          {t("modal.button")}
        </BtnGeneral>
      </div>
    </div>
  );

  //FINMODAL

  return (
    <>
      <GlobalStyles />
      <Modal
        onClose={handleCloseDialog}
        open={openDialog}
        BackdropComponent={Backdrop}
        BackdropProps={{ classes: { root: backdropClasses.root } }}
      >
        {bodyDialog()}
      </Modal>
      <ServicesCopyRoot>
        <Header2>
          <GoodDesignIsContainer>
            <GoodDesignIs8>{`${t("header.title")}`}</GoodDesignIs8>
            <GoodDesignIs9>{`${t("header.subtitle")}`}</GoodDesignIs9>
          </GoodDesignIsContainer>
        </Header2>
        <Header3>
          <Property1default>
            <Property1defaultInner>
              <FrameParent>
                <FrameGroup>
                  <ReassuranceParent>
                    <Reassurance>{`${t("section1.button1")}`}</Reassurance>
                    <ServicesassuranceIcon
                      alt="Icon-Reassurance"
                      src="/v1/Servicios3.svg"
                    />
                  </ReassuranceParent>
                  {/* <ReassuranceVehiculeParent>
                    <Reassurance>{`${t("section1.button2")}`}</Reassurance>
                    <ServicesassuranceIcon
                      alt="Icon-Reassurance-Vehicule"
                      src="/Servicios2.svg"
                    />
                  </ReassuranceVehiculeParent> */}
                </FrameGroup>
                <FrameWrapper>
                  <FrameContainer>
                    <FrameDiv>
                      <GoodDesignIs10>{`${t(
                        "section1.title"
                      )}`}</GoodDesignIs10>
                      <GoodDesignIs11>{`${t(
                        "section1.subtitle"
                      )}`}</GoodDesignIs11>
                    </FrameDiv>
                    <FrameWrapper1>
                      <GoodDesignIsWrapper>
                        <GoodDesignIs12>{`${t(
                          "section1.text"
                        )}`}</GoodDesignIs12>
                      </GoodDesignIsWrapper>
                    </FrameWrapper1>
                  </FrameContainer>
                </FrameWrapper>
                <FrameParent1>
                  <FrameWrapper2>
                    <GoodDesignIsFrame>
                      <GoodDesignIs13>
                        {`${t("section2.title1")} `} /{" "}
                        {` ${t("section2.title2")}`}
                      </GoodDesignIs13>
                    </GoodDesignIsFrame>
                  </FrameWrapper2>
                  <FrameWrapper3>
                    <GoodDesignIsParent1>
                      <GoodDesignIs14>
                        <div>{`${t("section2.subtitle")}`}</div>
                        <ButtonLock onClick={() => setOpenDialog(true)}>
                          {`${t("section2.button")}`}
                          {lock ? <IoMdLock /> : <IoMdUnlock />}
                        </ButtonLock>
                      </GoodDesignIs14>
                      <FrameParent2>
                        <AwsParent>
                          <GoodDesignText>{`${t(
                            "accordion1.title"
                          )}`}</GoodDesignText>
                          <Iconsdropleft
                            alt="icon-arrow"
                            src={
                              isAwsVisible["aws"]
                                ? "/v1/arrow-up.svg"
                                : "/v1/arrow-down.svg"
                            }
                            onClick={() =>
                              toggleAwsVisibility({
                                section: "aws",
                                response: "brands",
                              })
                            }
                          />
                        </AwsParent>

                        {isAwsVisible["aws"] && (
                          <>
                            <FrameWrapper4>
                              <GoodDesignIsParent2>
                                <Contact>{`${t(
                                  "accordion1.subtitle1"
                                )}`}</Contact>
                                <FrameParent3>
                                  <AwsGroup>
                                    <Aws>{`${t("accordion1.text1")}`}</Aws>
                                    <Iconsdropleft
                                      alt="icon-arrow"
                                      src={
                                        isParametersVisible["aws"]
                                          ? "/v1/arrow-up.svg"
                                          : "/v1/arrow-down.svg"
                                      }
                                      onClick={() =>
                                        toggleParametersVisibility("aws")
                                      }
                                    />
                                  </AwsGroup>
                                  {isParametersVisible["aws"] && (
                                    <AwsGroupContent>
                                      {`${t("accordion1.content1")}`}
                                      <ButtonsContainer>
                                        <StyledButton
                                          onClick={() =>
                                            handleFetchData({
                                              link: "catalogs/qualitas/brands/all",
                                              sections: "brands",
                                              response: "aws",
                                            })
                                          }
                                        >
                                          {`${t("accordion1.button1")}`}
                                        </StyledButton>
                                        <StyledButtonClear
                                          onClick={() =>
                                            clearData({ section: "brands" })
                                          }
                                        >
                                          {`${t("accordion1.button2")}`}
                                        </StyledButtonClear>
                                      </ButtonsContainer>
                                    </AwsGroupContent>
                                  )}
                                </FrameParent3>
                              </GoodDesignIsParent2>
                            </FrameWrapper4>
                            <FrameWrapper4>
                              <GoodDesignIsParent2>
                                <Contact>{`${t(
                                  "accordion1.subtitle2"
                                )}`}</Contact>
                                <FrameParent3>
                                  <AwsGroup>
                                    <Aws>{`${t("accordion1.text2")}`}</Aws>
                                    <Iconsdropleft
                                      alt="icon-arrow"
                                      src={
                                        isResponsesVisible["aws"]
                                          ? "/v1/arrow-up.svg"
                                          : "/v1/arrow-down.svg"
                                      }
                                      onClick={() =>
                                        toggleResponsesVisibility("aws")
                                      }
                                    />
                                  </AwsGroup>
                                  {isResponsesVisible["aws"] && (
                                    <AwsGroupContent>
                                      {data.brands && data.brands.length > 0 ? (
                                        <ListContainer>
                                          <ListContent>
                                            {data.brands.map((item, index) => (
                                              <ListItem key={index}>
                                                <p>{item}</p>
                                              </ListItem>
                                            ))}
                                          </ListContent>
                                        </ListContainer>
                                      ) : isTimeout ? (
                                        <p>{`${t("accordion1.loading")}`}</p>
                                      ) : (
                                        <p>{`${t("accordion1.content2")}`}</p>
                                      )}
                                    </AwsGroupContent>
                                  )}
                                </FrameParent3>
                              </GoodDesignIsParent2>
                            </FrameWrapper4>
                          </>
                        )}
                      </FrameParent2>
                      <FrameParent2>
                        <AwsParent>
                          <GoodDesignText>{`${t(
                            "accordion2.title"
                          )}`}</GoodDesignText>
                          <Iconsdropleft
                            alt="icon-arrow"
                            src={
                              isAwsVisible["aws1"]
                                ? "/v1/arrow-up.svg"
                                : "/v1/arrow-down.svg"
                            }
                            onClick={() =>
                              toggleAwsVisibility({
                                section: "aws1",
                                response: "subbrands",
                              })
                            }
                          />
                        </AwsParent>

                        {isAwsVisible["aws1"] && (
                          <>
                            <FrameWrapper4>
                              <GoodDesignIsParent2>
                                <Contact>{`${t(
                                  "accordion2.subtitle1"
                                )}`}</Contact>
                                <FrameParent3>
                                  <AwsGroup>
                                    <Aws>{`${t("accordion2.text1")}`}</Aws>
                                    <Iconsdropleft
                                      alt="icon-arrow"
                                      src={
                                        isParametersVisible["aws1"]
                                          ? "/v1/arrow-up.svg"
                                          : "/v1/arrow-down.svg"
                                      }
                                      onClick={() => {
                                        toggleParametersVisibility("aws1");
                                      }}
                                    />
                                  </AwsGroup>
                                  {isParametersVisible["aws1"] && (
                                    <AwsGroupContent>
                                      <AwsGroupContentParameter>
                                        <AwsGroupContentParameterItem>
                                          <Label htmlFor="brand">
                                            {`${t("accordion2.label1")}`}
                                          </Label>
                                          <Select
                                            id="brand"
                                            name="brand"
                                            onChange={(e) =>
                                              setSelectedBrand(e.target.value)
                                            }
                                            value={selectedBrand}
                                          >
                                            <Option value="">{`${t(
                                              "accordion2.option1"
                                            )}`}</Option>
                                            {data.brandOption &&
                                            data.brandOption.length > 0 ? (
                                              data.brandOption.map(
                                                (item, index) => (
                                                  <Option
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </Option>
                                                )
                                              )
                                            ) : (
                                              <Option disabled>
                                                {`${t("accordion2.option2")}`}
                                              </Option> // Mensaje cuando no hay datos
                                            )}
                                          </Select>
                                        </AwsGroupContentParameterItem>
                                        <AwsGroupContentParameterItem>
                                          <Label htmlFor="model">
                                            {`${t("accordion2.label2")}`}
                                          </Label>
                                          <Select
                                            id="model"
                                            name="model"
                                            onChange={(e) =>
                                              setSelectedModel(e.target.value)
                                            }
                                            value={selectedModel}
                                          >
                                            <Option value="">{`${t(
                                              "accordion2.option1"
                                            )}`}</Option>
                                            <Option value="2019">2019</Option>
                                            <Option value="2020">2020</Option>
                                            <Option value="2021">2021</Option>
                                          </Select>
                                        </AwsGroupContentParameterItem>
                                      </AwsGroupContentParameter>

                                      <ButtonsContainer>
                                        <StyledButton
                                          onClick={() =>
                                            handleFetchData({
                                              link: "catalogs/qualitas/subbrands",
                                              sections: "subbrands",
                                              param: {
                                                brand: selectedBrand,
                                                model: selectedModel,
                                              },
                                              response: "aws1",
                                            })
                                          }
                                        >
                                          {`${t("accordion1.button1")}`}
                                        </StyledButton>
                                        <StyledButtonClear
                                          onClick={() =>
                                            clearData({ section: "subbrands" })
                                          }
                                        >
                                          {`${t("accordion1.button2")}`}
                                        </StyledButtonClear>
                                      </ButtonsContainer>
                                    </AwsGroupContent>
                                  )}
                                </FrameParent3>
                              </GoodDesignIsParent2>
                            </FrameWrapper4>
                            <FrameWrapper4>
                              <GoodDesignIsParent2>
                                <Contact>{`${t(
                                  "accordion2.subtitle2"
                                )}`}</Contact>
                                <FrameParent3>
                                  <AwsGroup>
                                    <Aws>{`${t("accordion2.text2")}`}</Aws>
                                    <Iconsdropleft
                                      alt="icon-arrow"
                                      src={
                                        isResponsesVisible["aws1"]
                                          ? "/v1/arrow-up.svg"
                                          : "/v1/arrow-down.svg"
                                      }
                                      onClick={() =>
                                        toggleResponsesVisibility("aws1")
                                      }
                                    />
                                  </AwsGroup>
                                  {isResponsesVisible["aws1"] && (
                                    <AwsGroupContent>
                                      {data.subbrands &&
                                      data.subbrands.length > 0 ? (
                                        <ListContainer>
                                          <ListContent>
                                            {data.subbrands.map(
                                              (item, index) => (
                                                <ListItem key={index}>
                                                  <p>{item}</p>
                                                </ListItem>
                                              )
                                            )}
                                          </ListContent>
                                        </ListContainer>
                                      ) : isTimeout ? (
                                        <p>{`${t("accordion1.loading")}`}</p>
                                      ) : (
                                        <p>{`${t("accordion1.content2")}`}</p>
                                      )}
                                    </AwsGroupContent>
                                  )}
                                </FrameParent3>
                              </GoodDesignIsParent2>
                            </FrameWrapper4>
                          </>
                        )}
                      </FrameParent2>
                      <FrameParent2>
                        <AwsParent>
                          <GoodDesignText>{`${t(
                            "accordion3.title"
                          )}`}</GoodDesignText>
                          <Iconsdropleft
                            alt="icon-arrow"
                            src={
                              isAwsVisible["aws2"]
                                ? "/v1/arrow-up.svg"
                                : "/v1/arrow-down.svg"
                            }
                            onClick={() =>
                              toggleAwsVisibility({
                                section: "aws2",
                                response: "descriptionVehicles",
                              })
                            }
                          />
                        </AwsParent>

                        {isAwsVisible["aws2"] && (
                          <>
                            <FrameWrapper4>
                              <GoodDesignIsParent2>
                                <Contact>{`${t(
                                  "accordion3.subtitle1"
                                )}`}</Contact>
                                <FrameParent3>
                                  <AwsGroup>
                                    <Aws>{`${t("accordion3.text1")}`}</Aws>
                                    <Iconsdropleft
                                      alt="icon-arrow"
                                      src={
                                        isParametersVisible["aws2"]
                                          ? "/v1/arrow-up.svg"
                                          : "/v1/arrow-down.svg"
                                      }
                                      onClick={() =>
                                        toggleParametersVisibility("aws2")
                                      }
                                    />
                                  </AwsGroup>
                                  {isParametersVisible["aws2"] && (
                                    <AwsGroupContent>
                                      <AwsGroupContentParameter>
                                        <AwsGroupContentParameterItem>
                                          <Label htmlFor="brand">
                                            {`${t("accordion3.label1")}`}
                                          </Label>
                                          <Select
                                            id="brand"
                                            name="brand"
                                            onChange={(e) =>
                                              setSelectedBrand(e.target.value)
                                            }
                                            value={selectedBrand}
                                          >
                                            <Option value="">{`${t(
                                              "accordion3.option1"
                                            )}`}</Option>
                                            {data.brandOption &&
                                            data.brandOption.length > 0 ? (
                                              data.brandOption.map(
                                                (item, index) => (
                                                  <Option
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </Option>
                                                )
                                              )
                                            ) : (
                                              <Option disabled>
                                                {`${t("accordion3.option2")}`}
                                              </Option> // Mensaje cuando no hay datos
                                            )}
                                          </Select>
                                        </AwsGroupContentParameterItem>
                                        <AwsGroupContentParameterItem>
                                          <Label htmlFor="model">
                                            {`${t("accordion3.label2")}`}
                                          </Label>
                                          <Select
                                            id="model"
                                            name="model"
                                            onChange={(e) =>
                                              setSelectedModel(e.target.value)
                                            }
                                            value={selectedModel}
                                          >
                                            <Option value="">{`${t(
                                              "accordion3.option1"
                                            )}`}</Option>
                                            <Option value="2019">2019</Option>
                                            <Option value="2020">2020</Option>
                                            <Option value="2021">2021</Option>
                                          </Select>
                                        </AwsGroupContentParameterItem>
                                        <AwsGroupContentParameterItem>
                                          <Label htmlFor="subBrand">
                                            {`${t("accordion3.label3")}`}
                                          </Label>
                                          <Select
                                            id="subBrand"
                                            name="subBrand"
                                            onChange={(e) =>
                                              setSelectedSubBrand(
                                                e.target.value
                                              )
                                            }
                                            value={selectedSubBrand}
                                          >
                                            <Option value="">{`${t(
                                              "accordion3.option1"
                                            )}`}</Option>
                                            {data.subbrandOption &&
                                            data.subbrandOption.length > 0 ? (
                                              data.subbrandOption.map(
                                                (item, index) => (
                                                  <Option
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </Option>
                                                )
                                              )
                                            ) : (
                                              <Option disabled>
                                                {`${t("accordion3.option3")}`}
                                              </Option> // Mensaje cuando no hay datos
                                            )}
                                          </Select>
                                        </AwsGroupContentParameterItem>
                                      </AwsGroupContentParameter>

                                      <ButtonsContainer>
                                        <StyledButton
                                          onClick={() =>
                                            handleFetchData({
                                              link: "catalogs/qualitas/description",
                                              sections: "descriptionVehicles",
                                              param: {
                                                brand: selectedBrand,
                                                model: selectedModel,
                                                subBrand: selectedSubBrand,
                                              },
                                              response: "aws2",
                                            })
                                          }
                                        >
                                          {`${t("accordion1.button1")}`}
                                        </StyledButton>
                                        <StyledButtonClear
                                          onClick={() =>
                                            clearData({
                                              section: "descriptionVehicles",
                                            })
                                          }
                                        >
                                          {`${t("accordion1.button2")}`}
                                        </StyledButtonClear>
                                      </ButtonsContainer>
                                    </AwsGroupContent>
                                  )}
                                </FrameParent3>
                              </GoodDesignIsParent2>
                            </FrameWrapper4>
                            <FrameWrapper4>
                              <GoodDesignIsParent2>
                                <Contact>{`${t(
                                  "accordion3.subtitle2"
                                )}`}</Contact>
                                <FrameParent3>
                                  <AwsGroup>
                                    <Aws>{`${t("accordion3.text2")}`}</Aws>
                                    <Iconsdropleft
                                      alt="icon-arrow"
                                      src={
                                        isResponsesVisible["aws2"]
                                          ? "/v1/arrow-up.svg"
                                          : "/v1/arrow-down.svg"
                                      }
                                      onClick={() =>
                                        toggleResponsesVisibility("aws2")
                                      }
                                    />
                                  </AwsGroup>
                                  {isResponsesVisible["aws2"] && (
                                    <AwsGroupContent>
                                      <AwsGroupContent>
                                        {data.descriptionVehicles &&
                                        data.descriptionVehicles.length > 0 ? (
                                          <ListContainer>
                                            <ListContent>
                                              {data.descriptionVehicles.map(
                                                (item, index) => (
                                                  <ListItem key={index}>
                                                    <p>{item}</p>
                                                  </ListItem>
                                                )
                                              )}
                                            </ListContent>
                                          </ListContainer>
                                        ) : isTimeout ? (
                                          <p>{`${t("accordion1.loading")}`}</p>
                                        ) : (
                                          <p>{`${t("accordion1.content2")}`}</p>
                                        )}
                                      </AwsGroupContent>
                                    </AwsGroupContent>
                                  )}
                                </FrameParent3>
                              </GoodDesignIsParent2>
                            </FrameWrapper4>
                          </>
                        )}
                      </FrameParent2>
                      <FrameParent2>
                        <AwsParent>
                          <GoodDesignText>{`${t(
                            "accordion4.title"
                          )}`}</GoodDesignText>
                          <Iconsdropleft
                            alt="icon-arrow"
                            src={
                              isAwsVisible["aws3"]
                                ? "/v1/arrow-up.svg"
                                : "/v1/arrow-down.svg"
                            }
                            onClick={() =>
                              toggleAwsVisibility({
                                section: "aws3",
                                response: "vehicleExtens",
                              })
                            }
                          />
                        </AwsParent>

                        {isAwsVisible["aws3"] && (
                          <>
                            <FrameWrapper4>
                              <GoodDesignIsParent2>
                                <Contact>{`${t(
                                  "accordion4.subtitle1"
                                )}`}</Contact>
                                <FrameParent3>
                                  <AwsGroup>
                                    <Aws>{`${t("accordion4.text1")}`}</Aws>
                                    <Iconsdropleft
                                      alt="icon-arrow"
                                      src={
                                        isParametersVisible["aws3"]
                                          ? "/v1/arrow-up.svg"
                                          : "/v1/arrow-down.svg"
                                      }
                                      onClick={() =>
                                        toggleParametersVisibility("aws3")
                                      }
                                    />
                                  </AwsGroup>
                                  {isParametersVisible["aws3"] && (
                                    <AwsGroupContent>
                                      <AwsGroupContentParameter>
                                        <AwsGroupContentParameterItem>
                                          <Label htmlFor="brand">
                                            {`${t("accordion4.label1")}`}
                                          </Label>
                                          <Select
                                            id="brand"
                                            name="brand"
                                            onChange={(e) =>
                                              setSelectedBrand(e.target.value)
                                            }
                                            value={selectedBrand}
                                          >
                                            <Option value="">{`${t(
                                              "accordion4.option1"
                                            )}`}</Option>
                                            {data.brandOption &&
                                            data.brandOption.length > 0 ? (
                                              data.brandOption.map(
                                                (item, index) => (
                                                  <Option
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </Option>
                                                )
                                              )
                                            ) : (
                                              <Option disabled>
                                                {`${t("accordion4.option2")}`}
                                              </Option> // Mensaje cuando no hay datos
                                            )}
                                          </Select>
                                        </AwsGroupContentParameterItem>
                                        <AwsGroupContentParameterItem>
                                          <Label htmlFor="model">
                                            {`${t("accordion4.label2")}`}
                                          </Label>
                                          <Select
                                            id="model"
                                            name="model"
                                            onChange={(e) =>
                                              setSelectedModel(e.target.value)
                                            }
                                            value={selectedModel}
                                          >
                                            <Option value="">{`${t(
                                              "accordion4.option1"
                                            )}`}</Option>
                                            <Option value="2019">2019</Option>
                                            <Option value="2020">2020</Option>
                                            <Option value="2021">2021</Option>
                                          </Select>
                                        </AwsGroupContentParameterItem>
                                        <AwsGroupContentParameterItem>
                                          <Label htmlFor="subBrand">
                                            {`${t("accordion4.label3")}`}
                                          </Label>
                                          <Select
                                            id="subBrand"
                                            name="subBrand"
                                            onChange={(e) =>
                                              setSelectedSubBrand(
                                                e.target.value
                                              )
                                            }
                                            value={selectedSubBrand}
                                          >
                                            <Option value="">{`${t(
                                              "accordion4.option1"
                                            )}`}</Option>
                                            {data.subbrandOption &&
                                            data.subbrandOption.length > 0 ? (
                                              data.subbrandOption.map(
                                                (item, index) => (
                                                  <Option
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </Option>
                                                )
                                              )
                                            ) : (
                                              <Option disabled>
                                                {`${t("accordion4.option3")}`}
                                              </Option> // Mensaje cuando no hay datos
                                            )}
                                          </Select>
                                        </AwsGroupContentParameterItem>
                                        <AwsGroupContentParameterItem>
                                          <Label htmlFor="descriptionVehicles">
                                            {`${t("accordion4.label4")}`}
                                          </Label>
                                          <Select
                                            id="descriptionVehicles"
                                            name="descriptionVehicles"
                                            onChange={(e) =>
                                              setSelectedVehicleExtens(
                                                e.target.value
                                              )
                                            }
                                            value={selectdVehicleExtens}
                                          >
                                            <Option value="">{`${t(
                                              "accordion4.option1"
                                            )}`}</Option>
                                            {data.descriptionVehicleOption &&
                                            data.descriptionVehicleOption
                                              .length > 0 ? (
                                              data.descriptionVehicleOption.map(
                                                (item, index) => (
                                                  <Option
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </Option>
                                                )
                                              )
                                            ) : (
                                              <Option disabled>
                                                {`${t("accordion4.option4")}`}
                                              </Option> // Mensaje cuando no hay datos
                                            )}
                                          </Select>
                                        </AwsGroupContentParameterItem>
                                      </AwsGroupContentParameter>

                                      <ButtonsContainer>
                                        <StyledButton
                                          onClick={() =>
                                            handleFetchData({
                                              link: "catalogs/vehicle-by-insurer/extended",
                                              sections: "vehicleExtens",
                                              param: {
                                                brand: selectedBrand,
                                                subbrand: selectedSubBrand,
                                                model: selectedModel,
                                                description:
                                                  selectdVehicleExtens,
                                              },
                                              response: "aws3",
                                            })
                                          }
                                        >
                                          {`${t("accordion1.button1")}`}
                                        </StyledButton>
                                        <StyledButtonClear
                                          onClick={() =>
                                            clearData({
                                              section: "vehicleExtens",
                                            })
                                          }
                                        >
                                          {`${t("accordion1.button2")}`}
                                        </StyledButtonClear>
                                      </ButtonsContainer>
                                    </AwsGroupContent>
                                  )}
                                </FrameParent3>
                              </GoodDesignIsParent2>
                            </FrameWrapper4>
                            <FrameWrapper4>
                              <GoodDesignIsParent2>
                                <Contact>{`${t(
                                  "accordion4.subtitle2"
                                )}`}</Contact>
                                <FrameParent3>
                                  <AwsGroup>
                                    <Aws>{`${t("accordion4.text2")}`}</Aws>
                                    <Iconsdropleft
                                      alt="icon-arrow"
                                      src={
                                        isResponsesVisible["aws3"]
                                          ? "/v1/arrow-up.svg"
                                          : "/v1/arrow-down.svg"
                                      }
                                      onClick={() =>
                                        toggleResponsesVisibility("aws3")
                                      }
                                    />
                                  </AwsGroup>
                                  {isResponsesVisible["aws3"] && (
                                    <AwsGroupContent>
                                      {data.vehicleExtens ? (
                                        <TableContainer>
                                          <StyledTable>
                                            <thead>
                                              <tr>
                                                <TableHeader>{`${t(
                                                  "accordion4.tableHeader1"
                                                )}`}</TableHeader>
                                                <TableHeader>{`${t(
                                                  "accordion4.tableHeader2"
                                                )}`}</TableHeader>
                                                <TableHeader>{`${t(
                                                  "accordion4.tableHeader3"
                                                )}`}</TableHeader>
                                                <TableHeader>{`${t(
                                                  "accordion4.tableHeader4"
                                                )}`}</TableHeader>
                                                <TableHeader>{`${t(
                                                  "accordion4.tableHeader5"
                                                )}`}</TableHeader>
                                                <TableHeader>{`${t(
                                                  "accordion4.tableHeader6"
                                                )}`}</TableHeader>
                                                {/* Agrega más encabezados si es necesario */}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {data &&
                                                data.vehicleExtens.insurers &&
                                                Object.entries(
                                                  data.vehicleExtens.insurers
                                                ).map(([insurer, details]) => (
                                                  <TableRow key={insurer}>
                                                    <TableCell>
                                                      {insurer}
                                                    </TableCell>
                                                    <TableCell>
                                                      {details.AMIS}
                                                    </TableCell>
                                                    <TableCell>
                                                      {details.MARCA}
                                                    </TableCell>
                                                    <TableCell>
                                                      {details.SUBMARCA}
                                                    </TableCell>
                                                    <TableCell>
                                                      {details.MODELO}
                                                    </TableCell>
                                                    <TableCell>
                                                      {details.DESCRIPCION}
                                                    </TableCell>
                                                    {/* Agrega más celdas si es necesario */}
                                                  </TableRow>
                                                ))}
                                            </tbody>
                                          </StyledTable>
                                        </TableContainer>
                                      ) : isTimeout ? (
                                        <p>{`${t("accordion1.loading")}`}</p>
                                      ) : (
                                        <p>{`${t("accordion1.content2")}`}</p>
                                      )}
                                    </AwsGroupContent>
                                  )}
                                </FrameParent3>
                              </GoodDesignIsParent2>
                            </FrameWrapper4>
                          </>
                        )}
                      </FrameParent2>
                      <FrameParent2>
                        <AwsParent>
                          <GoodDesignText>{`${t(
                            "accordion5.title"
                          )}`}</GoodDesignText>
                          <Iconsdropleft
                            alt="icon-arrow"
                            src={
                              isAwsVisible["aws4"]
                                ? "/v1/arrow-up.svg"
                                : "/v1/arrow-down.svg"
                            }
                            onClick={() =>
                              toggleAwsVisibility({
                                section: "aws4",
                                response: "vehicleShorts",
                              })
                            }
                          />
                        </AwsParent>

                        {isAwsVisible["aws4"] && (
                          <>
                            <FrameWrapper4>
                              <GoodDesignIsParent2>
                                <Contact>{`${t(
                                  "accordion5.subtitle1"
                                )}`}</Contact>
                                <FrameParent3>
                                  <AwsGroup>
                                    <Aws>{`${t("accordion5.text1")}`}</Aws>
                                    <Iconsdropleft
                                      alt="icon-arrow"
                                      src={
                                        isParametersVisible["aws4"]
                                          ? "/v1/arrow-up.svg"
                                          : "/v1/arrow-down.svg"
                                      }
                                      onClick={() =>
                                        toggleParametersVisibility("aws4")
                                      }
                                    />
                                  </AwsGroup>
                                  {isParametersVisible["aws4"] && (
                                    <AwsGroupContent>
                                      <AwsGroupContentParameter>
                                        <AwsGroupContentParameterItem>
                                          <Label htmlFor="brand">
                                            {`${t("accordion5.label1")}`}
                                          </Label>
                                          <Select
                                            id="brand"
                                            name="brand"
                                            onChange={(e) =>
                                              setSelectedBrand(e.target.value)
                                            }
                                            value={selectedBrand}
                                          >
                                            <Option value="">{`${t(
                                              "accordion5.option1"
                                            )}`}</Option>
                                            {data.brandOption &&
                                            data.brandOption.length > 0 ? (
                                              data.brandOption.map(
                                                (item, index) => (
                                                  <Option
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </Option>
                                                )
                                              )
                                            ) : (
                                              <Option disabled>
                                                {`${t("accordion5.option2")}`}
                                              </Option> // Mensaje cuando no hay datos
                                            )}
                                          </Select>
                                        </AwsGroupContentParameterItem>
                                        <AwsGroupContentParameterItem>
                                          <Label htmlFor="model">
                                            {`${t("accordion5.label2")}`}
                                          </Label>
                                          <Select
                                            id="model"
                                            name="model"
                                            onChange={(e) =>
                                              setSelectedModel(e.target.value)
                                            }
                                            value={selectedModel}
                                          >
                                            <Option value="">{`${t(
                                              "accordion5.option1"
                                            )}`}</Option>
                                            <Option value="2019">2019</Option>
                                            <Option value="2020">2020</Option>
                                            <Option value="2021">2021</Option>
                                          </Select>
                                        </AwsGroupContentParameterItem>
                                        <AwsGroupContentParameterItem>
                                          <Label htmlFor="subBrand">
                                            {`${t("accordion5.label3")}`}
                                          </Label>
                                          <Select
                                            id="subBrand"
                                            name="subBrand"
                                            onChange={(e) =>
                                              setSelectedSubBrand(
                                                e.target.value
                                              )
                                            }
                                            value={selectedSubBrand}
                                          >
                                            <Option value="">{`${t(
                                              "accordion5.option1"
                                            )}`}</Option>
                                            {data.subbrandOption &&
                                            data.subbrandOption.length > 0 ? (
                                              data.subbrandOption.map(
                                                (item, index) => (
                                                  <Option
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </Option>
                                                )
                                              )
                                            ) : (
                                              <Option disabled>
                                                {`${t("accordion5.option3")}`}
                                              </Option> // Mensaje cuando no hay datos
                                            )}
                                          </Select>
                                        </AwsGroupContentParameterItem>
                                        <AwsGroupContentParameterItem>
                                          <Label htmlFor="descriptionVehicles">
                                            {`${t("accordion5.label4")}`}
                                          </Label>
                                          <Input
                                            id="descriptionVehiclesShort"
                                            name="descriptionVehiclesShort"
                                            onChange={(e) =>
                                              setInputVehicleShorts(
                                                e.target.value
                                              )
                                            }
                                            value={inputVehicleShorts}
                                            placeholder={`${t(
                                              "accordion5.inputDescription"
                                            )}`}
                                          />
                                        </AwsGroupContentParameterItem>
                                      </AwsGroupContentParameter>

                                      <ButtonsContainer>
                                        <StyledButton
                                          onClick={() =>
                                            handleFetchData({
                                              link: "catalogs/vehicle-by-insurer",
                                              sections: "vehicleShorts",
                                              param: {
                                                brand: selectedBrand,
                                                subbrand: selectedSubBrand,
                                                model: selectedModel,
                                                description: inputVehicleShorts,
                                              },
                                              response: "aws4",
                                            })
                                          }
                                        >
                                          {`${t("accordion1.button1")}`}
                                        </StyledButton>
                                        <StyledButtonClear
                                          onClick={() =>
                                            clearData({
                                              section: "vehicleShorts",
                                            })
                                          }
                                        >
                                          {`${t("accordion1.button2")}`}
                                        </StyledButtonClear>
                                      </ButtonsContainer>
                                    </AwsGroupContent>
                                  )}
                                </FrameParent3>
                              </GoodDesignIsParent2>
                            </FrameWrapper4>
                            <FrameWrapper4>
                              <GoodDesignIsParent2>
                                <Contact>{`${t(
                                  "accordion5.subtitle2"
                                )}`}</Contact>
                                <FrameParent3>
                                  <AwsGroup>
                                    <Aws>{`${t("accordion5.text2")}`}</Aws>
                                    <Iconsdropleft
                                      alt="icon-arrow"
                                      src={
                                        isResponsesVisible["aws4"]
                                          ? "/v1/arrow-up.svg"
                                          : "/v1/arrow-down.svg"
                                      }
                                      onClick={() =>
                                        toggleResponsesVisibility("aws4")
                                      }
                                    />
                                  </AwsGroup>
                                  {isResponsesVisible["aws4"] && (
                                    <AwsGroupContent>
                                      {data.vehicleShorts &&
                                      data.vehicleShorts.message ? (
                                        <p>{`${t("accordion5.message1")}`}</p>
                                      ) : data.vehicleShorts &&
                                        data.vehicleShorts.insurers ? (
                                        <TableContainer>
                                          <StyledTable>
                                            <thead>
                                              <tr>
                                                <TableHeader>{`${t(
                                                  "accordion5.tableHeader1"
                                                )}`}</TableHeader>
                                                <TableHeader>{`${t(
                                                  "accordion5.tableHeader2"
                                                )}`}</TableHeader>
                                                <TableHeader>{`${t(
                                                  "accordion5.tableHeader3"
                                                )}`}</TableHeader>
                                                <TableHeader>{`${t(
                                                  "accordion5.tableHeader4"
                                                )}`}</TableHeader>
                                                <TableHeader>{`${t(
                                                  "accordion5.tableHeader5"
                                                )}`}</TableHeader>
                                                <TableHeader>{`${t(
                                                  "accordion5.tableHeader6"
                                                )}`}</TableHeader>
                                                {/* Agrega más encabezados si es necesario */}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {data &&
                                                data.vehicleShorts.insurers &&
                                                Object.entries(
                                                  data.vehicleExtens.insurers
                                                ).map(([insurer, details]) => (
                                                  <TableRow key={insurer}>
                                                    <TableCell>
                                                      {insurer}
                                                    </TableCell>
                                                    <TableCell>
                                                      {details.AMIS}
                                                    </TableCell>
                                                    <TableCell>
                                                      {details.MARCA}
                                                    </TableCell>
                                                    <TableCell>
                                                      {details.SUBMARCA}
                                                    </TableCell>
                                                    <TableCell>
                                                      {details.MODELO}
                                                    </TableCell>
                                                    <TableCell>
                                                      {details.DESCRIPCION}
                                                    </TableCell>
                                                    {/* Agrega más celdas si es necesario */}
                                                  </TableRow>
                                                ))}
                                            </tbody>
                                          </StyledTable>
                                        </TableContainer>
                                      ) : isTimeout ? (
                                        <p>{`${t("accordion1.loading")}`}</p>
                                      ) : (
                                        <p>{`${t("accordion1.content2")}`}</p>
                                      )}
                                    </AwsGroupContent>
                                  )}
                                </FrameParent3>
                              </GoodDesignIsParent2>
                            </FrameWrapper4>
                          </>
                        )}
                      </FrameParent2>
                    </GoodDesignIsParent1>
                  </FrameWrapper3>
                </FrameParent1>
              </FrameParent>
            </Property1defaultInner>
          </Property1default>
        </Header3>
      </ServicesCopyRoot>
    </>
  );
};

export default CatalogServices;
