import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  h1 {
    font-family: DM Sans;
    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0;
  }
  h2 {
    font-family: DM Sans;
    font-size: 26px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: -0.02em;
    text-align: center;
    margin: 0;
  }
  h3 {
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0;
  }
`;

export const TeamPictureContainer = styled.div`
  padding: 45px 0;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 164px;
    background-color: #0e1d39;
  }
  hr {
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: -1;
  }
`;

export const DivButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  gap: 10px;
  background: #2240d9;
  border-radius: 16px;
  margin: 45px 0 15px 0;
`;

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 20px 0;
`;

export const ContactInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    margin-right: 10px;
  }
  a {
    font-family: DM Sans;
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.01em;
    text-align: left;
    text-decoration: underline;
    margin: 15px 0;
  }
`;
