import Carousel from "react-material-ui-carousel";
import { isMobile } from "react-device-detect";
import { NavLink as Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CarouselCaseStudies = () => {

  const { t } = useTranslation(["home"]);

  return (
    <>
      {!isMobile && (
        <>
          <div style={{ position: "relative" }}>
            <img
              src="Group 424.svg"
              alt="indicators text"
              style={{
                width: "93%",
                position: "absolute",
                top: " -45px",
                left: "2px",
              }}
            />
          </div>
          <Carousel
            className="jss1"
            navButtonsAlwaysInvisible="true"
            animation="slide"
            IndicatorIcon={
              <img
                src="Rectangle 143.svg"
                alt="indicator"
                className="inactive-indicator"
              />
            }
            indicatorIconButtonProps={{
              style: {
                width: "20%",
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                backgroundImage: 'url("/v1/Rectangle 143 (1).svg")',
                backgroundSize: "100%",
              },
            }}
            indicatorContainerProps={{
              style: {
                display: "flex",
                justifyContent: "center",
                boxSizing: "border-box",
                position: "absolute",
                top: "-62px",
              },
            }}
          >
            <div style={{ width: "100%" }}>
              <Link to="/v1/coru">
                <img src="/v1/Coru_portada.png" alt="Case Study" />
              </Link>
              <div className="case-study">
                <div>
                  <h4>
                    <Link to="/v1/coru">Coru / </Link>
                    <Link to="/v1/coru">
                      <span style={{ color: "#3C4860", paddingLeft: "10px" }}>
                        {t("featured-projects.coru-subtitle")}
                      </span>
                    </Link>
                  </h4>
                </div>
              </div>
              <p style={{marginTop: '20px'}}>
              {t("featured-projects.coru-description")}
              </p>
            </div>

            <div style={{ width: "100%" }}>
              <Link to="/v1/heroe">
                <img src="Héroe_portada.png" alt="Case Study" />
              </Link>
              <div className="case-study">
                <div>
                  <h4>
                    <Link to="/v1/heroe">Heroe / </Link>
                    <Link to="/v1/heroe">
                      <span style={{ color: "#3C4860", paddingLeft: "10px" }}>
                      {t("featured-projects.heroe-subtitle")}
                      </span>
                    </Link>
                  </h4>
                </div>
              </div>
              <p style={{marginTop: '20px'}}>
              {t("featured-projects.heroe-description")}
              </p>
            </div>

            <div style={{ width: "100%" }}>
              <img src="Creze_portada.png" alt="Case Study" />
              <div className="case-study">
                <div>
                  <h4>
                    Creze /
                    <span style={{ color: "#3C4860", paddingLeft: "10px" }}>
                    {t("featured-projects.creze-subtitle")}
                    </span>
                    <span
                      style={{
                        color: "#6B7485",
                        fontWeight: "normal",
                        paddingLeft: "15px",
                        fontSize: "18px",
                      }}
                    >
                      {t("featured-projects.coming-soon")}
                    </span>
                  </h4>
                </div>
              </div>
              <p style={{marginTop: '20px'}}>
              {t("featured-projects.creze-description")}
              </p>
            </div>

            <div style={{ width: "100%" }}>
              <img src="Solvo_portada.png" alt="Case Study" />
              <div className="case-study">
                <div>
                  <h4>
                    Solvo /
                    <span style={{ color: "#3C4860", paddingLeft: "10px" }}>
                    {t("featured-projects.solvo-subtitle")}
                    </span>
                    <span
                      style={{
                        color: "#6B7485",
                        fontWeight: "normal",
                        paddingLeft: "15px",
                        fontSize: "18px",
                      }}
                    >
                      {t("featured-projects.coming-soon")}
                    </span>
                  </h4>
                </div>
              </div>
              <p style={{marginTop: '20px'}}>
              {t("featured-projects.solvo-description")}
              </p>
            </div>

            <div style={{ width: "100%" }}>
              <img src="Bankaya_portada.png" alt="Case Study" />
              <div className="case-study">
                <div>
                  <h4>
                    Bankaya /
                    <span style={{ color: "#3C4860", paddingLeft: "10px" }}>
                    {t("featured-projects.bankaya-subtitle")}
                    </span>
                    <span
                      style={{
                        color: "#6B7485",
                        fontWeight: "normal",
                        paddingLeft: "15px",
                        fontSize: "18px",
                      }}
                    >
                      {t("featured-projects.coming-soon")}
                    </span>
                  </h4>
                </div>
              </div>
              <p style={{marginTop: '20px'}}>
              {t("featured-projects.bankaya-description")}
              </p>
            </div>
          </Carousel>
        </>
      )}

      {isMobile && (
        <>
          <div style={{ width: "100%" }}>
            <Link to="/v1/coru">
              <img
                src="/v1/Coru_portada.png"
                alt="Case Study"
                className="animated-basic"
              />
            </Link>
            <div className="case-study">
              <div>
                <Link to="/v1/coru">
                  <h4 className="animated-basic">
                    Coru /
                    <span style={{ color: "#3C4860", paddingLeft: "10px" }}>
                    {t("featured-projects.coru-subtitle")}
                    </span>
                  </h4>
                </Link>
              </div>
            </div>
            <p className="animated-basic">
            {t("featured-projects.coru-description")}
            </p>
          </div>

          <div style={{ width: "100%" }}>
            <Link to="/v1/heroe">
              <img
                src="Héroe_portada.png"
                alt="Case Study"
                className="animated-basic"
              />
            </Link>
            <div className="case-study">
              <div>
                <Link to="/v1/heroe">
                  <h4 className="animated-basic">
                    Heroe /
                    <span style={{ color: "#3C4860", paddingLeft: "10px" }}>
                    {t("featured-projects.heroe-subtitle")}
                    </span>
                  </h4>
                </Link>
              </div>
            </div>
            <p className="animated-basic">
            {t("featured-projects.heroe-description")}
            </p>
          </div>

          <div style={{ width: "100%" }}>
            <img
              src="Creze_portada.png"
              alt="Case Study"
              className="animated-basic"
            />
            <div className="case-study">
              <div>
                <h4 className="animated-basic">
                  Creze /
                  <span style={{ color: "#3C4860", paddingLeft: "10px" }}>
                  {t("featured-projects.creze-subtitle")}
                  </span>
                  {/* <span style={{color: '#6B7485', fontWeight: 'normal', paddingLeft: "15px", fontSize: "18px"  }}> 
                  (Case study coming soon)
                </span> */}
                </h4>
              </div>
            </div>
            <p className="animated-basic">
            {t("featured-projects.creze-description")}
            </p>
          </div>

          <div style={{ width: "100%" }}>
            <img
              src="Solvo_portada.png"
              alt="Case Study"
              className="animated-basic"
            />
            <div className="case-study">
              <div>
                <h4 className="animated-basic">
                  Solvo /
                  <span style={{ color: "#3C4860", paddingLeft: "10px" }}>
                  {t("featured-projects.solvo-subtitle")}
                  </span>
                  {/* <span style={{color: '#6B7485', fontWeight: 'normal', paddingLeft: "15px", fontSize: "18px"  }}> 
                  (Case study coming soon)
                </span> */}
                </h4>
              </div>
            </div>
            <p className="animated-basic">
            {t("featured-projects.solvo-description")}
            </p>
          </div>

          <div style={{ width: "100%" }}>
            <img
              src="Bankaya_portada.png"
              alt="Case Study"
              className="animated-basic"
            />
            <div className="case-study">
              <div>
                <h4 className="animated-basic">
                  Bankaya /
                  <span style={{ color: "#3C4860", paddingLeft: "10px" }}>
                  {t("featured-projects.bankaya-subtitle")}
                  </span>
                  {/* <span style={{color: '#6B7485', fontWeight: 'normal', paddingLeft: "15px", fontSize: "18px"  }}> 
                  (Case study coming soon)
                </span> */}
                </h4>
              </div>
            </div>
            <p className="animated-basic">
            {t("featured-projects.bankaya-description")}
            </p>
          </div>
        </>
      )}
    </>
  );
};
export default CarouselCaseStudies;
