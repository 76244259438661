import { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const animationSlideInLeft = keyframes`
    0% {
        transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
  `;
const OpcionesDeMen = styled.b`
  position: absolute;
  top: 205px;
  left: 177px;
  line-height: 120%;
`;
const OpcionesDeMenParentRoot = styled.div`
  position: relative;
  background-color: var(--neutral-white);
  width: 497px;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  max-width: 90%;
  text-align: left;
  font-size: var(--common-small-title-size);
  color: var(--black);
  font-family: var(--common-small-title);
  &.animate {
    animation: 0.25s ease 0s 1 normal forwards ${animationSlideInLeft};
  }
`;

const FrameComponent = ({ onClose }) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <OpcionesDeMenParentRoot data-animate-on-scroll>
      <OpcionesDeMen>opciones de menú</OpcionesDeMen>
    </OpcionesDeMenParentRoot>
  );
};

export default FrameComponent;
