import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Carousel from 'react-material-ui-carousel';
import PaginationBullets from '../../NewRocket/components/PaginationBullets/index.jsx';

const AlliedCarousel = () => {
  const [selectedItem, setSelectedItem] = useState(0);

  const URL_ICONS = '/alliedCarousel/icons';

  const items = [
    {
      header: 'Technology and Digital Finance',
      icon: '/alliedCarousel/artificial-intelligence.svg',
      title: 'Fintech',
      content: [
        `${URL_ICONS}/artificial-intelligence/coru.svg`,
        `${URL_ICONS}/cripto/bitsafve.svg`,
        `${URL_ICONS}/fintech/vexi.svg`,
        `${URL_ICONS}/fintech/creze.svg`,
        `${URL_ICONS}/fintech/solvo.svg`,
        `${URL_ICONS}/fintech/principal.svg`,
        `${URL_ICONS}/fintech/chib.svg`,
        `${URL_ICONS}/fintech/master-group.svg`,
        `${URL_ICONS}/fintech/plata.svg`,
        `${URL_ICONS}/fintech/bankaya.svg`,
        `${URL_ICONS}/fintech/dani-sofom.svg`,
        `${URL_ICONS}/fintech/innohub.svg`,
        `${URL_ICONS}/fintech/tribal.svg`,
        `${URL_ICONS}/fintech/numina.svg`,
        `${URL_ICONS}/fintech/lend.svg`,
        `${URL_ICONS}/fintech/avanttia.svg`,
        `${URL_ICONS}/fintech/lumo.svg`,
        `${URL_ICONS}/fintech/techreo.svg`,
      ],
    },
    {
      header: 'Energy and manufacturing',
      subItems: [
        {
          icon: '/alliedCarousel/energy.svg',
          title: 'Energy',
          content: [
            `${URL_ICONS}/energy/seos.svg`,
            `${URL_ICONS}/energy/a3t.svg`,
          ],
        },
        {
          icon: '/alliedCarousel/manufacturing.svg',
          title: 'Manufacturing',
          content: [`${URL_ICONS}/manufacturing/collado.svg`],
        },
      ],
    },
    {
      header: 'Insurance & Reinsurance',
      icon: '/alliedCarousel/insurance-reinsurance.svg',
      title: 'Insurance & Reinsurance',
      content: [
        `${URL_ICONS}/insurance-reinsurance/patria-re.svg`,
        `${URL_ICONS}/insurance-reinsurance/solventatech.svg`,
        `${URL_ICONS}/insurance-reinsurance/beneficiamx.svg`,
        `${URL_ICONS}/insurance-reinsurance/hey-billy.svg`,
        `${URL_ICONS}/insurance-reinsurance/ahorraseguros.svg`,
        `${URL_ICONS}/insurance-reinsurance/grupo-promass.svg`,
        `${URL_ICONS}/insurance-reinsurance/lockton.svg`,
        `${URL_ICONS}/insurance-reinsurance/volt.svg`,
        `${URL_ICONS}/insurance-reinsurance/copaprose.svg`,
        `${URL_ICONS}/insurance-reinsurance/sicas.svg`,
        `${URL_ICONS}/insurance-reinsurance/gplus-seguros.svg`,
      ],
    },
    {
      header: 'Investments',
      icon: '/alliedCarousel/investments.svg',
      title: 'Investments',
      content: [
        `${URL_ICONS}/investments/principal.svg`,
        `${URL_ICONS}/investments/loop-capital.svg`,
      ],
    },
    {
      header: 'Services',

      title: 'Services',
      content: [
        `${URL_ICONS}/servicesIcons/amispass.svg`,
        `${URL_ICONS}/servicesIcons/wedder.svg`,
        `${URL_ICONS}/servicesIcons/unitam.svg`,
      ],
    },
    {
      header: 'E-commerce',
      icon: '/alliedCarousel/telecommunications.svg',
      title: 'E-commerce',
      content: [
        `${URL_ICONS}/telecommunications/telfin.svg`,
        `${URL_ICONS}/e-commerce/ballena.svg`,
        `${URL_ICONS}/e-commerce/hereo.svg`,
        // `${URL_ICONS}/e-commerce/taller-maya.svg`,
      ],
    },
  ];

  const [activeLikeBullet, setActiveLikeBullet] = useState(0);
  const halfIndex = Math.ceil(items.length / 2);
  const column1Items = items.slice(0, halfIndex);
  const column2Items = items.slice(halfIndex);

  const [width, setWidth] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(false);
  const [isClickable, setIsClickable] = useState(true);
  const [autoPlay, setAutoPlay] = useState(true);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    // Limpia el evento cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log('aqui', activeLikeBullet);
    return () => {
      // limpieza del efecto
    };
  }, [activeLikeBullet]);

  useEffect(() => {
    // efecto a ejecutar
    if (width < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    setActiveLikeBullet(0);
  }, [width]);

  const blockClicksTemporarily = (callback, delay) => {
    if (!isClickable) return;
    setIsClickable(false);
    callback();
    setTimeout(() => setIsClickable(true), delay);
  };

  const PrevPage = (length) => {
    if (!autoPlay) {
      blockClicksTemporarily(() => {
        setActiveLikeBullet((prevActiveLikeBullet) => {
          if (prevActiveLikeBullet > 0) {
            return prevActiveLikeBullet - 1;
          } else {
            return length - 1;
          }
        });
      }, 500);
    }
  };

  const NextPage = (length) => {
    if (!autoPlay) {
      blockClicksTemporarily(() => {
        setActiveLikeBullet((prevActiveLikeBullet) => {
          if (prevActiveLikeBullet < length - 1) {
            return prevActiveLikeBullet + 1;
          } else {
            return 0;
          }
        });
      }, 500);
    }
  };

  useEffect(() => {
    console.log(autoPlay, 'autoPlay');
    return () => {
      // limpieza del efecto
    };
  }, [autoPlay]);

  const renderContent = (item) => {
    // ----------------------

    const chunkArray = (arr, size) => {
      const chunks = [];
      for (let i = 0; i < arr.length; i += size) {
        chunks.push(arr.slice(i, i + size));
      }
      return chunks;
    };

    return item.subItems ? (
      item.subItems.map((subItem, index) => (
        <OptionsContent key={index}>
          <TitleContent
            style={{
              color:
                subItem.title === 'Fintech' || subItem.title === 'Manufacturing'
                  ? '#00AA58'
                  : '#FFA800',
            }}
          >
            {/* {subItem.icon && <Iconimg src={subItem.icon} alt='titleIcon' />} */}
            {subItem.title}
          </TitleContent>

          {subItem.content.length > (mobile ? 3 : 5) ? (
            <>
              <Carousel
                indicators={false}
                animation='slide'
                interval={3000}
                autoPlay={autoPlay}
                navButtonsAlwaysInvisible
                index={activeLikeBullet}
                onChange={(index) => {
                  setActiveLikeBullet(index);
                }}
              >
                {chunkArray(subItem.content, mobile ? 3 : 5).map(
                  (chunk, index) => (
                    <>
                      <ItemsContent shouldWrap={subItem.content.length > 4}>
                        {/* Mapear cada imagen dentro del subarray */}

                        {chunk.map((image, i) => (
                          <ImgContainer>
                            <ImageComponent key={i} src={image} />
                          </ImgContainer>
                        ))}
                      </ItemsContent>
                    </>
                  )
                )}
              </Carousel>
              <PaginationSection>
                <PaginationContainer>
                  <PlayPause
                    src='/pause.svg'
                    onClick={() => setAutoPlay(!autoPlay)}
                  />
                  <PaginationBullets
                    onHandleBulletClick={(indexBullet) => {
                      setActiveLikeBullet(indexBullet);
                    }}
                    totalSlides={
                      chunkArray(subItem.content, mobile ? 3 : 5).length
                    }
                    rootActiveBullet={activeLikeBullet}
                    activeBulletColor='#ffa800'
                    bulletColor='#D4DEE9'
                    notClickable
                  />
                </PaginationContainer>
                <Circle
                  autoPlay={autoPlay}
                  onClick={() =>
                    PrevPage(chunkArray(subItem.content, mobile ? 3 : 5).length)
                  }
                >
                  <img
                    src='/arrow-left.svg'
                    alt='line'
                    style={{ height: '22px' }}
                  />
                </Circle>
                <Circle
                  autoPlay={autoPlay}
                  onClick={() =>
                    NextPage(chunkArray(subItem.content, mobile ? 3 : 5).length)
                  }
                >
                  <img
                    src='/arrow-left.svg'
                    alt='line'
                    style={{ height: '22px', transform: 'rotate(180deg)' }}
                  />
                </Circle>
              </PaginationSection>
            </>
          ) : (
            <>
              <ItemsContent shouldWrap={subItem.content.length > 4}>
                {subItem.content.map((imagePath, idx) => (
                  <ImageComponent key={idx} src={imagePath} alt={imagePath} />
                ))}
              </ItemsContent>
            </>
          )}
        </OptionsContent>
      ))
    ) : (
      <OptionsContent>
        <TitleContent
          style={{
            color:
              item.title === 'Fintech' || item.title === 'Manufacturing'
                ? '#00AA58'
                : '#FFA800',
          }}
        >
          {/* {item.icon && <Iconimg src={item.icon} alt='titleIcon' />} */}
          {item.title}
        </TitleContent>
        {item.content.length > (mobile ? 3 : 5) ? (
          <>
            <Carousel
              activeIndicatorIconButtonProps={{
                style: { color: 'blue' },
              }}
              animation='slide'
              interval={3000}
              autoPlay={autoPlay}
              indicators={false}
              navButtonsAlwaysInvisible
              index={activeLikeBullet}
              onChange={(index) => setActiveLikeBullet(index)}
            >
              {chunkArray(item.content, mobile ? 3 : 5).map((chunk, index) => (
                <>
                  <ItemsContent shouldWrap={item.content.length > 4}>
                    {/* Mapear cada imagen dentro del subarray */}

                    {chunk.map((image, i) => (
                      <ImgContainer>
                        <ImageComponent key={i} src={image} />
                      </ImgContainer>
                    ))}
                  </ItemsContent>
                </>
              ))}
            </Carousel>
            <PaginationSection>
              <PaginationContainer>
                <PlayPause
                  src='/pause.svg'
                  onClick={() => setAutoPlay(!autoPlay)}
                />
                <PaginationBullets
                  onHandleBulletClick={(indexBullet) => {
                    setActiveLikeBullet(indexBullet);
                  }}
                  totalSlides={chunkArray(item.content, mobile ? 3 : 5).length}
                  rootActiveBullet={activeLikeBullet}
                  activeBulletColor='#ffa800'
                  bulletColor='#D4DEE9'
                  notClickable
                />
              </PaginationContainer>
              <Circle
                autoPlay={autoPlay}
                onClick={() =>
                  PrevPage(chunkArray(item.content, mobile ? 3 : 5).length)
                }
              >
                <img
                  src='/arrow-left.svg'
                  alt='line'
                  style={{ height: '22px' }}
                />
              </Circle>
              <Circle
                autoPlay={autoPlay}
                onClick={() =>
                  NextPage(chunkArray(item.content, mobile ? 3 : 5).length)
                }
              >
                <img
                  src='/arrow-left.svg'
                  alt='line'
                  style={{ height: '22px', transform: 'rotate(180deg)' }}
                />
              </Circle>
            </PaginationSection>
          </>
        ) : (
          <>
            <ItemsContent shouldWrap={item.content.length > 4}>
              {item.content.map((imagePath, idx) => (
                <ImageComponent key={idx} src={imagePath} alt='iconImg' />
              ))}
            </ItemsContent>
          </>
        )}
      </OptionsContent>
    );
  };

  return (
    <ContainerCarousel>
      <HeaderCarousel>
        <TypographyCompanies>Companies we help grow</TypographyCompanies>
        <TypographyWork>WORK.</TypographyWork>
        <OptionsWrapper>
          <OptionsHeader>
            {column1Items.map((item, index) => (
              <ItemsHeader
                key={index}
                active={selectedItem === index}
                onClick={() => setSelectedItem(index)}
              >
                {item.header}
              </ItemsHeader>
            ))}
          </OptionsHeader>
          <OptionsHeader>
            {column2Items.map((item, index) => (
              <ItemsHeader
                key={index + halfIndex} // Ajustar los índices para la segunda columna
                active={selectedItem === index + halfIndex}
                onClick={() => setSelectedItem(index + halfIndex)}
              >
                {item.header}
              </ItemsHeader>
            ))}
          </OptionsHeader>
        </OptionsWrapper>
      </HeaderCarousel>
      <ContentCarousel>
        <Content2>{renderContent(items[selectedItem])}</Content2>
      </ContentCarousel>
    </ContainerCarousel>
  );
};

export default AlliedCarousel;

const PlayPause = styled.img`
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    background-color: rgb(94 111 141);
  }
`;

const Circle = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #395280;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: ${({ autoPlay }) => (autoPlay ? 'brightness(0.7)' : '')};
  &:hover {
    cursor: pointer;
    filter: ${({ autoPlay }) =>
      autoPlay ? 'brightness(0.7)' : 'brightness(1.2)'};
  }
`;

const PaginationContainer = styled.div`
  width: auto;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
  background-color: #395280;
  height: 44px;
  align-items: center;
  display: flex;
`;

const PaginationSection = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 10px;
`;

const ContainerCarousel = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding-top: 22.15px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 28.937px;
  flex-shrink: 0;
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: fit-content;
  @media (max-width: 1024px) {
    width: auto;
  }
`;

const HeaderCarousel = styled.div`
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  align-self: stretch;
  @media (max-width: 1024px) {
    align-items: flex-start;
    padding: 54px 30px 0px;
  }
`;

const TypographyCompanies = styled.div`
  width: auto;
  color: #fff;
  text-align: center;
  font-family: 'Red Hat Text';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const TypographyWork = styled.div`
  width: 306px;
  color: #fff;
  text-align: center;
  font-family: 'Red Hat Text';
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media (max-width: 1024px) {
    width: auto;
    font-size: 24px;
    min-width: 92px;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  gap: 32px;
`;

const OptionsHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  gap: 10px;
`;

const ItemsHeader = styled.div`
  display: flex;
  width: 100%;
  min-height: 22px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  color: ${({ active }) => (active ? '#fff' : '#395280')};
  text-align: left;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding: 5px 10px;
  border-radius: 5px;
  &:hover {
    color: ${({ active }) => (active ? '#fff' : '#6D80A3')};
  }
  @media (max-width: 1024px) {
    font-size: 10px;
  }
`;

const ContentCarousel = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  height: auto;
  min-height: 500px;
  padding: 64px;
  justify-content: center;
  align-items: start;
  flex-shrink: 0;
  @media (max-width: 1024px) {
    align-items: start;
    height: auto;
  }
`;

const Content2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  min-height: 600px;
  width: 100%;
  @media (max-width: 1024px) {
    gap: 24px;
    min-height: 0;
    flex-direction: column;
  }
`;

const OptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  width: 100%;
  @media (max-width: 1024px) {
    gap: 32px;
  }
`;

const TitleContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  color: #ffa800;
  text-align: center;
  font-family: 'Red Hat Text';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

const ItemsContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: ${(props) => (props.shouldWrap ? 'wrap' : 'nowrap')};
  width: 100%;
  gap: 46px;
  height: 80px;
`;

const ImageComponent = styled.img`
  max-width: 250px;
  height: auto;
  max-height: 80px;
  @media (max-width: 1024px) {
    max-width: 80px;
    max-height: 40px;
    height: 40px;
  }
`;

const Iconimg = styled.img`
  max-width: 65px;
  max-height: 65px;
  @media (max-width: 1024px) {
    max-width: 35px;
    max-height: 35px;
  }
`;
