/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Logo from './HomeRedesign/assets/logo.svg';
import RedesignCarrusel from './HomeRedesign/components/RedesignCarrusel';
import Loop from './HomeRedesign/components/Loop';
import Footer from './NewRocket/components/Footer';
import Interactiveform from './HomeRedesign/components/Interactiveform';
import CompanyCarrusel from './HomeRedesign/components/CompanyCarrusel';
import succeesss from './HomeRedesign/assets/succeesss.gif';

const HomeRedesign = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(false);

  const searchParams = window.location.search;

  useEffect(() => {
    const params = new URLSearchParams(searchParams.toString());

    const utm_source = params.get('utm_source');
    const utm_medium = params.get('utm_medium');
    const utm_campaign = params.get('utm_campaign');

    const utmParams = {
      utm_source,
      utm_medium,
      utm_campaign,
    };

    const utmParamsString = Object.entries(utmParams)
      .filter(([key, value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    if (utmParamsString) {
      sessionStorage.setItem('utmParams', utmParamsString);
    }
  }, [searchParams]);

  useEffect(() => {
    const currentBtn = sessionStorage.getItem('currentBtn');
    if (!currentBtn) {
      sessionStorage.setItem('currentBtn', 'btn_clutch');
    }
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    // Limpia el evento cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // efecto a ejecutar
    if (width < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth', // Desplazamiento suave
      });
    }
  };

  return (
    <>
      <Layout>
        <NavBar>
          <NavBarContainer>
            <LogoImg src={Logo} alt='logo' />
            <MenuContainer>
              <MenuOptions onClick={() => scrollToSection('gif')}>
                Our Process
              </MenuOptions>
              <MenuOptions>Our Work</MenuOptions>
            </MenuContainer>
          </NavBarContainer>
        </NavBar>
        <Header>
          <HeaderContainer1>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '11px' }}
            >
              <Title>
                Make <span>AI</span> work for you!
              </Title>
              <Text>
                We stay at the forefront of the field to make sure you identify
                and implement the best in tech tailored to your needs.
              </Text>
            </div>
            <CarruselDeskContainer>
              {mobile ? null : <RedesignCarrusel />}
            </CarruselDeskContainer>
          </HeaderContainer1>
          <HeaderContainer2>
            <Interactiveform />
          </HeaderContainer2>
        </Header>

        {mobile ? (
          <CarruselMobileContainer>
            <RedesignCarrusel />
          </CarruselMobileContainer>
        ) : null}
        <Loop />
        <GifContainer id='gif'>
          <img src={succeesss} alt='gif' />
        </GifContainer>
        <CarruselContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
          >
            <Text
              color='#fff'
              family='Red Hat Text'
              size='44px'
              weight='500'
              line='53px'
              msize='31px'
            >
              Featured Projects
            </Text>
            <Text size='16px'>And what our clients have to say</Text>
          </div>
          <CompanyCarrusel />
        </CarruselContainer>

        <Footer />
      </Layout>
    </>
  );
};

export default HomeRedesign;

const Layout = styled.div`
  background-color: #050e2a;
  color: #fff;
`;
const Header = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 42px;
  padding: 64px;
  @media screen and (max-width: 1023px) {
    padding: 32px;
    flex-direction: column;
  }
`;
const HeaderContainer1 = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: start;
`;

const CarruselDeskContainer = styled.div`
  display: flex;
  max-width: 100%;
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;
const CarruselMobileContainer = styled.div`
  display: none;
  margin: 0 0 32px 0;
  @media screen and (max-width: 1023px) {
    display: flex;
    padding: 0 32px;
  }
`;

const HeaderContainer2 = styled.div`
  max-width: 320px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: start;
  width: 100%;
  @media screen and (max-width: 1023px) {
    max-width: 100%;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-family: 'Red Hat Text';
  font-weight: 500;
  font-size: 44px;
  color: #fff;
  span {
    color: #a6f674;
  }
  line-height: 52px;
`;

const Text = styled.p`
  font-family: ${(props) => props.font || 'DM Sans'};
  font-size: ${(props) => props.size || '24px'};
  font-weight: ${(props) => props.weight || '400'};
  color: ${(props) => props.color || '#fff'};
  margin: 0;
  line-height: ${(props) => props.line};
  @media screen and (max-width: 1023px) {
    font-size: ${(props) => props.msize || '16px'};
  }
`;

const NavBar = styled.div`
  width: 100%;
  border-bottom: 1px solid #000;
  @media screen and (max-width: 1023px) {
  }
`;

const NavBarContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 64px;
  @media screen and (max-width: 1023px) {
    padding: 18px 32px;
  }
`;

const MenuContainer = styled.div`
  gap: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoImg = styled.img`
  width: 170px;
  @media screen and (max-width: 1023px) {
    width: 136px;
  }
`;

const MenuOptions = styled.span`
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 500;
  border: none;
  background-color: transparent;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  cursor: pointer;
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const GifContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0 0;
  img {
    width: 100%;
    max-width: 569px;
  }
  @media screen and (max-width: 1023px) {
    padding: 16px 32px;
  }
`;

const CarruselContainer = styled.div`
  display: flex;
  padding: 32px;
  width: 100%;
  max-width: 755px;
  margin: 0 auto;
  gap: 64px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
