import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_LOCATION_BY_IP } from "./../queries";

const useGetLocationIp = (paisNav = "") => {
   const [pais, setPais] = useState("");
   const [ip, setIp] = useState(""); // estado para la IP
   const [isLoader, setLoader] = useState(true);

   const getIP = async () => {
      const response = await fetch("https://api.ipify.org/?format=json");
      console.log(response)
      const data = await response.json();
      console.log(data)
      return data;
   };
   const [getLocationByIp] = useLazyQuery(GET_LOCATION_BY_IP, {
      fetchPolicy: "cache-and-network",
      onCompleted({ getLocationByIp }) {
         console.log(getLocationByIp)
         if (getLocationByIp.statusCode === 200) {
            let { data } = getLocationByIp;

            if (window.location.host.indexOf("therocketcode.com") !== -1 || window.location.host.indexOf("localhost") !== -1) {

               if (data.countryCode === "US") {
                  setPais("usa");
                  sessionStorage.setItem("country", "usa");
               } else if (data.countryCode === "MX") {
                  setPais("mx");
                  sessionStorage.setItem("country", "mex");
               }
               /*
               if (window.location.pathname.indexOf("usa") !== -1) {
                  sessionStorage.setItem("country", "usa");
                  setPais("usa");
               } else {
                  sessionStorage.setItem("country", "mex");
                  setPais("mx");
               } */
            } else {
               if (data.countryCode === "US") {
                  setPais("usa");
                  sessionStorage.setItem("country", "usa");
               } else if (data.countryCode === "MX") {
                  setPais("mx");
                  sessionStorage.setItem("country", "mex");
               }
            }
         }
         setLoader(false);
      },
      onError(err) {
         setLoader(false);
         console.log(err);
      },
   });

   useEffect(() => {
      if (paisNav === "") {
         getIP().then((data) => {
            console.log(data.ip)
            setIp(data.ip);
            getLocationByIp({ variables: { ip:`${data.ip}` } });
         });
      }
   }, [pais, getLocationByIp, paisNav]);

   // const memoizedCallback = useCallback(() => {
   //    doSomething(a, b);
   // }, [a, b]);

   return [pais, setPais, isLoader, ip];
};

export default useGetLocationIp;
