import { useState, useCallback, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import FrameComponent from './components/FrameComponent';
import PortalDrawer from './components/PortalDrawer';
import Carousel from 'react-material-ui-carousel';
import { useTranslation } from 'react-i18next';

const carAnimation = keyframes`
  0% {
    opacity: 0;
    right: 100%;
  }
  20%{
    opacity: 1;
    right: 28%;
  }
  25% {
    opacity: 1;
    right: 30%;
  }
  72%{
    opacity: 1;
    right: 30%;
  }
  76%{
    opacity: 1;
    right: 28%;
  }
  80% {
    opacity: 1;
    right: 30%;
  }
  100% {
    opacity: 0;
    right: -120%;
  }
`;

const trailAnimation = keyframes`
0% {
  opacity: 0;
  right: 110%;
}
20%{
  opacity: 1;
  right: 8%;
}
25% {
  opacity: 1;
  right: 10%;
}
72%{
  opacity: 1;
  right: 10%;
}
76%{
  opacity: 1;
  right: 8%;
}
80% {
  opacity: 1;
  right: 10%;
}
100% {
  opacity: 1;
  right: -20%;
  
}
`;

const logoAnimation = keyframes`
0% {
  opacity: 0;
  bottom: -10%;
}
25% {
  opacity: 0; bottom: -10%;
}
80% {
  opacity: 0; bottom: -10%;
}
100% {
  opacity: 1;
  bottom: 25%;
}
`;

const textAnimation = keyframes`
0% {
  opacity: 0;
  transform: translateY(50%);
}
25% {
  opacity: 1;  transform: translateY(-0);
}
100% {
  opacity: 1;  transform: translateY(0);
}
`;
const Carimage = styled.img`
  position: absolute;
  bottom: 10%;
  right: 40%;
  width: 80%;
  min-width: 360px;
  transform: translateX(50%);
  z-index: 2;
  animation: ${carAnimation} 5s ease-in-out;
  animation-fill-mode: forwards;
`;

const Trailimage = styled.img`
  position: absolute;
  bottom: 50%;
  transform: translateY(+50%);
  width: 100%;
  right: 50%;
  mask-box-image: -webkit-radial-gradient(white, transparent 80%);
  animation: ${trailAnimation} 5s ease-in-out;
  animation-fill-mode: forwards;
`;

const Logoimage = styled.img`
  position: absolute;
  bottom: 10%;
  left: 50%;
  width: 60%;
  transform: translateX(-50%) translateY(-50%);
  animation: ${logoAnimation} 5s ease-in-out;
  animation-fill-mode: forwards;
  min-width: 260px;
`;

const GroundImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const Header = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  @media screen and (max-width: 768px) {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;
const GoodDesignIs = styled.b`
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: var(--common-small-title-size);
  line-height: 120%;
  font-family: var(--common-small-title);
  color: var(--neutral-white);
  text-align: left;
`;
const GoodDesignIsWrapper = styled.div`
  position: relative;
  width: 132px;
  height: 19px;
  @media screen and (max-width: 960px) {
    flex: 1;
  }
  @media screen and (max-width: 768px) {
    flex: 1;
  }
`;
const FrameChild = styled.div`
  position: relative;
  background-color: var(--neutral-white);
  width: 26px;
  height: 1px;
`;
const RectangleParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base);
  gap: var(--gap-8xs);
`;
const Headermobile = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1px;
  height: 1px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    width: 65%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: var(--gap-0);
    padding-left: var(--padding-11xl);
    padding-top: var(--padding-11xl);
    box-sizing: border-box;
    display: flex;
  }
  @media screen and (max-width: 768px) {
    width: 28%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: var(--gap-0);
    padding-left: var(--padding-11xl);
    padding-right: 0px;
    box-sizing: border-box;
    display: flex;
  }
`;
const HeaderParent = styled.div`
  position: relative;
  width: 1440px;
  height: 1px;
  @media screen and (max-width: 1200px) {
    height: 0px;
  }
`;
const LinkOne = styled.div`
  position: relative;
  letter-spacing: -0.01em;
  line-height: 120%;
  color: white;
`;
const VectorIcon = styled.img`
  position: absolute;
  height: 29.58%;
  width: 52.5%;
  top: 36.25%;
  right: 23.75%;
  bottom: 34.17%;
  left: 23.75%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const ChevronDown = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;
const NavLinkDropdown = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
const Column1 = styled.div`
  width: 468px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    flex: unset;
    align-self: stretch;
  }
`;
const SimpleColorIcon = styled.img`
  position: relative;
  width: 41.3px;
  height: 38.2px;
`;
const GoodDesignIs1 = styled.div`
  position: relative;
  letter-spacing: -0.02em;
  line-height: 30.24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 151.4px;
  height: 24.6px;
  flex-shrink: 0;
`;
const FullLogoColorDark = styled.div`
  width: 205.5px;
  height: 38.2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;
const H1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 25.2px;
`;
const Button = styled.div`
  position: relative;
  line-height: 150%;
`;
const StyleprimarySmalltrueDar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
`;
const Column2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-family: var(--text-regular-normal);
  @media screen and (max-width: 768px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-13xl);
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: var(--gap-13xl);
    align-items: center;
    justify-content: center;
  }
`;
const Navbar3 = styled.div`
  align-self: stretch;
  background-color: var(--primary-navy);
  //   border-bottom: 1px solid var(--black);
  box-sizing: border-box;
  height: 100px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px var(--padding-45xl);
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
const TransformandoLaHomologacin = styled.span``;
const Ia = styled.span`
  color: var(--secondary-yellow);
`;
const MediumLengthHeroContainer = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    width: 100%;
  }
  @media screen and (min-width: 1696px) and (max-width: 1762px) {
    padding: 0px 50px; /* Ajusta este valor según sea necesario */
  }
`;
const FrameIcon = styled.div`
  flex: 1;
  position: relative;
  max-width: 100%;
  height: 278px;
  @media screen and (max-width: 1024px) {
    flex: unset;
    align-self: stretch;
  }
`;
const CatalogIa = styled.span`
  font-weight: 500;
`;
const Span = styled.span`
  font-size: 30.96px;
  font-weight: 500;
  font-family: var(--desktop-h2);
`;
const RangoDeEficiencia = styled.span`
  font-size: var(--font-size-19xl-7);
  font-weight: 500;
  font-family: var(--common-small-title);
`;
const B = styled.span`
  font-family: var(--common-small-title);
`;
const B1 = styled.b`
  font-family: var(--common-small-title);
`;
const De = styled.span`
  font-size: 22.11px;
`;
const EsLaSolucinLderBasadaEn = styled.span`
  color: var(--neutral-white);
  font-size: 22.11px;
`;
const LoremIpsumDolorContainer = styled.div`
  flex: 1;
  position: relative;
  line-height: 120%;
  animation: ${textAnimation} 5s ease-in-out;
  animation-fill-mode: forwards;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 768px) {
    font-size: var(--desktop-h3-size);
  }
`;
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-19xl-7);
  color: var(--secondary-yellow);

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
  }
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
`;
const GoodDesignIs2 = styled.b`
  position: relative;
  line-height: 112%;
`;
const Button1 = styled.div`
  border-radius: var(--br-5xs);
  background-color: var(--secondary-blue);
  width: 224px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-13xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--common-small-title-size);
  cursor: pointer;
  color: #ffffff;
`;
const Column3 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
  @media screen and (max-width: 768px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Header26 = styled.nav`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-45xl) var(--padding-183xl);
  text-align: center;
  font-size: var(--desktop-h1-size);
  color: var(--neutral-white);
  font-family: var(--common-small-title);
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
`;
const FrameChild1 = styled.img`
  position: relative;
  width: 400px;
  height: 500px;
  overflow: hidden;
  flex-shrink: 0;
  @media screen and (max-width: 768px) {
    width: 50%;
    height: 50%;
  }
`;
const ObtainAccurateVehicle = styled.span`
  color: var(--neutral-white);
`;
const GoodDesignIsContainer = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--desktop-h2-size);
  line-height: 120%;
  font-weight: 500;
  font-family: var(--desktop-h2);
  color: var(--secondary-green);
`;
const OurExperienceWith = styled.div`
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 120%;
`;
const Button2 = styled.div`
  border-radius: var(--br-5xs);
  background-color: var(--secondary-blue);
  width: 224px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-13xl);
  box-sizing: border-box;
`;
const GoodDesignIsParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-13xl);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 768px) {
    flex: unset;
    align-self: stretch;
  }
`;
const FrameGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-45xl) var(--padding-183xl);
  gap: var(--gap-5xl);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: var(--gap-5xl);
    align-items: center;
    justify-content: center;
    padding: var(--padding-xl) var(--padding-11xl) var(--padding-101xl);
    box-sizing: border-box;
  }
`;
const GoodDesignIs5 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
  font-weight: 500;
`;
const Text1 = styled.div`
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 120%;
  color: white;
  @media screen and (max-width: 768px) {
    width: 75%;
  }
`;
const GoodDesignIsFrame = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--common-small-title-size);
`;
const Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const TitleParent = styled.div`
  flex: 1;
  height: 449px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-45xl);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    flex: unset;
    align-self: stretch;
  }
`;
const FrameIcon1 = styled.img`
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 440px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 768px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Header1 = styled.div`
  align-self: stretch;
  background-color: var(--primary-navy);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-61xl) var(--padding-183xl);
  gap: var(--gap-5xl);
  font-size: var(--desktop-h3-size);
  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: var(--gap-5xl);
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: var(--gap-5xl);
    align-items: center;
    justify-content: center;
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
`;
const Catalogai = styled.span`
  color: var(--secondary-red);
`;
const GoodDesignIsWrapper2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--common-small-title-size);
  font-family: var(--common-small-title);
  @media screen and (max-width: 768px) {
    align-self: center;
  }
`;
const Title3 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--desktop-h2-size);
  font-family: var(--desktop-h2);
`;
const FrameChild2 = styled.img`
  position: relative;
  width: 80px;
  height: 82px;
`;
const Essential = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 35px;
`;
const Package = styled.p`
  margin: 0;
  font-size: var(--common-small-title-size);
  letter-spacing: -0.01em;
`;
const EssentialPackage = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const Mo = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--desktop-h4-size);
  line-height: 120%;
  font-weight: 500;
  font-family: var(--desktop-h2);
  color: var(--secondary-yellow);
`;
const EssentialPackageParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const BulletIcon = styled.img`
  position: relative;
  width: 24px;
  height: 24px;
`;
const ListItem = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const BulletGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const List = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--common-small-title-size);
`;
const FrameParent2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-29xl);
`;
const Button3 = styled.div`
  border-radius: var(--br-5xs);
  background-color: var(--black);
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-13xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--common-small-title-size);
  cursor: pointer;
  text-align: center;
  margin-top: auto;
`;
const FrameParent1 = styled.div`
  background-color: var(--secondary-blue);
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-5xl);
  gap: var(--gap-29xl);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 768px) {
    flex: unset;
    align-self: stretch;
  }
`;
const FrameChild3 = styled.img`
  position: relative;
  width: 83.5px;
  height: 80px;
`;
const Mo1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--desktop-h4-size);
  line-height: 120%;
  font-weight: 500;
  font-family: var(--desktop-h2);
  color: var(--primary-navy);
`;
const Text3 = styled.div`
  flex: 1;
  position: relative;
  line-height: 120%;
`;
const HomologationFor5InsurersWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
const BulletParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
`;
const ListItemParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--common-small-title-size);
  min-height: 148px;
`;
const FrameParent3 = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--secondary-green);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-5xl);
  gap: var(--gap-29xl);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 768px) {
    flex: unset;
    align-self: stretch;
  }
`;
const FrameChild4 = styled.img`
  position: relative;
  width: 83px;
  height: 80.7px;
`;
const ListItemGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--common-small-title-size);
  min-height: 148px;
  // color: var(--primary-navy);
`;
const GoodDesignIs14 = styled.b`
  flex: 1;
  position: relative;
  line-height: 112%;
`;
const Button5 = styled.div`
  border-radius: var(--br-5xs);
  background-color: var(--black);
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-13xl);
  box-sizing: border-box;
  font-size: var(--common-small-title-size);
  cursor: pointer;
`;
const FrameParent5 = styled.div`
  background-color: var(--secondary-yellow);
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-5xl);
  gap: var(--gap-29xl);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 768px) {
    flex: unset;
    align-self: stretch;
  }
`;
const FrameContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  @media screen and (max-width: 1200px) {
    flex-direction: row;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
    display: none;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    justify-content: center;
    border-radius: 0px;
    display: none;
  }
`;
const FrameContainerCarousel = styled.div`
  display: block;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: auto;
  @media screen and (min-width: 961px) {
    display: none;
  }
`;
const EssentialPackageGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const FrameParent9 = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--secondary-blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-13xl) var(--padding-5xl);
  gap: var(--gap-13xl);
`;
const HomologationFor5InsurersContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const FrameParent11 = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--secondary-green);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-13xl) var(--padding-5xl);
  gap: var(--gap-13xl);
`;
const FrameParent13 = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--secondary-yellow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-13xl) var(--padding-5xl);
  gap: var(--gap-13xl);
`;
const FrameParent8 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1036px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  @media screen and (max-width: 960px) {
    width: 100%;
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    padding-bottom: 0px;
    box-sizing: border-box;
  }
`;
const EllipseDiv = styled.div`
  position: relative;
  border-radius: 50%;
  background-color: var(--secondary-blue);
  width: 16px;
  height: 16px;
`;
const FrameChild8 = styled.div`
  position: relative;
  border-radius: 50%;
  background-color: var(--neutral-white);
  width: 16px;
  height: 16px;
`;
const EllipseParent = styled.div`
  position: absolute;
  top: 573px;
  left: 470px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  @media screen and (max-width: 960px) {
    padding-top: var(--padding-21xl);
    box-sizing: border-box;
  }
`;
const FrameParent7 = styled.div`
  position: relative;
  width: 100%;
  height: 589px;
  display: none;
  @media screen and (max-width: 960px) {
    width: 100%;
    display: flex;
  }
`;
const Section = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-61xl) var(--padding-183xl);
  gap: 80px;
  text-align: center;
  font-size: var(--desktop-h3-size);
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const Text31 = styled.div`
  flex: 1;
  position: relative;
  line-height: 120%;
`;
const GoodDesignIsGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const FrameWrapper1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const FrameWrapper = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 769px) {
    margin: auto;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    align-items: center;
    justify-content: center;
  }
`;
const SectionInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const ButtonChild = styled.img`
  position: relative;
  width: 16px;
  height: 16px;
  display: none;
`;
const Button9 = styled.div`
  border-radius: var(--br-5xs);
  background-color: var(--neutral-white);
  width: 224px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-13xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  cursor: pointer;
`;
const Button10 = styled.div`
  border-radius: var(--br-5xs);
  background-color: var(--secondary-blue);
  border: 2px solid var(--neutral-white);
  box-sizing: border-box;
  width: 224px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-13xl);
  color: var(--neutral-white);
  cursor: pointer;
`;
const ButtonParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  text-align: left;
  font-size: var(--common-small-title-size);
  color: var(--secondary-blue);
  font-family: var(--common-small-title);
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Section1 = styled.div`
  align-self: stretch;
  background-color: var(--secondary-blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-45xl) var(--padding-183xl);
  gap: var(--gap-45xl);
  text-align: center;
  font-size: var(--desktop-h2-size);
  font-family: var(--desktop-h2);
  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: flex-start;
    padding-left: 0px;
    padding-right: 0px;
    box-sizing: border-box;
    width: 100%;
  }
`;
const FrameWrapper3 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const FrameWrapper2 = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const SectionChild = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    flex-direction: row;
  }
`;
const LineDiv = styled.div`
  flex: 1;
  position: relative;
  border-top: 2px solid var(--secondary-green);
  box-sizing: border-box;
  height: 2px;
`;
const MaskGroupIconG = styled.img`
  position: relative;
  width: 165px;
  height: 165px;
  object-fit: cover;
  border-style: solid;
  border-radius: 50%;
  border-color: #00aa58;
  border-width: 2px;
`;
const MaskGroupIcon = styled.img`
  position: relative;
  width: 165px;
  height: 165px;
  object-fit: cover;
`;
const LineParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-13xl) 0px;
`;
const EssentialPackage1 = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const CeoAbcCompany = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--common-small-title-size);
  letter-spacing: -0.01em;
  line-height: 120%;
`;
const VectorIcon1 = styled.img`
  position: relative;
  width: 20px;
  height: 18.9px;
`;
const Stars = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin: auto;
`;
const JaneSmithParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-5xl);
  gap: var(--gap-5xs);
`;
const WebflowBlack = styled.img`
  position: relative;
  width: 120px;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
`;
const OurExperienceWithCatalogiaParent = styled.div`
  align-self: stretch;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-base) var(--padding-5xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--common-small-title-size);
`;
const FrameParent16 = styled.div`
  flex: 1;
  border-radius: var(--br-5xs);
  border: 2px solid var(--secondary-green);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const FrameChild11 = styled.div`
  flex: 1;
  position: relative;
  border-top: 2px solid var(--secondary-yellow);
  box-sizing: border-box;
  height: 2px;
`;
const FrameParent17 = styled.div`
  flex: 1;
  border-radius: var(--br-5xs);
  border: 2px solid var(--secondary-yellow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const FrameChild13 = styled.div`
  flex: 1;
  position: relative;
  border-top: 2px solid var(--secondary-red);
  box-sizing: border-box;
  height: 2px;
`;
const FrameParent18 = styled.div`
  flex: 1;
  border-radius: var(--br-5xs);
  border: 2px solid var(--secondary-red);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const FrameParent15 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--common-title-size);
  font-family: var(--common-small-title);
  @media screen and (max-width: 1200px) {
    flex-direction: row;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: flex-start;
    justify-content: flex-start;
  }
`;
const Section2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-45xl) var(--padding-183xl);
  gap: var(--gap-45xl);
  text-align: center;
  font-size: var(--desktop-h2-size);
  font-family: var(--desktop-h2);
  @media screen and (max-width: 768px) {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
`;
const OurCompany = styled.b`
  position: relative;
  line-height: 120%;
`;
const MeetTheTeamParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--desktop-small-body-size);
`;
const OurCompanyParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const GoodDesignIs24 = styled.b`
  position: relative;
  line-height: 206.9%;
  display: inline-block;
  width: 180px;
`;
const CaseStudies = styled.p`
  margin: 0;
`;
const GoodDesignIs241 = styled.div`
  position: relative;
  line-height: 206.9%;
  display: inline-block;
  width: 180px;
`;
const GoodDesignIsParent2 = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
`;
const LocationOnIcon = styled.img`
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;
const LocationOnParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-mini);
`;
const GoodDesignIsContainer3 = styled.div`
  position: relative;
  font-size: var(--desktop-small-body-size);
  line-height: 120%;
`;
const FrameParent22 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  font-size: var(--common-small-title-size);
`;
const FrameParent21 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
`;
const LocationOnGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-mini);
`;
const FrameParent24 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  font-size: var(--common-small-title-size);
`;
const FrameParent23 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
`;
const GoodDesignIsContainer7 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--desktop-small-body-size);
  line-height: 120%;
`;
const Contacttherocketcodecom = styled.div`
  position: relative;
  text-decoration: underline;
  line-height: 120%;
`;
const ContacttherocketcodecomParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--desktop-small-body-size);
`;
const ContactParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const IconbrandsfacebookF = styled.img`
  position: relative;
  width: 12.4px;
  height: 24px;
`;
const IconbrandsfacebookFParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-base);
`;
const PrivacyPolicyContainer = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--desktop-caption-size);
  line-height: 120%;
`;
const GoodDesignIsParent5 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
  text-align: right;
`;
const FrameParent19 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    flex-direction: row;
    gap: var(--gap-13xl);
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: var(--gap-13xl);
    align-items: center;
    justify-content: center;
  }
`;
const FooterInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 768px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Menu = styled.div`
  position: relative;
  font-size: var(--common-small-title-size);
  text-align: right;
  display: none;
`;
const Footer = styled.div`
  align-self: stretch;
  background-color: var(--primary-navy);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-45xl) var(--padding-101xl);
  font-size: var(--common-title-size);
  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: var(--gap-0);
    align-items: center;
    justify-content: flex-start;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: var(--gap-0);
    align-items: center;
    justify-content: flex-start;
  }
`;
const HomeEspRoot = styled.div`
  position: relative;
  background-color: var(--primary-navy);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--common-small-title-size);
  color: var(--neutral-white);
  font-family: var(--common-small-title);
`;

const CatalogAI = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.substring(1); // Remover el caracter '#' del hash
      const element = document.getElementById(id);
      if (element) {
        // Desplazamiento adicional de 50px
        const offset = 130;
        const elementPosition = element.offsetTop - offset;
        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth',
        });
      }
    }
  }, []);

  const { t } = useTranslation(['catalog-ai']);
  const [isFrameOpen, setFrameOpen] = useState(false);

  const openFrame = useCallback(() => {
    setFrameOpen(true);
  }, []);

  const closeFrame = useCallback(() => {
    setFrameOpen(false);
  }, []);

  const goToContact = () => {
    window.location.href = '/v1/contact-ai';
  };

  // const goToPricing = () => {
  //   window.location.href = '#pricing'
  // }

  const pricingRef = useRef(null);

  const goToPricing = () => {
    // Paso 4: Calcula la posición del elemento destino
    const sectionPosition = pricingRef.current.offsetTop - 130;

    // Paso 4: Realiza el desplazamiento
    window.scrollTo({
      top: sectionPosition,
      behavior: 'smooth', // Opcional: para un desplazamiento suave
    });
  };

  return (
    <>
      <HomeEspRoot>
        {/* <HeaderParent>
          <Header />
          <Headermobile onClick={openFrame}>
            <GoodDesignIsWrapper>
              <GoodDesignIs>The Rocket Code</GoodDesignIs>
            </GoodDesignIsWrapper>
            <RectangleParent>
              <FrameChild />
              <FrameChild />
              <FrameChild />
            </RectangleParent>
          </Headermobile>
        </HeaderParent>
        <Navbar3>
          <Container>
            <Column>
              <Column1>
                <LinkOne>Nosotros</LinkOne>
                <LinkOne>Servicios</LinkOne>
                <NavLinkDropdown>
                  <LinkOne>Soluciones</LinkOne>
                  <ChevronDown>
                    <VectorIcon alt="" src="/vector.svg" />
                  </ChevronDown>
                </NavLinkDropdown>
              </Column1>
            </Column>
            <H1>
              <FullLogoColorDark>
                <SimpleColorIcon alt="" src="/simple-color.svg" />
                <GoodDesignIs1>rocket code</GoodDesignIs1>
              </FullLogoColorDark>
            </H1>
            <Column2>
              <StyleprimarySmalltrueDar>
                <Button>Menú</Button>
              </StyleprimarySmalltrueDar>
            </Column2>
          </Container>
        </Navbar3> */}
        <Header26 id='navigate'>
          <Column3>
            <Content>
              <MediumLengthHeroContainer>
                <TransformandoLaHomologacin>{`${t(
                  'header.title'
                )} `}</TransformandoLaHomologacin>
                <Ia>{t('header.titleC')}</Ia>
              </MediumLengthHeroContainer>
              <FrameParent>
                <FrameIcon>
                  <Carimage src='./car.svg' alt='' />
                  <Trailimage src='./trail.svg' alt='' />
                  <GroundImage src='./ground.png' alt='' />
                  <Logoimage src='./catalogailogo.png' alt='' />
                </FrameIcon>
                <LoremIpsumDolorContainer>
                  <CatalogIa>{t('header.subtitle1')}</CatalogIa>
                  <EsLaSolucinLderBasadaEn>
                    <Span>{` `}</Span>
                    <TransformandoLaHomologacin>{`${t(
                      'header.subtitle2'
                    )} `}</TransformandoLaHomologacin>
                    <RangoDeEficiencia>{`${t(
                      'header.subtitle3'
                    )} `}</RangoDeEficiencia>
                    <De>
                      <B>{t('header.subtitle4')}</B>
                      <B1>{` `}</B1>
                    </De>
                  </EsLaSolucinLderBasadaEn>
                  <CatalogIa>
                    <TransformandoLaHomologacin>
                      {t('header.subtitle5')}
                    </TransformandoLaHomologacin>
                  </CatalogIa>
                  <EsLaSolucinLderBasadaEn>
                    <CatalogIa>{` `}</CatalogIa>
                    <TransformandoLaHomologacin>
                      {t('header.subtitle6')}
                    </TransformandoLaHomologacin>
                  </EsLaSolucinLderBasadaEn>
                </LoremIpsumDolorContainer>
              </FrameParent>
            </Content>
            <ButtonParent>
              <Button1
                id='TopContact'
                name='TopBtn'
                onClick={() => goToContact()}
              >
                <GoodDesignIs2 id='top'>{t('header.button')}</GoodDesignIs2>
              </Button1>
              <Button9 onClick={() => goToPricing()}>
                <ButtonChild alt='' src='/v1/frame-51425.svg' />
                <GoodDesignIs2>{t('header.button2')}</GoodDesignIs2>
                <ButtonChild alt='' src='/v1/frame-51424.svg' />
              </Button9>
            </ButtonParent>
          </Column3>
        </Header26>
        <FrameGroup>
          <FrameChild1 alt='' src='/v1/frame-52044.svg' />
          <GoodDesignIsParent>
            <GoodDesignIsContainer>
              <TransformandoLaHomologacin>{`${t(
                'section1.title'
              )} `}</TransformandoLaHomologacin>
              <ObtainAccurateVehicle>
                {t('section1.subtitle')}
              </ObtainAccurateVehicle>
            </GoodDesignIsContainer>
            <OurExperienceWith>{t('section1.text')}</OurExperienceWith>
            <Button2>
              <GoodDesignIs2>Despeguemos</GoodDesignIs2>
            </Button2>
          </GoodDesignIsParent>
        </FrameGroup>
        <Header1>
          <TitleParent>
            <Title>
              <GoodDesignIs5>{t('section2.title1')}</GoodDesignIs5>
              <GoodDesignIsFrame>
                <Text1>{t('section2.text1')}</Text1>
              </GoodDesignIsFrame>
            </Title>
            <Title>
              <GoodDesignIs5>{`${t('section2.title2')}`}</GoodDesignIs5>
              <GoodDesignIsFrame>
                <Text1>{t('section2.text2')}</Text1>
              </GoodDesignIsFrame>
            </Title>
            <Title>
              <GoodDesignIs5>{t('section2.title3')}</GoodDesignIs5>
              <GoodDesignIsFrame>
                <Text1>{t('section2.text3')}</Text1>
              </GoodDesignIsFrame>
            </Title>
          </TitleParent>
          <FrameIcon1 alt='' src={t('section2.image')} />
        </Header1>
        <Section>
          <Title3 id='pricing' ref={pricingRef}>
            <GoodDesignIs5>
              <TransformandoLaHomologacin>{`${t(
                'section3.title1'
              )} `}</TransformandoLaHomologacin>
              <Catalogai>{`${t('section3.title2')} `}</Catalogai>
              <TransformandoLaHomologacin>
                {t('section3.title3')}
              </TransformandoLaHomologacin>
            </GoodDesignIs5>
            <GoodDesignIsWrapper2>
              <Text1>{t('section3.text')}</Text1>
            </GoodDesignIsWrapper2>
          </Title3>
          <FrameContainer>
            <FrameParent1>
              <FrameParent2>
                <FrameChild2 alt='' src='/v1/frame-52040.svg' />
                <EssentialPackageParent>
                  <EssentialPackage>
                    <Essential>{`${t('pricing.title1')} `}</Essential>
                    <Package>{t('pricing.subtitle')}</Package>
                  </EssentialPackage>
                  <Mo>{t('pricing.price1')}</Mo>
                </EssentialPackageParent>
                <ListItemParent>
                  <BulletParent>
                    <BulletIcon alt='' src='/v1/bullet.svg' />
                    <HomologationFor5InsurersWrapper>
                      <LinkOne>{t('pricing.bullet1_2_1')}<strong>{t('pricing.bullet1_2_2')}</strong></LinkOne>
                    </HomologationFor5InsurersWrapper>
                  </BulletParent>
                  <ListItem>
                    <BulletIcon alt='' src='/v1/bullet.svg' />
                    <Text3>{t('pricing.bullet1_1_1')}<strong>{t('pricing.bullet1_1_2')}</strong></Text3>
                  </ListItem>
                  {/* <BulletGroup>
                    <BulletIcon alt="" src="/bullet.svg" />
                    <Text1>
                      {t("pricing.bullet1_3")}
                    </Text1>
                  </BulletGroup> */}
                </ListItemParent>
              </FrameParent2>
              <Button3
                id='BasicoContact'
                name='BasicoBtn'
                onClick={() => goToContact()}
              >
                <GoodDesignIs2 id='basico'>{t('pricing.button')}</GoodDesignIs2>
              </Button3>
            </FrameParent1>
            <FrameParent3>
              <FrameParent2>
                <FrameChild3 alt='' src='/v1/frame-52038.svg' />
                <EssentialPackageParent>
                  <EssentialPackage>
                    <Essential>{`${t('pricing.title2')} `}</Essential>
                    <Package>{t('pricing.subtitle')}</Package>
                  </EssentialPackage>
                  <Mo1>{t('pricing.price2')}</Mo1>
                </EssentialPackageParent>
                <ListItemParent>
                  <BulletParent>
                    <BulletIcon alt='' src='/v1/bullet.svg' />
                    <HomologationFor5InsurersWrapper>
                      <LinkOne>{t('pricing.bullet2_2_1')}<strong>{t('pricing.bullet2_2_2')}</strong></LinkOne>
                    </HomologationFor5InsurersWrapper>
                  </BulletParent>
                  <ListItem>
                    <BulletIcon alt='' src='/v1/bullet.svg' />
                    <Text3>{t('pricing.bullet2_1_1')}<strong>{t('pricing.bullet2_1_2')}</strong></Text3>
                  </ListItem>
                  {/* <BulletGroup>
                    <BulletIcon alt="" src="/bullet.svg" />
                    <Text1>
                      {t("pricing.bullet2_3")}
                    </Text1>
                  </BulletGroup> */}
                </ListItemParent>
              </FrameParent2>
              <Button3
                id='ProContact'
                name='ProBtn'
                onClick={() => goToContact()}
              >
                <GoodDesignIs2 id='pro'>{t('pricing.button')}</GoodDesignIs2>
              </Button3>
            </FrameParent3>
            <FrameParent5>
              <FrameParent2>
                <FrameChild4 alt='' src='/v1/frame-520381.svg' />
                <EssentialPackageParent>
                  <EssentialPackage>
                    <Essential>{`${t('pricing.title3')} `}</Essential>
                    <Package>{t('pricing.subtitle')}</Package>
                  </EssentialPackage>
                  <Mo1>{t('pricing.price3')}</Mo1>
                </EssentialPackageParent>
                <ListItemGroup>
                  <BulletGroup>
                    <BulletIcon alt='' src='/v1/bullet.svg' />
                    <LinkOne>{t('pricing.bullet3_2_1')}<strong>{t('pricing.bullet3_2_2')}</strong></LinkOne>
                  </BulletGroup>
                  <ListItem>
                    <BulletIcon alt='' src='/v1/bullet.svg' />
                    <Text3>{t('pricing.bullet3_1_1')}<strong>{t('pricing.bullet3_1_2')}</strong></Text3>
                  </ListItem>
                  <BulletGroup>
                    <BulletIcon alt='' src='/v1/bullet.svg' />
                    <Text1>{t('pricing.bullet3_3_1')}<strong>{t('pricing.bullet3_3_2')}</strong></Text1>
                  </BulletGroup>
                </ListItemGroup>
              </FrameParent2>
              <Button5
                id='EliteContact'
                name='EliteBtn'
                onClick={() => goToContact()}
              >
                <GoodDesignIs14 id='elite'>
                  {t('pricing.button')}
                </GoodDesignIs14>
              </Button5>
            </FrameParent5>
          </FrameContainer>
          <FrameContainerCarousel>
            <Carousel navButtonsAlwaysInvisible>
              <FrameParent1>
                <FrameParent2>
                  <FrameChild2 alt='' src='/v1/frame-52040.svg' />
                  <EssentialPackageParent>
                    <EssentialPackage>
                      <Essential>{`${t('pricing.title1')} `}</Essential>
                      <Package>{t('pricing.subtitle')}</Package>
                    </EssentialPackage>
                    <Mo>{t('pricing.price1')}</Mo>
                  </EssentialPackageParent>
                  <ListItemParent>
                    <BulletParent>
                      <BulletIcon alt='' src='/v1/bullet.svg' />
                      <HomologationFor5InsurersWrapper>
                        <LinkOne>{t('pricing.bullet1_2_1')}<strong>{t('pricing.bullet1_2_2')}</strong></LinkOne>
                      </HomologationFor5InsurersWrapper>
                    </BulletParent>
                    <ListItem>
                      <BulletIcon alt='' src='/v1/bullet.svg' />
                      <Text3>{t('pricing.bullet1_1_1')}<strong>{t('pricing.bullet1_1_2')}</strong></Text3>
                    </ListItem>
                    {/* <BulletGroup>
                    <BulletIcon alt="" src="/bullet.svg" />
                    <Text1>
                      {t("pricing.bullet1_3")}
                    </Text1>
                  </BulletGroup> */}
                  </ListItemParent>
                </FrameParent2>
                <Button3
                  id='BasicoContact'
                  name='ProBtn'
                  onClick={() => goToContact()}
                >
                  <GoodDesignIs2 id='basico'>
                    {t('pricing.button')}
                  </GoodDesignIs2>
                </Button3>
              </FrameParent1>
              <FrameParent3>
                <FrameParent2>
                  <FrameChild3 alt='' src='/v1/frame-52038.svg' />
                  <EssentialPackageParent>
                    <EssentialPackage>
                      <Essential>{`${t('pricing.title2')} `}</Essential>
                      <Package>{t('pricing.subtitle')}</Package>
                    </EssentialPackage>
                    <Mo1>{t('pricing.price2')}</Mo1>
                  </EssentialPackageParent>
                  <ListItemParent>
                    <BulletParent>
                      <BulletIcon alt='' src='/v1/bullet.svg' />
                      <HomologationFor5InsurersWrapper>
                        <LinkOne>{t('pricing.bullet2_2_1')}<strong>{t('pricing.bullet2_2_2')}</strong></LinkOne>
                      </HomologationFor5InsurersWrapper>
                    </BulletParent>
                    <ListItem>
                      <BulletIcon alt='' src='/v1/bullet.svg' />
                      <Text3>{t('pricing.bullet2_1_1')}<strong>{t('pricing.bullet2_1_2')}</strong></Text3>
                    </ListItem>
                    {/* <BulletGroup>
                    <BulletIcon alt="" src="/bullet.svg" />
                    <Text1>
                      {t("pricing.bullet2_3")}
                    </Text1>
                  </BulletGroup> */}
                  </ListItemParent>
                </FrameParent2>
                <Button3
                  id='ProContact'
                  name='ProBtn'
                  onClick={() => goToContact()}
                >
                  <GoodDesignIs2 id='pro'>{t('pricing.button')}</GoodDesignIs2>
                </Button3>
              </FrameParent3>
              <FrameParent5>
                <FrameParent2>
                  <FrameChild4 alt='' src='/v1/frame-520381.svg' />
                  <EssentialPackageParent>
                    <EssentialPackage>
                      <Essential>{`${t('pricing.title3')} `}</Essential>
                      <Package>{t('pricing.subtitle')}</Package>
                    </EssentialPackage>
                    <Mo1>{t('pricing.price3')}</Mo1>
                  </EssentialPackageParent>
                  <ListItemGroup>
                    <BulletGroup>
                      <BulletIcon alt='' src='/v1/bullet.svg' />
                      <LinkOne>{t('pricing.bullet3_2_1')}<strong>{t('pricing.bullet3_2_2')}</strong></LinkOne>
                    </BulletGroup>
                    <ListItem>
                      <BulletIcon alt='' src='/v1/bullet.svg' />
                      <Text3>{t('pricing.bullet3_1_1')}<strong>{t('pricing.bullet3_1_2')}</strong></Text3>
                    </ListItem>
                    <BulletGroup>
                      <BulletIcon alt='' src='/v1/bullet.svg' />
                      <Text1>{t('pricing.bullet3_3_1')}<strong>{t('pricing.bullet3_3_2')}</strong></Text1>
                    </BulletGroup>
                  </ListItemGroup>
                </FrameParent2>
                <Button5
                  id='EliteContact'
                  name='EliteBtn'
                  onClick={() => goToContact()}
                >
                  <GoodDesignIs14 id='elite'>
                    {t('pricing.button')}
                  </GoodDesignIs14>
                </Button5>
              </FrameParent5>
            </Carousel>
          </FrameContainerCarousel>
          {/*  <FrameParent9>
                <EssentialPackageParent>
                  <FrameChild2 alt="" src="/frame-520401.svg" />
                  <EssentialPackageGroup>
                    <EssentialPackage>
                      <Essential>{`Essential `}</Essential>
                      <Package>Package</Package>
                    </EssentialPackage>
                    <Mo>$12,000/mo</Mo>
                  </EssentialPackageGroup>
                  <List>
                    <ListItem>
                      <BulletIcon alt="" src="/bullet.svg" />
                      <Text1>For 5,000 units</Text1>
                    </ListItem>
                    <ListItem>
                      <BulletIcon alt="" src="/bullet.svg" />
                      <Text1>Homologation for 3 insurers.</Text1>
                    </ListItem>
                    <BulletGroup>
                      <BulletIcon alt="" src="/bullet.svg" />
                      <Text1>
                        Aditional insurer at $4,500/mo. Extra consumption at
                        $0.50 per unit.
                      </Text1>
                    </BulletGroup>
                  </List>
                </EssentialPackageParent>
                <Button3>
                  <GoodDesignIs2>Buy now</GoodDesignIs2>
                </Button3>
              </FrameParent9>
              <FrameParent11>
                <EssentialPackageParent>
                  <FrameChild3 alt="" src="/frame-520382.svg" />
                  <EssentialPackageGroup>
                    <EssentialPackage>
                      <Essential>{`Pro `}</Essential>
                      <Package>Package</Package>
                    </EssentialPackage>
                    <Mo1>$22,000/mo</Mo1>
                  </EssentialPackageGroup>
                  <ListItemParent>
                    <ListItem>
                      <BulletIcon alt="" src="/bullet.svg" />
                      <Text3>For 5,000 units</Text3>
                    </ListItem>
                    <BulletParent>
                      <BulletIcon alt="" src="/bullet.svg" />
                      <HomologationFor5InsurersContainer>
                        <LinkOne>Homologation for 5 insurers.</LinkOne>
                      </HomologationFor5InsurersContainer>
                    </BulletParent>
                    <BulletGroup>
                      <BulletIcon alt="" src="/bullet.svg" />
                      <Text1>
                        Aditional insurer at $4,000/mo. Extra consumption at
                        $0.50 per unit.
                      </Text1>
                    </BulletGroup>
                  </ListItemParent>
                </EssentialPackageParent>
                <Button3>
                  <GoodDesignIs2>Buy now</GoodDesignIs2>
                </Button3>
              </FrameParent11>
              <FrameParent13>
                <EssentialPackageParent>
                  <FrameChild4 alt="" src="/frame-520383.svg" />
                  <EssentialPackageGroup>
                    <EssentialPackage>
                      <Essential>{`Elite `}</Essential>
                      <Package>Package</Package>
                    </EssentialPackage>
                    <Mo1>$30,000/mo</Mo1>
                  </EssentialPackageGroup>
                  <ListItemGroup>
                    <ListItem>
                      <BulletIcon alt="" src="/bullet.svg" />
                      <Text3>For 5,000 units</Text3>
                    </ListItem>
                    <BulletGroup>
                      <BulletIcon alt="" src="/bullet.svg" />
                      <LinkOne>Homologation for 8 insurers.</LinkOne>
                    </BulletGroup>
                    <BulletGroup>
                      <BulletIcon alt="" src="/bullet.svg" />
                      <Text1>
                        Aditional insurer at $3,500/mo. Extra consumption at
                        $0.50 per unit.
                      </Text1>
                    </BulletGroup>
                  </ListItemGroup>
                </EssentialPackageParent>
                <Button3>
                  <GoodDesignIs2>Buy now</GoodDesignIs2>
                </Button3>
              </FrameParent13> */}
        </Section>
        <Section1>
          <SectionInner>
            <FrameWrapper>
              <FrameWrapper1>
                <GoodDesignIsGroup>
                  <GoodDesignIs5>{t('section4.title')}</GoodDesignIs5>
                  <GoodDesignIsWrapper2>
                    <Text31>{t('section4.text')}</Text31>
                  </GoodDesignIsWrapper2>
                </GoodDesignIsGroup>
              </FrameWrapper1>
            </FrameWrapper>
          </SectionInner>
          <ButtonParent>
            <Button9
              id='BottomContact'
              name='BottomBtn'
              onClick={() => goToContact()}
            >
              <ButtonChild alt='' src='/v1/frame-51425.svg' />
              <GoodDesignIs2 id='bottom'>{t('section4.button1')}</GoodDesignIs2>
              <ButtonChild alt='' src='/v1/frame-51424.svg' />
            </Button9>
            {/* <Button10 onClick={() => goToContact()}>
              <GoodDesignIs2>{t("section4.button2")}</GoodDesignIs2>
            </Button10> */}
          </ButtonParent>
        </Section1>
        <Section2>
          <SectionChild>
            <FrameWrapper2>
              <FrameWrapper3>
                <GoodDesignIsGroup>
                  <GoodDesignIs5>{t('section5.title')}</GoodDesignIs5>
                  <GoodDesignIsWrapper2>
                    <Text1>{t('section5.subtitle')}</Text1>
                  </GoodDesignIsWrapper2>
                </GoodDesignIsGroup>
              </FrameWrapper3>
            </FrameWrapper2>
          </SectionChild>
          <FrameParent15>
            <FrameParent16>
              <LineParent>
                <LineDiv />
                <MaskGroupIconG alt='' src='/v1/Testimonio_1.png' />
                <LineDiv />
              </LineParent>
              <JaneSmithParent>
                <EssentialPackage1>Zitlalkoatl Mesino</EssentialPackage1>
                <CeoAbcCompany>{t('section5.job1')}</CeoAbcCompany>
                <Stars>
                  <VectorIcon1 alt='' src='/v1/vector1.svg' />
                  <VectorIcon1 alt='' src='/v1/vector2.svg' />
                  <VectorIcon1 alt='' src='/v1/vector3.svg' />
                  <VectorIcon1 alt='' src='/v1/vector4.svg' />
                  <VectorIcon1 alt='' src='/v1/vector2.svg' />
                </Stars>
              </JaneSmithParent>
              <OurExperienceWithCatalogiaParent>
                <OurExperienceWith>{t('section5.text1')}</OurExperienceWith>
                {/* <WebflowBlack alt="" src="/webflow--black.svg" /> */}
              </OurExperienceWithCatalogiaParent>
            </FrameParent16>
            <FrameParent17>
              <LineParent>
                <FrameChild11 />
                <MaskGroupIcon alt='' src='/v1/Testimonio_2.svg' />
                <FrameChild11 />
              </LineParent>
              <JaneSmithParent>
                <EssentialPackage1>Rubén García</EssentialPackage1>
                <CeoAbcCompany>{t('section5.job2')}</CeoAbcCompany>
                <Stars>
                  <VectorIcon1 alt='' src='/v1/vector1.svg' />
                  <VectorIcon1 alt='' src='/v1/vector2.svg' />
                  <VectorIcon1 alt='' src='/v1/vector3.svg' />
                  <VectorIcon1 alt='' src='/v1/vector4.svg' />
                  <VectorIcon1 alt='' src='/v1/vector2.svg' />
                </Stars>
              </JaneSmithParent>
              <OurExperienceWithCatalogiaParent>
                <OurExperienceWith>{t('section5.text2')}</OurExperienceWith>
                {/* <WebflowBlack alt="" src="/webflow--black.svg" /> */}
              </OurExperienceWithCatalogiaParent>
            </FrameParent17>
            <FrameParent18>
              <LineParent>
                <FrameChild13 />
                <MaskGroupIcon alt='' src='/v1/Testimonio_3.svg' />
                <FrameChild13 />
              </LineParent>
              <JaneSmithParent>
                <EssentialPackage1>Víctor Bolaños</EssentialPackage1>
                <CeoAbcCompany>{t('section5.job3')}</CeoAbcCompany>
                <Stars>
                  <VectorIcon1 alt='' src='/v1/vector1.svg' />
                  <VectorIcon1 alt='' src='/v1/vector2.svg' />
                  <VectorIcon1 alt='' src='/v1/vector3.svg' />
                  <VectorIcon1 alt='' src='/v1/vector4.svg' />
                  <VectorIcon1 alt='' src='/v1/vector2.svg' />
                </Stars>
              </JaneSmithParent>
              <OurExperienceWithCatalogiaParent>
                <OurExperienceWith>{t('section5.text3')}</OurExperienceWith>
                {/* <WebflowBlack alt="" src="/webflow--black.svg" /> */}
              </OurExperienceWithCatalogiaParent>
            </FrameParent18>
          </FrameParent15>
        </Section2>
        {/* <Footer>
          <FooterInner>
            <FrameParent19>
              <OurCompanyParent>
                <OurCompany>Our company</OurCompany>
                <MeetTheTeamParent>
                  <EssentialPackage>Meet the team</EssentialPackage>
                  <EssentialPackage>Careers</EssentialPackage>
                </MeetTheTeamParent>
              </OurCompanyParent>
              <OurCompanyParent>
                <EssentialPackage1>Locations</EssentialPackage1>
                <OurCompanyParent>
                  <FrameParent21>
                    <GoodDesignIsParent2>
                      <GoodDesignIs24>Site</GoodDesignIs24>
                      <GoodDesignIs241>
                        <CaseStudies>Case Studies</CaseStudies>
                        <CaseStudies>Services</CaseStudies>
                        <CaseStudies>About</CaseStudies>
                        <CaseStudies>Blog</CaseStudies>
                      </GoodDesignIs241>
                    </GoodDesignIsParent2>
                    <FrameParent22>
                      <LocationOnParent>
                        <LocationOnIcon alt="" src="/location-on.svg" />
                        <OurCompany>México City</OurCompany>
                      </LocationOnParent>
                      <GoodDesignIsContainer3>
                        <CaseStudies>Perif. Sur 3720</CaseStudies>
                        <CaseStudies>Jardínes del Pedregal</CaseStudies>
                        <CaseStudies>Alvaro Obregón</CaseStudies>
                        <CaseStudies>01900 México City, México.</CaseStudies>
                      </GoodDesignIsContainer3>
                    </FrameParent22>
                  </FrameParent21>
                  <FrameParent23>
                    <GoodDesignIsParent2>
                      <GoodDesignIs24>Site</GoodDesignIs24>
                      <GoodDesignIs241>
                        <CaseStudies>Case Studies</CaseStudies>
                        <CaseStudies>Services</CaseStudies>
                        <CaseStudies>About</CaseStudies>
                        <CaseStudies>Blog</CaseStudies>
                      </GoodDesignIs241>
                    </GoodDesignIsParent2>
                    <FrameParent24>
                      <LocationOnGroup>
                        <LocationOnIcon alt="" src="/location-on.svg" />
                        <Text3>Querétaro, México</Text3>
                      </LocationOnGroup>
                      <GoodDesignIsContainer3>
                        <CaseStudies>Av. Paseo Monte Miranda 15</CaseStudies>
                        <CaseStudies>Int. 812</CaseStudies>
                        <CaseStudies>Colonia Miranda</CaseStudies>
                        <CaseStudies>76240 El Marques, Querétaro.</CaseStudies>
                      </GoodDesignIsContainer3>
                    </FrameParent24>
                  </FrameParent23>
                  <FrameParent21>
                    <GoodDesignIsParent2>
                      <GoodDesignIs24>Site</GoodDesignIs24>
                      <GoodDesignIs241>
                        <CaseStudies>Case Studies</CaseStudies>
                        <CaseStudies>Services</CaseStudies>
                        <CaseStudies>About</CaseStudies>
                        <CaseStudies>Blog</CaseStudies>
                      </GoodDesignIs241>
                    </GoodDesignIsParent2>
                    <FrameParent22>
                      <LocationOnParent>
                        <LocationOnIcon alt="" src="/location-on.svg" />
                        <OurCompany>Miami Brickwell</OurCompany>
                      </LocationOnParent>
                      <GoodDesignIsContainer7>
                        <CaseStudies>1395 Brickell Ave</CaseStudies>
                        <CaseStudies>Miami, FL 33131</CaseStudies>
                        <CaseStudies>USA.</CaseStudies>
                      </GoodDesignIsContainer7>
                    </FrameParent22>
                  </FrameParent21>
                </OurCompanyParent>
              </OurCompanyParent>
              <ContactParent>
                <EssentialPackage1>Contact</EssentialPackage1>
                <ContacttherocketcodecomParent>
                  <Contacttherocketcodecom>
                    contact@therocketcode.com
                  </Contacttherocketcodecom>
                  <EssentialPackage>+52 1 55 2775 5335</EssentialPackage>
                </ContacttherocketcodecomParent>
              </ContactParent>
              <GoodDesignIsParent5>
                <OurCompany>The Rocket Code</OurCompany>
                <IconbrandsfacebookFParent>
                  <IconbrandsfacebookF
                    alt=""
                    src="/iconbrandsfacebook-f1.svg"
                  />
                  <BulletIcon alt="" src="/iconbrandsinstagram1.svg" />
                  <BulletIcon alt="" src="/iconbrandslinkedin-in1.svg" />
                </IconbrandsfacebookFParent>
                <PrivacyPolicyContainer>
                  <CaseStudies>{`© Copyright 2023 Rocket Code. `}</CaseStudies>
                  <CaseStudies>All rights reserved.</CaseStudies>
                </PrivacyPolicyContainer>
              </GoodDesignIsParent5>
            </FrameParent19>
          </FooterInner>
          <Menu>Case Studies Services About Blog Careers</Menu>
        </Footer> */}
      </HomeEspRoot>
      {isFrameOpen && (
        <PortalDrawer
          overlayColor='rgba(113, 113, 113, 0.3)'
          placement='Left'
          onOutsideClick={closeFrame}
        >
          <FrameComponent onClose={closeFrame} />
        </PortalDrawer>
      )}
    </>
  );
};

export default CatalogAI;
