import styled from 'styled-components';
import { device } from '../../../style/device';
import { BtnGeneral } from '../../../style/btn-styles';
import { Vacancies } from '../data/vacancies';
import HiringForm from './HiringForm';
import { NavLink as Link } from 'react-router-dom';
import { use } from 'i18next';
import { useEffect, useRef } from 'react';

const Body = styled.div`
  padding: 174px 120px 64px;
  display: flex;
  flex-direction: column;
  p {
    font-size: 16px;
  }
  .decoration {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 64px;
    align-items: stretch;
    margin-bottom: 50px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .left-decoration {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    width: 100%;
  }
  .show-desktop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
  .title-share {  
    font-family: "DM Sans";
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%
    color: #fff;
    margin: 25px 0px 0px 0px;
  }
  .icon-puzzle {
    width: 201px;
    height: 148px;
  }
  .buttons-share {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
  .subtitle-share {
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0px;
  }
  .icons-share {
    display: flex;
    align-items: flex-start;
    gap: 32px;
  }
  .icon-share {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .right-decoration {
    display: flex;
    padding-top: 140px; 
    margin-top: -140px;
    align-items: center;
    width: 100%;
  }
  .gold {
    color: #ffa800;
    padding-top: 20px;
  }
  li {
    color: white;
    line-height: 29px;
    list-style: disc;
  }
  ul {
    padding-left: 15px;
  }
  .green-title {
    color: #00aa58;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .description {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 50px 0px 0px;
  }
  @media screen and (max-width: 768px) {
    padding: 290px 32px 32px 32px;
    flex-direction: row;
    align-content: baseline;
    .decoration {
      
    }
    .left-decoration {
      display: flex;
      flex-direction: column;
      justify-content: baseline;
      align-items: center;
    }
    .show-desktop {
      align-items: center;
    }
    .buttons-share {
      align-items: center;
    }
    .icons-share {
      align-items: center;
    }
    .right-decoration {
      display: flex;
      padding-top: 200px; 
      margin-top: -200px;
      flex-direction: column;
      justify-content: baseline;
      align-items: center;
    }
    h4 {
      font-size: 25px;
      margin: 0px;
    }
    .buttons {
      padding: 80px 0px 80px;
    }
    .gold {
      padding-top: 40px;
    }
    .description {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      padding-bottom: 30px;
    }
  }
`;

const VacanciesLs = styled.div`
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 9;
  transition: top 0.2s;
  background-color: #0e1d39;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    padding-left: 40px;
    h4 {
      font-size: 55px;
      weight: 700;
      line-height: 66px;
      margin: 0px;
    }
  }
  .location {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'DM Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
  img {
    padding-right: 24px;
    padding-left: 16px;
  }
  p {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin: 5px 0;
    line-height: 24px;
  }
  .button-apply {
    display: flex;
    justify-content: flex-end;
    button {
      background-color: #fff;
      color: #2240d9;
      padding: 16px 32px;
      border: none;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      width: 168px;
      height: 50px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    .button-apply {
      justify-content: center;
      width: 100%;
      margin-top: 20px;
      button {
        width: 100%;
      }
    }
    .location {
      font-size: 16px;
    }
    .title {
      padding-left: 10px;
      h4 {
        font-size: 30px;
        line-height: 48px;
      }
    }
  }
`;

export default function Vacancie({ match }) {
  const vacancie = Vacancies.find((el) => el.url === match.params.name);

  const vacanciesLsRef = useRef(null); // Referencia para acceder al elemento DOM de VacanciesLs

  useEffect(() => {
    const handleScroll = () => {
      const shouldBeFixed = window.scrollY > 70; // Ajusta este valor según la altura de tu navbar
      if (vacanciesLsRef.current) {
        vacanciesLsRef.current.style.top = shouldBeFixed ? '0px' : '70px';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToApplySection = () => {
    const applySection = document.getElementById('apply-section');
    if (applySection) {
      applySection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Body>
        <div className='description'>
          <div className='description'>
            <VacanciesLs ref={vacanciesLsRef}>
              <div className='title-container'>
                <Link to='/v1/new-hiring'>
                  <div>
                    <img src='/v1/arrow-left.svg' alt='Arrow Left' />
                  </div>
                </Link>
                <div className='title'>
                  <h4>{vacancie.name}</h4>
                  <div className='location'>
                    <p>{vacancie.type}</p>
                    <img
                      src={`/v1/hiring/${vacancie.svg}.svg`}
                      alt={vacancie.svg}
                    />
                    <p>{vacancie.location}</p>
                  </div>
                </div>
              </div>
              <div className='button-apply'>
                <button onClick={scrollToApplySection}>Aplicar ahora</button>
              </div>
            </VacanciesLs>
            <h4 className='gold'>{vacancie.subtitle1}</h4>
            <ul>
              {vacancie.text1.map((el, i) => (
                <li key={i}>{el}</li>
              ))}
            </ul>
            <h4 className='gold'>{vacancie.subtitle2}</h4>
            <ul>
              {vacancie.text2.map((el, i) => (
                <li key={i}>{el}</li>
              ))}
            </ul>
            <h4 className='gold'>{vacancie.subtitle3}</h4>
            <ul>
              {vacancie.text3.map((el, i) => (
                <li key={i}>{el}</li>
              ))}
            </ul>
            {vacancie.subtitle4 && (
              <>
                <h4 className='gold'>{vacancie.subtitle4}</h4>
                <ul>
                  {vacancie.text4.map((el, i) => (
                    <li key={i}>{el}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <div className='decoration'>
            <div className='left-decoration'>
              <div className='show-desktop'>
                <h2 className='title-share'>!Aplica Ahora!</h2>
                <img
                  src='/v1/hiring/puzzle.svg'
                  alt='Rompecabezas'
                  className='icon-puzzle'
                />
              </div>
              <div className='buttons-share'>
                <h5 className='subtitle-share'>Comparte esta vacante</h5>
                <div className='icons-share'>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src='/v1/Facebook.svg'
                      alt='Facebook'
                      className='icon-share'
                    />
                  </a>
                  <a
                    href='https://www.instagram.com/therocketcode?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src='/v1/Instagram.svg'
                      alt='Instagram'
                      className='icon-share'
                    />
                  </a>
                  <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src='/v1/Linkedin.svg'
                      alt='Linkedin'
                      className='icon-share'
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className='right-decoration' id='apply-section'>
              <HiringForm vacancie={vacancie} />{' '}
            </div>
          </div>
        </div>
      </Body>
    </>
  );
}
