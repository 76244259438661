import React from 'react';
import Graphs from './DashboardComponents/Graphs';
import styled from 'styled-components';
import HeaderDashboard from './DashboardComponents/HeaderDashboard';
import Sidebar from './DashboardComponents/Sidebar';
// import DashboardLogin from './DashboardComponents/DashboardLogin.js';
import { useEffect } from 'react';
import { useState } from 'react';

const Dashboard = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let Mobile = screenWidth < 1024 ? true : false;

  useEffect(() => {
    setOpenMenu(false);
  }, [Mobile]);

  return (
    <>
      <BrowserRoot>
        <>
          <HeaderDashboard
            mobile={Mobile}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
          />
          <Flex>
            <Sidebar
              mobile={Mobile}
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
            />

            <DashboardContainer openMenu={openMenu}>
              <Graphs />
            </DashboardContainer>
          </Flex>
        </>
      </BrowserRoot>
    </>
  );
};

export default Dashboard;

const DashboardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 983px;
  box-sizing: border-box;
  display: ${({ openMenu }) => (openMenu ? 'none' : 'flex')};
`;

const BrowserRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: #0e1d39;
  // height: 1070px;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-family: 'DM Sans';
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
`;
