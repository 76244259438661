import styled from 'styled-components';
import { useEffect } from 'react';

const TooltipRoot = styled.div`
  position: fixed;
  max-width: ${(props) => props.width + 'px'};
  margin: 16px;
  z-index: 999;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  padding: var(--spacing-lg, 17.123px);
  flex-direction: column;
  align-self: stretch;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  font-size: 17.123px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.684px; /* 150% */

  background-color: #158514;
  border: 1px solid #158514;
  border-radius: 8px;

  top: ${(props) => props.top};
  left: ${(props) => props.left};

  transform: translateX(-50%);
  box-sizing: border-box;

  @media screen and (max-width: 1023px) {
    width: 100vw;
  }

  @media screen and (max-width: 642px) {
    width: 100vw;
    top: 60%;
    left: 50%;
    box-sizing: border-box;
  }
`;

const TooltipTitle = styled.h3`
  color: #fff;
  font-size: 17.123px;
  font-style: normal;
  font-weight: 800;
  line-height: 25.684px; /* 150% */
`;

const TooltipContent = styled.p`
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 17.123px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.684px; /* 150% */
`;

const TooltipArrowsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
`;

const TooltipArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const TooltipAngle = styled.div`
position: relative
display: flex;
width: 100%;
height: 1px;
display: flex;
align-items: center;
justify-content: center;

&::before {
  content: '';
  position: absolute;
  top: -10px;
  width: 2px;
  height: 0px;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #158514 transparent;
}
`;

const Tooltip = ({ stage, setStage, Tooltips }) => {
  useEffect(() => {
    const firstTimeLogged = sessionStorage.getItem('firstTimeLogged');
    if (Boolean(firstTimeLogged)) {
      setStage((prev) => {
        return {
          ...prev,
          isActive: true,
        };
      });
    }
  }, []);

  const handleNextStep = () => {
    if (stage.index === Tooltips.length - 1) {
      sessionStorage.removeItem('firstTimeLogged');
      setStage((prev) => {
        return {
          ...prev,
          isActive: false,
        };
      });
      window.history.go('/v1/swarm/chat');
      return;
    }
    setStage((prev) => {
      return {
        ...prev,
        index: prev.index + 1,
        content: Tooltips[prev.index + 1].content,
        top: Tooltips[prev.index + 1].top,
        left: Tooltips[prev.index + 1].left,
      };
    });
  };

  const handlePreviousStep = () => {
    setStage((prev) => {
      return {
        ...prev,
        index: stage.index - 1,
        content: Tooltips[stage.index - 1].content,
        top: Tooltips[stage.index - 1].top,
        left: Tooltips[stage.index - 1].left,
      };
    });
  };

  return (
    <>
      {stage.isActive && (
        <TooltipRoot
          top={stage.top}
          left={stage.left}
          width={stage.index === 3 ? '240' : '455'}
        >
          <TooltipAngle />

          <TooltipTitle>{`Paso ${stage.index + 1}`}</TooltipTitle>
          <TooltipContent>{stage.content}</TooltipContent>

          <TooltipArrowsWrapper>
            {stage.index > 0 && (
              <TooltipArrow onClick={handlePreviousStep}>
                <img
                  src="/v1/swarm/NextStep.svg"
                  alt="prev step"
                  style={{ transform: 'rotate(180deg)' }}
                />
              </TooltipArrow>
            )}
            <TooltipArrow onClick={handleNextStep}>
              <img
                src={
                  Tooltips.length - 1 === stage.index
                    ? '/v1/swarm/finalStage.svg'
                    : '/v1/swarm/NextStep.svg'
                }
                alt="next step"
              />
            </TooltipArrow>
          </TooltipArrowsWrapper>
        </TooltipRoot>
      )}
    </>
  );
};

export default Tooltip;
