import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`;

export const ToastContainer = styled.div`
  max-width: 20rem;
  background-color: #ea3737;
  color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  position: fixed;
  top: 1rem;
  right: 1rem;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  animation: ${props => props.closing ? fadeOut : fadeIn} 0.3s ease-in-out forwards;
  display: ${props => props.toastMessage.length > 0 ? 'flex' : 'none'};
`;

export const ToastMessage = styled.div`
  font-size: 0.875rem;
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  color: white;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.1s ease-in-out;

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

export const CloseIcon = styled.svg`
  width: ${props => props.width ? props.width : '1.2rem'};
  height: ${props => props.height ? props.height : '1.2rem'};
`;

export const CloseIconContainer = styled.div`
  position: relative;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`;


