import { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  sendMessageToAssistantBot,
  startConversation,
} from '../services/swarm.service';
import styled from 'styled-components';
import SendButton from './SendButton';
import useVersionExtractor from '../hooks/useExtractVersionFromPath';

const FormElement = styled.form`
  position: sticky;
  z-index: 1000;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px 0px;
  min-width: 300px;
  width: 100%;
  margin: 0;
  padding: 16px 96px;
  background-color: #fff;
  box-sizing: border-box;
  transition: all 0.3s ease;

  @media screen and (max-width: 420px) {
    display: flex;
    width: 100%;
    height: auto;
    padding: 0px;
  }
`;

const FormDesktopRoot = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0px 12px;
`;

const InputRoot = styled.textarea`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 16px 32px;
  min-height: 1rem;
  max-height: 14rem;
  border: 1px solid #2240d9;
  background-color: transparent;
  border-radius: 8px;
  font-family: 'DM Sans';
  font-size: 16px;
  color: #003047;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 24px;
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Estilos para Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
`;

const Form = ({
  isLoading,
  setIsLoading,
  threadId,
  setThreadId,
  isFinished,
  setIsFinished,
  setMessages,
  messages,
  setThreads,
  chatConfig,
  idSession,
  setIdSession,
  setIsFetchingAssistantBot,
  conversationId,
  setConversationId,
}) => {
  const textareaRef = useRef();
  const history = useHistory();
  const formRef = useRef();
  const [initialAnswer, setInitialAnswer] = useState('');
  const version = useVersionExtractor();

  useEffect(() => {
    const textarea = textareaRef.current;
    const resizeTextarea = () => {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === 'Enter') {
        e.preventDefault();
        formRef.current.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true })
        );
      }
    };

    textarea.addEventListener('input', resizeTextarea);
    textarea.addEventListener('keydown', handleKeyDown);
    resizeTextarea();
    return () => {
      textarea.removeEventListener('input', resizeTextarea);
      textarea.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) {
      setIsLoading(false);
      // stopStreaming({ threadId });
      setThreadId('');
      return;
    }
    try {
      const formData = new FormData(e.target);
      let task = formData.get('prompt');
      task = task.replace(/&/g, 'and');
      if (!task || task === '') {
        setIsLoading(false);
        return;
      }
      const updatedInitialAnswer = initialAnswer.concat('\n', task);
      setInitialAnswer(updatedInitialAnswer);

      e.target.reset();
      textareaRef.current.style.height = 'auto';

      setMessages((prevMessages) => [
        ...prevMessages,
        { message: task, name: 'You' },
      ]);

      setIsFetchingAssistantBot((prev) => !prev);
      const swarmResponse = await startConversation({
        version,
        task,
        chatConfig,
        conversation_id: conversationId,
      });
      const { conversation_id, ai_message, messages, DDBB_CONVERSATION_ID } =
        swarmResponse;
      if (!conversationId) setConversationId(conversation_id);
      if (ai_message) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { message: ai_message, name: 'AutoSales Proposal' },
        ]);
      }
      if (messages && messages.length > 0 && DDBB_CONVERSATION_ID) {
        setThreads((prevThreads) => [
          {
            threadId: DDBB_CONVERSATION_ID,
            chat_name: 'Propuesta Cliente',
          },
          ...prevThreads,
        ]);
      }
      if (DDBB_CONVERSATION_ID) {
        setThreadId(DDBB_CONVERSATION_ID);

        setTimeout(() => {
          history.push(`/v1/${version}/proposal/${DDBB_CONVERSATION_ID}`);
        }, 1000);
        // history.push(`/v1/${version}/proposal/${DDBB_CONVERSATION_ID}`);
      }
      setIsFetchingAssistantBot((prev) => !prev);
    } catch (error) {
      console.error('Error al enviar el mensaje', error);
      return;
    } finally {
      e.target.reset();
      setIsFetchingAssistantBot(false);
      setIsLoading(false);
    }
  };

  return (
    <FormElement ref={formRef} onSubmit={handleSubmit}>
      <FormDesktopRoot>
        <InputRoot
          ref={textareaRef}
          disabled={isLoading || isFinished}
          placeholder="Escribe aquí..."
          id="prompt"
          name="prompt"
        />
        <SendButton
          disabled={isLoading || isFinished || setIsFetchingAssistantBot}
        >
          Enviar
        </SendButton>
      </FormDesktopRoot>
    </FormElement>
  );
};

export default Form;
