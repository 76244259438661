const SvgHowWeWork04 = () => {
  return (
    <div className="img-desktop">
      <svg width="470" height="340" viewBox="0 0 535 431" fill="none">
        <path className="geo-figure" d="M358.391 372.211L358.945 372.211L359.221 371.731L531.831 72.0453L532.658 70.6084L531 70.6084L184.111 70.6084L183.557 70.6084L183.281 71.0885L10.6713 370.774L9.84374 372.211L11.5019 372.211L358.391 372.211Z" stroke="white" strokeWidth="1.91697"/>
        <rect className="geo-figure" y="271.755" width="314.574" height="314.574" transform="rotate(-59.7555 0 271.755)" fill="#EF0000"/>
        <svg className="geo-figure">
          <path d="M249.596 281.755C272.479 252.883 242.516 227.5 210.228 202.671C177.94 177.842 147.976 152.456 170.859 123.583" stroke="white" strokeWidth="3" strokeMiterlimit="10"/>
          <path d="M170.859 281.755C147.976 252.883 177.94 227.5 210.228 202.671C242.516 177.842 272.486 152.456 249.596 123.583" stroke="white" strokeWidth="3" strokeMiterlimit="10"/>
          <path d="M164.065 134.344H256.147" stroke="white" strokeWidth="3" strokeMiterlimit="10"/>
          <path d="M168.012 161.672H252.042" stroke="white" strokeWidth="3" strokeMiterlimit="10"/>
          <path d="M193.289 189.004H227.365" stroke="white" strokeWidth="3" strokeMiterlimit="10"/>
          <path d="M193.289 216.335H227.365" stroke="white" strokeWidth="3" strokeMiterlimit="10"/>
          <path d="M167.614 243.667H252.449" stroke="white" strokeWidth="3" strokeMiterlimit="10"/>
          <path d="M164.825 270.995H255.642" stroke="white" strokeWidth="3" strokeMiterlimit="10"/>
        </svg>
      </svg>
    </div>
  )
}

export default SvgHowWeWork04
