import { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { revalidateSession } from '../../services/swarm.service';
import Loader from '../Loader';
import styled from 'styled-components';
import AppLayout from '../Layuout/AppLayout';
import useVersionExtractor from '../../hooks/useExtractVersionFromPath';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const version = useVersionExtractor();
  const [isLoading, setIsLoading] = useState(true);
  const [isSessionValid, setIsSessionValid] = useState(false);

  useEffect(() => {
    const validateSession = async () => {
      setIsLoading(true);
      const result = await revalidateSession({ version });

      if (result?.error || !result) {
        localStorage.removeItem('token');
        setIsSessionValid(false);
        setIsLoading(false);
        return;
      }
      setIsSessionValid(!result.error);
      setIsLoading(false);
    };

    validateSession();
  }, [version]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : isSessionValid ? (
          <AppLayout>
            <Component {...props} />
          </AppLayout>
        ) : (
          <Redirect to={`/v1/${version}/login`} />
        )
      }
    />
  );
};

export default PrivateRoute;
