import styled from "styled-components";

export const Nav = styled.div`
  width: 90%;
  margin: 0 !important;
  position: absolute;
  top: 31px;
  left: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20vw;
  z-index: 3;
  font-size: 24px;
  @media screen and (max-width: 1200px) {
    width: 90%;
    align-items: center;
    justify-content: space-between;
    gap: 0px;
  }
  @media screen and (max-width: 430px) {
    display: none;
  }
`;

export const LogoChild = styled.img`
  width: 33.8px;
  position: relative;
  height: 33.8px;
  object-fit: cover;
  cursor: pointer;
`;
export const RocketCode = styled.div`
  height: 31px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: "IBM Plex Sans";

`;
export const Logo = styled.div`
  height: 35.9px;
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  @media screen and (max-width: 1200px) {
    width: 20%;
  }
`;
export const Services1 = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.08em;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "IBM Plex Sans";
  color: #fff;
  text-align: left;
  display: inline-block;
  cursor: pointer !important;
`;
export const Menu1 = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  @media screen and (max-width: 1200px) {
    width: auto;
    align-self: unset;
  }
`;