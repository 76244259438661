import { createContext, useState } from 'react';

export const SwarmContext = createContext();

export const SwarmProvider = ({ children }) => {
  const [threads, setThreads] = useState([]);
  const [messages, setMessages] = useState([]);
  const [threadId, setThreadId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isFirstTimeLogged, setIsFirstTimeLogged] = useState(true);

  return (
    <SwarmContext.Provider
      value={{
        threads,
        setThreads,
        messages,
        setMessages,
        threadId,
        setThreadId,
        isLoading,
        setIsLoading,
        isFinished,
        setIsFinished,
        isFirstTimeLogged,
        setIsFirstTimeLogged,
      }}
    >
      {children}
    </SwarmContext.Provider>
  );
};
