import styled from 'styled-components';
import Loader from '../components/Loader';
import FinishView from '../components/FinishView';
import HeaderMobile from '../components/HeaderMobile';
import Form from '../components/Form';
import Sidebar from '../components/Sidebar';
import TooltipContent from '../components/Tooltip';
import { useState } from 'react';
import MockSidebar from '../components/MockSidebar';

const HolaSoy = styled.span``;

const Swarm1 = styled.b``;
const GoodDesignIsContainer1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 120%;
`;
const GoodDesignIsParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0px;
`;
const ListElement = styled.li``;
const ListParent = styled.ul`
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: 21px;
`;
const ListContainer = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const QuestionaryContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px 0px;
  font-size: 16px;
  color: #0e1d39 !important;
`;
const QuestionConteiner = styled.div`
  min-width: 300px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px 0px;
  padding: 32px 96px 0px;
  box-sizing: border-box;

  @media screen and (max-width: 642px) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
    box-sizing: border-box;
  }
`;
const ResponseConteiner = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0px;
  min-width: 300px;
  padding: 32px 96px 0px;
  color: #0e1d39 !important;
  @media screen and (max-width: 642px) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
    box-sizing: border-box;
  }
`;
const ResponseElement = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px 0px;
  font-size: 16px;
  color: #0e1d39 !important;
  & > p,
  ul,
  ol,
  div,
  ol > li > p,
  ul > li > p,
  ol > li > div,
  ul > li > d,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #0e1d39 !important;
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 14px 0px;
  }

  h2,
  h3,
  h4 {
    color: var(--PRIMARY-NAVY, #0e1d39);
    font-family: 'DM Sans';
    font-size: 19.22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
`;
const BodyContent = styled.div`
  position: relative;
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px 0px;
  overflow-y: auto;
  min-height: 100vh;

  @media screen and (max-width: 420px) {
    gap: 20px 0px;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
`;
const ChatRoot = styled.div`
  position: relative;
  width: 100%;
  height: 100vh; // 100vh
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 40px;
  color: #0e1d39 !important;
  font-family: 'DM Sans';

  @media screen and (max-width: 420px) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

const Icon = styled.img`
  width: 18px;
  position: relative;
  height: 16.3px;
`;

const LoaderContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.button`
  border-radius: 8px;
  background-color: #2240d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid #2240d9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 22px;
  gap: 0px 10px;
  text-align: left;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */

  &:hover {
    background-color: #1d2f7b;
    border-color: #1d2f7b;
  }

  &:disabled {
    background-color: #d6e0ef;
    color: #98a2b3;
    cursor: not-allowed;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
  align-self: stretch;
`;

const Bigtittle = styled.h3`
  color: #0e1d39;
  text-align: center;

  /* DESKTOP/H1 */
  ${'' /* font-family: Roboto; */}
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 76.8px */
`;

const Smalltittle = styled.h3`
  color: #0e1d39;
  text-align: center;
  font-family: 'DM Sans';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 48px */
`;
const initialAnswer = [
  '¿Podría contarnos brevemente sobre el core de tu negocio y tu modelo de negocio principal?',
  '¿Qué factores consideras que son clave para el crecimiento de tus ventas y márgenes?',
  '¿Quienes son tus competidores mas relevantes y que tienen ellos en tecnología que no tienen ustedes?',
  '¿Qué tecnologías apoyan el éxito y la eficiencia operativa de tu empresa?',
  'Basándote en te experiencia, ¿Dónde ve oportunidades de mejoras en tu negocio mediante la tecnología para aumentar ventas o reducir costos?',
  'Respecto al presupuesto para tecnología, ¿Podrías compartir cómo lo han distribuido hasta ahora? ¿Han preferido soluciones internas o externalizadas? ¿Por qué?',
  '¿Cuál es el desafío más grande que enfrenta tu empresa y que cree que podría ser resuelto con una solución tecnológica?',
  'Tiene algún presupuesto y tiempo esperado para la implementación de este proyecto?',
  '¿Existen limitaciones técnicas, financieras o de otro tipo que debamos tener en cuenta? ¿Cuáles son sus principales preocupaciones que podrían afectar el progreso del proyecto?',
  'Hasta ahora, ¿qué soluciones o tecnologías ha considerado para abordar este desafío? ¿Qué le ha frenado para implementarlas o por qué no han sido efectivas?',
  '¿Cómo definiría el éxito en la implementación de una nueva solución tecnológica para su empresa? ¿Existen métricas específicas o KPIs que esté buscando mejorar?',
  'Existe alguna limitacion o consideracion financiera o de tiempo que debamos considerar? Costo y tiempos de Implementacion.',
];

const MockPage = () => {
  const Tooltips = [
    {
      content:
        'Para ayudarte con tu propuesta, es necesario que le des a Swarm el contexto sobre te proyecto. Usa nuestras preguntas para guiar tus respuestas.',
      top: `60%`,
      left: `50%`,
    },
    {
      content:
        'Swarm suele tomar algo de tiempo en responder con tu propuesta. Dependiendo de la información que proporcionaste el chat puede tardar desde 5 minutos hasta 30 minutos.',
      top: `60%`,
      left: `50%`,
    },
    {
      content:
        'Descarga tu propuesta en el botón azul o verde. Lo que puedes esperar es un pdf con un listado acerca de los puntos clave a desarrollar y los recursos necesarios.',
      top: `40%`,
      left: `50%`,
    },
    {
      content:
        'Crea un nuevo proyecto o visualiza tu historial en el menú de navegación',
      top: `55%`,
      left: `250px`,
    },
  ];

  const [stage, setStage] = useState({
    isActive: false,
    index: 0,
    content: Tooltips[0].content,
    top: Tooltips[0].top,
    left: Tooltips[0].left,
  });

  const handleNewProyect = (event) => {
    event.preventDefault();
    sessionStorage.removeItem('firstTimeLogged');
    window.history.go('/swarm/chat');
  };

  return (
    <>
      <ChatRoot>
        <HeaderMobile />
        <BodyContent>
          {(() => {
            switch (stage.index) {
              case 0:
                return (
                  <>
                    <QuestionConteiner>
                      <GoodDesignIsParent>
                        <GoodDesignIsContainer1>
                          <HolaSoy>{`Prepárate para compartir toda la información que tengas sobre tu cliente. `}</HolaSoy>
                          <Swarm1>SWARM</Swarm1>
                          <HolaSoy>
                            {' '}
                            te guiará con una serie de preguntas para entender
                            mejor tus necesidades.
                          </HolaSoy>
                        </GoodDesignIsContainer1>
                      </GoodDesignIsParent>

                      {stage.index === 0 && (
                        <span
                          style={{
                            position: 'relative',
                            zIndex: 1000,
                            maxWidth: '100%',
                            boxSizing: 'border-box',
                          }}
                        >
                          <TooltipContent
                            setStage={setStage}
                            stage={stage}
                            Tooltips={Tooltips}
                          />
                        </span>
                      )}

                      {initialAnswer && initialAnswer.length && (
                        <QuestionaryContainer>
                          {initialAnswer.map((message, index) => (
                            <ListContainer key={index}>
                              <ListParent>
                                <ListElement>{message}</ListElement>
                              </ListParent>
                            </ListContainer>
                          ))}
                        </QuestionaryContainer>
                      )}
                    </QuestionConteiner>
                    <Form />
                  </>
                );
              case 1:
                return (
                  <>
                    <Loader />
                    {stage.index === 1 && (
                      <span
                        style={{
                          position: 'relative',
                          zIndex: 1000,
                          maxWidth: '100%',
                          boxSizing: 'border-box',
                          padding: '16px;',
                        }}
                      >
                        <TooltipContent
                          setStage={setStage}
                          stage={stage}
                          Tooltips={Tooltips}
                        />
                      </span>
                    )}
                  </>
                );
              case 2:
                return (
                  <>
                    {stage.index === 2 && (
                      <>
                        <FinishView />
                        <span
                          style={{
                            position: 'relative',
                            zIndex: 1000,
                            maxWidth: '100%',
                            boxSizing: 'border-box',
                          }}
                        >
                          <TooltipContent
                            setStage={setStage}
                            stage={stage}
                            Tooltips={Tooltips}
                          />
                        </span>
                      </>
                    )}
                  </>
                );
              case 3:
                return (
                  <ChatRoot>
                    <MockSidebar />
                    <ResponseConteiner>
                      <TitleWrapper>
                        <Bigtittle>¡Bienvenida Sandra!</Bigtittle>
                        <Smalltittle>¿Empezamos con tu propuesta?</Smalltittle>

                        <ButtonContainer
                          type="button"
                          onClick={handleNewProyect}
                        >
                          Nuevo Chat <Icon alt="" src="/v1/send-icon.svg" />
                        </ButtonContainer>
                      </TitleWrapper>
                    </ResponseConteiner>
                    <span
                      style={{
                        position: 'relative',
                        zIndex: 1000,
                        maxWidth: '100%',
                        boxSizing: 'border-box',
                      }}
                    >
                      <TooltipContent
                        setStage={setStage}
                        stage={stage}
                        Tooltips={Tooltips}
                      />
                    </span>
                  </ChatRoot>
                );
              default:
                return <FinishView />;
            }
          })()}
        </BodyContent>
      </ChatRoot>
    </>
  );
};

export default MockPage;
