import styled from "styled-components"
import {device} from "./device"

export const Header = styled.header`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: flex-end;
	padding: 100px 0 50px;
	h1 {
	margin: 10px 0;
	}
	img {
		width: 60%;
	}
	& .head-line {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: flex-start;
		align-content: center;
		padding: 10px 40px;	
	}

	@media ${device.tablet} {
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
	align-content: flex-end;
	padding: 150px 0 5%;
	h1 {
		margin: 0;
	}
	img {
		width: 50%;
	}
	& .head-line {
		position: absolute;
		justify-content: center;
		padding: 0 50% 0 15%;
	}
	}
`

export const ServicesDescription = styled.section`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	align-content: center;
	padding: 50px 40px;
	h2 {
		font-size: 24px;
	}
	h3 {
		margin-bottom: 9px;
	}
	h4 {
		font-size: 18px;
		font-weight: 500;
		line-height: 24.44px;
		margin: 10px 0 40px;
	}
	p {
		margin: 15px 0 0;
	}
	ul {
		color: white;
		columns: 2;
		font-size: 14px;
		list-style-image: url("Vector 985.png");
		padding-left: 25px;
	}
	li {
		padding: 10px 0 0;
	}
	& .service-menu {
		display: none;
		width: 30%;
	}
	& .service-list {
		width: 100%;
		height: 150px;
		box-sizing: border-box;
	}
	& .service {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: center;
		align-content: center;
	}
	& .featured-projects {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: baseline;
		align-content: center;
		margin-top: 50px;
	}
	& .case-study-img {
		box-sizing: border-box;
		width: 100%;
	}
	@media ${device.tablet} {
		display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: flex-start;
	align-content: stretch;
	padding: 50px 15%;
	h3 {
		margin-top: 0;
	}
	ul {
		color: white;
		columns: 3;
		font-size: 14px;
		list-style-image: url("Vector 985.png");
	}	
	& .service-block{
		width: 70%;
	}
	& .service-menu {
		display: block;
		width: 30%;
	}
	& .service-menu ul {
		font-size: 18px;
		list-style: none;
		columns: 1;
		padding: 0;
	}
	& .service-menu li {
		padding: 0 0 10px;
		cursor: pointer;
	}
	& .case-study-block {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
	}
	& .featured-projects {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-around;
		align-items: flex-start;
		align-content: center;
		margin: 0;
	}
	& .title-featured-projects h2{
		margin: 0;
	}
	& .service {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: center;
	}
	& .service-list {
		width: 100%;
		height: auto;
		box-sizing: border-box;
	}
	& .case-study {
		width: 47%;
	}
	}
`