export const getQueryVariable = (variable) => {
  
    let sPaginaURL = window.location.search.substring(1);
  
    let sURLVariables = sPaginaURL.split("&");
    for (let i = 0; i < sURLVariables.length; i++) {
      let pair = sURLVariables[i].split("=");
      if (pair[0] === variable) {
        switch (variable) {
          case "utm_source":
            sessionStorage.setItem("source", pair[1]);
            break;
          case "utm_medium":
            sessionStorage.setItem("medium", pair[1]);
            break;
          case "utm_campaign":
            sessionStorage.setItem("campaign", pair[1]);
            break;
          case "utm_content":
            sessionStorage.setItem("content", pair[1]);
            break;
          default:
            console.log(variable);
            break;
        }
        return pair[1];
      }
    }
    return "";
  };
  