import styled from "styled-components"
import {device} from "./device"

export const Header = styled.header`

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 30px 0;
  .container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0px;
  }
  .img-cont {
    img {
      width: 256px;
    }
  }

@media ${device.tablet} {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 150px 0;
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 160px 0px 0px;
  }
  .img-cont {
    width: 50%;
    padding-right: 50px;
    img {
      width: 100%;
    }
  }
  .text-cont {
    width: 50%;
    padding-left: 50px;
  }
}
`

export const Tec = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 80px;
  img {
    padding-top: 28px;
    width: 100%;
  }
  h3 {
    text-align: center;
  }

@media ${device.tablet} {
  padding: 70px 110px 150px;
  img {
    padding-top: 0px;
  }
  .tec-text-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 80px;
    justify-content: center;
  }
}
`

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 0px 30px 0px;
  .recruitment {
    display: flex;
    flex-direction: column-reverse;
    align-items: baseline;
    padding: 0px 0px 70px;
  }
  
  .revenue {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0px 0px 70px;
  }

  .left-cont {
    width: 100%;
    padding: 0px;
  }
  .right-cont {
    width: 100%;
    padding: 0px;
  }

@media ${device.tablet} {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 110px 100px;
  .recruitment, .revenue{
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0px 0px 80px;
  }
  .left-cont {
    width: 50%;
    padding-right: 50px;
  }
  .right-cont {
    width: 50%;
    padding-left: 50px;
  }
}
`

export const OtherProyects = styled.div`

.prev-proyect {
  padding: 0px 30px 50px;
  h3, h4 {
    display: none;
  }
  img {
    max-width: 100%;
  }
  .hide {
    display: none;
  }
}
.next-proyect {
  display: none;
}

@media ${device.tablet} {
  display: flex;
  width: 100%;
  justify-content: center;
  .prev-proyect {
    display: flex;
    flex-direction: column;
    width: 45%;
    padding: 0px 20px 20px 110px;
    .hide {
      display: inherit;
    }
    img {
      width: 100%;
    }
    a, h3, h4 {
      display: inline;
    }
    p {
      display: none;
    }
  }

  .next-proyect {
    display: flex;
    flex-direction: column;
    width: 45%;
    padding: 0px 110px 20px 20px;
  }
}
`