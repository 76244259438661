import { useEffect, useState, useCallback } from 'react';
import Logo from './Logo';
import NewProyectButton from './NewProyectButton';
import styled from 'styled-components';
import {
  deleteHistoryChat,
  fetchHistory,
  fetchThreads,
  renameHistoryChat,
} from '../services/swarm.service';
import DeleteIcon from '@material-ui/icons/Delete';
import { Edit } from '@material-ui/icons';
import NavbarIcon from './NavbarIcon';

const Reciente = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 28.49px;
`;

const ProposalBuilder = styled.div`
  display: flex;
  padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
  align-items: center;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  color: var(--NEUTRAL-WHITE, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;

const PropuestaCliente = styled.div`
  flex: 1;
  color: #fff;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-size: 16px;
  line-height: 28.49px;
  font-weight: 600;
  cursor: pointer;
`;
const PropuestaClienteWrapper = styled.div`
  display: flex;
  align-self: stretch;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 14px;
  min-height: 40px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &:hover {
    background-color: #1d4ed8;
  }
`;
const InputChangeName = styled.input`
  display: none;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  line-height: 28.49px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
`;
const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 36px;
  box-sizing: border-box;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80%;

  .delete-icon,
  .edit-icon {
    min-width: 18px;
  }

  .delete-icon {
    cursor: pointer;
    color: #d1d5db;
    transition: all 0.5s ease;

    &:hover {
      color: #c1494a;
    }
  }

  .edit-icon {
    cursor: pointer;
    color: #d1d5db;
    transition: all 0.5s ease;

    &:hover {
      color: #00aa58;
    }
  }
`;

const RecienteParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 24px;
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
  background: #0e1d39;

  @media screen and (max-width: 642px) {
    display: none;
    padding-top: 0px;
    padding-bottom: 0px;
    box-sizing: border-box;
  }
`;

const SidebarNav = styled.nav`
  padding-top: var(--spacing-4xl, 32px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 80px;
  align-self: stretch;
  border-right: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
`;

const NavElement = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: var(--spacing-md, 8px);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--Colors-Text-text-primary, #1f2937);
  font-size: 16px;
  font-weight: 600;

  &:hover {
    background-color: var(--Colors-Background-background-secondary, #f9fafb);
  }
`;

const SidebarRoot = styled.div`
  align-self: stretch;
  background-color: #0e1d39;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 24px;
  box-sizing: border-box;
  gap: 24px 0px;
  min-width: 260px;
  max-width: 300px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  overflow-y: auto;
  @media screen and (max-width: 642px) {
    display: none;
    padding-top: 0px;
    padding-bottom: 0px;
    box-sizing: border-box;
  }
`;

const MockSidebar = ({
  setMessages,
  setThreadId,
  setIsFinished,
  setThreads,
  setIsLoading,
  ...props
}) => {
  const threads = [
    {
      chat_name: 'Chat 1',
      threadId: '1',
    },
    {
      chat_name: 'Chat 2',
      threadId: '2',
    },
  ];

  return (
    <SidebarWrapper>
      <SidebarNav>
        <NavbarIcon src="/v1/swarm/chat-icon.svg" to="/v1/swarm/chat" name="chat" />
        <NavbarIcon
          src="/v1/swarm/settings-icon.svg"
          to="/v1/swarm/settings"
          name="settings"
        />
        <NavbarIcon
          src="/v1/swarm/logout-icon.svg"
          to="/v1/swarm/logout"
          name="logout"
        />
      </SidebarNav>
      <SidebarRoot>
        <ProposalBuilder>PROPOSAL BUILDER</ProposalBuilder>
        <NewProyectButton />

        {threads.length > 0 && (
          <RecienteParent>
            <Reciente>Reciente</Reciente>
            {threads.map((thread, index) => (
              <PropuestaClienteWrapper key={index}>
                <PropuestaCliente
                  disabled
                  name={thread.chat_name}
                  id={`div-${thread.threadId}`}
                  value={thread.chat_name}
                >
                  {thread.chat_name}
                </PropuestaCliente>
                <InputChangeName
                  type="text"
                  name="chat_name"
                  id={`input-${thread.threadId}`}
                />
                <IconsWrapper>
                  <DeleteIcon className="delete-icon" />
                  <Edit className="edit-icon" />
                </IconsWrapper>
              </PropuestaClienteWrapper>
            ))}
          </RecienteParent>
        )}
      </SidebarRoot>
    </SidebarWrapper>
  );
};

export default MockSidebar;
