import styled from "styled-components";

const GoodDesignIs = styled.div`
  position: absolute;
  height: 64.24%;
  width: 73.65%;
  top: 17.88%;
  left: 26.35%;
  letter-spacing: -0.02em;
  line-height: 30.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
const SimpleColorIcon = styled.img`
  position: absolute;
  height: 100%;
  width: 20.09%;
  top: 0%;
  right: 79.91%;
  bottom: 0%;
  left: 0%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const LogoRoot = styled.div`
  width: 150.3px;
  position: relative;
  height: 30.2px;
  text-align: left;
  font-size: 25.6px;
  color: #fff;
  font-family: "DM Sans";
`;

const Logo = () => {
  return (
    <LogoRoot>
      <GoodDesignIs>Swarm</GoodDesignIs>
      <SimpleColorIcon alt="" src="/v1/simple-color.svg" />
    </LogoRoot>
  );
};

export default Logo;
