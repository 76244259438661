import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { MenuBook } from '@material-ui/icons';

const GoodDesignIs = styled.div`
  position: absolute;
  height: 64.27%;
  width: 73.65%;
  top: 17.99%;
  left: 26.35%;
  letter-spacing: -0.02em;
  line-height: 30.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
const SimpleColorIcon = styled.img`
  position: absolute;
  height: 100%;
  width: 20.09%;
  top: 0%;
  right: 79.91%;
  bottom: 0%;
  left: 0%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const GoodDesignIsParent = styled.div`
  width: 209.1px;
  position: relative;
  height: 38.9px;
  font-size: 25.6px;
`;
const GoodDesignIs1 = styled.div`
  position: relative;
  line-height: 120%;
`;
const Button = styled.div`
  border-radius: 8px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  font-size: 18px;
`;
const GoodDesignIs2 = styled.div`
  position: relative;
  letter-spacing: -0.01em;
  line-height: 120%;
  // @media screen and (max-width: 1023px) {
  //   display: none;
  // }
`;
const GoodDesignIs3 = styled.b`
  position: relative;
  line-height: 112%;
`;
const Button1 = styled.div`
  border-radius: 8px;
  background-color: #00aa58;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
`;
const IconUserChild = styled.img`
  position: absolute;
  height: 87.5%;
  width: 87.5%;
  top: 6.25%;
  right: 6.25%;
  bottom: 6.25%;
  left: 6.25%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const IconUser = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  width: 24px;
  position: relative;
  height: 24px;
`;
const ButtonParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
`;
const Menu = styled.div`
  width: 434px;
  position: relative;
  white-space: pre-wrap;
  text-align: right;
  display: none;
`;
const HeaderRoot = styled.div`
  position: relative;
  top: 0px;
  left: 0px;
  background-color: #0e1d39;
  width: 100%;
  height: 87px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: 'DM Sans';
  z-index: 11;
`;

const Flex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;
  gap: 8px;
`;

const HeaderDashboard = ({ mobile, openMenu, setOpenMenu }) => {
  let userName = localStorage.getItem('userEmail');

  if (userName && userName.startsWith('"') && userName.endsWith('"')) {
    try {
      userName = JSON.parse(userName);
    } catch (e) {
      console.error('Error al parsear el userName:', e);
    }
  }

  return (
    <>
      <HeaderRoot>
        <GoodDesignIsParent>
          <GoodDesignIs>rocket code</GoodDesignIs>
          <SimpleColorIcon alt='' src='/v1/simple-color.svg' />
        </GoodDesignIsParent>
        <ButtonParent>
          <IconUser
            onClick={() => {
              mobile ? setOpenMenu(!openMenu) : console.log('');
            }}
          >
            <IconUserChild
              alt=''
              src={
                mobile
                  ? `${openMenu ? './cross.png' : '/v1/menu-hamburguesa.svg'}`
                  : '/v1/group-1000002509.svg'
              }
            />
          </IconUser>
          {!mobile && <GoodDesignIs2>{userName}</GoodDesignIs2>}
        </ButtonParent>
      </HeaderRoot>
      {mobile && (
        <>
          <Flex>
            <IconUser>
              <IconUserChild alt='' src='/v1/group-1000002509.svg' />
            </IconUser>
            <GoodDesignIs2>{userName}</GoodDesignIs2>
          </Flex>
        </>
      )}
    </>
  );
};

export default HeaderDashboard;
