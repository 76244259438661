import { useRef, useEffect } from "react";
import {
  HomeBody,
  Header,
  Bullets,
  HowWeWork,
  OurServices,
  FeaturedProjects,
  CaseStudiesLink,
  Blog,
  BlogLink,
} from "../style/home-styles";
import CarouselCaseStudies from "../components/CarouselCaseStudies";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { BtnGeneral, Btn } from "../style/btn-styles";
import TestimoniesHome from "../components/Testimonies";
import SvgBullet01 from "../components/animations/SvgBullet01";
import SvgBullet02 from "../components/animations/SvgBullet02";
import SvgHowWeWork01 from "../components/animations/SvgHowWeWork01";
import SvgHowWeWork02 from "../components/animations/SvgHowWeWork02";
import SvgHowWeWork03 from "../components/animations/SvgHowWeWork03";
import SvgHowWeWork04 from "../components/animations/SvgHowWeWork04";
import { isMobile } from "react-device-detect";
import { getQueryVariable } from "../helpers/ReadUrl";
import TagManager from "react-gtm-module";
import HowWeWorkHome from "../components/HowWeWorkHome";
import { useTranslation } from "react-i18next";
import { CustomersCarousel } from "./NewHone/components/CustomersCarousel";
import AlliedCarousel from "./NewHone/components/AlliedCarousel";

function analytics() {
  const tagManagerArgs = {
    gtmId: "GTM-TJGF6MN",
    dataLayer: {
      event: window.location.pathname,
      pageName: "The Rocket Code - Home",
    },
  };
  TagManager.initialize(tagManagerArgs);
}

gsap.registerPlugin(ScrollTrigger);
gsap.defaults({ ease: "none" });

export default function Home() {
  const { t } = useTranslation(["home"]);

  useEffect(() => {
    analytics();
  }, []);

  useEffect(() => {
    getQueryVariable("utm_source");
    getQueryVariable("utm_medium");
    getQueryVariable("utm_campaign");
  }, []);

  const titleHeaderRef = useRef(null);
  const textHeaderRef = useRef(null);
  const btnHeaderRef = useRef(null);
  const btnIaRef = useRef(null);
  const servicesRowRef = useRef(null);
  const servicesSecRef = useRef(null);
  const megaTitleRef = useRef(null);
  const headerSecRef = useRef(null);

  useEffect(() => {
    //Animaciones Estáticas Header
    gsap.from(titleHeaderRef.current, {
      duration: 1,
      autoAlpha: 0,
      y: 10,
      delay: 0.5,
    });
    gsap.from(textHeaderRef.current, {
      duration: 1,
      autoAlpha: 0,
      y: 10,
      delay: 0.8,
    });
    gsap.from(btnHeaderRef.current, {
      duration: 1,
      autoAlpha: 0,
      y: 10,
      delay: 1,
    });
    //SVG Animaciones Header
    const t1 = gsap.timeline({
      defaults: { duration: 0.5, autoAlpha: 0, x: 80, ease: "back.out(1.5)" },
    });
    t1.from(".head-white", {}, "=.1")
      .from(".head-green", {}, "-=.3")
      .from(".head-red", {}, "-=.3")
      .from(".head-yellow", {}, "-=.3")
      .from(".head-blue", {}, "-=.3");
  }, []);

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        //Animaciones de Scroll
        const animatedBasics = gsap.utils.toArray(".animated-basic");
        animatedBasics.forEach((animatedBasic) => {
          gsap.from(animatedBasic, {
            y: 50,
            autoAlpha: 0,
            duration: 1,
            scrollTrigger: {
              trigger: animatedBasic,
              toggleActions: "restart none none reset",
            },
          });
        });
      }, 700);

      return () => {
        ScrollTrigger.getAll().forEach((t) => t.kill());
      };
    }
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setTimeout(() => {
        //Animaciones de Textos Scroll
        const animatedTexts = gsap.utils.toArray(".animated-text");
        animatedTexts.forEach((animatedText) => {
          gsap.from(animatedText, {
            y: 50,
            autoAlpha: 0,
            duration: 1,
            scrollTrigger: {
              trigger: animatedText,
              toggleActions: "restart none none reset",
            },
          });
        });

        //Animaciones Scroll
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: servicesSecRef.current,
            start: "top +600px",
            // end: () => "+=" + servicesRowRef.current.offsetWidth,
            end: "bottom +40%",
            scrub: 1,
            // pin: true,
            // markers: true,
            anticipatePin: 1,
          },
        });

        const t2 = gsap.timeline({
          scrollTrigger: {
            trigger: headerSecRef.current,
            start: "top -170px",
            end: "950",
            scrub: 0.8,
            // markers: true,
            anticipatePin: 1,
          },
        });

        tl.fromTo(
          servicesRowRef.current,
          { left: "0%", x: 0 },
          { left: "100%", x: 0 - servicesRowRef.current.clientWidth }
        );

        t2.fromTo(
          megaTitleRef.current,
          { left: "0%", x: 0 },
          { left: "100%", x: 0 - megaTitleRef.current.clientWidth }
        );
      }, 700);
    }

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, []);

  const renderServices = () => {
    if (isMobile) {
      return (
        <OurServices>
          <div className="services-head">
            <h2 className="animated-basic" style={{ textAlign: "left" }}>
              {t("services.title")}
            </h2>
            <p className="animated-basic">{t("services.subtitle")}</p>
            {/* <BtnGeneral to="/services">
              SEE SERVICES
              <img className="arrow-btn" src="Group 218.png" alt="Arrow"/>
            </BtnGeneral> */}
          </div>
          <div className="services-block">
            <div className="service-mobile animated-basic">
              <img src="/v1/Group 273.png" alt="App Development" />
              <h4>{t("services.app-development")}</h4>
            </div>
            <div className="service-mobile animated-basic">
              <img src="/v1/Group 275.png" alt="Web Development" />
              <h4>{t("services.web-development")}</h4>
            </div>
            <div className="service-mobile animated-basic">
              <img src="/v1/Group 272.png" alt="Api integrations" />
              <h4>{t("services.api-integrations")}</h4>
            </div>
            <div className="service-mobile animated-basic">
              <img src="/v1/Group 271.png" alt="Product Strategy" />
              <h4>{t("services.product-strategy")}</h4>
            </div>
            <div className="service-mobile animated-basic">
              <img src="/v1/growth-hacking.png" alt="Growth Hacking" />
              <h4>{t("services.growth-hacking")}</h4>
            </div>
            <div className="service-mobile animated-basic">
              <img src="/v1/Group 268.png" alt="Data Analytics" />
              <h4>{t("services.data-analytics")}</h4>
            </div>
          </div>
        </OurServices>
      );
    }
    return (
      <OurServices ref={servicesSecRef}>
        <div className="services-head">
          <h2 className="animated-text">{t("services.title")}</h2>
          <p className="animated-text">{t("services.subtitle")}</p>
          {/* <BtnGeneral to="/services">
            OUR SERVICES
            <img className="arrow-btn" src="Group 218.png" alt="Arrow"/>
          </BtnGeneral> */}
        </div>
        <div className="service-type" ref={servicesRowRef}>
          <img
            src={t("services.img-services")}
            alt="Services"
            style={{ padding: "0px 90px 0px 170px" }}
          />
        </div>
      </OurServices>
    );
  };

  const renderHeaderAndBullets = () => {
    if (isMobile) {
      return (
        <>
          <Header>
            <div className="head-line">
              <h1 className="title-home" ref={titleHeaderRef} style={{ visibility: "hidden" }}>
                {t("header.title")}
              </h1>
              <p ref={textHeaderRef} style={{ visibility: "hidden" }}>
                {t("header.subtitle")}
              </p>
              <BtnGeneral
                to="/v1/contact"
                ref={btnHeaderRef}
                style={{ visibility: "hidden" }}
              >
                {t("header.cta")}
                <img className="arrow-btn" src="/v1/Group 218.png" alt="Arrow" />
              </BtnGeneral>
            </div>
            <img
              className="geoform-header animated-basic"
              src="/v1/Group 227.png"
              alt="Header Geoforms"
            />
          </Header>

          <Bullets>
            <h1 className="mega-title animated-basic mob-mega">
              {t("bullets.mega-title")}
            </h1>
            <div className="ia">
              <h2 className="animated-text animated-basic">{t("ai.title")}</h2>
              <p className="animated-text animated-basic">
                {t("ai.description")}
              </p>
              <Btn
                to="/v1/ai"
                ref={btnIaRef}
                className="animated-text animated-basic"
              >
                {t("ai.cta")}
              </Btn>
              <img
                src="/v1/ia.svg"
                alt="ai head"
                className="animated-text animated-basic"
              />
            </div>
            <div className="catalogIA">
              <div>
                <h2 className="animated-text animated-basic">{t("catalogai.title")}<span>{t("catalogai.title-last-word")}</span></h2>
                <Btn to="/v1/catalogos" ref={btnIaRef} className="animated-text animated-basic">
                  {t("catalogai.cta")}
                </Btn>
              </div>
              <img src="/v1/catalogAI.gif" alt="ai catalog" className="animated-text animated-basic" />
            </div>
            <div className="bullet">
              <div className="text-bullet">
                <h3 className="animated-text animated-basic">
                  {t("bullets.b1-title")}
                </h3>
                <p className="animated-text animated-basic">
                  {t("bullets.b1-subtitle")}
                </p>
              </div>
              <img
                src="/v1/Group 286.svg"
                alt="Sec1 Geoforms"
                className="animated-basic"
              />
            </div>
            <div className="bullet">
              <img
                src="/v1/Group 285.svg"
                alt="Sec1 Geoforms"
                className="geo-arrow animated-basic"
              />
              <div className="text-bullet">
                <h3 className="animated-text animated-basic">
                  {t("bullets.b2-title")}
                </h3>
                <p className="animated-text animated-basic">
                  {t("bullets.b2-subtitle")}
                </p>
              </div>
            </div>
          </Bullets>
        </>
      );
    }
    return (
      <>
        <Header ref={headerSecRef}>
          <div className="head-line">
            <h1 ref={titleHeaderRef} style={{ visibility: "hidden" }}>
              {t("header.title")}
            </h1>
            <p ref={textHeaderRef} style={{ visibility: "hidden" }}>
              {t("header.subtitle")}
            </p>
            <BtnGeneral
              to="/v1/contact"
              ref={btnHeaderRef}
              style={{ visibility: "hidden" }}
            >
              {t("header.cta")}
              <img className="arrow-btn" src="Group 218.png" alt="Arrow" />
            </BtnGeneral>
          </div>
          <svg
            className="geoform-header"
            width="786"
            height="625"
            viewBox="0 0 786 625"
            fill="none"
          >
            <circle
              className="head-green"
              cx="274"
              cy="467"
              r="155"
              fill="#00AA58"
            />
            <path
              className="head-white"
              d="M868 623.5L868.867 623.5L869.3 622.748L1225.67 3.74842L1226.96 1.50001L1224.37 1.50001L362.37 1.49996L361.503 1.49996L361.07 2.25155L4.70041 621.252L3.40598 623.5L6.00034 623.5L868 623.5Z"
              stroke="white"
              strokeWidth="3"
            />
            <path
              className="head-blue"
              d="M631.83 3.00002L1028 3.00001L850.171 312L454 312L631.83 3.00002Z"
              fill="#2240D9"
            />
            <path
              className="head-yellow"
              d="M901.861 622L465 622L643.139 312L1080 312L901.861 622Z"
              fill="#FFA800"
            />
            <path
              className="head-red"
              d="M454 312L632 622L276 622L454 312Z"
              fill="#EF0000"
            />
          </svg>
        </Header>

        <Bullets>
          <h1 ref={megaTitleRef} className="mega-title">
            {t("bullets.mega-title")}
          </h1>
          <div className="ia">
            <h2 className="animated-text">{t("ai.title")}</h2>
            <p className="animated-text">{t("ai.description")}</p>
            <Btn to="/v1/ai" ref={btnIaRef} className="animated-text">
              {t("ai.cta")}
            </Btn>
            <img src="ia.svg" alt="ai head" className="animated-text" />
          </div>
          <div className="catalogIA">
            <div>
              <h2 className="animated-text">{t("catalogai.title")}<span>{t("catalogai.title-last-word")}</span></h2>
              <Btn to="/v1/catalogos" ref={btnIaRef} className="animated-text">
                {t("catalogai.cta")}
              </Btn>
            </div>
            <img src="catalogAI.gif" alt="ai catalog" className="animated-text" />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <img src="Line 1.svg" alt="Line" className="animated-text" />
          </div>
          <div className="bullet">
            <div className="text-bullet">
              <h3 className="animated-text">{t("bullets.b1-title")}</h3>
              <p className="animated-text">{t("bullets.b1-subtitle")}</p>
            </div>
            <SvgBullet01 />
          </div>
          <div className="bullet">
            <SvgBullet02 />
            <div className="text-bullet">
              <h3 className="animated-text">{t("bullets.b2-title")}</h3>
              <p className="animated-text">{t("bullets.b2-subtitle")}</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <img src="Line 1.svg" alt="Line" className="animated-text" />
          </div>
        </Bullets>
      </>
    );
  };

  return (
    <HomeBody>
      {renderHeaderAndBullets()}

      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "center",
        }}
      >
        <img src="Line 1.svg" alt="Line" className="animated-basic" />
      </div>

      <div id="services">{renderServices()}</div>

      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "center",
        }}
      >
        <img src="Line 1.svg" alt="Line" className="animated-basic" />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "center",
        }}
      >
        <img src="Line 1.svg" alt="Line" className="animated-text" />
      </div>

      <FeaturedProjects>
        <div className="title-featured-projects">
          <h2 className="animated-basic animated-text">
            {t("featured-projects.title")}
          </h2>
          <CaseStudiesLink
            to="/v1/case-studies"
            className="animated-basic animated-text"
          >
            <p>{t("featured-projects.link")}</p>
            <span>
              <img className="arrow" src="Group 122.svg" alt="Arrow" />
            </span>
          </CaseStudiesLink>
        </div>

        <div style={{ minHeight: "555px", overflowX: "clip" }}>
          <CarouselCaseStudies />
        </div>
      </FeaturedProjects>

      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "center",
        }}
      >
        <img src="Line 1.svg" alt="Line" className="animated-basic" />
      </div>

      <HowWeWork name="HowWeWork">
        {!isMobile && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <img
                src="Line 1.svg"
                alt="Line"
                className="animated-text"
                style={{ width: "unset" }}
              />
            </div>
            <div className="points">
              <div>
                <p className="how-we-work animated-text animated-basic">
                  {t("how-we-work.title2")}
                </p>
                {/* <p className="animated-text animated-basic">01</p> */}
                <img
                  className="img-mobile animated-basic"
                  src="Group 251.png"
                  alt="How we work 01"
                />
                <h2 className="animated-text animated-basic">
                  {t("how-we-work.startup-title")}
                </h2>
                <p className="animated-text animated-basic">
                  {t("how-we-work.startup-description")}
                </p>
              </div>
              <SvgHowWeWork01 />
            </div>
            <div className="points">
              <div>
                <p className="how-we-work animated-text animated-basic">
                  {t("how-we-work.title")}
                </p>
                {/* <p className="animated-text animated-basic">02</p> */}
                <img
                  className="img-mobile animated-basic"
                  src="Group 249.png"
                  alt="How we work 02"
                />
                <h2 className="animated-text animated-basic">
                  {t("how-we-work.scale-title")}
                </h2>
                <p className="animated-text animated-basic">
                  {t("how-we-work.scale-description")}
                </p>
              </div>
              <SvgHowWeWork02 />
            </div>
            <div className="points">
              <div>
                <p className="how-we-work animated-text animated-basic">
                  {t("how-we-work.title")}
                </p>
                {/* <p className="animated-text animated-basic">03</p> */}
                <img
                  className="img-mobile animated-basic"
                  src="Group 252.png"
                  alt="How we work 03"
                />
                <h2 className="animated-text animated-basic">
                  {t("how-we-work.fintech-title")}
                </h2>
                <p className="animated-text animated-basic">
                  {t("how-we-work.fintech-description")}
                </p>
              </div>
              <SvgHowWeWork03 />
            </div>
            <div className="points">
              <div>
                <p className="how-we-work animated-text animated-basic">
                  {t("how-we-work.title")}
                </p>
                {/* <p className="animated-text animated-basic">04</p> */}
                <img
                  className="img-mobile animated-basic"
                  src="Group 235.png"
                  alt="How we work 04"
                />
                <h2 className="animated-text animated-basic">
                  {t("how-we-work.insurtech-title")}
                </h2>
                <p className="animated-text animated-basic">
                  {t("how-we-work.insurtech-description")}
                </p>
              </div>
              <SvgHowWeWork04 />
            </div>
          </div>
        )}
        {isMobile && <HowWeWorkHome />}
      </HowWeWork>
      {/* <FeaturedProjects>
        <div>
          <h2 className="animated-basic animated-text">
            {t("trust-section.title")}
          </h2>
        </div>
      </FeaturedProjects> */}
      <div style={{marginBottom: '30px'}}>
        <AlliedCarousel />
      </div>
      {/* <TestimoniesHome /> */}

      {/* <Blog>
        <div>
          <h2 className="animated-text animated-basic">BLOG</h2>
          <p className="animated-text animated-basic">{t("blog.subtitle")}</p>
          {isMobile && (
            <img src="/blog-img.svg" alt="Blog" className="animated-basic" />
          )}
          <BlogLink
            href="https://blog.therocketcode.com/"
            target="_blank"
            rel="noreferrer"
            className="animated-text animated-basic"
          >
            {t("blog.cta")}
          </BlogLink>
        </div>
        {!isMobile && (
          <img className="animated-text" src="/blog-img.svg" alt="Blog" />
        )}
      </Blog> */}

      {/* <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "center",
        }}
      >
        <img src="Line 1.svg" alt="Line" className="animated-basic" />
      </div> */}
    </HomeBody>
  );
}
