import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(210deg);
  }

  100% {
    transform: rotate(720deg);
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RotatingImage = styled.img`
  animation: ${rotate} 2s linear infinite;
`;

const Loader = () => {
  return (
    <LoaderContainer>
      <RotatingImage src="/v1/swarm/Swarm-Loader.png" alt="Loading..." />
    </LoaderContainer>
  );
};

export default Loader;
