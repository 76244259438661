import ForgotPassword from '../DashboardLoginComponents/ForgotPassword';
// import Header from '../DashboardLoginComponents/Header';
import LoginForm from '../DashboardLoginComponents/LoginForm';
import styled from 'styled-components';
import NewPassword from '../DashboardLoginComponents/NewPassword';
import LoginSwarm from '../DashboardLoginComponents/LoginSwarm';

const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 80px 24px;
`;
const LoginRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: #0e1d39;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const DashboardLogin = ({ isOcr = false }) => {
  const pathLogin = isOcr ? '/v1/login-ocr' : '/v1/login';

  return (
    <LoginRoot>
      <Container>
        {window.location.pathname === pathLogin && <LoginForm isOcr={isOcr} />}
        {window.location.pathname === '/v1/forgot-password' && <ForgotPassword />}
        {window.location.pathname === '/v1/password-recovery' && <NewPassword />}
        {window.location.pathname === '/v1/swarm' && <LoginSwarm />}
      </Container>
    </LoginRoot>
  );
};

export default DashboardLogin;
