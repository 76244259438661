import styled from "styled-components";
import { device } from "../style/device";
import { BtnGeneral } from "../style/btn-styles";
import { Vacancies } from "../vacancies";

const Body = styled.div`
  padding: 120px 30px 100px;
  display: flex;
  flex-direction: column;
  p {
    font-size: 16px;
  }
  .left-decoration {
    position: relative;
  }
  .gold {
    color: #ffa800;
    padding-top: 20px;
  }
  li {
    color: white;
    line-height: 29px;
    list-style: disc;
  }
  ul {
    padding-left: 15px;
  }
  .green-title {
    color: #00aa58;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .description {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 50px 0px 0px;
  }

  @media ${device.tablet} {
    padding: 130px 149px 0px 80px;
    flex-direction: row;
    align-content: baseline;
    .left-decoration {
      display: flex;
      flex-direction: column;
      justify-content: baseline;
      align-items: center;
      padding-right: 185px;
    }
    h2 {
      padding-top: 150px;
    }
    h3 {
      margin: 10px 0px;
    }
    h4 {
      font-size: 25px;
      margin: 0px;
    }
    .buttons {
      padding: 80px 0px 80px;
    }
    .gold {
      padding-top: 40px;
    }
    .description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      padding-bottom: 30px;
    }
  }
`;

const VacanciesLs = styled.div`
  padding-bottom: 30px;
  .location {
    display: flex;
    justify-content: baseline;
  }
  img {
    padding-right: 5px;
  }
  p {
    font-size: 14px;
    font-weight: 700;
    color: #9a9a9a;
    margin: 5px 0;
    line-height: 29px;
  }
`;

export default function Vacancie({ match }) {
  const vacancie = Vacancies.find((el) => el.url === match.params.name);

  return (
    <>
      <Body>
        <div className="left-decoration">
          <h2 className="show-desktop">
            We're
            <br />
            hiring
          </h2>
          <h5 className="show-desktop">Join us!</h5>
          <img src="/v1/puzzle.svg" alt="Rompecabezas" className="show-desktop" />
          <img
            src="/v1/puzzle.svg"
            alt="Rompecabezas"
            className="show-mobile"
            style={{
              position: "absolute",
              right: "0px",
              top: "0px",
              width: "90px",
            }}
          />
        </div>
        <div className="description">
          <h3 className="show-desktop">Vacancies</h3>

          <div className="description">
            <p className="green-title">{vacancie.type}</p>
            <VacanciesLs>
              <h4>{vacancie.name}</h4>
              <div className="location">
                <img src="/v1/location-gray.svg" alt="pin" />
                <p>{vacancie.location}</p>
              </div>
            </VacanciesLs>
            <h3 className="show-desktop">{vacancie.name}</h3>
            <h4 className="gold show-mobile">About us</h4>
            <p className="show-mobile">
              At Rocket Code, we design and develop awesome products with
              extraordinary code. We are a team of highly skilled product
              thinkers and programming engineers that plan and execute forming
              habits products with flawless code.
              <br />
              <br />
              We help our clients to build amazing products, hassle-free!
              <br />
              <br />
              We have been around almost 6 years and through our clients, we
              have helped millions of users to have outstanding digital
              experiences. From Fintechs, insurtechs, corporates, and private
              projects we go deep into our client's goals to help them win their
              markets.
              <br />
              <br />
              We have several projects going on right now, and we are looking
              for a great product designer talent to help us optimize all our
              efforts. We need someone self-driven, intelligent, creative, and
              super committed. Are you the one?
            </p>
            <h4 className="gold">Responsibilities</h4>
            <ul>
              {vacancie.responsabilities.map((el, i) => (
                <li key={i}>{el}</li>
              ))}
            </ul>
            <h4 className="gold">Qualifications / Skills:</h4>
            <ul>
              {vacancie.qualifications.map((el, i) => (
                <li key={i}>{el}</li>
              ))}
            </ul>
            <h4 className="gold">Education, Experience:</h4>
            <ul>
              {vacancie.experience.map((el, i) => (
                <li key={i}>{el}</li>
              ))}
            </ul>
            <h4 className="gold">Work hours and benefits:</h4>
            <ul>
              <li>
                100% Home Office, except specific investors, clients and
                providers meetings.
              </li>
              <li>Flexible hours</li>
            </ul>
            <h4 className="gold show-desktop">About us</h4>
            <p className="show-desktop">
              At Rocket Code, we design and develop awesome products with
              extraordinary code. We are a team of highly skilled product
              thinkers and programming engineers that plan and execute forming
              habits products with flawless code.
              <br />
              <br />
              We help our clients to build amazing products, hassle-free!
              <br />
              <br />
              We have been around almost 6 years and through our clients, we
              have helped millions of users to have outstanding digital
              experiences. From Fintechs, insurtechs, corporates, and private
              projects we go deep into our client's goals to help them win their
              markets.
              <br />
              <br />
              We have several projects going on right now, and we are looking
              for a great {vacancie.name} talent to help us optimize all our
              efforts. We need someone self-driven, intelligent, creative, and
              super committed. Are you the one?
            </p>
          </div>
          <div className="buttons">
            <BtnGeneral
              to="/v1/hiring"
              style={{ backgroundColor: "rgba(255,255,255,0)", color: "white" }}
              className="show-desktop"
            >
              BACK
            </BtnGeneral>
            <BtnGeneral to={`/v1/hiring/apply/${vacancie.url}`}>
              APPLY NOW
            </BtnGeneral>
          </div>
        </div>
      </Body>
    </>
  );
}
