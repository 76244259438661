import styled from 'styled-components';
import SVGComponent from '../SVGComponent';
import { useState } from 'react';

const NavContainer = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 261.741px;
  height: 85vh;
  padding: 15.262px 12.21px var(--spacing-none, 0px) 12.21px;

  border-radius: 9.157px;
  border: 0.763px solid rgba(0, 0, 0, 0.05);
  background: var(--Colors-Background-bg-primary, #fff);
  box-shadow: 0px 3.602px 3.602px 0px rgba(0, 0, 0, 0.25);
`;

const IconWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  width: 36.629px;
  height: 36.629px;
  padding: var(--spacing-none, 9.157px);
  justify-content: center;
  align-items: center;

  border-radius: 7.631px;
  border: 0.763px solid
    var(
      --Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border,
      #eaecf0
    );
  box-shadow: 0px 0.763px 1.526px 0px rgba(16, 24, 40, 0.05);

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -50%;
    left: -50%;

    width: 73.257px;
    height: 73.257px;
    border-radius: 50%;
    border: 1px solid
      var(
        --Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border,
        #eaecf0
      );
    flex-shrink: 0;
    stroke-width: 0.763px;
    stroke: var(--Colors-Border-border-secondary, #eaecf0);

    opacity: 0.95;
  }

  &::after {
    width: 109.886px;
    height: 109.886px;
    flex-shrink: 0;
    stroke-width: 0.763px;

    content: '';
    display: block;
    position: absolute;
    top: -100%;
    left: -100%;

    border-radius: 50%;
    border: 1px solid
      var(
        --Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border,
        #eaecf0
      );

    opacity: 0.75;
  }
`;

const NavbarTittleWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9.157px;
  width: 100%;
  padding: 9.157px 0px 9.157px 0px;
  align-self: stretch;
`;

const NavbarPrimaryTittle = styled.h2`
  color: var(--colors-text-text-primary-900, #101828);
  font-family: Inter, sans-serif;
  font-size: 13.736px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.367px; /* 155.556% */
`;

const NavbarSubTittle = styled.p`
  color: var(--colors-text-text-tertiary-600, #475467);
  font-family: Inter, sans-serif;
  font-size: 10.683px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.262px; /* 142.857% */
`;

const NavElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9.157px;
  width: 100%;
  padding: 9.157px 0px 9.157px 0px;
  align-self: stretch;

  overflow-y: auto;
`;

const NavElementContainer = styled.a`
  display: flex;
  padding: var(--spacing-xl, 16px);
  align-items: center;
  gap: var(--spacing-xs, 4px);

  width: 100%;

  border-radius: 9.184px;
  border: 0.763px solid ${(props) => props.borderColor || '#e0f0ff'};
  background: var(--Colors-Background-bg-primary, #fff);

  cursor: pointer;
`;

const NavElementImgWrapper = styled.div`
  display: flex;
  width: 24.419px;
  height: 24.419px;
  padding: 6.105px 6.105px 6.105px 6.104px;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-full, 7630.184px);
  background: var(--SECONDARY-ALICE-BLUE, #e0f0ff);
`;

const NavElementText = styled.p`
  flex: 1;
  display: flex;
  align-items: center;
  color: var(--colors-text-text-secondary-700, #344054);
  font-family: Inter, sans-serif;
  font-size: 10.683px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.262px; /* 142.857% */
`;

const ProposalNavbar = ({ navElements }) => {
  const [selectedNavElement, setSelectedNavElement] = useState('');
  return (
    <NavContainer>
      <IconWrapper>
        <SVGComponent
          src={'/v1/swarm/left-indent-01.svg'}
          strokeColor={'#000000'}
        />
      </IconWrapper>
      <NavbarTittleWrapper>
        <NavbarPrimaryTittle>Indice de la propuesta</NavbarPrimaryTittle>
        <NavbarSubTittle>Navega por los temas de tu interes</NavbarSubTittle>
      </NavbarTittleWrapper>

      <NavElementsWrapper>
        {navElements.length &&
          navElements.map((element, index) => (
            <NavElementContainer
              borderColor={
                selectedNavElement === element ? '#2240D9' : undefined
              }
              onClick={() => setSelectedNavElement(element)}
              key={index}
              href={`#${element}`}
            >
              <NavElementImgWrapper>
                <SVGComponent
                  src={'/v1/swarm/layers.svg'}
                  strokeColor={'#2240D9'}
                />
              </NavElementImgWrapper>
              <NavElementText>{element.replace(/-/g, ' ')}</NavElementText>
            </NavElementContainer>
          ))}
      </NavElementsWrapper>
    </NavContainer>
  );
};

export default ProposalNavbar;
