import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Router from "./Router";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/client/react";
import "./i18n";
import GlobalStyles from "./global";
import { RecoilRoot } from "recoil";
import { SnackbarProvider } from "notistack";

const client = new ApolloClient({
  //uri: 'http://localhost:5001/api',
  uri: "https://therocketcode.com/api",
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <RecoilRoot>
        <SnackbarProvider>
          <Suspense fallback={"Loading..."}>
            <GlobalStyles />
            <Router />
          </Suspense>
        </SnackbarProvider>
      </RecoilRoot>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
