import styled from "styled-components"
import {device} from "./device"

export const Header = styled.header`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: space-around;
align-items: center;
align-content: center;
padding: 150px 40px 50px;
img {
  width: 100%;
  box-sizing: border-box;
}
& .arrow {
  width: 40%;
  margin: 70px;
}
@media ${device.tablet} {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  img {
    width: 510px;
  }
  p {
    font-size: 72px;
  }
  & .sub-header {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 40%;
  }
  & .arrow {
    width: 124px;
  }
}
`

export const OurOrigins = styled.section`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: space-around;
align-items: center;
align-content: center;
text-align: center;
padding: 50px 40px 50px;
h2 {
  font-size: 40px;
  margin: 10px 0;
}
img {
  width: 70%;
}
p {
  margin-bottom: 130px;
}
@media ${device.tablet} {
  margin: 0 20%;
  img {
    width: 270px;
  }
  h5 {
    font-size: 24px;
    font-weight: 500;
    margin: 44px 0 0;
  }
  h2 {
    font-size: 64px;
    margin: 0 0 24px;
  }
  & .show-mobile {
    display: none;
  }
}
`

export const Team = styled.section`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: space-around;
align-items: center;
align-content: center;
text-align: center;
padding: 0px 40px 50px;
h2 {
  font-size: 40px;
  margin: 20px 0;
}
img {
  width: 100%;
  box-sizing: border-box;
}
ul {  
  display: flex;
	flex-direction: column;
  list-style: none;
  color: white;
  columns: 2;
  font-size: 14px;
  font-weight: 700;
  padding: 0;
}
& .team-composition {
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	align-content: center;
}
& .show-desk {
  display: none;
}
@media ${device.tablet} {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0;
  margin: 0 10% 210px;
  h2 {
    font-size: 64px;
    line-height: 72px;
    font-weight: 400;
    margin-top: 0;
  }
  p {
    font-size: 24px;
    line-height: 32px;
  }
  img {
    width: 482px;
    margin-bottom: 20px;
  }
  & .show-desk {
    display: block;
  }
  & .show-mobile {
    display: none;
  }
  & .team-composition-header {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    text-align: left;
    width: 400px;
  }
  & .team-composition {
    display: flex;
    flex-direction: column;
  }
  & .development-product {
    display: flex;
    padding-left: 30px;
    width: 482px;
    gap: 30px;
  }
  & .team-list {
		display: block;
    text-align: left;
		width: 50%;
	}
	& .team-list ul {
		font-size: 18px;
		list-style: none;
		columns: 1;
		padding: 0;
	}
	& .team-list li {
		padding: 0 0 20px;
	}
}
`

export const Values = styled.section`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: space-around;
align-items: center;
align-content: center;
text-align: center;
padding: 0px 40px 50px;
h2 {
  font-size: 40px;
  margin: 10px 0;
}
& .show-desk {
  display: none;
}
@media ${device.tablet} {
  padding: 0;
  margin: 0 15% 210px;
  p {
    width: 810px;
    margin: 24px 0 90px;
  }
  h2 {
    font-size: 64px;
  }
  & .show-desk {
    display: block;
  }
  & .show-mobile {
    display: none;
  }
  & .values {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
}
`

export const Blog = styled.section`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: space-around;
align-items: center;
align-content: center;
padding: 0px 40px;
h2 {
  font-size: 40px;
  margin: 10px 0;
}
h4 {
  margin: 15px 0;
}
h5 {
  margin: 15px 0;
}
p {
  margin: 18px 0 100px;
}
img {
  width: 100%;
  box-sizing: border-box;
}
@media ${device.tablet} {
  flex-direction: row;
  h2 {
    font-size: 72px;
    line-height: 80px;
    max-width: 280px;
  }
  img {
    width: 400px;
  }
  & .article {
    width: 400px;
  }
}
`

export const Contact = styled.section`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: space-around;
align-items: center;
align-content: center;
text-align: center;
padding: 0px 40px 50px;
h2 {
  font-size: 40px;
  margin: 10px 0;
}
img {
  width: 30%;
  margin-bottom: 30px;
}
@media ${device.tablet} {
  padding: 0;
  margin: 130px 0 200px;
  h2 {
    font-size: 64px;
    line-height: 72px;
    font-weight: 500;
    width: 400px;
  }
  p {
    width: 400px;
  }
  img {
    width: 98px;
  }
}
`