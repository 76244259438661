import { NavLink as Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { device } from "./device";

export const Btn = styled(Link)`
  border: 0;
  box-sizing: border-box;
  color: ${({ secondary }) => (secondary ? "black" : "white")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 16px;
  width: 316px;
  height: 54px;
  background: ${({ secondary }) => (secondary ? "white" : "#2240d9")};
  border-radius: 8px;
  cursor: pointer;
  margin: 20px 0;
  @media (max-width: 768px) {
    padding: 14px 20px;
    width: 100%;
    max-width: 316px;
  }
`;

export const BtnGeneral = styled.a`
  padding: 14px 55px;
  border: 2px solid white;
  background-color: white;
  color: black;
  cursor: pointer;
  position: relative;
  transform: translate(12px);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  .arrow-btn {
    position: absolute;
    padding-left: 20px;
    width: 15px;
  }
  &:after {
    transition: all 0.2s ease-in-out;
    content: "";
    position: absolute;
    top: 1px;
    left: -12px;
    right: 1px;
    bottom: -12px;
    border: 4px solid white;
    z-index: -1;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    transform: translate(17px, -5px);
    background: #fff;
    color: #010606;
  }
  &:hover:after {
    transition: all 0.2s ease-in-out;
    content: "";
    position: absolute;
    top: 6px;
    left: -17px;
    right: 6px;
    bottom: -17px;
    border: 4px solid white;
    z-index: -1;
  }
  @media ${device.tablet} {
    padding: 14px 70px;
    .arrow-btn {
      position: absolute;
      padding-left: 40px;
      width: 15px;
    }
  }
`;
export const BtnStyled = styled.button`
  padding: 14px 70px;
  color: black;
  cursor: pointer;
  position: relative;
  transform: translate(12px);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  ${(props) =>
    props.disabled
      ? css`
          background-color: #868e9c;
          border: 2px solid #868e9c;
        `
      : css`
          background-color: white;
          border: 2px solid white;
        `};
  & .arrow-btn {
    position: absolute;
    padding-left: 40px;
    width: 15px;
  }
  &:after {
    transition: all 0.2s ease-in-out;
    content: "";
    position: absolute;
    top: 1px;
    left: -12px;
    right: 1px;
    bottom: -12px;
    border: 4px solid white;
    z-index: -1;
    ${(props) =>
      props.disabled
        ? css`
            border: 4px solid #868e9c;
          `
        : css`
            border: 4px solid white;
          `};
  }
  &:hover {
    ${(props) =>
      props.disabled
        ? css``
        : css`
            transition: all 0.2s ease-in-out;
            transform: translate(17px, -5px);
            background: #fff;
            color: #010606;
          `};
  }
  &:hover:after {
    ${(props) =>
      props.disabled
        ? css``
        : css`
            transition: all 0.2s ease-in-out;
            content: "";
            position: absolute;
            top: 6px;
            left: -17px;
            right: 6px;
            bottom: -17px;
            border: 4px solid white;
            z-index: -1;
          `};
  }
`;
