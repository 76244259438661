import { gql } from "@apollo/client";

export const GET_USER = gql`
  query GetUser($email: String!) {
    getuser(email: $email) {
      response
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation SendEmail($email: String, $name: String) {
    sendEmail(email: $email, name: $name) {
      statusCode
      message
      error
      response
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation CreateContact($input: Contact) {
    createContactHubspot(input: $input) {
      statusCode
      message
      error
      response
      data
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($input: Contact) {
    updateContactHubspot(input: $input) {
      statusCode
      message
      error
      response
      data
    }
  }
`;
