const SvgHowWeWork03 = () => {
  return (
    <div className="img-desktop">
      <svg width="520" height="400" viewBox="0 0 620 500" fill="none">
        <path className="geo-figure" d="M410.391 404.644L410.945 404.644L411.221 404.164L583.831 104.478L584.658 103.042L583 103.042L236.111 103.042L235.557 103.042L235.281 103.522L62.6713 403.207L61.8437 404.644L63.5019 404.644L410.391 404.644Z" stroke="white" strokeWidth="1.91697"/>
        <path className="geo-figure" d="M273.729 451.61L79.4761 119.975L467.981 119.975L273.729 451.61Z" fill="#2240D9"/>
        <svg className="geo-figure">
          <mask id="path-3-outside-1" maskUnits="userSpaceOnUse" x="212.563" y="243.206" width="112" height="34" fill="black">
          <rect fill="white" x="212.563" y="243.206" width="112" height="34"/>
          <path d="M320.998 243.206V264.657C320.998 269.711 297.396 273.801 268.284 273.801C239.165 273.801 215.563 269.711 215.563 264.657V243.206"/>
          </mask>
          <path d="M317.998 243.206V264.657H323.998V243.206H317.998ZM317.998 264.657C317.998 264.03 318.371 263.887 317.987 264.213C317.627 264.518 316.916 264.95 315.717 265.442C313.345 266.415 309.732 267.356 305.046 268.168C295.722 269.785 282.723 270.801 268.284 270.801V276.801C282.958 276.801 296.316 275.772 306.071 274.08C310.924 273.238 315.031 272.209 317.994 270.993C319.462 270.391 320.823 269.674 321.867 268.789C322.887 267.924 323.998 266.548 323.998 264.657H317.998ZM268.284 270.801C253.842 270.801 240.841 269.785 231.517 268.168C226.83 267.356 223.217 266.415 220.845 265.442C219.645 264.95 218.935 264.518 218.575 264.213C218.19 263.887 218.563 264.03 218.563 264.657H212.563C212.563 266.548 213.674 267.924 214.694 268.789C215.738 269.674 217.1 270.391 218.568 270.993C221.531 272.209 225.638 273.238 230.492 274.08C240.248 275.772 253.608 276.801 268.284 276.801V270.801ZM218.563 264.657V243.206H212.563V264.657H218.563Z" fill="white" mask="url(#path-3-outside-1)"/>
          <path d="M268.277 253.858C282.893 253.858 296.161 252.831 305.808 251.157C310.619 250.323 314.603 249.315 317.418 248.161C318.819 247.586 320.017 246.941 320.89 246.2C321.751 245.471 322.491 244.475 322.491 243.213C322.491 241.952 321.751 240.956 320.89 240.226C320.017 239.486 318.819 238.841 317.418 238.266C314.603 237.112 310.619 236.104 305.808 235.27C296.161 233.596 282.893 232.569 268.277 232.569C253.662 232.569 240.394 233.596 230.747 235.27C225.935 236.104 221.952 237.112 219.137 238.266C217.736 238.841 216.538 239.486 215.664 240.226C214.804 240.956 214.063 241.952 214.063 243.213C214.063 244.475 214.804 245.471 215.664 246.2C216.538 246.941 217.736 247.586 219.137 248.161C221.952 249.315 225.935 250.323 230.747 251.157C240.394 252.831 253.662 253.858 268.277 253.858Z" stroke="white" strokeWidth="3"/>
          <mask id="path-6-outside-2" maskUnits="userSpaceOnUse" x="217.691" y="221.539" width="112" height="34" fill="black">
          <rect fill="white" x="217.691" y="221.539" width="112" height="34"/>
          <path d="M326.126 221.539V242.99C326.126 248.044 302.524 252.134 273.412 252.134C244.293 252.134 220.691 248.044 220.691 242.99V221.539"/>
          </mask>
          <path d="M326.126 221.539V242.99C326.126 248.044 302.524 252.134 273.412 252.134C244.293 252.134 220.691 248.044 220.691 242.99V221.539" fill="#2240D9"/>
          <path d="M323.126 221.539V242.99H329.126V221.539H323.126ZM323.126 242.99C323.126 242.363 323.499 242.22 323.115 242.546C322.755 242.852 322.044 243.283 320.845 243.775C318.473 244.748 314.86 245.689 310.174 246.502C300.85 248.118 287.851 249.134 273.412 249.134V255.134C288.086 255.134 301.444 254.105 311.199 252.413C316.052 251.572 320.159 250.542 323.122 249.327C324.59 248.724 325.951 248.008 326.995 247.122C328.015 246.257 329.126 244.881 329.126 242.99H323.126ZM273.412 249.134C258.97 249.134 245.969 248.118 236.645 246.502C231.958 245.689 228.345 244.748 225.973 243.775C224.773 243.283 224.063 242.852 223.703 242.546C223.318 242.22 223.691 242.363 223.691 242.99H217.691C217.691 244.881 218.802 246.257 219.822 247.122C220.866 248.008 222.228 248.724 223.696 249.327C226.659 250.542 230.766 251.572 235.62 252.413C245.376 254.105 258.735 255.134 273.412 255.134V249.134ZM223.691 242.99V221.539H217.691V242.99H223.691Z" fill="white" mask="url(#path-6-outside-2)"/>
          <path d="M273.412 232.189C288.027 232.189 301.296 231.162 310.943 229.489C315.754 228.654 319.737 227.647 322.553 226.492C323.953 225.918 325.152 225.272 326.025 224.532C326.886 223.802 327.626 222.806 327.626 221.545C327.626 220.284 326.886 219.288 326.025 218.558C325.152 217.818 323.953 217.172 322.553 216.598C319.737 215.443 315.754 214.436 310.943 213.601C301.296 211.928 288.027 210.901 273.412 210.901C258.797 210.901 245.529 211.928 235.881 213.601C231.07 214.436 227.087 215.443 224.272 216.598C222.871 217.172 221.672 217.818 220.799 218.558C219.938 219.288 219.198 220.284 219.198 221.545C219.198 222.806 219.938 223.802 220.799 224.532C221.672 225.272 222.871 225.918 224.272 226.492C227.087 227.647 231.07 228.654 235.881 229.489C245.529 231.162 258.797 232.189 273.412 232.189Z" stroke="white" strokeWidth="3"/>
          <mask id="path-9-outside-3" maskUnits="userSpaceOnUse" x="210.427" y="197.243" width="112" height="34" fill="black">
          <rect fill="white" x="210.427" y="197.243" width="112" height="34"/>
          <path d="M318.861 197.243V218.694C318.861 223.747 295.26 227.838 266.148 227.838C237.028 227.838 213.427 223.747 213.427 218.694V197.243"/>
          </mask>
          <path d="M318.861 197.243V218.694C318.861 223.747 295.26 227.838 266.148 227.838C237.028 227.838 213.427 223.747 213.427 218.694V197.243" fill="#2240D9"/>
          <path d="M315.861 197.243V218.694H321.861V197.243H315.861ZM315.861 218.694C315.861 218.067 316.235 217.924 315.85 218.25C315.49 218.555 314.78 218.987 313.58 219.479C311.208 220.452 307.595 221.392 302.909 222.205C293.585 223.822 280.586 224.838 266.148 224.838V230.838C280.821 230.838 294.179 229.809 303.934 228.117C308.787 227.275 312.894 226.246 315.857 225.03C317.325 224.428 318.687 223.711 319.731 222.826C320.751 221.961 321.861 220.585 321.861 218.694H315.861ZM266.148 224.838C251.705 224.838 238.705 223.822 229.38 222.205C224.693 221.392 221.08 220.452 218.708 219.479C217.508 218.987 216.798 218.555 216.438 218.25C216.053 217.924 216.427 218.067 216.427 218.694H210.427C210.427 220.585 211.538 221.961 212.557 222.826C213.602 223.711 214.963 224.428 216.431 225.03C219.394 226.246 223.501 227.275 228.355 228.117C238.111 229.809 251.471 230.838 266.148 230.838V224.838ZM216.427 218.694V197.243H210.427V218.694H216.427Z" fill="white" mask="url(#path-9-outside-3)"/>
          <path d="M266.141 207.895C280.756 207.895 294.024 206.868 303.671 205.194C308.483 204.36 312.466 203.352 315.281 202.198C316.682 201.623 317.88 200.978 318.753 200.238C319.614 199.508 320.354 198.512 320.354 197.251C320.354 195.989 319.614 194.993 318.753 194.263C317.88 193.523 316.682 192.878 315.281 192.303C312.466 191.149 308.483 190.141 303.671 189.307C294.024 187.633 280.756 186.606 266.141 186.606C251.525 186.606 238.257 187.633 228.61 189.307C223.798 190.141 219.815 191.149 217 192.303C215.599 192.878 214.401 193.523 213.528 194.263C212.667 194.993 211.927 195.989 211.927 197.251C211.927 198.512 212.667 199.508 213.528 200.238C214.401 200.978 215.599 201.623 217 202.198C219.815 203.352 223.798 204.36 228.61 205.194C238.257 206.868 251.525 207.895 266.141 207.895Z" stroke="white" strokeWidth="3"/>
        </svg>
      </svg>
    </div>
  )
}

export default SvgHowWeWork03
