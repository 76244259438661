import styled from 'styled-components';

export const FooterWrapper = styled.div`
  color: #000;
  background-color: #fff;
  width: 100%;
  /* height: 819px; */
  /* height: 700px; */
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: 90px 40px 45.6px;
  box-sizing: border-box;
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: auto;
    flex-direction: column;
    gap: -265px;
    align-items: center;
    justify-content: flex-start;
  }
  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: flex-end;
  }
  @media screen and (max-width: 430px) {
    padding: 70px 20px 45.6px;
  }
`;

export const Containersocialbottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding: 100px 0px 20px; */
  box-sizing: border-box;
  font-size: 14px;
  margin-top: 139px;
  @media screen and (max-width: 1200px) {
    align-self: stretch;
    width: auto;
    gap: 0px;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (max-width: 430px) {
    /* background-color: antiquewhite; */
    margin-top: 60px;
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const DocsWrapper = styled.div`
  display: flex;
  gap: 69px;
  @media screen and (max-width: 430px) {
    flex-direction: column;
    /* background-color: tomato; */
    width: 100%;
    gap: 24px;
  }
`;

export const SocialIconsWrapper = styled.div`
  display: flex;
  gap: 67px;

  @media screen and (max-width: 430px) {
    margin-top: 50px;
  }
`;

export const Frame9 = styled.div`
  align-self: stretch;
  /* height: 430px; */
  /* overflow: hidden; */
  /* flex-shrink: 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
  /* background-color: antiquewhite; */
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-end;
  }
  /* background-color: yellowgreen; */
`;
export const Div = styled.div`
  /* width: 100%; */
  position: absolute;
  line-height: 160%;
  font-weight: 600;
  display: inline-block;
  height: 65px;
  /* flex-shrink: 0; */
  font-size: 40px;
  right: 0;
  @media screen and (max-width: 430px) {
    position: relative;
    font-size: 25px;
    height: auto;
    text-decoration: none;
  }
`;

export const ContactContent = styled.div`
  height: 73px;
  /* overflow: hidden; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: flex-start;
  background-color: cadetblue;
  gap: 202px;
  @media screen and (max-width: 1200px) {
    align-items: flex-end;
    justify-content: flex-start;
  }
`;

export const Frame1 = styled.div`
  height: 73px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;
export const ContactFrame = styled.div`
  /* align-self: stretch; */
  /* height: 73px; */
  /* background-color: aliceblue; */
  /* overflow: hidden; */
  /* flex-shrink: 0; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  flex-wrap: wrap;
  /* background-color: cornflowerblue; */
  position: relative;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 430px) {
    /* background-color: ; */
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: unset;
  }
`;
export const FrameChild = styled.div`
  width: 100%;
  position: relative;
  border-top: 1px solid #050e2a;
  box-sizing: border-box;
  height: 1px;
  opacity: 0.2;
  margin-top: 92px;
  @media screen and (max-width: 430px) {
    margin-top: 0px;
    margin: 40px 0;
  }
`;
export const Frame4 = styled.div`
  width: 296.1px;
  height: 122px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 41px;
  font-size: 18px;
  color: rgba(5, 14, 42, 0.7);
`;

export const Frame5 = styled.div`
  width: 70%;
  /* height: 178px; */
  /* min-height: 220px; */
  /* max-height: 278px; */
  /* overflow: hidden; */
  /* flex-shrink: 0; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 26px;
  /* min-height: 200px; */
  @media screen and (max-width: 430px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const Frame6 = styled.div`
  width: 243px;
  /* height: 121.3px; */
  height: auto;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 23px;
`;
export const Frame8 = styled.div`
  width: 100%;
  /* overflow: hidden; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  height: auto;
  margin-top: 87px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: auto;
    flex-direction: column;
    padding-top: 100px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 960px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
  @media screen and (max-width: 430px) {
    margin-top: 0px;
    padding-top: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }
`;
export const Frame7 = styled.div`
  /* height: 180px; */
  /* min-height: 290px; */
  /* max-height: 298px; */
  width: 283px;
  height: 240px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 41px;
  @media screen and (max-width: 1200px) {
    flex: 1;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 430px) {
    width: 100%;
    /* background-color: #f5f5f5; */
    flex-direction: column;
    flex-wrap: unset;
  }
`;

export const FrameGroup = styled.div`
  width: 243px;
  height: 178px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
`;

export const FrameIcon = styled.img`
  /* width: 49.5px; */
  position: absolute;
  height: 60px;
  left: 0;
  /* overflow: hidden; */
  /* flex-shrink: 0; */
  @media screen and (max-width: 430px) {
    position: relative;
  }
`;

export const FrameParent = styled.div`
  width: 283px;
  height: 178px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 41px;
`;

export const ViewOnMap = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.08em;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  @media screen and (max-width: 430px) {
    align-self: left;
  }
`;

export const ViewOnMap1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.08em;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #050e2a;
  cursor: pointer;
`;

export const Contacttherocketcodecom = styled.div`
  position: relative;
  line-height: 160%;
  font-weight: 600;
  white-space: pre-wrap;
  font-size: 40px;
  @media screen and (max-width: 1200px) {
    /* font-size: 30px; */
  }
  @media screen and (max-width: 430px) {
    font-size: 25px;
    text-decoration: none;
  }
`;

export const MxicoCity = styled.div`
  position: relative;
  line-height: 160%;
  font-weight: 600;
  font-size: 20px;
`;

export const PlazaArtzContainer = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 56px;
  line-height: 120%;
  color: rgba(5, 14, 42, 0.7);
`;

export const Frame3 = styled.div`
  /* width: 283.4px;
  height: 121.3px; */
  /* background-color: thistle; */
  /* overflow: hidden; */
  /* flex-shrink: 0; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 23px;

  @media screen and (max-width: 430px) {
    width: 58%;
    gap: 10px;
  }
`;

export const QuertaroMxicoParent = styled.div`
  width: 297px;
  height: 178px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 23px;
`;

export const AvPaseoMonteContainer = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;

export const Menu = styled.div`
  width: 338px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 30px 107px;
  text-align: right;
  font-size: 14px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 430px) {
    width: 100%;
    flex-direction: column;
    justify-content: left;
    gap: 24px;
  }
`;

export const Services = styled.div`
  /* width: 100px; */
  position: relative;
  letter-spacing: 0.08em;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  align-items: left;
  flex-shrink: 0;
  color: black;
  cursor: pointer;
  font-size: 14px;
  text-align: right;
  @media screen and (max-width: 430px) {
    text-align: left;
    width: 100px;
  }
`;
export const SocialIcons = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
`;
export const PlazaArtzAnillo = styled.p`
  letter-spacing: -0.01em;
  line-height: 120%;
  display: flex;
  color: ${(props) => props.fontColour || 'white'};
  font-size: ${(props) => props.fontsize || '1vw'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  margin: 0;
  @media screen and (max-width: 960px) {
    text-align: justify;
  }
  @media screen and (max-width: 430px) {
    text-align: left;
  }
`;

export const PrivacyPolicy = styled.div`
  font-size: 14px;
  position: relative;
  letter-spacing: 0.08em;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  @media screen and (max-width: 430px) {
    text-align: left;
  }
`;
