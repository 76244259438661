import styled from "styled-components";

export const ContentLogin = styled.div`
  /* min-width: 360px; */
  max-width: 360px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0px;
  opacity: 0px;
`;

export const TextErrorLogin = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(255, 0, 0, 1);
  opacity: 0px;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  /* max-width: 360px;
    max-height: 74px; */
  opacity: 0px;
`;

export const TextLogin = styled.h1`
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  text-align: left;
  color: rgba(16, 24, 40, 1);
`;

export const TextLoginDescription = styled.p`
  width: 100%;
  font-size: ${(props) => (props.isDontHaveAccount ? "14px" : "16px")};
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(71, 84, 103, 1);

  display: flex;
  justify-content: ${(props) =>
        props.isDontHaveAccount ? "center" : "normal"};
  align-items: ${(props) => (props.isDontHaveAccount ? "center" : "normal")};

  gap: 6px;

  /* font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(71, 84, 103, 1); */
`;

export const FormLogin = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  border-radius: 8px;
  opacity: 0px;
`;

export const LabelLogin = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(52, 64, 84, 1);
`;

export const InputLogin = styled.input`
  min-width: 360px;
  min-height: 44px;
  padding: 10px 14px 10px 14px;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  opacity: 0px;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background: var(--Colors-Background-bg-primary, rgba(255, 255, 255, 1));
  border: 1px solid var(--Colors-Border-border-primary, rgba(208, 213, 221, 1));
`;

export const DataAccountLogin = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ButtonLogin = styled.button`
  border: 1px solid rgba(166, 246, 116, 1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background: rgba(166, 246, 116, 1);
  max-width: 360px;
  max-height: 44px;
  padding: 8px;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  color: rgba(5, 14, 42, 1);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
`;

export const RemenberPassword = styled.div`
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  color: rgba(52, 64, 84, 1);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

export const CheckFormLogin = styled.input`
  width: 16px;
  height: 16px;
  opacity: 1;
  cursor: pointer;
  border-radius: 4px;
`;

export const LinkForm = styled.a`
  color: rgba(53, 125, 255, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
`;

export const ContainerInputLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;
