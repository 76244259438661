import styled from "styled-components";

export const CenterMainContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const AlignCenterContainer = styled.div`
    margin: 0 auto;
    padding: 50px 80px;
;`

export const ContainerGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-flow: dense;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const CardBots = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  background-color: white;
  border: 1px solid gray;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgb(31 41 55 / var(--tw-bg-opacity));
  transition: background-color 0.3s ease;
  width: 100%;

  &:hover {
    /* background-color: rgb(55 65 81); */
  }

  h5 {
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    color: #333;
    flex-grow: 1;
  }

  p {
    font-weight: normal;
    color: #777;
  }

  @media (prefers-color-scheme: dark) {
    background-color: rgb(31 41 55);
    border-color: rgb(55 65 81);

    &:hover {
      /* background-color: rgb(55 65 81); */
    }

    h5 {
      color: white;
    }

    p {
      color: #aaa;
    }
  }
`;

export const IconRunBot = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00aa58;
    border-radius: 100px;
    margin: 0px 10px 0px 0px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #00cd6a;
    }

    svg {
        color: white;
    }
`;

export const DetailsBot = styled.div`
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 50%);
    z-index: 1;
    transition: background-color 0.3s ease;
    
    &:hover {
        background-color: rgb(0 0 0 / 70%);
    }
`;