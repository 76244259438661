/* eslint-disable */
import styled from 'styled-components';

const GoodDesignIs = styled.div`
  position: absolute;
  height: 64.27%;
  width: 73.67%;
  top: 17.99%;
  left: 26.33%;
  letter-spacing: -0.02em;
  line-height: 30.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
const SimpleColorIcon = styled.img`
  position: absolute;
  height: 100%;
  width: 20.1%;
  top: 0%;
  right: 79.9%;
  bottom: 0%;
  left: 0%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const GoodDesignIsParent = styled.div`
  width: 210px;
  position: relative;
  height: 38.9px;
  min-width: 210px;
`;
const GoodDesignIs1 = styled.div`
  flex: 1;
  position: relative;
  line-height: 120%;
`;
const Button = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  box-sizing: border-box;
  min-width: 100px;
`;
const ButtonParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
`;
const GoodDesignIs2 = styled.b`
  flex: 1;
  position: relative;
  line-height: 112%;
`;
const Button1 = styled.div`
  border-radius: 8px;
  background-color: #00aa58;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  box-sizing: border-box;
  min-width: 124px;
  font-size: 16px;
`;
const FrameParent = styled.div`
  flex: 1;
  height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  text-align: center;
  font-size: 18px;
`;
const Menu = styled.div`
  width: 434px;
  position: relative;
  font-size: 16px;
  white-space: pre-wrap;
  text-align: right;
  display: none;
`;
const Header = styled.div`
  width: 1440px;
  background-color: #0e1d39;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 120px;
  box-sizing: border-box;
  gap: 10px;
`;
const GoodDesignIs3 = styled.div`
  position: absolute;
  top: 8.9px;
  left: 70.4px;
  letter-spacing: -0.02em;
  line-height: 39.31px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 196.8px;
  height: 31.9px;
`;
const SimpleColorIcon1 = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 53.7px;
  height: 49.7px;
`;
const FullLogoColorDark = styled.div`
  width: 267.2px;
  position: relative;
  height: 49.7px;
`;
const H = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Title = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const Text1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;
const GroupChild = styled.div`
  position: absolute;
  height: 102.08%;
  width: 100.28%;
  top: -1.04%;
  right: -0.14%;
  bottom: -1.04%;
  left: -0.14%;
  border-radius: 8px;
  border: 1px solid #395280;
  box-sizing: border-box;
`;
const GoodDesignIs4 = styled.div`
  position: absolute;
  height: 52.29%;
  width: 64.55%;
  top: 24.17%;
  left: 4.86%;
  letter-spacing: -0.01em;
  line-height: 120%;
  display: flex;
  align-items: center;
`;
const GroupItem = styled.div`
  position: absolute;
  height: 33.33%;
  width: 11.9%;
  top: -16.04%;
  right: 83.32%;
  bottom: 82.71%;
  left: 4.77%;
  background-color: #0e1d39;
  display: none;
`;
const GoodDesignIs5 = styled.div`
  position: absolute;
  height: 33.33%;
  width: 9.52%;
  top: -16.04%;
  left: 5.88%;
  font-size: 12px;
  line-height: 120%;
  color: #fff;
  text-align: center;
  display: none;
  align-items: center;
  justify-content: center;
`;
const RectangleParent = styled.input`
  position: absolute;
  height: 85.71%;
  width: 100%;
  top: 13.75%;
  right: 0%;
  bottom: 0.54%;
  left: 0%;
  outline: none;
  border-radius: 8px;
  border: 1px solid #395280;
  box-sizing: border-box;
  background: none;
  padding: 0px 16px;
  caret-color: #fff;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: #fff;
`;
const Component = styled.div`
  align-self: stretch;
  position: relative;
  height: 56px;
`;
const Textfieldoutlined = styled.div`
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  color: #9a9a9a;
`;
const Body = styled.div`
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 120%;
`;
const Link = styled.div`
  width: 328px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Text2 = styled.b`
  position: relative;
  line-height: 120%;
`;
const Iconsdropright = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  display: none;
`;
const Button2 = styled.div`
  align-self: stretch;
  border-radius: 8px;
  background-color: #2240d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  gap: 16px;
  cursor: pointer;
`;
const Buttons = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 18px;
`;
const Text3 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  text-align: center;
  font-size: 16px;
`;
const RestorePasswordForm = styled.div`
  flex: 1;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(76, 78, 100, 0.22);
  border: 1px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 24px;
  gap: 40px;
  min-width: 300px;
  max-width: 400px;
`;
const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 80px 24px;
  font-size: 32.8px;
`;
const RecuperarContraseaRoot = styled.div`
  position: relative;
  background-color: #0e1d39;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 25.6px;
  color: #fff;
  font-family: 'DM Sans';
`;

const NewPassword = () => {
  return (
    <>
      <>
        <RecuperarContraseaRoot>
          <Container>
            <RestorePasswordForm>
              <H>
                <FullLogoColorDark>
                  <GoodDesignIs3>rocket code</GoodDesignIs3>
                  <SimpleColorIcon1 alt='' src='/v1/simple-color.svg' />
                </FullLogoColorDark>
              </H>
              <Text3>
                <Text1>
                  <Title>Recuperar contraseña</Title>
                </Text1>
                <Textfieldoutlined>
                  <Component>
                    <RectangleParent placeholder='Nueva contraseña' />
                  </Component>
                </Textfieldoutlined>
                <Link>
                  <Body style={{ textAlign: 'left' }}>
                    Crea una nueva contraseña para tu cuenta. Recuerda que esta
                    debe tener:
                    <ul style={{ listStyle: 'inside' }}>
                      <li>Mínimo 8 caracteres.</li>
                      <li>Al menos una letra mayúscula.</li>
                      <li>Al menos un número.</li>
                      <li>Un carácter especial, como *, #, o !</li>
                    </ul>
                  </Body>
                </Link>
                <Buttons>
                  <Button2>
                    <Text2>Restaurar contraseña</Text2>
                    <Iconsdropright alt='' src='/v1/iconsdropright.svg' />
                  </Button2>
                </Buttons>
              </Text3>
            </RestorePasswordForm>
          </Container>
        </RecuperarContraseaRoot>
      </>
    </>
  );
};

export default NewPassword;
