import { atom } from "recoil";

const userData = JSON.parse(localStorage.getItem('marketplace-userData'));

export const authUserDataState = atom({
    key: "authUserState",
    default: userData || {
        id: "",
        email: "",
        username: "",
        is_active: false,
    },
});

export const isAuthenticatedState = atom({
    key: "isAuthenticatedState",
    default: false,
});
