import styled from 'styled-components';
import SVGComponent from '../components/SVGComponent';
import FormComponent from '../components/OnboardingForm';
import { useState } from 'react';
import React from 'react';
import { MainContentTitle } from '../../../components/typing/TypingEffect';
import { removeSessionStorageItem } from '../services/swarm.service';

const OnboardingContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  min-width: 100%;
  height: 100vh;
`;

const Maincontent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
`;

const ColoredSpan = styled.span`
  background: var(
    --Gradient-1,
    linear-gradient(90deg, #00aa58 28.6%, #2240d9 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const OnboardingPage = () => {
  const [step, setStep] = useState(0);
  const [fields, setFields] = useState({});
  const [errorFields, setErrorFields] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorFields({});

    const formData = new FormData(event.target);
    const values = {};

    formData.forEach((value, key) => {
      values[key] = value;
    });

    // Patrones de regex
    const namePattern = /^[a-zA-Z\s]*$/;
    const phonePattern = /^\d{10}$/;

    if (values.nombre && !namePattern.test(values.nombre)) {
      setErrorFields((prevFields) => ({
        ...prevFields,
        nombre: 'El nombre solo puede contener letras.',
      }));
      return;
    }

    if (values.telefono && !phonePattern.test(values.telefono)) {
      setErrorFields((prevFields) => ({
        ...prevFields,
        telefono: 'El teléfono debe contener sólo dígitos. Mínimo 10 dígitos.',
      }));
      return;
    }

    setFields((prevFields) => ({ ...prevFields, ...values }));

    setStep((prev) => prev + 1);
  };

  const handleStart = () => {
    removeSessionStorageItem({ item: 'firstTimeLogged' });
    return window.location.replace('/v1/swarm/chat');
  };

  return (
    <>
      <OnboardingContainer>
        {step === 0 && (
          <>
            <Maincontent>
              <SVGComponent src={'/v1/swarm/OnboardingLogo.svg'} />
              <MainContentTitle>
                Vamos a crear tu perfil. ¿Cómo te llamas?
              </MainContentTitle>
            </Maincontent>
            <FormComponent
              inputs={[
                {
                  type: 'text',
                  name: 'nombre',
                  placeholder: 'Mi nombre es...',
                },
              ]}
              onSubmit={handleSubmit}
              error={errorFields.nombre}
            />
          </>
        )}
        {step === 1 && (
          <>
            <Maincontent>
              <SVGComponent src={'/v1/swarm/OnboardingLogo.svg'} />
              <MainContentTitle>
                Hola <ColoredSpan>{fields.nombre}</ColoredSpan>, mucho gusto.
                Antes de empezar a trabajar, necesitamos un número de teléfono
                para respaldar tu información de forma segura.
              </MainContentTitle>
            </Maincontent>
            <FormComponent
              inputs={[
                {
                  type: 'text',
                  name: 'telefono',
                  placeholder: 'Número de teléfono...',
                },
              ]}
              onSubmit={handleSubmit}
              error={errorFields.telefono}
            />
          </>
        )}
        {step === 2 && (
          <>
            <Maincontent>
              <SVGComponent src={'/v1/swarm/OnboardingLogo.svg'} />
              <MainContentTitle>
                ¡Gracias por tu información! Estamos listos para empezar.
              </MainContentTitle>
            </Maincontent>
            <FormComponent inputs={[]} onSubmit={handleStart} />
          </>
        )}
      </OnboardingContainer>
    </>
  );
};

export default OnboardingPage;
