import React, { useState, useEffect } from 'react';

export const TypingEffect = ({ children }) => {
  const contentArray = React.Children.toArray(children).flatMap(
    (child, childIndex) => {
      if (typeof child === 'string') {
        return child.split(' ').map((word, wordIndex) => ({
          type: 'text',
          value: word + ' ',
          key: `${childIndex}-${wordIndex}`,
        }));
      } else if (React.isValidElement(child)) {
        return [{ type: 'element', value: child, key: `${childIndex}` }];
      } else {
        return child;
      }
    }
  );

  const [displayText, setDisplayText] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (contentArray.length > 0) {
      const interval = setInterval(() => {
        if (index < contentArray.length) {
          setDisplayText((prevText) => [...prevText, contentArray[index]]);
          setIndex((prevIndex) => prevIndex + 1);
        } else {
          clearInterval(interval);
        }
      }, 100);

      return () => clearInterval(interval);
    }
  }, [index, contentArray]);

  return (
    <>
      {displayText.map((item) =>
        item.type === 'text'
          ? item.value
          : React.cloneElement(item.value, { key: item.key })
      )}
    </>
  );
};

export const MainContentTitle = ({ children }) => {
  return (
    <h3
      style={{
        maxWidth: '500px',
        color: 'var(--TEXT-YALE-BLUE, #004c97)',
        fontFamily: 'DM Sans',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '120%',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        margin: '0 auto',
        borderRight: 'none',
      }}
    >
      <TypingEffect>{children}</TypingEffect>
    </h3>
  );
};
