import { isMobile } from 'react-device-detect';
import { BtnStyled } from '../../../style/btn-styles';
import { useState, useEffect } from 'react';
import { TextField, Checkbox } from '@material-ui/core';
import '../../../style/form.css';
import { BtnGeneral } from '../../../style/btn-styles';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Vacancies } from '../data/vacancies';
import emailjs from 'emailjs-com';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import { gql, useMutation } from '@apollo/client';

const InputFile = styled.div`
  position: relative;
  color: #c7c7c7;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  width: 200px;
  height: 56px;
  margin-right: 20px;
  input {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: 200px;
    opacity: 0;
  }
  @media (max-width: 800px) {
    width: 100%;
    height: 52px;
    margin-bottom: 5px;
  }
`;

const UPLOAD_FILE = gql`
  mutation UploadFile($input: img!) {
    uploadFile(input: $input) {
      Location
    }
  }
`;

const SAVE_VACANCY = gql`
  mutation SaveVacancy($input: VacancyInput!) {
    saveVacancy(input: $input) {
      id
      first_name
      last_name
      whatsapp
      email
      country
      city
      address
      portfolio
      type_vacancie
      vacancie
      linkedin
      cv
    }
  }
`;


export default function HiringForm({ vacancie }) {
  // const vacancie = Vacancies.find((el) => el.url === match.params.name);
  let history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      //Animaciones de Scroll
      const animatedBasics = gsap.utils.toArray('.animated-basic');
      animatedBasics.forEach((animatedBasic) => {
        gsap.from(animatedBasic, {
          y: 50,
          autoAlpha: 0,
          duration: 1,
          scrollTrigger: {
            trigger: animatedBasic,
            toggleActions: 'restart none none reset',
          },
        });
      });
    }, 700);

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, []);

  const [check, setCheck] = useState(false);
  const [changeBorderColor, setChangeBorderColor] = useState('#4E596E');
  const [btndisabled, Setbtndisabled] = useState(true);
  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    whatsapp: '',
    country: '',
    city: '',
    address: '',
    portfolio: '',
    linkedin: '',
  });

  const [error, setError] = useState({
    first_name: false,
    last_name: false,
    email: false,
    whatsapp: false,
    country: false,
    city: false,
    address: false,
    portfolio: false,
    linkedin: false,
  });

  const [textError, SettextError] = useState({
    first_name: '',
    last_name: '',
    email: '',
    whatsapp: '',
    country: '',
    city: '',
    address: '',
    portfolio: '',
    linkedin: '',
  });

  const ValidPhone = () => {
    if (
      form.whatsapp.length < 9 ||
      ('whatsapp' && !/^[0-9]+$/i.test(form.whatsapp))
    ) {
      textError.whatsapp =
        "Please enter a phone number that's at least 10 numbers long.";
      error.whatsapp = true;
    } else {
      textError.whatsapp = '';
      error.whatsapp = false;
    }
    eBtn();
  };

  const eBtn = () => {
    if (
      form.email.length !== '' &&
      form.email.length !== 0 &&
      !error.email &&
      form.first_name.length !== '' &&
      form.first_name.length !== 0 &&
      !error.first_name &&
      form.last_name.length !== '' &&
      form.last_name.length !== 0 &&
      !error.last_name &&
      form.whatsapp.length !== '' &&
      form.whatsapp.length !== 0 &&
      !error.whatsapp &&
      form.country.length !== '' &&
      form.country.length !== 0 &&
      !error.country &&
      form.city.length !== '' &&
      form.city.length !== 0 &&
      !error.city &&
      check &&
      document.getElementById('input').files[0]
    ) {
      Setbtndisabled(false);
    } else {
      Setbtndisabled(true);
    }
  };

  const ValidEmail = () => {
    if (
      form.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,15}$/i.test(form.email)
    ) {
      textError.email = 'Email must be formatted correctly.';
      error.email = true;
    } else {
      textError.email = '';
      error.email = false;
    }
    eBtn();
  };

  const ValidLetras = (nombre, valor) => {
    if (nombre && !/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+$/i.test(valor)) {
      textError[nombre] = 'Please complete the information.';
      error[nombre] = true;
    } else {
      textError[nombre] = '';
      error[nombre] = false;
    }
    eBtn();
  };

  useEffect(() => {
    eBtn();
  });

  useEffect(() => {
    setChangeBorderColor('#4E596E');
  }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case 'email':
        ValidEmail();
        break;
      case 'first_name':
        ValidLetras('first_name', form.first_name);
        break;
      case 'last_name':
        ValidLetras('last_name', form.last_name);
        break;
      case 'country':
        ValidLetras('country', form.country);
        break;
      case 'whatsapp':
        ValidPhone();
        break;
      default:
        break;
    }
  };

  const [saveVacancy] = useMutation(SAVE_VACANCY);
  const [uploadFile] = useMutation(UPLOAD_FILE);

  const guardar = (e) => {
    e.preventDefault();
    if (
      !error.email &&
      !error.first_name &&
      !error.last_name &&
      !error.whatsapp &&
      !error.city &&
      !error.country
    ) {
      const selectedFile = document.getElementById('input').files[0];
  
      function getBase64(file) {
        return new Promise(function (resolve, reject) {
          var reader = new FileReader();
          reader.onload = function () {
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      }
  
      getBase64(selectedFile).then(function (result) {
        uploadFile({
          variables: {
            input: {
              name: selectedFile.name,
              type: selectedFile.type,
              base64: result.split(',').pop(),
              folder: 'routines',
            },
          },
        }).then(({ data }) => {
          const cvUrl = data.uploadFile.Location;
  
          saveVacancy({
            variables: {
              input: {
                first_name: form.first_name,
                last_name: form.last_name,
                whatsapp: form.whatsapp,
                email: form.email,
                country: form.country,
                city: form.city,
                address: form.address,
                portfolio: form.portfolio,
                linkedin: form.linkedin,
                type_vacancie: vacancie.type,
                vacancie: vacancie.name,
                cv: cvUrl,
              },
            },
          }).then(() => {
            var template_params = {
              first_name: form.first_name,
              last_name: form.last_name,
              phone: form.whatsapp,
              email: form.email,
              country: form.country,
              city: form.city,
              address: form.address,
              website_blog: form.portfolio,
              linkedin_profile: form.linkedin,
              type: vacancie.type,
              vacancie: vacancie.name,
              cv: cvUrl,
            };
  
            var service_id = 'default_service';
            let user_id = 'user_Uj9X7ydRRn7BWERgHBG1q';
            var template_id = 'template_reclutamiento';
            emailjs.send(service_id, template_id, template_params, user_id).then(
              (response) => {
                console.log('SUCCESS!', response.status, response.text);
              },
              (err) => {
                console.log('FAILED...', err);
              }
            );
            setOpenDialog(true);
          });
        });
      });
    } else {
      console.log('Error');
    }
  };

  const checker = () => {
    if (check) {
      setCheck(false);
      setChangeBorderColor('#4E596E');
    } else {
      setCheck(true);
      setChangeBorderColor('white');
    }
  };

  const allowLetters = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ ]/g, ''); //[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+
  };

  const wordsToUppercase = (e) => {
    e.target.value = e.target.value.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase()
    );
  };

  //MODAL

  const deviceDialogStyles = () => {
    if (isMobile) {
      return {
        root: {
          position: 'absolute',
          width: '85vw',
          height: '88vh',
          backgroundColor: '#FFA800',
          backgroundImage: 'url("./Group 381.png")',
          border: 'none',
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '15px 15px',
        },
      };
    }
    return {
      root: {
        position: 'absolute',
        width: '570px',
        height: '370px',
        backgroundColor: '#FFA800',
        backgroundImage: 'url("./Group 381.png")',
        border: 'none',
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px 60px',
      },
    };
  };

  const useDialogStyles = makeStyles(() => deviceDialogStyles());

  const useBackdropStyles = makeStyles({
    root: {
      backgroundColor: 'rgba(14,29,57,0.7)',
      backdropFilter: 'blur(5px)',
    },
  });

  const [dialogStyle] = useState(getDialogStyle);
  const dialogClasses = useDialogStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const backdropClasses = useBackdropStyles();

  const handleCloseDialog = () => {
    setOpenDialog(false);
    history.push('/v1/new-hiring');
  };

  function getDialogStyle() {
    return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
      border: 'none',
    };
  }

  const bodyDialog = () => (
    <div className={dialogClasses.root} style={dialogStyle}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: 'auto',
        }}
      >
        <h1
          style={{
            fontFamily: 'DM Sans',
            fontSize: '40px',
            margin: '0px',
            textAlign: 'center',
            fontWeight: '700',
            color: 'black',
          }}
        >
          Thanks for getting in touch!
        </h1>
        <p
          style={{
            fontFamily: 'DM Sans',
            fontSize: '18px',
            textAlign: 'center',
            color: 'black',
            marginBottom: '60px',
          }}
        >
          We’ve received your information and <br /> you’ll hear from us very
          soon.
        </p>
        <BtnGeneral to='/' style={{ margin: '5px 30px 20px' }}>
          GO TO HOME
        </BtnGeneral>
      </div>
    </div>
  );

  //FINMODAL

  //INPUT FILE

  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const progressBar = () => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          setProgress(0);
          setShowProgressBar(false);
          clearInterval(timer);
          return;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 110);

    return () => {
      clearInterval(timer);
    };
  };

  const [fileName, setFileName] = useState('');
  const handleFileChange = () => {
    const selectedFile = document.getElementById('input').files[0];
    if (selectedFile.size > 5000000) {
      alert('Tu archivo no debe de pesar más de 5mb');
      document.getElementById('input').value = '';
      return;
    }
    setFileName(selectedFile.name);
    setShowProgressBar(true);
    progressBar();
  };

  // FIN INPUT FILE

  const renderContent = () => {
    if (isMobile) {
      return (
        <>
          <Modal
            onClose={handleCloseDialog}
            open={openDialog}
            BackdropComponent={Backdrop}
            BackdropProps={{ classes: { root: backdropClasses.root } }}
          >
            {bodyDialog()}
          </Modal>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              // padding: "150px 30px 0px",
            }}
          >
            {/* <div
              style={{
                maxHeight: "356px",
                maxWidth: "400px",
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <img
                style={{ height: "130px", paddingBottom: "40px" }}
                src="/puzzle.svg"
                alt="Logo Rocket"
                className="animated-basic"
              />
            </div> */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <form
                action='https://therocketcode.com/'
                id='form'
                onSubmit={guardar}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h3
                    style={{
                      fontSize: '25px',
                      textAlign: 'left',
                      marginBottom: '5px',
                    }}
                    className='animated-basic'
                  >
                    {vacancie.name}
                  </h3>
                  <p
                    style={{
                      fontSize: '18px',
                      color: '#00AA58',
                      margin: '0px 0px 15px',
                    }}
                    className='animated-basic'
                  >
                    {vacancie.type}
                  </p>
                </div>

                <TextField
                  error={error.first_name}
                  helperText={textError.first_name}
                  style={{
                    margin: '10px 0',
                    background: 'transparent',
                    border: 'none',
                    color: 'white',
                    fontFamily: 'DM Sans',
                    fontSize: '20px',
                    outline: 'none',
                    width: '100%',
                  }}
                  variant='outlined'
                  label='Nombre'
                  type='text'
                  id='first_name'
                  name='first_name'
                  value={form.first_name}
                  onChange={handleChange}
                  onInput={(e) => {
                    allowLetters(e);
                    wordsToUppercase(e);
                  }}
                  className='animated-basic'
                />

                <TextField
                  error={error.last_name}
                  helperText={textError.last_name}
                  style={{
                    margin: '10px 0',
                    background: 'transparent',
                    border: 'none',
                    color: 'white',
                    fontFamily: 'DM Sans',
                    fontSize: '20px',
                    outline: 'none',
                    width: '100%',
                  }}
                  variant='outlined'
                  label='Apellidos'
                  type='text'
                  id='last_name'
                  name='last_name'
                  value={form.last_name}
                  onChange={handleChange}
                  onInput={(e) => {
                    allowLetters(e);
                    wordsToUppercase(e);
                  }}
                  className='animated-basic'
                />

                <div
                  style={{
                    width: '100%',
                    margin: '10px 0',
                    borderColor: 'white',
                  }}
                >
                  <TextField
                    error={error.whatsapp}
                    helperText={textError.whatsapp}
                    inputProps={{ maxLength: 13 }}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'DM Sans',
                      fontSize: '20px',
                      outline: 'none',
                      width: '100%',
                    }}
                    id='whatsapp'
                    name='whatsapp'
                    type='tel'
                    value={form.whatsapp}
                    onChange={handleChange}
                    variant='outlined'
                    label='Teléfono'
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    className='animated-basic'
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    margin: '10px 0',
                    borderColor: 'white',
                  }}
                >
                  <TextField
                    error={error.email}
                    helperText={textError.email}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'DM Sans',
                      fontSize: '20px',
                      outline: 'none',
                      width: '100%',
                    }}
                    id='email'
                    name='email'
                    type='email'
                    value={form.email}
                    onChange={handleChange}
                    variant='outlined'
                    label='Email'
                    className='animated-basic'
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    margin: '10px 0',
                    borderColor: 'white',
                  }}
                >
                  <TextField
                    error={error.country}
                    helperText={textError.country}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'DM Sans',
                      fontSize: '20px',
                      outline: 'none',
                      width: '100%',
                    }}
                    type='text'
                    onInput={(e) => {
                      allowLetters(e);
                      wordsToUppercase(e);
                    }}
                    id='country'
                    name='country'
                    value={form.country}
                    onChange={handleChange}
                    variant='outlined'
                    label='País'
                    className='animated-basic'
                    inputProps={{ maxLength: 20 }}
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    margin: '10px 0',
                    borderColor: 'white',
                  }}
                >
                  <TextField
                    error={error.city}
                    helperText={textError.city}
                    onInput={(e) => {
                      allowLetters(e);
                      wordsToUppercase(e);
                    }}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'DM Sans',
                      fontSize: '20px',
                      outline: 'none',
                      width: '100%',
                    }}
                    type='text'
                    id='city'
                    name='city'
                    value={form.city}
                    onChange={handleChange}
                    variant='outlined'
                    label='Ciudad'
                    className='animated-basic'
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    borderColor: 'white',
                    margin: '10px 0',
                  }}
                >
                  <TextField
                    error={error.address}
                    helperText={textError.address}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'DM Sans',
                      fontSize: '20px',
                      outline: 'none',
                      width: '100%',
                    }}
                    type='text'
                    id='address'
                    name='address'
                    value={form.address}
                    onChange={handleChange}
                    variant='outlined'
                    label='Dirección (opcional)'
                    className='animated-basic'
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    margin: '5px 0',
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                  }}
                  className='animated-basic'
                >
                  <InputFile
                    style={
                      fileName === ''
                        ? {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '5px',
                            width: '100%',
                            padding: '14px',
                          }
                        : { display: 'none' }
                    }
                  >
                    <span>Sube tu CV</span>
                    <input
                      type='file'
                      id='input'
                      onChange={handleFileChange}
                      style={{ flex: 1, marginRight: '10px' }}
                    />
                    <span
                      style={{
                        fontSize: '12px',
                        color: '#888',
                      }}
                    >
                      Máximo 5mb
                    </span>
                  </InputFile>
                  <LinearProgress variant='determinate' value={100} />
                  {showProgressBar ? (
                    <div style={{ width: '100%', marginBottom: '15px' }}>
                      <LinearProgress variant='determinate' value={progress} />
                    </div>
                  ) : null}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {fileName === '' ? (
                      <></>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src='/v1/doc-orange.svg'
                          alt='CV'
                          style={{ maxHeigth: '32px' }}
                        />
                        <p
                          style={{
                            paddingLeft: '10px',
                            opacity: 0.3,
                            margin: 0,
                          }}
                        >
                          {fileName}
                        </p>
                        <img
                          src='/v1/remove.svg'
                          alt='Remove'
                          style={{ cursor: 'pointer', paddingLeft: '10px' }}
                          onClick={() => {
                            setFileName('');
                            document.getElementById('input').value = '';
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    width: '100%',
                    borderColor: 'white',
                    margin: '10px 0',
                  }}
                >
                  <TextField
                    error={error.portfolio}
                    helperText={textError.portfolio}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'DM Sans',
                      fontSize: '20px',
                      outline: 'none',
                      width: '100%',
                    }}
                    type='text'
                    id='portfolio'
                    name='portfolio'
                    value={form.portfolio}
                    onChange={handleChange}
                    variant='outlined'
                    label='Sitio web, libro, blog o Github (opcional)'
                    className='animated-basic'
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    borderColor: 'white',
                    margin: '10px 0',
                  }}
                >
                  <TextField
                    error={error.linkedin}
                    helperText={textError.linkedin}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'DM Sans',
                      fontSize: '20px',
                      outline: 'none',
                      width: '100%',
                    }}
                    type='text'
                    id='linkedin'
                    name='linkedin'
                    value={form.linkedin}
                    onChange={handleChange}
                    variant='outlined'
                    label='Perfil de LinkedIn (opcional)'
                    className='animated-basic'
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    borderColor: changeBorderColor,
                    margin: '20px 0px 10px',
                    // border: "2px solid #4E596E",
                    padding: '0px 10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className='animated-basic'
                >
                  <Checkbox
                    color='primary'
                    checked={check}
                    onChange={checker}
                    name='Privacy policy'
                  />
                  <p style={{ margin: 0, color: changeBorderColor }}>
                    He leído y estoy de acuerdo con la{' '}
                    <a
                      href='https://therocketcode.com/v1/privacy'
                      target='blank'
                      style={{
                        color: changeBorderColor,
                        textDecoration: 'underline',
                        fontWeight: 600,
                      }}
                    >
                      política de privacidad
                    </a>
                  </p>
                </div>

                <BtnStyled
                  disabled={btndisabled}
                  style={{
                    width: '-webkit-fill-available',
                    margin: '30px 0px 50px',
                    textAlign: 'center',
                  }}
                  className='animated-basic'
                >
                  APLICAR
                </BtnStyled>
              </form>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <Modal
          onClose={handleCloseDialog}
          open={openDialog}
          BackdropComponent={Backdrop}
          BackdropProps={{ classes: { root: backdropClasses.root } }}
        >
          {bodyDialog()}
        </Modal>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <form
              action='https://therocketcode.com/'
              id='form'
              onSubmit={guardar}
              style={{
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h3
                  style={{
                    fontSize: '25px',
                    textAlign: 'left',
                    marginBottom: '5px',
                  }}
                  className='animated-basic'
                >
                  {vacancie.name}
                </h3>
                <p
                  style={{
                    fontSize: '18px',
                    color: '#00AA58',
                    margin: '0px 0px 15px',
                  }}
                  className='animated-basic'
                >
                  {vacancie.type}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  margin: '0',
                }}
              >
                <TextField
                  error={error.first_name}
                  helperText={textError.first_name}
                  style={{
                    flex: 1,
                    margin: '10px 10px 10px 0px',
                    background: 'transparent',
                    border: 'none',
                    color: 'white',
                    fontFamily: 'DM Sans',
                    fontSize: '20px',
                    outline: 'none',
                    width: '100%',
                  }}
                  variant='outlined'
                  label='Nombre'
                  type='text'
                  id='first_name'
                  name='first_name'
                  value={form.first_name}
                  onChange={handleChange}
                  onInput={(e) => {
                    allowLetters(e);
                    wordsToUppercase(e);
                  }}
                  className='animated-basic'
                />

                <TextField
                  error={error.last_name}
                  helperText={textError.last_name}
                  style={{
                    flex: 1,
                    margin: '10px 0px 10px 10px',
                    background: 'transparent',
                    border: 'none',
                    color: 'white',
                    fontFamily: 'DM Sans',
                    fontSize: '20px',
                    outline: 'none',
                    width: '100%',
                  }}
                  variant='outlined'
                  label='Apellidos'
                  type='text'
                  id='last_name'
                  name='last_name'
                  value={form.last_name}
                  onChange={handleChange}
                  onInput={(e) => {
                    allowLetters(e);
                    wordsToUppercase(e);
                  }}
                  className='animated-basic'
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  margin: '0',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    margin: '10px 10px 10px 0px',
                    borderColor: 'white',
                  }}
                >
                  <TextField
                    error={error.whatsapp}
                    helperText={textError.whatsapp}
                    inputProps={{ maxLength: 13 }}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'DM Sans',
                      fontSize: '20px',
                      outline: 'none',
                      width: '100%',
                    }}
                    id='whatsapp'
                    name='whatsapp'
                    type='tel'
                    value={form.whatsapp}
                    onChange={handleChange}
                    variant='outlined'
                    label='Teléfono'
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    className='animated-basic'
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    margin: '10px 0px 10px 10px',
                    borderColor: 'white',
                  }}
                >
                  <TextField
                    error={error.email}
                    helperText={textError.email}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'DM Sans',
                      fontSize: '20px',
                      outline: 'none',
                      width: '100%',
                    }}
                    id='email'
                    name='email'
                    type='email'
                    value={form.email}
                    onChange={handleChange}
                    variant='outlined'
                    label='Email'
                    className='animated-basic'
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  margin: '0',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    margin: '10px 10px 10px 0px',
                    borderColor: 'white',
                  }}
                >
                  <TextField
                    error={error.country}
                    helperText={textError.country}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'DM Sans',
                      fontSize: '20px',
                      outline: 'none',
                      width: '100%',
                    }}
                    type='text'
                    onInput={(e) => {
                      allowLetters(e);
                      wordsToUppercase(e);
                    }}
                    id='country'
                    name='country'
                    value={form.country}
                    onChange={handleChange}
                    variant='outlined'
                    label='País'
                    className='animated-basic'
                    inputProps={{ maxLength: 20 }}
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    margin: '10px 0px 10px 10px',
                    borderColor: 'white',
                  }}
                >
                  <TextField
                    error={error.city}
                    helperText={textError.city}
                    onInput={(e) => {
                      allowLetters(e);
                      wordsToUppercase(e);
                    }}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      color: 'white',
                      fontFamily: 'DM Sans',
                      fontSize: '20px',
                      outline: 'none',
                      width: '100%',
                    }}
                    type='text'
                    id='city'
                    name='city'
                    value={form.city}
                    onChange={handleChange}
                    variant='outlined'
                    label='Ciudad'
                    className='animated-basic'
                  />
                </div>
              </div>

              <div
                style={{
                  width: '100%',
                  borderColor: 'white',
                  margin: '10px 0',
                }}
              >
                <TextField
                  error={error.address}
                  helperText={textError.address}
                  style={{
                    background: 'transparent',
                    border: 'none',
                    color: 'white',
                    fontFamily: 'DM Sans',
                    fontSize: '20px',
                    outline: 'none',
                    width: '100%',
                  }}
                  type='text'
                  id='address'
                  name='address'
                  value={form.address}
                  onChange={handleChange}
                  variant='outlined'
                  label='Dirección (opcional)'
                  className='animated-basic'
                />
              </div>

              <div
                style={{
                  width: '100%',
                  margin: '5px 0',
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}
                className='animated-basic'
              >
                <InputFile
                    style={
                      fileName === ''
                        ? {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '5px',
                            width: '100%',
                            padding: '14px',
                          }
                        : { display: 'none' }
                    }
                  >
                    <span>Sube tu CV</span>
                    <input
                      type='file'
                      id='input'
                      onChange={handleFileChange}
                      style={{ flex: 1, marginRight: '10px' }}
                    />
                    <span
                      style={{
                        fontSize: '12px',
                        color: '#888',
                      }}
                    >
                      Máximo 5mb
                    </span>
                  </InputFile>
                <LinearProgress variant='determinate' value={100} />
                {showProgressBar ? (
                  <div style={{ width: '100%', marginBottom: '15px' }}>
                    <LinearProgress variant='determinate' value={progress} />
                  </div>
                ) : null}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {fileName === '' ? (
                    <></>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src='/v1/v1/doc-orange.svg'
                        alt='CV'
                        style={{ maxHeigth: '32px' }}
                      />
                      <p
                        style={{
                          paddingLeft: '10px',
                          opacity: 0.3,
                          margin: 0,
                        }}
                      >
                        {fileName}
                      </p>
                      <img
                        src='/v1/v1/remove.svg'
                        alt='Remove'
                        style={{ cursor: 'pointer', paddingLeft: '10px' }}
                        onClick={() => {
                          setFileName('');
                          document.getElementById('input').value = '';
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: '100%',
                  borderColor: 'white',
                  margin: '10px 0',
                }}
              >
                <TextField
                  error={error.portfolio}
                  helperText={textError.portfolio}
                  style={{
                    background: 'transparent',
                    border: 'none',
                    color: 'white',
                    fontFamily: 'DM Sans',
                    fontSize: '20px',
                    outline: 'none',
                    width: '100%',
                  }}
                  type='text'
                  id='portfolio'
                  name='portfolio'
                  value={form.portfolio}
                  onChange={handleChange}
                  variant='outlined'
                  label='Sitio web, libro, blog o Github (opcional)'
                  className='animated-basic'
                />
              </div>

              <div
                style={{
                  width: '100%',
                  borderColor: 'white',
                  margin: '10px 0',
                }}
              >
                <TextField
                  error={error.linkedin}
                  helperText={textError.linkedin}
                  style={{
                    background: 'transparent',
                    border: 'none',
                    color: 'white',
                    fontFamily: 'DM Sans',
                    fontSize: '20px',
                    outline: 'none',
                    width: '100%',
                  }}
                  type='text'
                  id='linkedin'
                  name='linkedin'
                  value={form.linkedin}
                  onChange={handleChange}
                  variant='outlined'
                  label='Perfil de LinkedIn (opcional)'
                  className='animated-basic'
                />
              </div>

              <div
                style={{
                  width: '100%',
                  borderColor: changeBorderColor,
                  margin: '20px 0px 10px',
                  // border: "2px solid #4E596E",
                  padding: '0px 10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                className='animated-basic'
              >
                <Checkbox
                  color='primary'
                  checked={check}
                  onChange={checker}
                  name='Privacy policy'
                />
                <p style={{ margin: 0, color: changeBorderColor }}>
                  He leído y estoy de acuerdo con la{' '}
                  <a
                    href='https://therocketcode.com/v1/privacy'
                    target='blank'
                    style={{
                      color: changeBorderColor,
                      textDecoration: 'underline',
                      fontWeight: 600,
                    }}
                  >
                    política de privacidad
                  </a>
                </p>
              </div>

              <BtnStyled
                disabled={btndisabled}
                style={{
                  width: '-webkit-fill-available',
                  margin: '30px 0px 50px',
                  textAlign: 'center',
                }}
                className='animated-basic'
              >
                APLICAR
              </BtnStyled>
            </form>
          </div>
        </div>
      </>
    );
  };

  return renderContent();
}
