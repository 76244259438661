const SvgHowWeWork02 = () => {
  return (
    <div className="img-desktop">
      <svg width="520" height="400" viewBox="0 0 620 500" fill="none">
        <path className="geo-figure" d="M410.39 404.576L411.257 404.576L411.69 403.824L584.299 104.139L585.594 101.89L582.999 101.89L236.11 101.89L235.243 101.89L234.81 102.641L62.2011 402.327L60.9059 404.576L63.5009 404.576L410.39 404.576Z" stroke="white" strokeWidth="3"/>
        <rect className="geo-figure" x="113.355" y="416.495" width="319.495" height="319.495" transform="rotate(-90 113.355 416.495)" fill="#00AA58"/>
        <svg className="geo-figure">
          <path d="M213.952 316.887H331.89" stroke="white" strokeWidth="3"/>
          <path d="M272.918 316.888V242.832" stroke="white" strokeWidth="3"/>
          <path d="M272.918 242.831C272.918 213.442 296.746 189.614 326.136 189.614C326.142 219.003 302.314 242.831 272.918 242.831Z" stroke="white" strokeWidth="3"/>
          <path d="M219.7 189.614C249.09 189.614 272.918 213.442 272.918 242.831C243.528 242.831 219.7 219.003 219.7 189.614Z" stroke="white" strokeWidth="3"/>
          <path d="M272.918 290.803C272.918 261.413 296.746 237.585 326.136 237.585C326.142 266.975 302.314 290.803 272.918 290.803Z" stroke="white" strokeWidth="3"/>
          <path d="M219.7 237.585C249.09 237.585 272.918 261.413 272.918 290.803C243.528 290.803 219.7 266.975 219.7 237.585Z" stroke="white" strokeWidth="3"/>
        </svg>
      </svg>
    </div>
  )
}

export default SvgHowWeWork02
