import { Checkbox, FormControlLabel } from '@mui/material';
import styled from 'styled-components';
// import useAuth from '../hooks/useAuth';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const GoodDesignIs = styled.div`
  position: absolute;
  top: 8.9px;
  left: 70.4px;
  letter-spacing: -0.02em;
  line-height: 39.31px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 196.8px;
  height: 31.9px;
`;
const SimpleColorIcon = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 53.7px;
  height: 49.7px;
`;
const FullLogoColorDark = styled.div`
  width: 267.2px;
  position: relative;
  height: 49.7px;
`;
const H = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Title = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const Text1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;
const GroupChild = styled.div`
  position: absolute;
  height: 102.08%;
  width: 100.28%;
  top: -1.04%;
  right: -0.14%;
  bottom: -1.04%;
  left: -0.14%;
  border-radius: 8px;
  border: 1px solid #395280;
  box-sizing: border-box;
`;
const GoodDesignIs1 = styled.div`
  position: absolute;
  height: 52.29%;
  width: 64.55%;
  top: 24.17%;
  left: 4.86%;
  letter-spacing: -0.01em;
  line-height: 120%;
  display: flex;
  align-items: center;
`;
const GroupItem = styled.div`
  position: absolute;
  height: 33.33%;
  width: 11.9%;
  top: -16.04%;
  right: 83.32%;
  bottom: 82.71%;
  left: 4.77%;
  background-color: #0e1d39;
  display: none;
`;
const GoodDesignIs2 = styled.div`
  position: absolute;
  height: 33.33%;
  width: 9.52%;
  top: -16.04%;
  left: 5.88%;
  font-size: 12px;
  line-height: 120%;
  color: #fff;
  text-align: center;
  display: none;
  align-items: center;
  justify-content: center;
`;
const RectangleParent = styled.input`
  position: absolute;
  height: 85.71%;
  width: 100%;
  top: 13.75%;
  right: 0%;
  bottom: 0.54%;
  left: 0%;
  outline: none;
  border-radius: 8px;
  border: 1px solid #395280;
  box-sizing: border-box;
  background: none;
  padding: 0px 16px;
  caret-color: #fff;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: #fff;
  font-size: 16px;
`;
const Component = styled.div`
  align-self: stretch;
  position: relative;
  height: 56px;
`;
const Textfieldoutlined = styled.div`
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  text-align: left;
  color: #9a9a9a;
`;
const OlvidasteTuContrasea = styled.span`
  letter-spacing: -0.01em;
`;
const Aqu = styled.b`
  font-size: 18px;
  text-decoration: underline;
`;
const LookAgainAtContainer = styled.div`
  flex: 1;
  position: relative;
  line-height: 120%;
`;
const LinkStyled = styled.div`
  width: 328px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Buttons = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  color: #2240d9;
  width: 100%;
`;
const Text2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  text-align: center;
  font-size: 16px;
`;
const LoginFormRoot = styled.form`
  flex: 1;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(76, 78, 100, 0.22);
  border: 1px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 24px;
  gap: 40px;
  min-width: 300px;
  max-width: 400px;
  text-align: left;
  font-size: 32.8px;
  color: #fff;
  font-family: 'DM Sans';
`;

const Button = styled.button`
  border-radius: 8px;
  background-color: #2240d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  gap: 16px;
  text-align: left;
  font-size: 18px;
  color: #fff;
  font-family: 'DM Sans';
  border: none;
  width: 100%;
  cursor: pointer;
`;

const LoginSwarm = () => {
  //   const {
  //     email,
  //     setEmail,
  //     password,
  //     setPassword,
  //     rememberMe,
  //     setRememberMe,
  //     handleLogin,
  //   } = useAuth();

  return (
    <LoginFormRoot
    // onSubmit={handleLogin}
    >
      <H>
        <FullLogoColorDark>
          <GoodDesignIs>rocket code</GoodDesignIs>
          <SimpleColorIcon alt='' src='/v1/simple-color.svg' />
        </FullLogoColorDark>
      </H>
      <Text2>
        <Text1>
          <Title>¡Bienvenido! Inicia sesión</Title>
        </Text1>
        <Textfieldoutlined>
          <Component>
            <RectangleParent
              placeholder='Email'
              //   value={email}
              //   onChange={(e) => setEmail(e.target.value)}
            />
          </Component>
          <Component>
            <RectangleParent
              placeholder='Contraseña'
              type='password'
              //   value={password}
              //   onChange={(e) => setPassword(e.target.value)}
            />
          </Component>
          <FormControlLabel
            control={
              <Checkbox
              // checked={rememberMe}
              // onChange={(e) => setRememberMe(e.target.checked)}
              />
            }
            label='Recordar contraseña'
          />
        </Textfieldoutlined>
        <Buttons>
          <Button type='submit'>Iniciar sesión</Button>
          <LinkStyled>
            <LookAgainAtContainer>
              <OlvidasteTuContrasea>{`¿Olvidaste tu contraseña? Haz click `}</OlvidasteTuContrasea>
              <Link to='/v1/forgot-password' style={{ color: '#2240D9' }}>
                <Aqu>aquí</Aqu>
              </Link>
            </LookAgainAtContainer>
          </LinkStyled>
        </Buttons>
      </Text2>
    </LoginFormRoot>
  );
};

export default LoginSwarm;
