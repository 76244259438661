import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import React, { useState } from 'react';
import LayoutApp from './components/LayoutApp';
import CaseStudies from './pages/CaseStudies';
import Services from './pages/Services';
import About from './pages/About';
import Blog from './pages/Blog';
import Home from './pages/Home';
import ScrollToTop from './components/ScrollToTop';
import Contact from './pages/Contact';
import Contact2 from './pages/Contact2';
import Letter from './pages/Letter';
import Privacy from './pages/Privacy';
import CaseStudyCoru from './pages/CaseStudyCoru';
import CaseStudyHeroe from './pages/CaseStudyHeroe';
import Hiring from './pages/Hiring';
import Vacancie from './pages/Vacancie';
import HiringForm from './pages/HiringForm';
import useGetLocationIp from './hooks/LocationIp';
import { PaisContext } from './useContext/PaisContext';
import AILanding from './pages/AILanding/AILanding';
import NewHome from './pages/NewHone/NewHome';
import TeamContactCard from './pages/TeamCards/TeamContactCard';
import RocketBot from './pages/RocketBot/RocketBot';
import RocketFuel from './pages/RocketFuel/RocketFuel';
import RocketServices from './pages/Services/RocketServices';
import MeetTheTeam from './pages/MeetTheTeam/MeetTheTeam';
import CatalogAI from './pages/CatalogAI/CatalogAI';
import CatalogServices from './pages/CatalogServices/CatalogServices';
import CatalogAIDemo from './pages/CatalogAIDemo/CatalogAIDemo';
import NewHiring from './pages/NewHiring/Hiring';
import NewVacancieForm from './pages/NewHiring/components/Vacancie';
import Dashboard from './pages/Dashboard/Dashboard';
import DashboardLogin from './pages/Dashboard/DashboardComponents/DashboardLogin';
import { PrivateRoute } from './components/PrivateRoute';
import ThankYouContact from './pages/ThankYou/ThankYouContact';
import WhatsappButton from './components/WhatsappButton';
import CatalogAiForm from './pages/CatalogAiForm';
import Thankyoucatalogform from './pages/ThankYou/Thankyoucatalogform';
import SwarmRoutes from './pages/SwarmAI';
import LoginWithFB from './pages/LoginWithFB';
import DownloadAccountStatement from './pages/OCRLab/AccountStatmentXlsx';
// import NewRocket from './pages/NewRocket/NewRocket';
import NewServices from './pages/NewRocket/Pages/NewServices';
import OCR from './pages/OCRLab/OCR';
import Webinar2024 from './pages/Webinar2024/Webinar2024';
import IntroPDF from './pages/IntroPDF/IntroPDF';
// import ComingSoon from './pages/NewRocket/Pages/ComingSoon';
import Error from './pages/NewRocket/Pages/Error';
import HomeRedesign from './pages/HomeRedesign';
import Bots from './pages/bots/Bots';
import MarketplaceRoutes from './pages/marketplace/routes/index';

export default function Router() {
  const [paisNav, setPaisNav] = useState('');
  const [pais, setPais, isLoader] = useGetLocationIp(paisNav);

  return (
    <PaisContext.Provider
      value={{ pais, setPais, isLoader, paisNav, setPaisNav }}
    >
      <BrowserRouter>
        <LayoutApp>
          <ScrollToTop />
          <WhatsappButton />
          <Switch>
            {/* <Route exact path='/v1/clutch' component={HomeRedesign} /> */}
            <Route exact path='/v1' redirectTo='/' component={Home} />
            {/* <Route exact path='/v1/case-studies' component={CaseStudies} />
            <Route exact path='/v1/services' component={Services} />
            <Route exact path='/v1/about' component={About} />
            <Route exact path='/v1/blog' component={Blog} />
            <Route exact path='/v1/ai' component={AILanding} />
            <Route exact path='/v1/contact' component={Contact} />
            <Route exact path='/v1/contact-ai2' component={CatalogAiForm} />
            <Route exact path='/v1/letter' component={Letter} />
            <Route exact path='/v1/privacy' component={Privacy} />
            <Route exact path='/v1/coru' component={CaseStudyCoru} />
            <Route exact path='/v1/heroe' component={CaseStudyHeroe} />
            <Route exact path='/v1/hiring' component={Hiring} />
            <Route exact path='/v1/hiring/apply/:name' component={HiringForm} />
            <Route exact path='/v1/hiring/:name' component={Vacancie} />
            <Route exact path='/v1/new-hiring' component={NewHiring} />
            <Route
              exact
              path='/v1/new-hiring/:name'
              component={NewVacancieForm}
            /> */}
            {/* <Route exact path='/v1/new-home' component={NewHome} />
            <Route exact path='/v1/team/:member' component={TeamContactCard} />
            <Route exact path='/v1/rocketbot' component={RocketBot} />
            <Route exact path='/v1/rocketfuel' component={RocketFuel} />
            <Route exact path='/v1/rocketservices' component={RocketServices} />
            <Route exact path='/v1/meet-us' component={MeetTheTeam} />
            <Route exact path='/v1/catalogos' component={CatalogAI} />
            <Route exact path='/v1/catalogos-demo' component={CatalogAIDemo} /> */}
            {/* <Route exact path='/v1/new-homerocket' component={NewRocket} /> */}
            {/* <Route exact path='/v1/new-services' component={NewServices} /> */}
            {/* <Route exact path='/v1/new-homerocket/coming-soon' component={ComingSoon} /> */}
            {/* <Route exact path='/v1/error' component={Error} />
            <Route
              exact
              path='/v1/catalogos-documentacion'
              component={CatalogServices}
            />
            <Route exact path='/v1/contact-ai' component={Contact2} />
            <Route
              exact
              path='/v1/download-xlsx-account-statement'
              component={DownloadAccountStatement}
            />
            <PrivateRoute exact path='/v1/dashboard' component={Dashboard} />
            <Route exact path='/v1/login' component={DashboardLogin} />
            <Route
              exact
              path='/v1/login-ocr'
              component={() => <DashboardLogin isOcr={true} />}
            />
            <Route exact path='/v1/bots' component={Bots} />
            <PrivateRoute
              exact
              path='/v1/ocr/'
              component={OCR}
              redirectTo='/v1/login-ocr'
            />
            <Route
              exact
              path='/v1/forgot-password'
              component={DashboardLogin}
            />
            <Route
              exact
              path='/v1/password-recovery'
              component={DashboardLogin}
            />
            <Route
              exact
              path='/v1/contact-ai-thankyou'
              component={ThankYouContact}
            />
            <Route
              exact
              path='/v1/catalog-ai-thankyou'
              component={Thankyoucatalogform}
            /> */}
            {/* <Route path='/v1/marketplace' component={MarketplaceRoutes} /> */}
            <Route path='/v1/swarm' component={SwarmRoutes} />
            <Route path='/v1/autosales' component={SwarmRoutes} />
            {/* <Route path='/v1/loginwithfb' component={LoginWithFB} />
            <Route path='/v1/webinar-cxtrends2024' component={Webinar2024} />
            <Route path='/v1/rocket-code-Intro' component={IntroPDF} /> */}
            <Redirect to='/v1/error' />
          </Switch>
        </LayoutApp>
      </BrowserRouter>
    </PaisContext.Provider>
  );
}
