import styled from "styled-components";

export const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba(5, 14, 42, 1);
  border-radius: 80px 0px 0px 80px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const LoginImage = styled.img`
  max-width: 877px;
  max-height: 830px;
  width: 100%;
  height: auto;
  object-fit: contain;
`;