import React, { useState, useEffect, useRef } from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';

const Puntos = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 10px;
  width: fit-content;

  @media screen and (max-width: 1200px) {
    width: auto;
    padding-left: 0px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 960px) {
    width: auto;
  }
  @media screen and (max-width: 430px) {
    width: auto;
    z-index: 99999999;
  }
`;

const Bullet = styled.div`
  width: ${({ isActive }) => (isActive ? '24px' : '8px')};
  height: 8px;
  border-radius: ${({ isActive }) => (isActive ? '30px' : '50%')};
  background-color: ${({ bulletColor, darkMode }) =>
    darkMode
      ? '#777777'
      : bulletColor
      ? bulletColor
      : 'rgba(255, 255, 255, 0.4)'};
  cursor: pointer;
`;

const AnimatedBullet = styled.div`
  position: absolute;
  height: 9px;
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  background-color: ${({ activeBulletColor }) =>
    activeBulletColor ? activeBulletColor : '#a6f674'};
  transition: width 0.1s ease, transform 0.1s ease 0.1s;
  width: ${({ width }) => `${width}px`};
  transform: ${({ position }) => `translateX(${position}px) rotate(180deg)`};
  cursor: pointer;
`;

const PaginationBullets = ({
  totalSlides = 5,
  darkMode,
  activeBulletPage,
  onHandleBulletClick,
  rootActiveBullet,
  activeBulletColor,
  bulletColor,
  notClickable,
}) => {
  const [activeBullet, setActiveBullet] = useState(0);
  const [position, setPosition] = useState(0);
  const [oldPosition, setOldPosition] = useState(0);
  const [width, setWidth] = useState(24);
  const [borderRadius, setBorderRadius] = useState(50);
  const [isReturning, setIsReturning] = useState(false);
  const bulletRefs = useRef([]);

  useEffect(() => {
    if (bulletRefs.current[activeBullet]) {
      const bullet = bulletRefs.current[activeBullet];
      const newPosition = bullet.offsetLeft;
      setBorderRadius(30);
      if (!isReturning) {
        setWidth(Math.abs(newPosition - oldPosition) + 10);
        setPosition(newPosition);
      }
      setPosition(newPosition);

      setBorderRadius(30);
      setWidth(24);
      setIsReturning(false);
    }
  }, [activeBullet, isReturning, oldPosition]);

  const handleBulletClick = useCallback(
    (index) => {
      if (notClickable) {
      } else {
        onHandleBulletClick(index);
        setOldPosition(position);
        if (index < activeBullet) {
          setActiveBullet(index);
          setIsReturning(true);
          return;
        }
        setIsReturning(false);
        setActiveBullet(index);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeBullet, onHandleBulletClick, position]
  );

  useEffect(() => {
    handleBulletClick(activeBulletPage);
  }, [activeBulletPage, handleBulletClick]);

  useEffect(() => {
    if (rootActiveBullet !== undefined) {
      setActiveBullet(rootActiveBullet);
    }
  }, [rootActiveBullet]);

  return (
    <Puntos>
      <AnimatedBullet
        position={position}
        width={width}
        borderRadius={borderRadius}
        activeBulletColor={activeBulletColor}
      />
      {Array(totalSlides)
        .fill(null)
        .map((_, index) => (
          <Bullet
            key={index}
            ref={(el) => (bulletRefs.current[index] = el)}
            isActive={activeBullet === index}
            darkMode={darkMode}
            onClick={() => handleBulletClick(index)}
            bulletColor={bulletColor}
          />
        ))}
    </Puntos>
  );
};

export default PaginationBullets;
