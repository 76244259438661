import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  display: block;
`;

const SVGComponent = ({ src, ...props }) => {
  const [paths, setPaths] = useState([]);
  const [svgProps, setSvgProps] = useState({});

  useEffect(() => {
    fetch(src)
      .then((response) => response.text())
      .then((data) => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(data, 'image/svg+xml');
        const svgElement = svgDoc.querySelector('svg');
        const paths = Array.from(svgElement.querySelectorAll('path')).map(
          (path) => {
            const pathAttributes = path
              .getAttributeNames()
              .reduce((acc, name) => {
                acc[name] = path.getAttribute(name);
                return acc;
              }, {});
            return pathAttributes;
          }
        );
        const svgAttributes = svgElement
          .getAttributeNames()
          .reduce((acc, name) => {
            acc[name] = svgElement.getAttribute(name);
            return acc;
          }, {});
        setPaths(paths);
        setSvgProps(svgAttributes);
      });
  }, [src]);

  return (
    <SVG {...svgProps} {...props}>
      {paths.map((pathProps, i) => (
        <path key={i} {...pathProps} stroke={props.strokeColor || '#ffffff'} />
      ))}
    </SVG>
  );
};

export default SVGComponent;
