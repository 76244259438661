import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ShortHeadingHere = styled.h1`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-family: Roboto;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media screen and (max-width: 520px) {
    font-size: 40px;
    max-width: 90%;
  }
`;
const LoremIpsumDolor = styled.div`
  align-self: stretch;
  position: relative;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
const Content = styled.div`
  display: flex;
  width: 58%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  flex: 1 0 0;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 520px) {
    width: 100%;
    gap: 16px;
    align-self: stretch;
  }
`;
const Header = styled.div`
  width: 100%;
  background-color: #0e1d39;
  height: auto;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 112px 64px;
  box-sizing: border-box;
  font-size: 56px;
  color: #fff;
  @media screen and (max-width: 520px) {
    padding: 112px 24px 32px 24px;
    box-sizing: border-box;
  }
`;
const DemoDesktopRoot = styled.div`
  position: relative;
  background-color: #0e1d39;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  color: #f5f5f5;
  font-family: Roboto;
  @media screen and (max-width: 420px) {
    padding-left: 0px;
    box-sizing: border-box;
  }
`;

const Button9 = styled.div`
  border-radius: var(--br-5xs);
  background-color: var(--neutral-white);
  width: 224px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-13xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  cursor: pointer;
`;
const ButtonChild = styled.img`
  position: relative;
  width: 16px;
  height: 16px;
  display: none;
`;
const GoodDesignIs2 = styled.b`
  position: relative;
  line-height: 112%;
`;
const ButtonParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-45xl);
  text-align: left;
  font-size: var(--common-small-title-size);
  color: var(--secondary-blue);
  font-family: var(--common-small-title);
  margin-top: 32px;
  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
`;
const Thankyoucatalogform = () => {
  const { t } = useTranslation(['thank-you']);

  // ENDPOINT GET

  const returnInit = () => {
    window.location.href = '/v1/catalogos';
  };

  return (
    <DemoDesktopRoot>
      <Header>
        <img
          style={{ height: '120px', margin: '32px 0' }}
          src='../Simple Color.png'
          alt='Logo Rocket'
          className=''
        />
        <Content>
          <ShortHeadingHere>{`${t('header.title')}`}</ShortHeadingHere>
          <LoremIpsumDolor>{`${t('header.description-p1')}`}</LoremIpsumDolor>
        </Content>
        <ButtonParent>
          <Button9 onClick={() => returnInit()}>
            <ButtonChild alt='' src='/v1/frame-51425.svg' />
            <GoodDesignIs2>{`${t('header.cta')}`}</GoodDesignIs2>
            <ButtonChild alt='' src='/v1/frame-51424.svg' />
          </Button9>
        </ButtonParent>
      </Header>
    </DemoDesktopRoot>
  );
};

export default Thankyoucatalogform;
