import styled from "styled-components";
import {
  LoginContainer,
  ModalContainer,
  ImageContainer,
  LoginImage,
} from "../../styles/auth/loginMarketplace.style.component";
import { MainLogo } from '../../styles/NavBar/NavBarUseCase.style.component'
import LoginFormContainer from "../../components/auth/containers/LoginFormContainer";

export const ContentModalLogo = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
`;

const LoginMarketplace = () => {
  return (
    <LoginContainer>
      <ModalContainer>
        <ContentModalLogo>
          <MainLogo src="/v1/marketplace/main-logo.svg" alt="Logo The Rocket Code"/>
        </ContentModalLogo>
        <LoginFormContainer />
      </ModalContainer>
      <ImageContainer>
        <LoginImage src="/v1/marketplace/login-logo.svg" alt="logo" />
      </ImageContainer>
    </LoginContainer>
  );
};

export default LoginMarketplace;
