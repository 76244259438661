import styled from "styled-components"
import {device} from "./device"

export const PrivacyBody = styled.div`
background-image: url('./Dots.png');
h2 {
  font-weight: 700;
  text-align: center;
  padding: 150px 0 20px;
  margin: 0px;
}
h3 {
  padding: 0 20px;
  font-size: 20px;
  line-height: inherit;
}
img {
  max-height: 266px;
  margin-right: 50px;
}
p {
  padding: 20px 20px 100px;
}
hr {
  height:0.5px; 
  border-width: 0; 
  color: gray; 
  background-color:#7A818E;
}

@media ${device.tablet} {
  p {
  padding: 50px 200px 100px;
  }
  h3 {
  padding: 0 100px 0 200px;
  font-size: 32px;
  }
  h2 {
  font-weight: 700;
  text-align: center;
  padding: 200px 0 100px;
  margin: 0px;
  }
}
`