import styled from 'styled-components';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import SVGComponent from '../SVGComponent';
import FormAskAIComponent from './AskAIForm';
import { rewriteMessage } from '../../services/swarm.service';

const ContentWrapper = styled.div`
  position: absolute;
  z-index: 1100;
  right: -250px;
  top: 50%;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  border-radius: 8px;

  padding: var(--spacing-md, 16px);

  background: var(--Colors-Background-bg-primary, #fff);

  color: var(--PRIMARY-NAVY, #0e1d39) !important;
  font-family: Inter, sans-serif;
  font-size: 13.736px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.367px; /* 155.556% */

  & > p,
  & > div > p,
  & > div > div > p {
    color: var(--PRIMARY-NAVY, #0e1d39);
    font-family: Inter, sans-serif;
    font-size: 13.736px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.367px; /* 155.556% */
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;

  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 5px;
  border: 0.75px solid var(--SECONDARY-YELLOW, #ffa800);
  background: var(--SECONDARY-YELLOW, #ffa800);
  box-shadow: 0px 0.5px 1px 0px rgba(16, 24, 40, 0.05);
`;

const OptionsWrapper = styled.div`
  display: ${(props) => (props.showOptions ? 'flex' : 'none')};
  width: 100%;
  height: ${(props) => (props.showOptions ? 'auto' : '0')};
  padding: ${(props) => (props.showOptions ? '6px' : '0')};
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 9.184px;
  border: 0.76px solid #ffa800;
  background: var(--Colors-Background-bg-primary, #fff);

  /* Shadows/shadow-lg */
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  transition: all 0.3s ease;
`;

const Option = styled.div`
  flex: 1 0 0;
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: var(--spacing-xxs, 4px) var(--spacing-md, 8px);
  gap: var(--spacing-md, 8px);

  border-radius: 8px;

  cursor: pointer;

  transition: all 0.3s ease;

  &:hover {
    background: var(--Colors-Background-bg-secondary, #f7f8fa);
  }
`;

const AskAITooltip = ({ selectedText, ...props }) => {
  const { threadId } = useParams();
  const [showOptions, setShowOptions] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleMouseUp = (event) => {
    event.stopPropagation();
  };

  const handleFormSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    const { range } = selectedText;
    const fragment = range.cloneContents();
    const tempDiv = document.createElement('div');
    tempDiv.appendChild(fragment);
    const htmlString = tempDiv.innerHTML;
    tempDiv.innerHTML = htmlString;

    const uniqueId = 'temp-' + Date.now();

    const selectedElement = range.commonAncestorContainer;
    if (selectedElement.nodeType === Node.ELEMENT_NODE) {
      selectedElement.id = uniqueId;
    } else if (selectedElement.parentNode) {
      selectedElement.parentNode.id = uniqueId;
    }

    try {
      const formData = new FormData(event.target);
      const prompt = formData.get('prompt');

      const response = await rewriteMessage({
        threadId,
        prompt,
        message: JSON.stringify(htmlString),
      });

      if (response.error) {
        return;
      }

      const editedElement = document.getElementById(uniqueId);
      if (editedElement) {
        const newElement = document.createElement('div');
        newElement.innerHTML = response.message;
        range.deleteContents();
        range.insertNode(newElement);
        editedElement.id = '';
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ContentWrapper
        onMouseEnter={() => setShowOptions(true)}
        // onMouseLeave={() => setShowOptions(false)}
        onMouseUp={handleMouseUp}
        {...props}
      >
        <RowContainer onClick={() => setShowOptions((prev) => !prev)}>
          <IconWrapper>
            <SVGComponent src={'/v1/swarm/stars.svg'} />
          </IconWrapper>
          <p>AI Edit</p>
        </RowContainer>

        <OptionsWrapper showOptions={showOptions}>
          <Option
            onClick={() => setShowForm(true)}
            // onMouseLeave={() => setShowForm(false)}
          >
            {showForm ? (
              <FormAskAIComponent
                selectedText={selectedText}
                name={'prompt'}
                placeholder={'Escribe como te gustaría editar este texto.'}
                handleFormSubmit={handleFormSubmit}
                isLoading={isLoading}
              />
            ) : (
              <>
                <SVGComponent
                  src={'/v1/swarm/stars.svg'}
                  strokeColor={'#667085'}
                />
                <p>Re-Write</p>
              </>
            )}
          </Option>
        </OptionsWrapper>
      </ContentWrapper>
    </>
  );
};

export default AskAITooltip;
