import styled from "styled-components"
import {device} from "./device"

export const BlogStyles = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  padding: 120px 40px 40px;
  h1 {
    text-align: center;
    margin-bottom: 15px;
  }
  h3 {
    margin: 15px 0;
  }
  h4 {
    margin: 15px 0;
  }
  h5 {
    margin: 15px 0;
  }
  ul {
    color: white;
    text-align: center;
    font-size: 20px;
    list-style: none;
    padding: 0;
    margin-bottom: 70px;
  }
  li {
    display: inline-block;
    padding-right: 20px;
  }
  img {
    box-sizing: border-box;
    width: 100%;
  }
  & .header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
  }
  & .image-first-article {
    width: 100vw;
  }
  & .article {
    margin-bottom: 70px;
  }
  & .articles-desk {
    display: none;
  }
  @media ${device.tablet} {
    padding: 120px 110px 40px;
    h1 {
      margin: 100px 0 40px;
    }
    h5 {
      font-size: 18px;
    }
    li {
      padding: 0 30px 0 30px;
    }
    & .image-first-article {
      width: 100%;
    }
    & .articles-mobile {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      gap: 25px;
    }
    & .articles-desk {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      gap: 25px;
    }
}
`