import { Checkbox, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import useFetchInsurers from '../hooks/useFetchInsurers';
import Button from './Button';

const SmallBodyBold = styled.b`
  flex: 1;
  position: relative;
  line-height: 120%;
  font-size: 16px;
`;
const Icon = styled.img`
  width: 40px;
  position: relative;
  height: 38px;
  display: none;
`;
const Content = styled.div`
  align-self: stretch;
  border-left: 2px solid #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 8px;
  gap: 8px;
`;
const Icon1 = styled.img`
  width: 40px;
  position: relative;
  height: 40px;
  display: none;
`;
const Content1 = styled.div`
  width: 188px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 8px;
  box-sizing: border-box;
  gap: 8px;
`;
const SmallBodyBold1 = styled.div`
  flex: 1;
  position: relative;
  line-height: 120%;
`;
const VectorIcon = styled.img`
  position: absolute;
  height: 96.25%;
  width: 83%;
  top: 1.5%;
  right: -74.5%;
  bottom: 2.25%;
  left: 91.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
`;
const GroupIcon = styled.img`
  position: absolute;
  height: 52%;
  width: 55.5%;
  top: 25%;
  right: 22%;
  bottom: 23%;
  left: 22.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const Icon2 = styled.div`
  width: 40px;
  position: relative;
  height: 40px;
  display: none;
`;
const Content2 = styled.div`
  width: 188px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 8px;
  box-sizing: border-box;
  gap: 8px;
  color: #fff;
`;
const Menu = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  color: #ffa800;
`;
const ColumnChild = styled.div`
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #2240d9;
  height: 2px;
`;
const Content3 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 8px;
  font-size: 16px;
`;
const Content4 = styled(FormControlLabel)`
  align-self: stretch;
`;
const CheckboxIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;
const Content5 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 8px;
  gap: 8px;
`;
const List = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;
const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;
const SidebarRoot = styled.div`
  border-radius: 0px 8px 0px 0px;
  background-color: #0e1d39;
  box-shadow: 4px 0px 10px rgba(0, 2, 18, 0.22);
  border: ${({ mobile }) => (mobile ? 'none' : '2px solid #2240d9')};
  box-sizing: border-box;
  width: ${({ mobile }) => (mobile ? '100%' : '240px')};
  min-width: ${({ mobile }) => (mobile ? '100%' : '240px')};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 24px;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-family: 'DM Sans';
  display: ${({ openMenu, mobile }) => (openMenu || !mobile ? 'flex' : 'none')};
`;

const MobileContainer = styled.div`
  position: absoulte;
  height: calc(100% - 87px);
  bottom: 0;
  width: 100%;
  background-color: red;
  z-index: 100;
`;

const mock = {
  insurers: [
    'QUALITAS',
    'HDI',
    'GNP',
    'CHUBB',
    'MAPFRE',
    'INBURSA',
    'AXA',
    'ATLAS',
    'ANA',
    'AXA_V2',
  ],
  count: 10,
};

const Sidebar = ({ mobile, openMenu }) => {
  console.log(mobile);
  const [data, setData] = useState({
    insurers: [],
  });

  // hook personalizado
  useFetchInsurers(setData);

  return (
    <>
      <SidebarRoot openMenu={openMenu} mobile={mobile}>
        <Column>
          <Menu>
            <Content>
              <SmallBodyBold>Dashboard</SmallBodyBold>
              <Icon alt="" src="/v1/icon.svg" />
            </Content>
            <Content1>
              <SmallBodyBold>Detalle de consultas</SmallBodyBold>
              <Icon1 alt="" src="/v1/icon.svg" />
            </Content1>
            <Content2>
              <SmallBodyBold1>Perfil</SmallBodyBold1>
              <Icon2>
                <VectorIcon alt="" src="/v1/vector.svg" />
                <GroupIcon alt="" src="/v1/group.svg" />
              </Icon2>
            </Content2>
          </Menu>
          <ColumnChild />
          <List>
            <Content3>
              <SmallBodyBold>Aseguradoras activas</SmallBodyBold>
            </Content3>
            {data.insurers &&
              data.insurers.map((insurer, index) => (
                <Content4
                  key={index}
                  label={insurer.catalogue_name}
                  control={<Checkbox color="primary" checked={true} />}
                />
              ))}
          </List>
          <ColumnChild />
          <List>
            <Content3>
              <SmallBodyBold>Catálogos Activos</SmallBodyBold>
            </Content3>

            <Content4
              label={'Vehículos particulares'}
              control={<Checkbox color="primary" defaultChecked />}
            />
            <Content4
              label={'Vehículos de servicio público'}
              control={<Checkbox color="primary" defaultChecked />}
            />
            <Content4
              label={'Motos'}
              control={<Checkbox color="primary" defaultChecked />}
            />
          </List>
          <Button />
        </Column>
      </SidebarRoot>
    </>
  );
};

export default Sidebar;
