import { useState, useCallback } from 'react';
import styled from 'styled-components';
import MenuOpen from '../components/MenuOpen';
import PortalDrawer from '../components/PortalDrawer';
import Subheader from '../components/Subheader';
import ArrowDown from '../components/ArrowDown';
import useVersionExtractor from '../hooks/useExtractVersionFromPath';

const GoodDesignIs = styled.div`
  position: absolute;
  height: 64.25%;
  width: 73.64%;
  top: 18%;
  left: 26.36%;
  letter-spacing: -0.02em;
  line-height: 30.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
const SimpleColorIcon = styled.img`
  position: absolute;
  height: 100%;
  width: 20.08%;
  top: 0%;
  right: 79.92%;
  bottom: 0%;
  left: 0%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const RocketLogo = styled.div`
  width: 215.1px;
  position: relative;
  height: 40px;
`;
const BurgerIcon = styled.img`
  position: absolute;
  height: 56.39%;
  width: 72.86%;
  top: 21.94%;
  right: 13.71%;
  bottom: 21.67%;
  left: 13.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const Iconsmenu = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  width: 35px;
  position: relative;
  height: 36px;
`;
const IconsmenuWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const HeaderMobile = styled.div`
  width: 1440px;
  background-color: #0e1d39;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 24px;
  box-sizing: border-box;
  gap: 0px 10px;
  @media screen and (max-width: 768px) {
    display: flex;
    align-self: stretch;
    width: auto;
    height: auto;
  }
`;
const HeaderDesktop = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 24px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const Navbar = styled.div`
  align-self: stretch;
  background-color: #0e1d39;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 0px;
  @media screen and (max-width: 768px) {
    display: flex;
  }
`;
const LogoChild = styled.img`
  height: 150px;
  width: 150px;
  position: relative;
  object-fit: cover;
  min-width: 150px;
  max-width: 150px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const S = styled.b`
  height: 154px;
  position: relative;
  line-height: 120%;
  display: inline-block;
  @media screen and (max-width: 960px) {
    font-size: 100px;
  }
  @media screen and (max-width: 768px) {
    font-size: 80px;
  }
`;
const Logo = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 40px;
  @media screen and (max-width: 768px) {
    align-self: stretch;
    width: auto;
    flex: 1;
    flex-direction: column;
    gap: 20px 40px;
    align-items: center;
    justify-content: center;
  }
`;
const Container = styled.div`
  align-self: stretch;
  height: 575px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 72px 96px;
  box-sizing: border-box;
  gap: 50px 0px;
  text-align: right;
  font-size: 128px;
  @media screen and (max-width: 768px) {
    align-self: stretch;
    width: auto;
    flex: 1;
    gap: 50px 0px;
    align-items: center;
    justify-content: center;
    padding: 72px 30px;
    box-sizing: border-box;
  }
`;
const SwarmRoot = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #0e1d39;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 25.6px;
  color: #fff;
  font-family: 'DM Sans';
  @media screen and (max-width: 768px) {
    width: auto;
    align-self: unset;
    height: 100%;
  }
`;

const Swarm = () => {
  const [isMenuOpenOpen, setMenuOpenOpen] = useState(false);
  const version = useVersionExtractor();

  const openMenuOpen = useCallback(() => {
    setMenuOpenOpen(true);
  }, []);

  const closeMenuOpen = useCallback(() => {
    setMenuOpenOpen(false);
  }, []);

  return (
    <>
      <SwarmRoot>
        <Container>
          <Logo>
            <LogoChild alt="" src="/v1/group-1000002486@2x.png" />
            <S>AutoSales Proposal</S>
          </Logo>
          <Subheader />
          <ArrowDown href={`/v1/${version}/chat`} />
        </Container>
      </SwarmRoot>
      {isMenuOpenOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeMenuOpen}
        >
          <MenuOpen onClose={closeMenuOpen} />
        </PortalDrawer>
      )}
    </>
  );
};

export default Swarm;
