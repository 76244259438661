import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from './device';

export const Nav = styled.nav`
  background: #0e1d39;
  width: 100vw;
  height: 80px;
  display: flex;
  position: sticky;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 20px;
  z-index: 10;
  .final__dropdown {
    position: relative;
    z-index: 10;
  }
  .final__dropdown__hover {
    display: flex;
    align-items: center;
    padding: 15px 0;
    background-color: rgba(0, 0, 0, 0);
    color: #ffffff;
    border: 0 solid #323b40;
    cursor: pointer;
  }
  .final__dropdown__hover:after {
    content: url(${(props) =>
      props.langOpen ? './cross.png' : './menu-hamburguesa.svg'});
    transform: rotate(${(props) => (props.langOpen ? '0deg' : '0deg')});
    padding: 10px;
  }
  .final__dropdown__menu {
    position: absolute;
    flex-direction: column;
    align-items: center;
    top: 100%;
    left: 0;
    min-width: 145px;
    background-color: #ffffff;
    color: #323b40;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
  }
  .final__dropdown .final__dropdown__menu {
    display: ${(props) => (props.langOpen ? 'flex' : 'none')};
  }
  .final__dropdown__menu p {
    font-size: 14px;
    display: block;
    padding: 10px;
    border-width: 0;
    color: black;
    margin: 0;
  }
  .final__dropdown__menu p:hover {
    color: blue;
    cursor: pointer;
  }
  .lang-opacity {
    display: ${(props) => (props.langOpen ? 'flex' : 'none')};
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #0e1d39;
  }
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  .modal-lang {
    display: ${(props) => (props.langOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    gap: 60px;
    top: 200px;
    right: 40px;
    margin: -90px 0 0 -100px;
    width: 100%;
    max-width: 200px;
    p {
      color: #ffffff;
      margin: 0;
    }
    a {
      padding: 0;
    }
  }
`;

export const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  /* &.active {
    color: #002fff;
  } */
  & .logo-nav {
    width: 135px;
  }
  @media ${device.tablet} {
    & .logo-nav {
      width: 209px;
    }
  }
`;

export const NavA = styled.a`
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  img {
    width: 209px;
  }
  /* &.active {
    color: #002fff;
  } */
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: none;
  @media ${device.tablet} {
    display: flex;
    align-items: center;
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  padding: 8px 16px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) =>
    props.btnGreen ? 'rgba(0, 170, 88, 1)' : '#2240d9'};
  color: var(--white, #fff);
  font-size: 16px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 112%;
  @media ${device.tablet} {
  }
`;

export const NavLinkFooter = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  padding: 8px 0;
  cursor: pointer;
`;

export const NavMenuNew = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  a {
    color: var(--white, #fff);
    font-size: 16px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: 700;
    line-height: 112%;
  }
  p {
    cursor: pointer;
    text-decoration: underline;
  }
  .language__text {
    display: flex;
    align-items: center;
    gap: 10px;

    .language__span {
      font-size: 18px;
      font-family: DM Sans;
      font-style: normal;
      font-weight: 700;
      line-height: 112%;
      color: #ffffff;
    }
  }
`;
