import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(5, 14, 42);
  /* background-color: red; */
  padding: 23.84px 20px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 999999999;
  @media screen and (min-width: 431px) {
    display: none;
  }
`;

export const Logo = styled.img`
  height: 34px;
  cursor: pointer;
`;

export const MenuIcon = styled.img`
  width: 49px;
  height: 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  opacity: ${({ fadeOut }) => (fadeOut ? '0' : '1')};
  /* background-color: antiquewhite; */
`;

export const NavLinks = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999999;
  background-color: rgb(5, 14, 42);
  flex-direction: column;
  align-items: flex-end;
  padding: 23.84px 20px;
  gap: 40px;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  display: flex; // Always flex
`;

export const NavLink = styled.p`
  color: white;
  text-decoration: none;
  margin: 0;
  padding: 0px;
  line-height: 30px;
  cursor: pointer;
  font-size: 40px;
  font-family: "IBM Plex Sans";
  letter-spacing: 0.08em;
`;

export const CloseButton = styled.img`
  cursor: pointer;
  height: 20px;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-end;
`;

export const OpenMenuHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 38px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
`
export const LogoText = styled.p`
  color: white;
  font-size: 24px;
  font-family: "IBM Plex Sans";
  letter-spacing: 0.08em;
  margin: 0;
  padding: 0;
  cursor: pointer;
`

export const En = styled.span`
  cursor: pointer;
  color: rgba(255, 255, 255, 0.4);
  &.active {
    color: white;
  }
`;
export const Es = styled.span`
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  &.active {
    color: white;
  }
`;

export const EnesWrapper = styled.div`
  margin: 0 !important;
  position: absolute;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  background: linear-gradient(
    107.1deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.09)
  );
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  z-index: 6;
  @media screen and (max-width: 1600px) {
    /* bottom: 60px; */
  }
  @media screen and (max-width: 430px) {
    bottom: 20px;
    right: 20px;
  }
`;
export const Lenguages = styled.div`
  font-size: 14px;
  position: relative;
  letter-spacing: 0.08em;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  gap: 3px;
`;