import { createGlobalStyle } from 'styled-components';

export const SwarmStyles = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #0e1d39;
    background-color: #f5f7fa;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: 'liga', 'kern';
    font-feature-settings: 'liga', 'kern';
    -webkit-font-variant-ligatures: common-ligatures;
    font-variant-ligatures: common-ligatures;
    }

    input[type="checkbox"] {
    opacity: 1;
    cursor: pointer;
    border-radius: 4px;
    }

    & .MuiTooltip-tooltip{
      padding: 0 !important;
      background-color: transparent !important;
    };

  h1 {
    font-family: "DM Sans", sans-serif;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 66px */

    @media screen and (max-width: 420px) {
      font-family: "DM Sans", sans-serif;
      font-size: 39px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 46.8px */
    }
  }

  h2 {
    /* DESKTOP/Desktop H2 */
    font-family: "DM Sans", sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 52.8px */

    @media screen and (max-width: 420px) {
      /* MOBILE/Mobile H2 */
      font-family: "DM Sans", sans-serif;
      font-size: 31px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 37.2px */
    }
  }

  h3 {
      /* DESKTOP/Desktop H3 */
    font-family: "DM Sans", sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 42px */

    @media screen and (max-width: 420px) {
      /* MOBILE/Mobile H3 */
      font-family: "DM Sans", sans-serif;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 30px */
    }
  }

  h4 {
    /* DESKTOP/Desktop H4 */
    font-family: "DM Sans", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 33.6px */

    @media screen and (max-width: 420px) {
      /* MOBILE/Mobile H4 */
      font-family: "DM Sans", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 24px */
    }
  }

  h5 {
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */

    @media screen and (max-width: 420px) {
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 16.8px */
    }
  }

  p {
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */

  @media screen and (max-width: 420px) {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    }
  }

  button {
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
  }
`;

export const styles = {
  p: {
    fontFamily: 'DM Sans, sans-serif',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '120%',
  },
  h1: {
    fontFamily: 'DM Sans, sans-serif',
    fontSize: '55px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%',
  },
  h2: {
    fontFamily: 'DM Sans, sans-serif',
    fontSize: '44px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
  },
  h3: {
    fontFamily: 'DM Sans, sans-serif',
    fontSize: '35px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
  },
  h4: {
    fontFamily: 'DM Sans, sans-serif',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
  },
  h5: {
    fontFamily: 'DM Sans, sans-serif',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%',
  },
  button: {
    fontFamily: 'DM Sans, sans-serif',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%',
  },

  mobile: {
    p: {
      fontFamily: 'DM Sans, sans-serif',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '120%',
    },
    h1: {
      fontFamily: 'DM Sans, sans-serif',
      fontSize: '39px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '120%',
    },
    h2: {
      fontFamily: 'DM Sans, sans-serif',
      fontSize: '31px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '120%',
    },
    h3: {
      fontFamily: 'DM Sans, sans-serif',
      fontSize: '25px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '120%',
    },
    h4: {
      fontFamily: 'DM Sans, sans-serif',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '120%',
    },
    h5: {
      fontFamily: 'DM Sans, sans-serif',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '120%',
    },
  },
};
