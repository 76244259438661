import styled from 'styled-components';

export const CardContent = styled.div`
    width: 100%;
    max-width: 800px;
    max-height: 200px;
    display: flex;
    /* overflow: auto; */
`;

export const ServiceImageContainer = styled.div`
    width: 100%;
    height: 100%;
    min-width: 320px;
    max-height: 200px;
    border-radius: 10px;
    border: 1px solid rgba(207, 207, 207, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${props => props.imageUrl});
`;

// export const ServiceImage = styled.img`
//     width: 100%;
//     height: 100%;
// `;

export const DetailsServiceContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
`;

export const ServiceTopic = styled.h3`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: rgba(53, 125, 255, 1);
`;

export const ServiceName = styled.h2`
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: rgba(16, 24, 40, 1);
`;

export const ServiceDescription = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(71, 84, 103, 1);
`;

export const ServiceAccessButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    width: 169px;
    height: 48px;
    padding: 18px;
    border-radius: 8px;
    color: ${props => props.hasAccess ? 'rgba(255, 255, 255, 1)' : 'rgba(5, 14, 42, 1)'};
    border: 1px solid ${props => props.hasAccess ? 'rgba(255, 255, 255, 1)' : 'rgba(5, 14, 42, 1)'};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    background: ${props => props.hasAccess ? 'rgba(5, 14, 42, 1)' : 'rgba(255, 255, 255, 1)'};

    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;

    cursor: pointer;

    &:hover {
        background: ${props => props.hasAccess ? 'rgba(255, 255, 255, 1)' : '#314277'};
        color: ${props => props.hasAccess ? 'rgba(5, 14, 42, 1)' : 'rgba(255, 255, 255, 1)'};
        border: 1px solid ${props => props.hasAccess ? 'rgba(5, 14, 42, 1)' : '#314277'};
    }
`;