import Carousel from 'react-elastic-carousel'
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Bullets = styled.div`
width: 100%;
padding-bottom: 80px;
.rec-dot {
  box-shadow: none;
  background-color: white;
  opacity: 20%;
  &_active {
    box-shadow: none;
    background-color: white;
    opacity: 100%;
  }
}
`

const HowWeWorkHome = () => {

  const { t } = useTranslation(["home"]);

    const items = [
      {
      id: 1,
      img: 'Group 251.png', 
      title: t("how-we-work.startup-title"),
      text: t("how-we-work.startup-description")
      },
      {
        id: 2,
        img: 'Group 249.png', 
        title: t("how-we-work.scale-title"),
        text: t("how-we-work.scale-description")
      },
      {
        id: 3,
        img: 'Group 252.png', 
        title: t("how-we-work.fintech-title"),
        text: t("how-we-work.fintech-description")
      },
      {
        id: 4,
        img: 'Group 235.png', 
        title: t("how-we-work.insurtech-title"),
        text: t("how-we-work.insurtech-description")
      },
    ]

  return (
    <Bullets>
      <h2 className='animated-basic' >{t("how-we-work.title")}</h2>
      {/* <p className='animated-basic' >{t("how-we-work.subtitle")}</p> */}
      <Carousel showArrows={ false } className='animated-basic' >
        {items.map(item => 
          <div id={item.id} style={{padding: '0 10px'}}>
            <img src={item.img} alt={`How we work 0 ${item.id}`}/>
            <h2>{item.title}</h2>
            <p>{item.text}</p>
        </div>
        )}
      </Carousel>
    </Bullets>
  )
}

export default HowWeWorkHome

