import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { v4 } from 'uuid';
import ReactMarkdown from 'react-markdown';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import HeaderMobile from '../components/HeaderMobile';
import Sidebar from '../components/Sidebar';
import styled from 'styled-components';
import Form from '../components/Form';
import FinishView from '../components/FinishView';
import Loader from '../components/Loader';
import MockPage from './MockPage';
import StepLoader from '../components/Loader/StepLoader';
import SVGComponent from '../components/SVGComponent';
import OnboardingPage from './OnboardingPage';
import useVersionExtractor from '../hooks/useExtractVersionFromPath';
import useNotificationPermission from '../hooks/useNotificationPermission';

const chatInteractions = [
  'Analisis de Mercado',
  'Situacion',
  'Por que ahora?',
  'Solucion',
  'Requerimientos',
  'Tecnologias a Utilizar',
  'Roadmap',
  'Equipo Requerido',
  'Informe Ejecutivo',
];

const HolaSoy = styled.span``;

const Swarm1 = styled.b``;
const GoodDesignIsContainer1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 120%;
`;
const GoodDesignIsParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0px;
`;
const ListElement = styled.li`
  list-style: disc;
`;
const ListParent = styled.ul`
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: 21px;
`;
const ListContainer = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const QuestionaryContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px 0px;
  font-size: 16px;
  color: #0e1d39 !important;
`;
const QuestionConteiner = styled.div`
  min-width: 300px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px 0px;
  padding: 72px var(--spacing-6xl, 48px) 32px var(--spacing-6xl, 48px);
  box-sizing: border-box;

  @media screen and (max-width: 420px) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
    box-sizing: border-box;
  }
`;
const ResponseConteiner = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 26px 0px;
  min-width: 300px;
  padding: 72px var(--spacing-6xl, 48px) 32px var(--spacing-6xl, 48px);
  color: #0e1d39 !important;
  @media screen and (max-width: 420px) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
    box-sizing: border-box;
  }
`;

const ResponseBodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const ResponseElement = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 10px 0px;
  font-size: 16px;
  color: #0e1d39 !important;
  & > p,
  div,
  div > p,
  ul,
  ol,
  div,
  ol > li > p,
  ul > li > p,
  ol > li > div,
  ul > li > d,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #0e1d39 !important;
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 14px 0px;
  }

  h2,
  h3,
  h4 {
    color: var(--PRIMARY-NAVY, #0e1d39);
  }
`;
const BodyContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 48px 0px;
  align-self: stretch;
  position: relative;
  overflow: hidden;
  overflow-y: auto;

  @media screen and (max-width: 420px) {
    gap: 20px 0px;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
`;

const BodyWrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: 1200px;
  padding: 32px 150px 0px 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px 0px;
  box-sizing: border-box;

  @media screen and (max-width: 420px) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
    box-sizing: border-box;
  }
`;

const ChatRoot = styled.div`
  position: relative;
  width: 100%;
  height: 100vh; // 100vh
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 40px;
  color: #0e1d39 !important;
  font-family: 'DM Sans';
  @media screen and (max-width: 420px) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

const LoaderContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Filters = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: var(
    --Component-colors-Components-Buttons-Secondary-button-secondary-fg,
    #344054
  );
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */

  transition: all 0.3s ease;

  & > span {
    color: #344054;
    cursor: pointer;
  }
`;

const InteractionsWrapper = styled.div`
  width: 600px;
  height: 300px;
  position: absolute;
  left: 0;
  top: calc(100% + 16px);
  z-index: 20;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

  gap: 16px;
  transition: all 0.3s ease;

  padding: 16px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;

  @media screen and (max-width: 420px) {
    grid-template-columns: repeat(2, 1fr);
  }

  &.hide {
    overflow: hidden;
    height: 0;
    width: 0;
    padding: 0;
    gap: 0;

    top: 0;
    z-index: -1;
  }
`;

const InteractionLabelWraper = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 16px;
  color: #0e1d39;
  cursor: pointer;
  transition: all 0.3s ease;
`;

const ChatPage = () => {
  const [permission, requestPermission] = useNotificationPermission();
  const [conversationId, setConversationId] = useState('');
  const [initialAnswer, setInitialAnswer] = useState('');
  const [idSession, setIdSession] = useState(v4());
  const [threadId, setThreadId] = useState('');
  const [threads, setThreads] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingAssistantBot, setIsFetchingAssistantBot] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isFirstTimeLogged, setIsFirstTimeLogged] = useState(true);
  const [showProps, setShowProps] = useState(false);
  const [chatConfig, setChatConfig] = useState(
    chatInteractions.reduce((acc, interaction) => {
      acc[interaction] = true;
      return acc;
    }, {})
  );
  const version = useVersionExtractor();
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const sessionStatus = sessionStorage.getItem('firstTimeLogged');
    if (!sessionStatus) {
      setIsFirstTimeLogged(false);
    }
  }, [setIsFirstTimeLogged]);

  useEffect(() => {
    if (permission === 'default') {
      requestPermission();
    }
  }, [permission, requestPermission]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  if (isFirstTimeLogged) {
    return <OnboardingPage />;
  }

  return (
    <>
      <ChatRoot>
        <HeaderMobile
          setMessages={setMessages}
          setThreadId={setThreadId}
          setIsFinished={setIsFinished}
          threads={threads}
          setThreads={setThreads}
        />
        <Sidebar
          threads={threads}
          isLoading={isLoading}
          threadId={threadId}
          conversationId={conversationId}
          setConversationId={setConversationId}
          setMessages={setMessages}
          setThreadId={setThreadId}
          setIsFinished={setIsFinished}
          setThreads={setThreads}
          setIsLoading={setIsLoading}
          setInitialAnswer={setInitialAnswer}
          setIdSession={setIdSession}
        />
        <BodyContent>
          <BodyWrapper>
            {isLoading ? (
              <StepLoader />
            ) : isFinished ? (
              <FinishView threadId={threadId} />
            ) : (
              <QuestionConteiner>
                <Filters
                  className={showProps ? 'isShow' : 'isHide'}
                  onClick={() =>
                    setShowProps((prevShowProps) => !prevShowProps)
                  }
                >
                  <span>Filtros</span>
                  <InteractionsWrapper className={showProps ? 'show' : 'hide'}>
                    {chatInteractions.map((interaction, index) => (
                      <InteractionLabelWraper key={index} htmlFor={interaction}>
                        <Checkbox
                          type="checkbox"
                          name={`${interaction}`}
                          id={`${interaction}`}
                          defaultChecked
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          onChange={(event) => {
                            setChatConfig((prevState) => ({
                              ...prevState,
                              [interaction]: event.target.checked,
                            }));
                          }}
                        />
                        {interaction}
                      </InteractionLabelWraper>
                    ))}
                  </InteractionsWrapper>
                  {showProps ? <ExpandLess /> : <ExpandMore />}
                </Filters>
                <GoodDesignIsParent>
                  <GoodDesignIsContainer1>
                    <HolaSoy>{`Prepárate para compartir toda la información que tengas sobre tu cliente. `}</HolaSoy>
                    <Swarm1>AutoSales Proposal</Swarm1>
                    <HolaSoy>
                      {' '}
                      te guiará con una serie de preguntas para entender mejor
                      tus necesidades.
                    </HolaSoy>
                  </GoodDesignIsContainer1>
                </GoodDesignIsParent>
              </QuestionConteiner>
            )}

            {messages && messages.length && !isLoading ? (
              <ResponseConteiner>
                {messages.map((item, index) => (
                  <ResponseBodyContainer key={index}>
                    {item.name === 'AutoSales Proposal' ||
                    item.name === 'You' ? (
                      <>
                        {item.name === 'AutoSales Proposal' && (
                          <SVGComponent src={'/v1/swarm/GeProLogo.svg'} />
                        )}
                        <ResponseElement>
                          <Swarm1>{item.name}</Swarm1>
                          <ReactMarkdown>{item.message}</ReactMarkdown>
                        </ResponseElement>
                      </>
                    ) : (
                      <ResponseElement>
                        <ReactMarkdown>{item.response}</ReactMarkdown>
                      </ResponseElement>
                    )}
                  </ResponseBodyContainer>
                ))}
                <div ref={messagesEndRef} />
              </ResponseConteiner>
            ) : (
              <></>
            )}

            {isFetchingAssistantBot && (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            )}

            {!isLoading && (
              <Form
                isLoading={isLoading}
                threadId={threadId}
                setIsLoading={setIsLoading}
                setThreadId={setThreadId}
                isFinished={isFinished}
                setIsFinished={setIsFinished}
                messages={messages}
                setMessages={setMessages}
                setThreads={setThreads}
                chatConfig={chatConfig}
                idSession={idSession}
                setIdSession={setIdSession}
                setIsFetchingAssistantBot={setIsFetchingAssistantBot}
                conversationId={conversationId}
                setConversationId={setConversationId}
              />
            )}
          </BodyWrapper>
        </BodyContent>
      </ChatRoot>
    </>
  );
};

export default ChatPage;