import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { device } from './device';
import { isMobile } from 'react-device-detect';

export const HomeBody = styled.div`
  /* background-image: url("Bg_dots.png") */
  overflow: hidden;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
  padding: 100px 0 40px;
  h1 {
    margin: 10px 0;
  }
  img {
    width: 60%;
  }
  & .head-line {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    align-content: center;
    padding: 10px 20px;
  }
  & .geoform-header {
    margin-top: 35px;
    width: 50%;
  }

  @media ${device.mobileL} {
    .head-line {
      padding: 10px 40px;
    }
  }

  @media ${device.tablet} {
    min-height: 508px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: flex-end;
    padding: 150px 0 3%;
    h1 {
      margin: 0;
    }
    img {
      width: 50%;
    }
    & .head-line {
      position: absolute;
      justify-content: center;
      padding: 0 50% 0 90px;
    }
    & .geoform-header {
      margin-top: 0px;
    }

    & .title-home {
      font-size: 54px;
    }
  }
`;

export const Bullets = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
  & .geo-arrow {
    max-width: 260px;
    padding-bottom: 30px;
  }
  & .bullet {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    padding: 10% 10% 62px;
  }
  .ia {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0 90px;
    h2 {
      margin: 0;
    }
    p {
      text-align: center;
      max-width: 600px;
    }
    img {
      max-width: 400px;
      margin-top: 30px;
    }
    @media (max-width: 768px) {
      margin: 40px 0;
      padding: 0 20px;
      img {
        margin-top: 10px;
        box-sizing: border-box;
        padding: 10px 30px;
        width: 100%;
      }
    }
  }
  .catalogIA {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: #ffa800;
    padding: 56px;
    margin: 90px 0;
    width: 100%;
    div:nth-child(1) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 41px;
      max-width: 500px;
    }
    h2 {
      text-align: center;
    }
    span {
      color: #2240d9;
    }
    img {
      max-width: 500px;
      margin-top: 30px;
    }
    @media (max-width: 768px) {
      width: auto;
      flex-direction: column;
      // padding: 40px 20px;
      margin-bottom: 40px;
      img {
        margin-top: 10px;
        box-sizing: border-box;
        padding: 10px 30px;
        width: 100%;
      }
    }
  }
  div:nth-child(3) {
    flex-direction: column;
  }
  h1 {
    font-size: 53px;
  }
  & .mega-title {
    width: 90%;
    text-align: center;
    line-height: 1;
    margin-top: 40px;
  }

  @media ${device.tablet} {
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    padding: 25% 0 5%;
    position: relative;
    h1 {
      font-size: 180px;
      font-weight: 500;
      margin-top: 1%;
    }
    & .bullet {
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      padding: 3% 15%;
    }
    img {
      max-width: 400px;
      height: auto;
    }
    & .geo-arrow {
      max-width: 300px;
      padding-bottom: 0px;
    }
    div:nth-child(3) {
      flex-direction: row;
    }
    & .text-bullet {
      width: 45%;
    }
    & .mega-title {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      position: absolute;
      line-height: '80px' !important;
      margin-top: '1%' !important;
      top: 87px;
      width: 3000px;
      margin-top: 0px;
    }

    & .mob-mega {
      font-size: 70px;
    }
  }
`;

export const HowWeWork = styled(Bullets)`
  padding: 54px 30px 0px;
  & .points {
    padding: 0 0 50px;
  }
  & .how-we-work {
    display: none;
  }
  & .img-desktop {
    display: none;
  }
  & .img-mobile {
    width: 100%;
  }

  @media ${device.tablet} {
    padding: 10%;
    padding-top: 100px;
    & .points {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: space-between;
      padding: 3% 15%;
    }
    & .how-we-work {
      display: block;
    }
    & .img-desktop {
      display: block;
      width: 50%;
      padding: 0 0 0 5%;
    }
    & .img-mobile {
      display: none;
    }
    h2,
    p,
    h3 {
      margin: 0.5rem 0;
    }
  }
`;

export const OurServices = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  /* overflow-x: hidden;
	box-sizing: border-box; */
  position: relative;
  padding: 80px 8% 20px;
  height: ${isMobile ? 'auto' : '500px'};
  p {
    text-align: left;
  }
  img {
    width: 100%;
  }
  & .services-head {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
    /* padding-bottom: 5%; */
    text-align: center;
    padding-bottom: 80px;
  }
  h2 {
    margin: 0;
  }
  & .service {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  & .service-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 139px;
    padding-bottom: 40px;
    h4 {
      text-align: center;
      font-size: 18px;
    }
  }
  & .services-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
  }
  @media ${device.tablet} {
    padding: 0 0 50px;
    /* img {
			width: 70%;
		} */
    h2 {
      margin: 0;
    }
    p {
      text-align: center;
      width: 70%;
    }
    img {
      width: 73%;
    }
    & .service-type {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      /* width: 100vw; */

      position: absolute;
      width: 2000px;
      margin-top: 250px;
    }
    & .services-head {
      align-items: center;
      padding-bottom: 0px;
    }
  }
`;

export const Testimonies = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  background-color: #00aa58;
  h2 {
    color: black;
    margin: 30px 0;
  }
  h3 {
    margin: 10px 0 30px;
    color: black;
    font-size: 20px;
    line-height: 26.04px;
  }
  h4 {
    margin: 0;
    color: black;
    font-weight: 500;
    font-size: 20px;
    line-height: 26.04px;
  }
  & .our-partners {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: space-around;
    padding: 15% 8%;
  }
  & .testimony {
    display: none;
  }
  & .testimony-mobile {
    display: flex;
    align-items: baseline;
    flex-direction: column;
  }
  & .testimony-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 0 0 90px;
  }
  & .logo-partner {
    height: 22px;
    margin-right: 10px;
  }
  & .arrow {
    width: 40%;
    margin: 0 5% 0;
    cursor: pointer;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: space-between;
    padding: 10% 10%;
    h2 {
      margin: 0.5rem 0.5rem 3rem;
    }
    h3 {
      font-size: 40px;
      line-height: 47.3px;
    }
    h4 {
      font-size: 24px;
      line-height: 31.25px;
      font-weight: 500;
      margin: 0.5rem;
    }
    & .testimony {
      display: flex;
      align-items: baseline;
      flex-direction: column;
      padding: 5%;
    }
    & .testimony-mobile {
      display: none;
    }
    & .our-partners {
      display: flex;
      align-items: baseline;
      flex-direction: column;
      justify-content: space-around;
      padding: 5%;
    }
    & .testimony-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 0 0 0.5rem;
    }
    & .arrow {
      width: 40%;
      margin: 5%;
    }
  }
`;

export const FeaturedProjects = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 80px 30px 20px;
  h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24.44px;
    margin: 10px 0;
  }
  img {
    box-sizing: border-box;
    width: 100%;
  }
  p {
    margin: 0 0 60px;
  }
  & .case-study-mobile {
    display: none;
  }
  .jss1 {
    overflow: visible !important;
  }
  h2 {
    font-size: 40px;
    line-height: 50px;
    margin: 0 0 20px;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    padding: 0px 225px;
    img {
      box-sizing: border-box;
      width: 100%;
    }
    h2 {
      font-size: 64px;
      line-height: 72px;
      margin: 40px 0;
    }
    h4 {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.25px;
      margin: 0 0 10px;
    }
    p {
      margin: 0;
    }
    & .title-featured-projects {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 80px;
      padding-top: 40px;
    }
    & .case-study {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: baseline;
      padding: 2% 0% 0%;
      height: auto;
    }
    & .case-description {
      padding: 0% 0% 8%;
    }
    & .case-study-mobile {
      display: inline;
    }
  }
`;

export const CaseStudiesLink = styled(Link)`
  color: #fff;
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  padding: 8px 0;
  cursor: pointer;
  & .arrow {
    padding-top: 3px;
    padding-left: 5px;
  }

  @media ${device.tablet} {
    align-items: flex-end;
    height: 50px;
    align-content: center;
    & .arrow {
      height: 24px;
      padding-top: 0px;
      padding-left: 9px;
    }
    span {
      align-items: center;
    }
    p {
      padding-bottom: 6px;
    }
  }
`;

export const Blog = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 8% 80px;
  img {
    width: 100%;
    padding-bottom: 50px;
  }
  div {
    text-align: center;
    max-width: 500px;
  }
  h2 {
    margin: 0px 0px 20px;
  }
  p {
    padding-bottom: 20px;
  }
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px 8% 50px;
    img {
      width: 45%;
      max-width: 850px;
      padding-left: 50px;
      padding-bottom: 0px;
    }
    div {
      text-align: center;
      max-width: 500px;
    }
    h2 {
      margin: 0px 0px 20px;
    }
    p {
      padding-bottom: 30px;
    }
  }
`;

export const BlogLink = styled.a`
  padding: 10px 30px;
  min-width: 75px;
  text-align: center;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  margin-left: 0px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
  @media ${device.tablet} {
    padding: 14px 46px;
    border: 2px solid white;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    margin-left: 24px;
    &:hover {
      transition: all 0.2s ease-in-out;
      background: #fff;
      color: #010606;
    }
  }
`;
