import { createGlobalStyle } from 'styled-components';

export const MarketplaceStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

  * {
  margin: 0;
  padding: 0;
}

body {
    font-family: Inter, sans-serif;
    color: rgba(16, 24, 40, 1);
    background-color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: 'liga', 'kern';
    font-feature-settings: 'liga', 'kern';
    -webkit-font-variant-ligatures: common-ligatures;
    font-variant-ligatures: common-ligatures;
    }
`;