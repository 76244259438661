import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const PartnersMobile = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  background-color: #00aa58;
  text-align: center;
  align-items: center;
  padding: 60px 20px;
  box-sizing: border-box;
  .rec-dot {
    box-shadow: none;
    background-color: white;
    opacity: 20%;
    &_active {
      box-shadow: none;
      background-color: white;
      opacity: 100%;
    }
  }
  h2 {
    width: 245px;
  }
`;
const items = ["/partners01.svg", "/partners02.svg", "/partners03.svg"];

const TestimoniesHome = () => {
  const { t } = useTranslation(["home"]);

  const renderLogos = () => {
    if (isMobile) {
      return (
        <PartnersMobile>
          <h2>{t("testimonies.title")}</h2>
          <Carousel showArrows={false} className="animated-basic">
            {items.map((item) => (
              <img src={item} alt="partners" />
            ))}
          </Carousel>
        </PartnersMobile>
      );
    }
    return (
      <img
        src={t("testimonies.img")}
        alt="Partners"
        style={{ width: "100%", height: "auto" }}
      />
    );
  };

  return <>{renderLogos()}</>;
};

export default TestimoniesHome;
