import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Carousel from 'react-material-ui-carousel';
import ArrowLeft from '../assets/carrusel/leftarrow.svg';
import Pause from '../assets/carrusel/pause.svg';
import PaginationBullets from '../../NewRocket/components/PaginationBullets/index.jsx';
import coru from '../assets/companies/coru.png';
import creze from '../assets/companies/Creze.png';
import numina from '../assets/companies/Numina.png';
import tribal from '../assets/companies/tribal.png';

const CompanyCarrusel = () => {
  const items = [
    {
      img: coru,
      content: {
        text: "Coru is a financial services technology platform that profiles users so that they connect with the ideal product through a financial gym analogy and conversational intelligence that promotes users' financial education.",
        bullets: [
          '70% more user acquisition.',
          'Connection with the most important financial and insurance institutions in Mexico.',
          'Free financial education for all Mexicans.',
        ],
      },
    },
    {
      img: numina,
      content: {
        text: 'Numina is a platform that allows companies to make available to their collaborators the advance collection of their accrued salary. To achieve this, an improvement was made in the processes and the implementation of custom APIs.',
        bullets: [
          'We improve the UX and UI of the platform for two types of users',
          'We fixed the security risks of the platform and changed the banking provider',
          'We implemented a new onboarding campaign resulting in a 500% growth',
        ],
      },
    },
    {
      img: tribal,
      content: {
        text: 'Tribal drives growth for startups and SMEs through corporate cards. The implementation of a CMS was carried out to manage Marketing content in an agile way. SEO and performance optimization. All this considering an ideal experience for the Tribal user-person.',
        bullets: [
          'UX improvement through a friendly dashboard',
          'We focus on user acquisition through Growth Hacking',
          'Thanks to the country in a box concept we serve the South American market',
        ],
      },
    },
    {
      img: creze,
      content: {
        text: 'Creze is the platform that helps SMEs to obtain loans, 100% online. It is a modular platform for risk assessment, pre-approval, assignment and credit maintenance. The challenge was to automate the credit origination process and improve the visual interface of the product.',
        bullets: [
          'Loan placement increased by 80%',
          'We automate 90% of credit approval',
          'Connection with the most important credit bureaus',
        ],
      },
    },
  ];

  const [activeLikeBullet, setActiveLikeBullet] = useState(0);

  const [width, setWidth] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(false);
  const [isClickable, setIsClickable] = useState(true);
  const [autoPlay, setAutoPlay] = useState(true);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    // Limpia el evento cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // efecto a ejecutar
    if (width < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    setActiveLikeBullet(0);
  }, [width]);

  const blockClicksTemporarily = (callback, delay) => {
    if (!isClickable) return;
    setIsClickable(false);
    callback();
    setTimeout(() => setIsClickable(true), delay);
  };

  const PrevPage = (length) => {
    if (!autoPlay) {
      blockClicksTemporarily(() => {
        setActiveLikeBullet((prevActiveLikeBullet) => {
          if (prevActiveLikeBullet > 0) {
            return prevActiveLikeBullet - 1;
          } else {
            return length - 1;
          }
        });
      }, 500);
    }
  };

  const NextPage = (length) => {
    if (!autoPlay) {
      blockClicksTemporarily(() => {
        setActiveLikeBullet((prevActiveLikeBullet) => {
          if (prevActiveLikeBullet < length - 1) {
            return prevActiveLikeBullet + 1;
          } else {
            return 0;
          }
        });
      }, 500);
    }
  };

  const renderContent = (item) => {
    return (
      <OptionsContent>
        <>
          <Carousel
            activeIndicatorIconButtonProps={{
              style: { color: 'blue' },
            }}
            animation='slide'
            interval={5000}
            autoPlay={autoPlay}
            indicators={false}
            navButtonsAlwaysInvisible
            index={activeLikeBullet}
            onChange={(index) => setActiveLikeBullet(index)}
          >
            {item.map((item, i) => (
              <>
                <ItemsContent>
                  <ImgContainer>
                    <ImageComponent key={i} src={item.img} />
                  </ImgContainer>
                  <Text size='16px'>{item.content.text}</Text>
                  <ul
                    style={{
                      listStyle: 'inside',
                      fontFamily: 'DM Sans',
                      fontWeight: '400',
                      fontSize: '16px',
                    }}
                  >
                    {item.content.bullets.map((bullet, i) => (
                      <li key={i}>{bullet}</li>
                    ))}
                  </ul>
                </ItemsContent>
              </>
            ))}
          </Carousel>
          <PaginationSection>
            <PaginationContainer>
              <PlayPause src={Pause} onClick={() => setAutoPlay(!autoPlay)} />
              <PaginationBullets
                onHandleBulletClick={(indexBullet) => {
                  setActiveLikeBullet(indexBullet);
                }}
                totalSlides={items.length}
                rootActiveBullet={activeLikeBullet}
                activeBulletColor='#A6F673'
                bulletColor='#D4DEE9'
                notClickable
              />
            </PaginationContainer>
            <Circle autoPlay={autoPlay} onClick={() => PrevPage(items.length)}>
              <img src={ArrowLeft} alt='line' style={{ height: '22px' }} />
            </Circle>
            <Circle autoPlay={autoPlay} onClick={() => NextPage(items.length)}>
              <img
                src={ArrowLeft}
                alt='line'
                style={{ height: '22px', transform: 'rotate(180deg)' }}
              />
            </Circle>
          </PaginationSection>
        </>
      </OptionsContent>
    );
  };

  return (
    <GeneralContainer>
      <ContainerCarousel>
        <ContentCarousel>
          <Content2>{renderContent(items)}</Content2>
        </ContentCarousel>
      </ContainerCarousel>
    </GeneralContainer>
  );
};

export default CompanyCarrusel;

const GeneralContainer = styled.div`
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Text = styled.p`
  font-family: ${(props) => props.font || 'DM Sans'};
  font-size: ${(props) => props.size || '24px'};
  font-weight: ${(props) => props.weight || '400'};
  color: ${(props) => props.color || '#fff'};
  margin: 0;
  line-height: ${(props) => props.line};
  @media screen and (max-width: 1023px) {
    font-size: ${(props) => props.msize || '16px'};
  }
`;

const RatedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 26px;
  align-items: center;
  font-family: 'Red Hat Text';
  font-size: 44px;
  font-color: #fff;
  font-weight: 500;
  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

const RatedImg = styled.img`
  height: 30px;
  @media (max-width: 1024px) {
    height: 15px;
  }
`;

const PlayPause = styled.img`
  border-radius: 50%;
  width: 25px;
  &:hover {
    cursor: pointer;
    background-color: rgb(94 111 141);
  }
  fill: #a6f673;
`;

const Circle = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #395280;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: ${({ autoPlay }) => (autoPlay ? 'brightness(0.7)' : '')};
  &:hover {
    cursor: pointer;
    filter: ${({ autoPlay }) =>
      autoPlay ? 'brightness(0.7)' : 'brightness(1.2)'};
  }
`;

const PaginationContainer = styled.div`
  width: auto;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
  background-color: #395280;
  height: 44px;
  align-items: center;
  display: flex;
`;

const PaginationSection = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 10px;
`;

const ContainerCarousel = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 28.937px;
  flex-shrink: 0;
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const HeaderCarousel = styled.div`
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  align-self: stretch;
  @media (max-width: 1024px) {
    align-items: flex-start;
    padding: 54px 30px 0px;
  }
`;

const TypographyCompanies = styled.div`
  width: auto;
  color: #fff;
  text-align: center;
  font-family: 'Red Hat Text';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const TypographyWork = styled.div`
  width: 306px;
  color: #fff;
  text-align: center;
  font-family: 'Red Hat Text';
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media (max-width: 1024px) {
    width: auto;
    font-size: 24px;
    min-width: 92px;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  gap: 32px;
`;

const OptionsHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  gap: 10px;
`;

const ItemsHeader = styled.div`
  display: flex;
  width: 100%;
  min-height: 22px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  color: ${({ active }) => (active ? '#fff' : '#395280')};
  text-align: left;
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding: 5px 10px;
  border-radius: 5px;
  &:hover {
    color: ${({ active }) => (active ? '#fff' : '#6D80A3')};
  }
  @media (max-width: 1024px) {
    font-size: 10px;
  }
`;

const ContentCarousel = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  height: auto;
  padding: 10px;
  justify-content: center;
  align-items: start;
  flex-shrink: 0;
  @media (max-width: 1024px) {
    align-items: start;
    height: auto;
  }
`;

const Content2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  width: 100%;
  @media (max-width: 1024px) {
    gap: 24px;
    min-height: 0;
    flex-direction: column;
  }
`;

const OptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  .Carousel-root-1 {
    width: 100%;
  }
`;

const TitleContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  color: #ffa800;
  text-align: center;
  font-family: 'Red Hat Text';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

const ItemsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
`;

const ImageComponent = styled.img`
  width: 100%;
`;

const Iconimg = styled.img`
  max-width: 65px;
  max-height: 65px;
  @media (max-width: 1024px) {
    max-width: 35px;
    max-height: 35px;
  }
`;
