/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

const WhatsappButton = () => {
  const displayButton =
    window.location.pathname === '/v1/catalogos' ||
    window.location.pathname === '/v1/catalogos-demo' ||
    window.location.pathname === '/v1/contact-ai2';

  return (
    <>
      {displayButton ? (
        <>
          <StyledWhatsappButton
            href='https://api.whatsapp.com/send?phone=5215652791932&text=Quiero%20descubrir%20c%C3%B3mo%20CatalogAI%20puede%20ayudarme%20a%20mejorar%20mis%20procesos%20de%20emisi%C3%B3n%20de%20p%C3%B3lizas%20de%20seguros%20al%20eliminar%20el%20proceso%20de%20homologar%20manualmente%20mis%20cat%C3%A1logos.%20%C2%BFCu%C3%A1les%20son%20las%20caracter%C3%ADsticas%20y%20beneficios%20que%20ofrece%3F'
            target='_blank'
          >
            <StyledImage alt='' src='/v1/whatsapp.svg' />
          </StyledWhatsappButton>
        </>
      ) : null}
    </>
  );
};

export default WhatsappButton;

const StyledWhatsappButton = styled.a`
  position: fixed;
  bottom: 56px;
  right: 56px;
  z-index: 5;
  width: 84px;
  animation-name: example;
  animation-duration: 1.5s;

  @keyframes example {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    bottom: 16px;
    right: 16px;
    width: 42px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  cursor: pointer;
`;
