import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Arrow from '../assets/carrusel/leftarrow.svg';
import { useHistory } from 'react-router-dom';
import { sendEmailWithTemplate } from '../../../utils/sendEmail';
import { submitGTM } from '../../../utils/submitGTM';
import { useApolloActions } from '../../../hooks/useApolloActionsContact';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(-200px);
  }
`;

const Interactiveform = () => {
  const [enProceso, setEnProceso] = useState(false);
  const [step, setStep] = useState(0);
  const [form, setForm] = useState({
    name: '',
    phone: '',
    email: '',
  });

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const history = useHistory();

  const formName = "Clutch Form";
  const templateID = "template_jjjyd38";

  const { handleApolloActions } = useApolloActions(form, formName);

  const next = async () => {
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (step === 0 && form.name !== '' && form.name.length >= 2) {
      setEnProceso(true);
      setTimeout(() => {
        setEnProceso(false);
        setStep(1); // Se cambió la coma por un punto y coma
        input2Ref.current.focus();
      }, 300);
    }
    if (step === 1 && form.phone !== '' && phoneRegex.test(form.phone)) {
      setEnProceso(true);
      setTimeout(() => {
        setEnProceso(false);
        setStep(2); // Se cambió la coma por un punto y coma
        input3Ref.current.focus();
      }, 300);
    }
    if (step === 2 && form.email !== '' && emailRegex.test(form.email)) {
      setEnProceso(true);
      setTimeout(async () => {
        setEnProceso(false);
        setStep(3); // Se cambió la coma por un punto y coma

        try {
          await handleApolloActions();
          await sendEmailWithTemplate(form, formName, templateID);
          submitGTM();
        } catch (error) {
          console.error("Error en la operación:", error);
        }
      }, 300);
    }
  };

  useEffect(() => {
    const phonereg = form.phone.slice(0, 10).replace(/\D/g, '');
    setForm({ ...form, phone: phonereg });
  }, [form.phone]);

  useEffect(() => {
    const namereg = form.name.replace(/\d/g, '');
    console.log('funcionando', namereg);
    setForm({ ...form, name: namereg });
  }, [form.name]);

  return (
    <>
      <Container>
        <FormTitle>Let's get in touch to support you and your team!</FormTitle>
        <InputLabelContainer>
          {step === 0 && (
            <FadeInContainer animar={enProceso}>
              <Label htmlFor='name'>What’s your name?</Label>
              <InputButtonContainer>
                <Input
                  type='text'
                  id='name'
                  name='name'
                  placeholder='Name'
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                  onKeyDown={(e) => e.key === 'Enter' && next()}
                  ref={input1Ref}
                  value={form.name}
                />
                <Button onClick={next}>
                  <img src={Arrow} alt='arrow' />
                </Button>
              </InputButtonContainer>
            </FadeInContainer>
          )}
          {step === 1 && (
            <FadeInContainer animar={enProceso}>
              <Label htmlFor='name'>Your cellphone...</Label>
              <InputButtonContainer>
                <Input
                  type='text'
                  id='phone'
                  name='phone'
                  placeholder='phone'
                  onChange={(e) => setForm({ ...form, phone: e.target.value })}
                  onKeyDown={(e) => e.key === 'Enter' && next()}
                  ref={input2Ref}
                  value={form.phone}
                />
                <Button onClick={next}>
                  <img src={Arrow} alt='arrow' />
                </Button>
              </InputButtonContainer>
            </FadeInContainer>
          )}
          {step === 2 && (
            <FadeInContainer animar={enProceso}>
              <Label htmlFor='name'>And your email...</Label>
              <InputButtonContainer>
                <Input
                  type='text'
                  id='email'
                  name='email'
                  placeholder='email'
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  onKeyDown={(e) => e.key === 'Enter' && next()}
                  ref={input3Ref}
                />
                <Button onClick={next}>
                  <img src={Arrow} alt='arrow' />
                </Button>
              </InputButtonContainer>
            </FadeInContainer>
          )}
          {step === 3 && (
            <FadeInContainer
              animar={enProceso}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '16px',
              }}
            >
              <Text
                color='#00AA58'
                family='Red Hat Text'
                size='60px'
                weight='500'
                line='72px'
                msize='44px'
              >
                Thanks!
              </Text>
              <Text size='16px'>We will contact you.</Text>
            </FadeInContainer>
          )}
        </InputLabelContainer>
      </Container>
    </>
  );
};

export default Interactiveform;

const FormTitle = styled.h3`
  margin: 0;
  line-height: 24px;
  font-family: 'Red Hat Text';
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  span {
    color: #a6f674;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
`;

const Input = styled.input`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 16px 32px;
  width: 100%;
  max-width: 311px;
  background: #fff;
  border: none;
  border-radius: 8px;
  padding: 16px;
  margin: 0 0 16px 0;
  outline: none;
  margin: 0;
  :focus-visible {
    outline: none;
  }
`;

const InputButtonContainer = styled.div`
  display: flex;
  gap: 23px;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const Label = styled.label`
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 16px;
  color: #fff;
`;

const Button = styled.button`
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  justify-content: center;
  align-items: center;
  display: flex;
  img {
    height: 15px;
    transform: rotate(180deg);
  }
`;

const FadeInContainer = styled.div`
  animation: ${({ animar }) => (animar ? fadeOut : fadeIn)} 0.35s ease-out;
`;

const Text = styled.p`
  font-family: ${(props) => props.font || 'DM Sans'};
  font-size: ${(props) => props.size || '24px'};
  font-weight: ${(props) => props.weight || '400'};
  color: ${(props) => props.color || '#fff'};
  line-height: ${(props) => props.line};
  margin: 0;
  @media screen and (max-width: 1023px) {
    font-size: ${(props) => props.msize || '16px'};
  }
`;
