const SvgHowWeWork01 = () => {
  return (
    <div className="img-desktop">
      <svg width="520" height="400" viewBox="0 0 620 500" fill="none" >
        <path className="geo-figure" d="M410.39 401.771L411.257 401.771L411.69 401.019L584.299 101.333L585.594 99.0848L582.999 99.0848L236.11 99.0848L235.243 99.0848L234.81 99.8362L62.2011 399.522L60.9059 401.771L63.5009 401.771L410.39 401.771Z" stroke="white" strokeWidth="3"/>
        <rect className="geo-figure" x="113.361" y="91" width="319.495" height="319.495" rx="159.747" fill="#FFA800"/>
        <svg className="geo-figure">
          <path d="M310.112 249.975C310.068 262.352 303.994 273.309 294.67 280.059C292.681 281.496 291.479 283.776 291.479 286.229V297.429C291.479 302.025 287.754 305.75 283.158 305.75H262.529C257.933 305.75 254.208 302.025 254.208 297.429V286.229C254.208 283.772 252.997 281.488 251.005 280.047C241.652 273.273 235.57 262.267 235.57 249.837C235.57 229.082 252.544 212.286 273.372 212.574C293.702 212.861 310.185 229.645 310.112 249.975Z" stroke="black" strokeWidth="3"/>
          <path d="M272.841 198.645V174.708" stroke="black" strokeWidth="3"/>
          <path d="M221.646 249.85H197.708" stroke="black" strokeWidth="3"/>
          <path d="M347.983 249.85H324.045" stroke="black" strokeWidth="3"/>
          <path d="M325.977 196.713L309.048 213.642" stroke="black" strokeWidth="3"/>
          <path d="M234.362 213.642L217.434 196.713" stroke="black" strokeWidth="3"/>
          <path d="M254.208 291.347H291.479" stroke="black" strokeWidth="3"/>
        </svg>
      </svg>
    </div>
  )
}

export default SvgHowWeWork01
