import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const SwarmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  height: 119px;
  padding: 10px 120px var(--spacing-none, 0px) 120px;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-shrink: 0;
  background: var(--PRIMARY-NAVY, #0e1d39);

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 119px;
    padding: 10px 20px var(--spacing-none, 0px) 20px;
    align-items: center;
    gap: 10px;

    color: var(--White, #fff);
    font-family: 'DM Sans';
    font-size: 25.635px;
    font-style: normal;
    font-weight: 500;
    line-height: 30.762px; /* 120% */
    letter-spacing: -0.513px;
  }
`;

const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const HeaderTittle = styled.h1`
  color: var(--White, #fff);
  font-family: 'DM Sans';
  font-size: 25.635px;
  font-style: normal;
  font-weight: 500;
  line-height: 30.762px; /* 120% */
  letter-spacing: -0.513px;

  @media screen and (max-width: 768px) {
    color: var(--White, #fff);
    font-family: 'DM Sans';
    font-size: 25.635px;
    font-style: normal;
    font-weight: 500;
    line-height: 30.762px; /* 120% */
    letter-spacing: -0.513px;
  }
`;

const HeaderButton = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--SECONDARY-GREEN, #00aa58);
  color: var(--White, #fff);

  font-family: 'DM Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 112%; /* 17.92px */

  outline: none;
  border: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const BurgerImg = styled.img`
  display: none;

  height: 40px;
  width: 40px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const SwarmLayout = ({ children }) => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push('/v1/swarm/login');
  };

  const handleHomeClick = () => {
    history.push('/v1/swarm');
  };

  return (
    <SwarmWrapper>
      <Header>
        <HeaderLogo onClick={handleHomeClick}>
          <img alt="logo" src="/v1/simple-color.svg" />
          <HeaderTittle>rocket code</HeaderTittle>
        </HeaderLogo>

        <HeaderButton onClick={handleButtonClick}>Crear cuenta</HeaderButton>

        <BurgerImg alt="" src={'/v1/menu-hamburguesa.svg'} />
      </Header>
      {children}
    </SwarmWrapper>
  );
};

export default SwarmLayout;
