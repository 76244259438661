import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`    
* {
     box-sizing: border-box;
}

body {
    margin: 0; line-height: normal;
  }
:root {

/* fonts */
--common-small-title: 'DM Sans';
--text-regular-normal: Roboto;
--desktop-h2: 'Red Hat Text';

/* font sizes */
--common-small-title-size: 16px;
--desktop-caption-size: 10px;
--common-title-size: 20px;
--desktop-small-body-size: 12px;
--desktop-h2-size: 44px;
--desktop-h4-size: 28px;
--desktop-h3-size: 35px;
--font-size-3xl-1: 22.1px;
--font-size-19xl-7: 38.7px;
--desktop-h1-size: 55px;

/* Colors */
--neutral-white: #fff;
--black: #000;
--secondary-blue: #2240d9;
--primary-navy: #0e1d39;
--secondary-red: #ef0000;
--secondary-yellow: #ffa800;
--secondary-green: #00aa58;

/* Gaps */
--gap-3xs: 10px;
--gap-8xs: 5px;
--gap-0: 0px;
--gap-13xl: 32px;
--gap-base: 16px;
--gap-11xl: 30px;
--gap-7xs: 6px;
--gap-mini: 15px;
--gap-45xl: 64px;
--gap-5xl: 24px;
--gap-5xs: 8px;
--gap-29xl: 48px;

/* Paddings */
--padding-base: 16px;
--padding-13xl: 32px;
--padding-5xs: 8px;
--padding-xl: 20px;
--padding-45xl: 64px;
--padding-101xl: 120px;
--padding-183xl: 202px;
--padding-31xl: 50px;
--padding-5xl: 24px;
--padding-61xl: 80px;
--padding-21xl: 40px;
--padding-11xl: 30px;

/* Border radiuses */
--br-5xs: 8px;

}
`;
