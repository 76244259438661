import styled from "styled-components";
import { useHistory } from 'react-router-dom'; 

const Text1 = styled.b`
  position: relative;
  font-size: 18px;
  line-height: 120%;
  font-family: "DM Sans";
  color: #fff;
  text-align: left;
`;
const Iconsdropright = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  display: none;
`;
const ButtonRoot = styled.button`
  cursor: pointer;
  border: none;
  padding: 8px 16px;
  background-color: #2240d9;
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const Button = () => {

  const history = useHistory(); 

  const handleLogout = () => {
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userPassword');
    history.push('/v1/login');
  };

  return (
    <ButtonRoot onClick={handleLogout}>
      <Text1>Cerrar sesión</Text1>
      <Iconsdropright alt="" src="/v1/iconsdropright.svg" />
    </ButtonRoot>
  );
};

export default Button;
