import styled from 'styled-components';
import PropTypes from 'prop-types';

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  width: 100%;
`;

const RectangleParent = styled.input`
  width: 100%;
  min-height: 48px;
  padding: 0px 16px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #395280;
  box-sizing: border-box;
  background: none;
  caret-color: #fff;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: #fff;
  font-size: 16px;
`;

const MessageDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  text-align: center;
  font-size: 16px;
`;

const Message = styled.p`
  width: 100%;
  text-align: left;
  color: var(--NEUTRAL-GRAY, #9a9a9a);
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const LoginFormInput = ({
  requiredFilds,
  setRequiredFilds,
  label,
  name,
  id,
  placeholder,
  ...rest
}) => {
  return (
    <Component>
      <RectangleParent
        name={name}
        id={id}
        placeholder={placeholder || label}
        {...rest}
        onFocus={() =>
          setRequiredFilds((prev) => [...prev.filter((item) => !item[name])])
        }
      />
      {requiredFilds.some((item) => Object.keys(item).includes(name)) && (
        <MessageDiv>
          <Message>
            {
              requiredFilds.find((item) => Object.keys(item).includes(name))[
                name
              ]
            }
          </Message>
        </MessageDiv>
      )}
    </Component>
  );
};

LoginFormInput.propTypes = {
  requiredFilds: PropTypes.array,
  setRequiredFilds: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default LoginFormInput;
