import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import { login, registerAccount } from '../../services/swarm.service';
import LoginFormInput from '../../components/RegisterInput';
import useVersionExtractor from '../../hooks/useExtractVersionFromPath';

const FormWraper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  min-height: 100vh;
  background-color: #0e1d39;
  padding: 16px;
  color: #fff;
  font-family: 'DM Sans';
`;

const RigthAlignContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 16px;
  width: 100%;

  & a {
    margin: 0;
    padding: 0;
    height: fit-content;
    color: #2240d9;
  }
`;

const CenterAlignContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
  width: 100%;
`;

const GoodDesignIs = styled.div`
  line-height: 39.31px;
  font-size: 32.8px;
  font-weight: 500;
  display: flex;
  align-items: center;

  color: var(--NEUTRAL-WHITE, #fff);
  font-family: 'DM Sans';
  font-size: 32.761px;
  font-style: normal;
  font-weight: 700;
  line-height: 39.313px; /* 120% */
  letter-spacing: -0.655px;
`;
const SimpleColorIcon = styled.img`
  display: flex;
  width: 60px;
  height: 63px;
`;
const FullLogoColorDark = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
`;
const Title = styled.b`
  line-height: 120%;
  font-size: 16px;

  color: var(--NEUTRAL-WHITE, #fff);
  text-align: center;

  /* COMMON/Title */
  font-family: 'DM Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
`;
const WelcomeMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 100%;
`;

const LabelPasswordRemember = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 16px;
  color: #9a9a9a;
  font-family: 'DM Sans';
  font-weight: 400;
  line-height: 120%;
  text-align: left;
  width: 100%;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

// const Checkbox = styled.input`
//   display: block;
//   width: 24px;
//   height: 24px;
//   cursor: pointer;
//   opacity: 0.5;

//   border-radius: 4px;
//   border: 2px solid #9a9a9a;

//   background: transparent;
// `;

const Checkbox = styled.input`
  display: none;

  & + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: -12px;
      width: 24px;
      height: 24px;
      border: 2px solid #9a9a9a;
      border-radius: 4px;
      background: transparent;
    }
    &:after {
      content: '';
      position: absolute;
      left: 6px;
      top: -6px;
      width: 15px;
      height: 15px;
      border-radius: 2px;
      background: #ffa800;
      transform: scale(0);
      transition: all 0.3s ease-in-out;
    }
  }

  &:checked + label:after {
    transform: scale(1);
  }
  &:checked + label:before {
    border-color: #ffa800;
  }
`;

const Textfieldoutlined = styled.div`
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  text-align: left;
  color: #9a9a9a;
`;

const BlueStyledText = styled.span`
  color: var(--SECONDARY-BLUE, #2240d9);
  text-align: right;

  /* COMMON/Body */
  font-family: 'DM Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.16px;
  cursor: pointer;
`;

const Buttons = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  color: #2240d9;
  width: 100%;
`;

const LoginFormRoot = styled.form`
  flex: 1;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(76, 78, 100, 0.22);
  border: 1px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 24px;
  gap: 24px;
  min-width: 300px;
  max-width: 400px;
  text-align: left;
  color: #fff;
  font-family: 'DM Sans';
`;

const Button = styled.button`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  gap: 16px;
  text-align: left;
  font-size: 18px;
  font-family: 'DM Sans';
  border: none;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #fff;
  background-color: ${(props) =>
    props.variant === 'secondary' ? 'transparent' : '#2240d9'};
  border: ${(props) =>
    props.variant === 'secondary' ? '1px solid #2240d9' : 'none'};

  &:hover {
    background-color: #2240d9;
    border: ${(props) =>
      props.variant === 'secondary' ? '1px solid #2240d9' : 'none'};
  }
`;

const StatusMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  text-align: center;
  font-size: 16px;
`;

const SessionForm = () => {
  const version = useVersionExtractor();
  const location = useLocation();
  const history = useHistory();
  // const [isRegister, setIsRegister] = useState(false);
  const [formType, setFormType] = useState({
    type: 'login',
    title: '¡Bienvenido! Inicia sesión',
    buttonText: 'Iniciar sesión',
  });
  const [formFields, setFormFields] = useState([
    { name: 'name', type: 'text', placeholder: 'Nombre', id: 'name' },
  ]);
  const [sessionStatus, setSessionStatus] = useState('');
  const [requiredFilds, setRequiredFilds] = useState([]);

  useEffect(() => {
    switch (location.pathname) {
      case `/v1/${version}/register`:
        setFormFields([
          { name: 'name', type: 'text', placeholder: 'Nombre', id: 'name' },
          { name: 'email', type: 'email', placeholder: 'Correo', id: 'email' },
          {
            name: 'password',
            type: 'password',
            placeholder: 'Contraseña',
            id: 'password',
          },
          {
            name: 'password-repeat',
            type: 'password',
            placeholder: 'Contraseña',
            id: 'password-repeat',
          },
        ]);
        setFormType({
          type: 'register',
          title: 'Crea tu cuenta',
          buttonText: 'Crear cuenta',
        });
        // setIsRegister(true);
        break;
      case `/v1/${version}/login`:
        setFormFields([
          { name: 'email', type: 'email', placeholder: 'Correo', id: 'email' },
          {
            name: 'password',
            type: 'password',
            placeholder: 'Contraseña',
            id: 'password',
          },
        ]);
        setFormType({
          type: 'login',
          title: '¡Bienvenido! Inicia sesión',
          buttonText: 'Iniciar sesión',
        });
        // setIsRegister(false);
        break;
      case `/v1/${version}/forgot-password`:
        setFormFields([
          { name: 'email', type: 'email', placeholder: 'Correo', id: 'email' },
        ]);
        setFormType({
          type: 'forgot-password',
          title: '¿Olvidaste tu contraseña?',
          buttonText: 'Enviar',
        });
        // setIsRegister(false);
        break;
      default:
        // setIsRegister(false);
        break;
    }
    setSessionStatus('');
    setRequiredFilds([]);
  }, [location.pathname]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSessionStatus('Enviando ...');
    setRequiredFilds([]);
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    if (formType.type === 'forgot-password') {
      // const forgotPasswordResponse = await forgotPassword({
      //   email: data.email,
      // });

      // if (forgotPasswordResponse.error) {
      //   setRequiredFilds(forgotPasswordResponse.fields || []);
      //   setSessionStatus(forgotPasswordResponse.message);
      //   return;
      // }

      // setSessionStatus(forgotPasswordResponse.message);
      // setRequiredFilds(forgotPasswordResponse.fields || []);
      return;
    }

    if (formType.type === 'register') {
      if (data['password'] !== data['password-repeat']) {
        setRequiredFilds([
          { 'password-repeat': 'Las contraseñas no coinciden' },
        ]);
        setSessionStatus('Las contraseñas no coinciden');
        return;
      }
      const registerResponse = await registerAccount({
        email: data.email,
        name: data.name,
        password: data.password,
      });

      if (registerResponse.error) {
        setRequiredFilds(registerResponse.fields || []);
        setSessionStatus(registerResponse.message);
        return;
      }

      setSessionStatus(registerResponse.message);
      setRequiredFilds(registerResponse.fields || []);
      sessionStorage.setItem('firstTimeLogged', true);
    }

    const sessionResponse = await login({
      email: data.email,
      password: data.password,
    });

    if (sessionResponse.error) {
      setRequiredFilds(sessionResponse.fields || []);
      setSessionStatus(sessionResponse.message);
      return;
    }

    sessionResponse.token &&
      localStorage.setItem('token', sessionResponse.token);
    setSessionStatus(sessionResponse.message);
    setRequiredFilds(sessionResponse.fields || []);

    history.push(`/v1/${version}/chat`);
    return;
  };

  return (
    <FormWraper>
      <LoginFormRoot onSubmit={handleSubmit}>
        <FullLogoColorDark>
          <SimpleColorIcon alt="" src="/v1/swarm/Swarm-Loader.png" />
          <GoodDesignIs>AutoSales Proposal</GoodDesignIs>
        </FullLogoColorDark>

        <WelcomeMessageWrapper>
          <Title>{formType.title}</Title>
        </WelcomeMessageWrapper>

        <Textfieldoutlined>
          {formFields.map((field) => (
            <LoginFormInput
              requiredFilds={requiredFilds}
              setRequiredFilds={setRequiredFilds}
              id={field.id}
              name={field.name}
              placeholder={field.placeholder}
              type={field.type}
            />
          ))}
        </Textfieldoutlined>

        <LabelPasswordRemember htmlFor="remember-password">
          <Checkbox
            type="checkbox"
            id="remember-password"
            name="remember-password"
          />
          <label></label>
          <span>Recuérdame</span>
        </LabelPasswordRemember>

        {formType.type === 'login' && (
          <RigthAlignContainer>
            <NavLink to={`/v1/${version}/forgot-password`}>
              <BlueStyledText>¿Olvidaste tu contraseña?</BlueStyledText>
            </NavLink>
          </RigthAlignContainer>
        )}

        {formType.type === 'forgot-password' && (
          <CenterAlignContainer>
            Ingresa tu correo y te mandaremos un link para recuperarla.
          </CenterAlignContainer>
        )}

        <Buttons>
          <Button type="submit">{formType.buttonText}</Button>
        </Buttons>

        <CenterAlignContainer>
          <>
            {(() => {
              switch (formType.type) {
                case 'register':
                  return (
                    <>
                      <p>¿Ya tienes cuenta?</p>
                      <NavLink to={`/v1/${version}/login`}>
                        <BlueStyledText>Inicia sesión</BlueStyledText>
                      </NavLink>
                    </>
                  );
                case 'login':
                  return (
                    <>
                      <p>¿No tienes cuenta?</p>
                      <NavLink to={`/v1/${version}/register`}>
                        <BlueStyledText>Crear cuenta</BlueStyledText>
                      </NavLink>
                    </>
                  );
                case 'forgot-password':
                  return <></>;
                default:
                  return <></>;
              }
            })()}
          </>
        </CenterAlignContainer>

        {sessionStatus && <StatusMessage>{sessionStatus}</StatusMessage>}
      </LoginFormRoot>
    </FormWraper>
  );
};

export default SessionForm;
