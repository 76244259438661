import styled from 'styled-components';
import { ArrowDownward } from '@material-ui/icons';

const ArrowdownRoot = styled.a`
  width: 85px;
  border-radius: 50px;
  background-color: #29529f;
  height: 85px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .arrowDownward {
    color: #fff;
    font-size: 40px;
  }
`;

const ArrowDown = ({ children, ...props }) => {
  return (
    <ArrowdownRoot {...props}>
      <ArrowDownward className="arrowDownward" />
    </ArrowdownRoot>
  );
};

export default ArrowDown;
