import { useEffect, useState, useCallback, Suspense } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import NewProyectButton from './NewProyectButton';
import {
  deleteHistoryChat,
  fetchHistorySanitized,
  fetchThreads,
  renameHistoryChat,
} from '../services/swarm.service';
import DeleteIcon from '@material-ui/icons/Delete';
import { Edit } from '@material-ui/icons';
import NavbarIcon from './NavbarIcon';
import Navbar from './Navigation/Navbar';
import { v4 } from 'uuid';
import useVersionExtractor from '../hooks/useExtractVersionFromPath';
import useNotification from '../hooks/useNotification';

const load = keyframes`
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
`;

const LoaderSkeleton = styled.div`
  position: relative;
  width: 100%;
  height: 18px;
  background-color: #9a9a9a;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
  transition: all 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0.7),
      transparent
    );
    animation: ${load} 2s infinite;
  }
`;

const Reciente = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 28.49px;
`;

const ProposalBuilder = styled.div`
  display: flex;
  padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
  align-items: center;
  gap: var(--spacing-md, 8px);
  align-self: stretch;
  color: var(--NEUTRAL-WHITE, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;

const PropuestaCliente = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #fff;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-size: 16px;
  line-height: 28.49px;
  font-weight: 600;
  cursor: pointer;
`;
const InputChangeName = styled.input`
  display: none;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  line-height: 28.49px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
`;
const IconsWrapper = styled.div`
  display: none;
  position: absolute;
  right: 14px;
  align-items: center;
  min-width: 36px;
  box-sizing: border-box;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80%;

  .delete-icon,
  .edit-icon {
    min-width: 18px;
  }

  .delete-icon {
    cursor: pointer;
    color: #d1d5db;
    transition: all 0.5s ease;

    &:hover {
      color: #c1494a;
    }
  }

  .edit-icon {
    cursor: pointer;
    color: #d1d5db;
    transition: all 0.5s ease;

    &:hover {
      color: #00aa58;
    }
  }
`;

const PropuestaClienteWrapper = styled.div`
  position: relative;
  display: flex;
  align-self: stretch;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 14px;
  min-height: 40px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &:hover {
    background-color: #1d4ed8;

    ${IconsWrapper} {
      display: flex;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const RoundedDot = styled.span`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #17b26a;
`;

const RecienteParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 24px;
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
  background: #0e1d39;

  @media screen and (max-width: 642px) {
    display: none;
    padding-top: 0px;
    padding-bottom: 0px;
    box-sizing: border-box;
  }
`;

const SidebarNav = styled.nav`
  padding-top: var(--spacing-4xl, 32px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 80px;
  align-self: stretch;
  border-right: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
`;

const NavElement = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: var(--spacing-md, 8px);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--Colors-Text-text-primary, #1f2937);
  font-size: 16px;
  font-weight: 600;

  &:hover {
    background-color: var(--Colors-Background-background-secondary, #f9fafb);
  }
`;

const SidebarRoot = styled.div`
  align-self: stretch;
  background-color: #0e1d39;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 24px;
  box-sizing: border-box;
  gap: 24px 0px;
  min-width: 260px;
  max-width: 300px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  overflow-y: auto;
  @media screen and (max-width: 642px) {
    display: none;
    padding-top: 0px;
    padding-bottom: 0px;
    box-sizing: border-box;
  }
`;

const Sidebar = ({
  setMessages,
  setThreadId,
  setIsFinished,
  threads,
  setThreads,
  setIsLoading,
  isLoading,
  threadId,
  setInitialAnswer,
  setIdSession,
  conversationId,
  setConversationId,
  ...props
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [recentThreads, setRecentThreads] = useState([]);
  const [threadsLast7Days, setThreadsLast7Days] = useState([]);
  const [threadsLastMonth, setThreadsLastMonth] = useState([]);
  const [showProyectHistory, setShowProyectHistory] = useState(true);
  const history = useHistory();
  const version = useVersionExtractor();

  useEffect(() => {
    setIsFetching(true);
    const getThreadsFromSessionStorage = async () => {
      let threads = [];
      try {
        threads = await fetchThreads({ version });
      } catch (error) {
        console.error('Error al obtener los hilos', error);
      }
      return threads;
    };

    getThreadsFromSessionStorage().then((threads) => {
      setThreads(threads);
      const now = new Date();
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(now.getDate() - 7);
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(now.getMonth() - 1);

      const recent = threads
        .filter((thread) => new Date(thread.CREATED_AT) > sevenDaysAgo)
        .sort((a, b) => new Date(b.CREATED_AT) - new Date(a.CREATED_AT));
      const last7Days = threads
        .filter(
          (thread) =>
            new Date(thread.CREATED_AT) <= sevenDaysAgo &&
            new Date(thread.CREATED_AT) > oneMonthAgo
        )
        .sort((a, b) => new Date(b.CREATED_AT) - new Date(a.CREATED_AT));
      const lastMonth = threads
        .filter((thread) => new Date(thread.CREATED_AT) <= oneMonthAgo)
        .sort((a, b) => new Date(b.CREATED_AT) - new Date(a.CREATED_AT));

      setRecentThreads(recent);
      setThreadsLast7Days(last7Days);
      setThreadsLastMonth(lastMonth);
      setIsFetching(false);
    });
  }, [setThreads]);

  const handleRenameSidebarElement = ({ threadId, name }) => {
    setRecentThreads((prev) =>
      prev.map((thread) => {
        if (thread.threadId === threadId) {
          return { ...thread, chat_name: name };
        }
        return thread;
      })
    );

    setThreadsLast7Days((prev) =>
      prev.map((thread) => {
        if (thread.threadId === threadId) {
          return { ...thread, chat_name: name };
        }
        return thread;
      })
    );

    setThreadsLastMonth((prev) =>
      prev.map((thread) => {
        if (thread.threadId === threadId) {
          return { ...thread, chat_name: name };
        }
        return thread;
      })
    );
  };

  const handleDeleteSidebarElement = ({ threadId }) => {
    setRecentThreads((prev) =>
      prev.filter((thread) => thread.threadId !== threadId)
    );
    setThreadsLast7Days((prev) =>
      prev.filter((thread) => thread.threadId !== threadId)
    );
    setThreadsLastMonth((prev) =>
      prev.filter((thread) => thread.threadId !== threadId)
    );
  };

  const handleChangeConversation = async ({ threadId }) => {
    try {
      // setThreadId(threadId);
      // setMessages([]);
      // const response = await fetchHistorySanitized({ version, threadId });
      // setMessages(response);
      history.push(`/v1/${version}/proposal/${threadId}`);
    } catch (error) {
      console.error('Error al obtener el historial', error);
    } finally {
      setIsFinished(true);
      setIsLoading(false);
    }
  };

  const handleNewProyect = useCallback(() => {
    setConversationId('');
    setThreadId('');
    setInitialAnswer('');
    setIdSession(v4());
    setMessages([]);
    setIsFinished(false);
    setIsLoading(false);
  }, [setConversationId, setThreadId, setInitialAnswer, setIdSession]);

  const removeFromSession = ({ threadId }) => {
    deleteHistoryChat({ version, threadId });
    const newHistory = threads.filter((item) => item.threadId !== threadId);
    setThreads(newHistory);
    handleDeleteSidebarElement({ threadId });
  };

  const renameChat = async ({ threadId, name }) => {
    try {
      if (name) {
        await renameHistoryChat({ version, threadId, name });
        const newThreads = threads.map((thread) => {
          if (thread.threadId === threadId) {
            return { ...thread, chat_name: name };
          }
          return thread;
        });
        setThreads(newThreads);

        handleRenameSidebarElement({ threadId, name });
      }

      return;
    } catch (error) {
      console.error('Error al renombrar el chat', error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleEditName = ({ threadId }) => {
    const div = document.getElementById(`div-${threadId}`);
    const input = document.getElementById(`input-${threadId}`);
    try {
      div.style.display = 'none';
      input.style.display = 'block';
      input.focus();

      input.addEventListener('blur', () => {
        renameChat({ threadId, name: input.value });
        div.style.display = 'flex';
        input.style.display = 'none';
      });

      input.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          renameChat({ threadId, name: input.value });
          div.style.display = 'flex';
          input.style.display = 'none';
        }
      });
    } catch (error) {
      console.error('Error al renombrar el chat', error);
    }

    return () => {
      div.style.display = 'flex';
      input.style.display = 'none';
    };
  };

  function renderThreads(threads) {
    return threads.map((thread, index) => (
      <PropuestaClienteWrapper key={index} disabled={isLoading}>
        <PropuestaCliente
          onClick={() => {
            if (!isLoading) {
              return handleChangeConversation({ threadId: thread.id });
            }
          }}
          name={thread.name}
          id={`div-${thread.id}`}
          value={thread.name}
        >
          {thread.name}

          {threadId === thread.id && <RoundedDot />}
        </PropuestaCliente>
        <InputChangeName
          type="text"
          name="chat_name"
          id={`input-${thread.id}`}
          defaultValue={thread.name}
        />
        <IconsWrapper>
          <DeleteIcon
            className="delete-icon"
            onClick={() => removeFromSession({ threadId: thread.id })}
          />
          <Edit
            className="edit-icon"
            onClick={() => handleEditName({ threadId: thread.id })}
          />
        </IconsWrapper>
      </PropuestaClienteWrapper>
    ));
  }

  return (
    <SidebarWrapper>
      {/* <Navbar setShowProyectHistory={setShowProyectHistory} /> */}
      {showProyectHistory && (
        <SidebarRoot>
          <ProposalBuilder>PROPOSAL BUILDER</ProposalBuilder>
          <NewProyectButton onClick={handleNewProyect} />

          <Suspense fallback={<LoaderSkeleton />}>
            {isFetching ? (
              <>
                <LoaderSkeleton />
                <LoaderSkeleton />
                <LoaderSkeleton />
              </>
            ) : (
              <RecienteParent>
                <Reciente>Recientes</Reciente>
                {renderThreads(recentThreads)}
                {threadsLast7Days.length > 0 && (
                  <>
                    <Reciente>Últimos 7 días</Reciente>
                    {renderThreads(threadsLast7Days)}
                  </>
                )}
                {threadsLastMonth.length > 0 && (
                  <>
                    <Reciente>Último mes</Reciente>
                    {renderThreads(threadsLastMonth)}
                  </>
                )}
              </RecienteParent>
            )}
          </Suspense>
        </SidebarRoot>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;
