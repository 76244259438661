import styled from 'styled-components';
import propTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import SVGComponent from './SVGComponent';

const NavbarIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 48px;
  height: 48px;
  cursor: pointer;
`;

const NavbarIcon = ({ src, name, ...rest }) => {
  const location = useLocation();
  const isActive = location.pathname.includes(name);
  return (
    <NavbarIconWrapper isActive={isActive}>
      <Link to={rest.to}>
        <SVGComponent
          src={src}
          strokeColor={isActive ? '#00AA58' : '#ffffff'}
        />
      </Link>
    </NavbarIconWrapper>
  );
};

NavbarIcon.propTypes = {
  src: propTypes.string,
  name: propTypes.string,
  to: propTypes.string,
};

export default NavbarIcon;
