import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 150px;
  padding: 200px 10% 0;
  color: white;
  max-width: 1250px;
  margin: 0 auto;
  h3 {
    margin: 0;
    font-size: 28px;
    line-height: 33px;
  }
  @media (max-width: 768px) {
    padding: 60px 20px 0;
    gap: 55px;
    p {
      font-size: 16px;
    }
  }
`;

export const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  p {
    max-width: 600px;
    margin-bottom: 50px;
  }
  img {
    box-sizing: border-box;
  }
  h1 {
    font-size: 55px;
    font-weight: 700;
    line-height: 65px;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    img {
      max-width: 100%;
      padding: 50px 20px 0;
    }
    h1 {
      font-size: 39px;
      line-height: 46px;
    }
    p {
      max-width: 600px;
      margin: 0px 0 10px;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Subtitle = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  h2 {
    font-size: 40px;
    line-height: 30px;
    margin: 0;
  }
  .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    font-size: 30px;
    line-height: 33px;
    overflow: hidden;
    border-right: 0.15em solid orange;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: 0.15em;
    animation: typing 3.5s steps(40, end) infinite,
      blink-caret 0.75s step-end infinite;
  }
  @keyframes typing {
    0% {
      width: 0;
    }
    80%,
    100% {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: orange;
    }
  }
  @media (max-width: 768px) {
    h2 {
      font-size: 31px;
      line-height: 36px;
    }
    span {
      all: unset;
      font-size: 31px;
      line-height: 36px;
    }
  }
`;

export const BulletOne = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    img {
      max-width: 150px;
    }
    h3 {
      font-size: 20px;
      line-height: 23px;
      font-weight: 700;
    }
  }
`;

export const BulletTwo = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    text-align: center;
    img {
      max-width: 150px;
    }
    h3 {
      font-size: 20px;
      line-height: 23px;
      font-weight: 700;
    }
  }
`;

export const Testimonies = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  .company {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      max-width: 150px;
      margin-bottom: 20px;
    }
    span {
      width: 100%;
      text-align: center;
    }
    .name {
      font-weight: 700;
      margin: 0;
      font-size: 1rem;
      text-align: center;
    }
  }
  .bullet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 50px;
    padding-bottom: 30px;
    span {
      max-width: 380px;
    }
    .division {
      height: 250px;
      width: 5px;
      background: white;
    }
  }
  @media (max-width: 768px) {
    gap: 30px;
    margin-bottom: 32px;
    h2 {
      text-align: center;
    }
    .bullet {
      flex-direction: column;
      padding-bottom: 0;
      padding: 10px;
      box-sizing: border-box;
      .division {
        height: 5px;
        width: 250px;
        background: white;
      }
      .title {
        width: 100%;
      }
      .testimony {
        text-align: center;
      }
    }
  }
`;
