import styled, { keyframes } from 'styled-components';

export const DividerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: var(--Colors-Border-border-secondary, rgba(234, 236, 240, 1));
  height: 1px;
  margin-bottom: 20px;
`;

export const TextContainer = styled.div`
  p:first-child {
    margin: 0;
    font-size: 35px;
    font-weight: 500;
    line-height: 42px;
    text-align: left;
    margin-bottom: 10px;
  }

  p:nth-child(2) {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: left;
    margin-bottom: 20px;
  }
`;

export const FormWraper = styled.div`
  gap: 16px;
  width: 100%;
  max-width: 1376px;
  min-height: 100vh;
  background-color: #0e1d39;
  padding: 30px 25px 0px 25px;
  color: #fff;
  font-family: "DM Sans";
`;

export const CenterMainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DocTextContainer = styled.div`
  display: flex;
  gap: 4px;

  p:first-child {
    margin: 0px 0px 0px 0px;
    color: rgba(34, 64, 217, 1);
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
    text-align: left;
  }

  p:nth-child(2) {
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
  }
`;

export const DocTextContainerGB = styled.div`
  p:first-child {
    margin: 0px 0px 0px 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }
`;

export const LoginFormRoot = styled.form`
  flex: 1;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(76, 78, 100, 0.22);
  border: 1px solid #fff;
  background-color: ${(props) => (props.isDragging ? "rgb(23 29 60)" : "none")};
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 15px;
  gap: 6px;
  /* min-width: 300px;
  max-width: 400px; */
  text-align: left;
  font-size: 32.8px;
  color: #fff;
  cursor: pointer;
`;

export const CardMainContainerDocs = styled.div`
  margin-bottom: 19px;
`;

export const CardDocumentLoaded = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0px 15px 0px;
  padding: 10px;
  border-radius: 10px;
  border: 2px 0px 0px 0px;
  opacity: 0px;
  border: ${(props) => {
    if (props.error) {
        return "1.5px solid #ea3737";
    } else if (!props.docUploaded) {
        return "1.5px solid rgba(154, 154, 154, 1)";
    } else {
        return "2px solid rgba(0, 170, 88, 1)";
    }
  }};

  line-height: 16.8px;
  text-align: left;

  div p:first-child {
    margin: 0px 0px 0px 12px;
    font-weight: 700;
    font-size: 14px;
  }

  div p:nth-child(2) {
    margin: 0px 0px 0px 12px;
    font-weight: 400;
    font-size: 14px;
  }

  &.new {
    animation: popIn 0.5s;
  }

  @keyframes popIn {
    from {
      transform: scale(0.9);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingCircle = styled.div`
  border: 4px solid  ${props => props.color || 'rgba(255, 168, 0, 1)'};
  border-top: 4px solid var(--Colors-Background-bg-tertiary, rgba(242, 244, 247, 1));
  border-radius: 50%;
  width: ${props => props.width || '32px'};
  height: ${props => props.height || '32px'};
  animation: ${spin} 1.4s linear infinite;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
`;

export const CardTable = styled.div`
  display: flex;
  flex-direction: column;
  width: Fill (1, 376px) px;
  height: Hug (641px) px;
  margin-bottom: 20px;
  gap: 0px;
  border-radius: var(--radius-xl);
  border: 2px 0px 0px 0px;
  opacity: 0px;
  border: 2px solid rgba(34, 64, 217, 1);
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

export const ScrollableContainer = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  max-height: 400px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* &::-webkit-scrollbar-button {
    display: block;
    height: 10px;
    background: #888;
  }

  &::-webkit-scrollbar-button:vertical:decrement {
    border-top: 2px solid #555;
  }

  &::-webkit-scrollbar-button:vertical:increment {
    border-bottom: 2px solid #555;
  } */
`;
export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;

  th,
  td {
    padding: 16px 16px 16px 16px;
  }

  th {
    position: sticky;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #4caf50;
    color: white;
    background: rgba(57, 82, 128, 1);
    top: 0;
    z-index: 2;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1.5px;
      background-color: var(--Colors-Border-border-secondary, rgba(234, 236, 240, 1));
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--Colors-Border-border-secondary, rgba(234, 236, 240, 1));
    }
  }

  tr:not(:last-child) td, p:not(.no-border):not(:last-child) {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    border-bottom: 1px solid var(--Colors-Border-border-secondary, rgba(234, 236, 240, 1));
  }

  p.no-border {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }

  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
`;

export const ComponentTopTable = styled.div`
  margin: 0px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;

export const TextDescriptionTable = styled.div`
  p:first-child {
    margin: 0px;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
  }

  p:nth-child(2) {
    margin: 0px;
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
  }
`;

export const DownloadButtonCSV = styled.button`
  width: 155px;
  height: 40px;
  padding: 10px 14px;
  gap: 6px;
  border: none;
  background: ${props => !props.disabled ? '#596fdf' : 'rgba(34, 64, 217, 1)'};
  border: 1px solid ${props => !props.disabled ? '#596fdf' : 'rgba(34, 64, 217, 1)'};
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => !props.disabled ? 'not-allowed' : 'pointer'};
`;

export const ErrorIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-color: #ea3737;
`;

export const CheckBoxDocument = styled.input.attrs((props) => ({
  type: "checkbox",
  checked: props.checked,
}))`
  position: relative;
  width: 16px;
  height: 16px;
  padding: 2px 0px 0px 0px;
  gap: 0px;
  border-radius: var(--spacing-xs);
  opacity: 0px;

  background: rgba(0, 170, 88, 1);
  -webkit-appearance: none;
  -moz-appearance: none;

  &:checked {
    background: rgba(0, 170, 88, 1);
    border: 1px solid rgba(0, 170, 88, 1);
    border-radius: 2px;
  }

  &:checked::before {
    content: "";
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    display: block;
    width: 3.5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
  }
`;
