import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 150px;
  padding: 200px 10% 0;
  color: white;
  max-width: 1250px;
  margin: 0 auto;
  h2 {
    margin: 0;
  }
  @media (max-width: 768px) {
    padding: 60px 20px 0;
    gap: 70px;
  }
`;

export const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  p {
    max-width: 600px;
    margin-bottom: 50px;
  }
  img {
    box-sizing: border-box;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    img {
      max-width: 100%;
    }
    p {
      max-width: 600px;
      margin: 10px 0 30px;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Services = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  gap: 70px;
  .bullet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 50px;
    div {
      max-width: 350px;
      h3 {
        margin: 15px 0;
      }
      ul {
        all: inherit;
        padding-left: 20px;
      }
      li {
        margin: 5px 0;
      }
    }
  }
  @media (max-width: 768px) {
    gap: 30px;
    .bullet {
      flex-direction: column;
      gap: 20px;
      div {
        h3 {
          font-size: 20px;
          font-weight: 700;
        }
      }
      img {
        max-height: 160px;
      }
      margin-bottom: 30px;
    }
  }
`;

export const Steps = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  .bullet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 50px;
    padding-bottom: 30px;
    .title {
      font-weight: 700;
      margin: 0;
      font-size: 1rem;
      width: 50%;
    }
    span {
      max-width: 380px;
    }
    .division {
      height: 60px;
      width: 5px;
      background: white;
    }
  }
  @media (max-width: 768px) {
    gap: 30px;
    h2 {
      text-align: center;
    }
    .bullet {
      padding-bottom: 0;
      border: 2px solid blue;
      padding: 10px;
      box-sizing: border-box;
      .title {
        width: 100%;
      }
    }
  }
`;

export const Contact = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  p {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
`;
