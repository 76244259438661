import { useState, useEffect } from 'react';

const useNotificationPermission = () => {
  const [permission, setPermission] = useState(Notification.permission);

  useEffect(() => {
    if (permission === 'default') {
      Notification.requestPermission().then((newPermission) => {
        setPermission(newPermission);
      });
    }
  }, [permission]);

  const requestPermission = () => {
    Notification.requestPermission().then((newPermission) => {
      setPermission(newPermission);
    });
  };

  return [permission, requestPermission];
};

export default useNotificationPermission;
