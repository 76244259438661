import Carousel from 'react-material-ui-carousel';
import styled from 'styled-components';

export const CustomersCarousel = () => {
  const Imgs = [
    { src: 'marcasLogos/coru.svg', alt: 'coru' },
    { src: 'marcasLogos/vexi.svg', alt: 'vexi' },
    { src: 'marcasLogos/creze.svg', alt: 'creze' },
    { src: 'marcasLogos/solvo.svg', alt: 'solvo' },
    { src: 'marcasLogos/danisofom.svg', alt: 'danisofom' },
    { src: 'marcasLogos/unitam.svg', alt: 'unitam' },
    { src: 'marcasLogos/patriare.svg', alt: 'patria re' },
    { src: 'marcasLogos/innohub.svg', alt: 'innohub' },
    { src: 'marcasLogos/tribal.svg', alt: 'tribal' },
    { src: 'marcasLogos/wedder.svg', alt: 'wedder' },
    { src: 'marcasLogos/numina.svg', alt: 'numina' },
    { src: 'marcasLogos/solventatech.svg', alt: 'solventatech' },
    { src: 'marcasLogos/lend.svg', alt: 'lend' },
    { src: 'marcasLogos/collado.svg', alt: 'collado' },
    { src: 'marcasLogos/avanttia.svg', alt: 'avanttia' },
    { src: 'marcasLogos/larus.svg', alt: 'larus' },
    { src: 'marcasLogos/life.svg', alt: 'life' },
    { src: 'marcasLogos/dr.svg', alt: 'dr' },
    { src: 'marcasLogos/amipass.svg', alt: 'amipass' },
    { src: 'marcasLogos/ahorraseguros.svg', alt: 'ahorraseguros' },
    { src: 'marcasLogos/seos.svg', alt: 'seos' },
    { src: 'marcasLogos/principal.svg', alt: 'principal' },
    { src: 'marcasLogos/heybilly.svg', alt: 'heybilly' },
    { src: 'marcasLogos/promas.svg', alt: 'grupo promass' },
    { src: 'marcasLogos/claim.svg', alt: 'claims' },
    { src: 'marcasLogos/xsglobal.svg', alt: 'xs global' },
    { src: 'marcasLogos/lockton.svg', alt: 'lockton' },
    { src: 'marcasLogos/beneficia.svg', alt: 'beneficia' },
    { src: 'marcasLogos/ballena.svg', alt: 'ballena' },
    { src: 'marcasLogos/master.svg', alt: 'master' },
    { src: 'marcasLogos/lumo.svg', alt: 'lumo' },
    { src: 'marcasLogos/chib.svg', alt: 'chib' },
  ];

  const chunkArray = (arr, size) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size));
    }
    return chunks;
  };

  const width = window.screen.width;
  let mobile = false;
  if (width <= 769) {
    mobile = true;
  }

  return (
    <>
      {mobile ? (
        <Carousel
          activeIndicatorIconButtonProps={{
            style: { color: 'blue' },
          }}
          animation='slide'
          duration={3000}
          interval={4000}
        >
          {chunkArray(Imgs, 6).map((chunk, index) => (
            <>
              <MobileContainer>
                {/* Mapear cada imagen dentro del subarray */}

                {chunk.map((image, i) => (
                  <ImgContainer>
                    <img key={i} src={image.src} alt={image.alt} />
                  </ImgContainer>
                ))}
              </MobileContainer>
            </>
          ))}
          {/* <MobileContainer>
            <ImgContainer>
              <img src={Imgs[0].src} />
            </ImgContainer>
            <ImgContainer>hola</ImgContainer>
            <ImgContainer>hola</ImgContainer>
            <ImgContainer>hola</ImgContainer>
            <ImgContainer>hola</ImgContainer>
            <ImgContainer>hola</ImgContainer>
          </MobileContainer> */}
          {/* <div className='bullet' style={{ textAlign: 'center' }}>
            <img
              src='CustomersM1.svg'
              alt='Customers'
              style={{ maxWidth: '100%' }}
            />
          </div>
          <div className='bullet' style={{ textAlign: 'center' }}>
            <img
              src='CustomersM2.svg'
              alt='Customers'
              style={{ maxWidth: '100%' }}
            />
          </div>
          <div className='bullet' style={{ textAlign: 'center' }}>
            <img
              src='CustomersM3.svg'
              alt='Customers'
              style={{ maxWidth: '100%' }}
            />
          </div>
          <div className='bullet' style={{ textAlign: 'center' }}>
            <img
              src='CustomersM4.svg'
              alt='Customers'
              style={{ maxWidth: '100%' }}
            />
          </div>
          <div className='bullet' style={{ textAlign: 'center' }}>
            <img
              src='CustomersM5.svg'
              alt='Customers'
              style={{ maxWidth: '100%' }}
            />
          </div>
          <div className='bullet' style={{ textAlign: 'center' }}>
            <img
              src='CustomersM6.svg'
              alt='Customers'
              style={{ maxWidth: '100%' }}
            />
          </div> */}
        </Carousel>
      ) : (
        <Carousel
          activeIndicatorIconButtonProps={{
            style: { color: 'blue' },
          }}
          animation='slide'
          duration={3000}
          interval={4000}
          style={{ paddingBottom: '25px' }}
        >
          {chunkArray(Imgs, 5).map((chunk, index) => (
            <>
              <DeskContainer>
                {/* Mapear cada imagen dentro del subarray */}

                {chunk.map((image, i) => (
                  <ImgContainer>
                    <img key={i} src={image.src} alt={image.alt} />
                  </ImgContainer>
                ))}
              </DeskContainer>
            </>
          ))}
          {/* <div className='bullet' style={{ textAlign: 'center' }}>
            <img
              src='Customers1.svg'
              alt='Customers'
              style={{ maxWidth: '100%' }}
            />
          </div>
          <div className='bullet' style={{ textAlign: 'center' }}>
            <img
              src='Customers2.svg'
              alt='Customers'
              style={{ maxWidth: '100%' }}
            />
          </div>
          <div className='bullet' style={{ textAlign: 'center' }}>
            <img
              src='Customers3.svg'
              alt='Customers'
              style={{ maxWidth: '100%' }}
            />
          </div>
          <div className='bullet' style={{ textAlign: 'center' }}>
            <img
              src='Customers4.svg'
              alt='Customers'
              style={{ maxWidth: '100%' }}
            />
          </div>
          <div className='bullet' style={{ textAlign: 'center' }}>
            <img
              src='Customers5.svg'
              alt='Customers'
              style={{ maxWidth: '100%' }}
            />
          </div>
          <div className='bullet' style={{ textAlign: 'center' }}>
            <img
              src='Customers6.svg'
              alt='Customers'
              style={{ maxWidth: '100%' }}
            />
          </div>
          <div className='bullet' style={{ textAlign: 'center' }}>
            <img
              src='Customers7.svg'
              alt='Customers'
              style={{ maxWidth: '100%' }}
            />
          </div> */}
        </Carousel>
      )}
    </>
  );
};

const MobileContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  gap: 32px;
  min-height: 200px;
  padding: 29px 32px;
`;

const DeskContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: auto;
  display: flex;
  gap: 64px;
  padding: 64px 120px;
  min-height: 200px;
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-grow: 1;
  @media (min-width: 770px) {
    img {
      width: 100%;
      max-height: 60px;
    }
  }
`;
