import React from 'react';
import styled from 'styled-components';
import {
  FrameDiv,
  Semana,
  Tiempo,
  S,
  TiempoDeRespuestaContainer,
  GroupParent,
  FrameParent3,
  PerformanceParent,
} from '../../pages/Dashboard/DashboardStyles';

const Svg = styled.svg`
  width: 70px;
  height: 60px;
  position: relative;
`;

const Path = styled.path`
  fill: none;
  stroke: ${(props) => props.stroke || 'tomato'};
  stroke-width: ${(props) => props.strokeWidth || '5'};
  stroke-linecap: round;
`;

const NeedlePath = styled.path`
  fill: #2240d9;
  stroke: #2240d9;
  transform-origin: 50% 75%;
  transform: ${(props) => `rotate(${props.angle}deg)`};
`;

const NeedleCircle = styled.circle`
  fill: #2240d9;
`;

const SmallCircle = styled.circle`
  fill: #0e1d39;
`;

const GaugeChart = ({ mock = {} }) => {
  const newValue = mock.seconds >= 31 ? 0 : 100 - (mock.seconds * 100) / 30;
  const radius = 30;
  const circumference = Math.PI * radius;
  const strokeDasharray = `${
    (newValue / 100) * circumference
  } ${circumference}`;

  const angle = (newValue / 100) * 180 - 90; // Ajustar la fórmula para calcular el ángulo

  return (
    <>
      {/* <Card>
          <Semana>Consultas en la semana</Semana>

          <PeriodChart />
        </Card> */}
      <PerformanceParent>
        <Semana>Performance</Semana>
        <FrameParent3>
          <GroupParent>
            {/* <GaugeChart /> */}{' '}
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Svg>
                <Path
                  stroke='#eee'
                  strokeWidth='5'
                  d={`M 5 45 A ${radius} ${radius} 0 0 1 65 45`}
                />
                <Path
                  stroke='#00AA58'
                  strokeWidth='5'
                  d={`M 5 45 A ${radius} ${radius} 0 0 1 65 45`}
                  strokeDasharray={strokeDasharray}
                />
                <NeedleCircle cx='35' cy='45' r='6.75911' />

                <NeedlePath d='M 30 45 L 35 25 L 40 45 Z' angle={angle} />
                <SmallCircle cx='35' cy='45' r='3' />
              </Svg>
            </div>
            <S>{mock.seconds ? `${mock.seconds.toFixed(1)} s` : ''}</S>
          </GroupParent>
          <TiempoDeRespuestaContainer>
            <Tiempo>Tiempo</Tiempo>
            <Tiempo>de respuesta</Tiempo>
            <Tiempo>promedio</Tiempo>
          </TiempoDeRespuestaContainer>
        </FrameParent3>
      </PerformanceParent>
    </>
  );
};

export default GaugeChart;
