import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  padding-bottom: 135px;
  @media screen and (max-width: 1600px) {
    padding-bottom: 80px;
  }
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 0;
    padding: 254px 104px 0 ;
    gap: 80.34px;
    @media screen and (max-width: 1600px) {
        padding: 160px 92px 0 ;
        gap: 40.17px;
    }
`

export const TitleContent = styled.div`
    display: flex;
    height: auto;
    margin: 0;
    justify-content: center;
`


export const Title = styled.h1`
  font-family: Teko;
    margin: 0;


/* Title/H1 - regular */
font-style: normal;
font-weight: 400;
font-size: 220px;
line-height: 70%;
/* identical to box height, or 154px */
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.03em;

/* Main/white */
color: #FFFFFF;


`;

export const AiTitle = styled.span`
    font-family: Teko;
    display: contents;
  font-style: normal;
  font-weight: 400;
  font-size: 220px;
  line-height: 70%;
  /* identical to box height, or 154px */
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;

  /* Main/green */
  color: #a6f674;
`

export const SubTitle = styled.h3`
    margin: 0;
    /* discover how our AI solutions can transform your business operations and outcomes. */

width: 507px;
height: 150px;

/* Title/H3 - regular */
font-family: Teko;
font-style: normal;
font-weight: 400;
font-size: 56px;
line-height: 90%;
/* or 50px */
letter-spacing: 0.01em;

/* Main/white */
color: #FFFFFF;


`

export const AiSubTitle = styled.span`
/* discover how our AI solutions can transform your business operations and outcomes. */

width: 507px;
height: 150px;

/* Title/H3 - regular */
font-family: Teko;
font-style: normal;
font-weight: 400;
font-size: 56px;
line-height: 90%;
/* or 50px */
letter-spacing: 0.01em;

/* Main/green */
color: #A6F674;
margin: 0;
`

export const ImageHeaderWrapper = styled.div`
    padding: 0px 240px;
    box-sizing: border-box;
    @media screen and (max-width: 1600px) {
        padding: 0px 60px;
    }
`

export const ImageHeader = styled.img`
    width: 100%;
    object-fit: contain;
    border-radius: 40px;
    margin-top: 105px;

    @media screen and (max-width: 1600px) {
        /* height: 650px; */
        margin-top: 80px;
    }
    /* height: 20px; */
`

export const DescrptionHeaderWrapper = styled.div`
    width: 100%;
    padding: 0px 240px;
    margin-top: 120px;
    display: flex;
    justify-content: space-between; 
    @media screen and (max-width: 1600px) {
        margin-top: 80px;
        padding: 0px 92px;
    }
`

export const FirstDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 41px;
    @media screen and (max-width: 1600px) {
        gap: 30px;
    }
`
export const FirstDescription = styled.p`
margin: 0;
/* Our agency offers personalized, collaborative service tailored to each client's specific needs, ensuring successful implementation of technological solutions. */

width: 505.91px;
/* height: 136px; */

/* Body/p1 - regular */
font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 140%;
/* or 34px */

/* Main/white */
color: #FFFFFF;
`

export const SecondDescription = styled.p`
margin: 0;
/* Consultative development: Strategic consulting and expert software development for seamless, valuable solutions. */

width: 505.8px;
height: 250px;

/* Title/H3 - regular */
font-family: 'Teko';
font-style: normal;
font-weight: 400;
font-size: 56px;
line-height: 90%;
/* or 50px */
letter-spacing: 0.01em;

/* Main/white */
color: #FFFFFF;


`

export const Btn = styled.button`
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  background-color: #a6f674;
  width: 197px;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 15px;
`;
export const TextBtn = styled.span`
  margin: 0;
  /* Talk to us */

/* width: 86px; */
height: 20px;

font-family: 'IBM Plex Sans';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 140%;
/* identical to box height, or 20px */
display: flex;
align-items: center;
letter-spacing: 0.08em;
text-transform: uppercase;

/* Main/dark navy */
color: #050E2A;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;

`;

export const VectorParent = styled.div`
     display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
`

export const Arrowimg = styled.img``

export const LineImg = styled.img``